type TypeIconRefresh = {
  width?: string;
  height?: string;
  color?: string;
};
export default function IconRefresh(props: TypeIconRefresh) {
  const {
    width = "16",
    height = "16",
    color = "var(--mui-palette-primary-main)",
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M28 16C28.0003 19.1549 26.7582 22.1829 24.5425 
        24.4288C22.3268 26.6746 19.3158 27.9576 16.1612 
        28H16C12.9353 28.0076 9.98535 26.8348 7.7625 24.725C7.66705 
        24.6347 7.59031 24.5265 7.53667 24.4066C7.48303 24.2866 
        7.45354 24.1573 7.44989 24.026C7.44623 23.8946 7.46848 
        23.7639 7.51536 23.6411C7.56224 23.5184 7.63284 23.4061 
        7.72312 23.3106C7.81341 23.2152 7.92161 23.1384 8.04155 
        23.0848C8.16149 23.0312 8.29082 23.0017 8.42216 22.998C8.55349 
        22.9943 8.68427 23.0166 8.807 23.0635C8.92974 23.1104 9.04205 
        23.181 9.1375 23.2712C10.5672 24.6196 12.3626 25.5168 14.2993 
        25.8507C16.236 26.1847 18.2281 25.9405 20.0269 25.1489C21.8256 
        24.3572 23.3512 23.053 24.4131 21.3993C25.475 19.7456 26.026 
        17.8157 25.9974 15.8507C25.9688 13.8856 25.3619 11.9725 24.2523 
        10.3504C23.1428 8.72835 21.5799 7.46914 19.7589 6.73012C17.9379 
        5.9911 15.9395 5.80504 14.0133 6.19518C12.0872 6.58532 10.3187 
        7.53436 8.92875 8.92375C8.91854 8.93479 8.90769 8.94522 8.89625 
        8.955L5.57375 12H9C9.26522 12 9.51957 12.1054 9.70711 12.2929C9.89464 
        12.4804 10 12.7348 10 13C10 13.2652 9.89464 13.5196 9.70711 13.7071C9.51957 
        13.8946 9.26522 14 9 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 
        13.5196 2 13.2652 2 13V7C2 6.73478 2.10536 6.48043 2.29289 6.29289C2.48043 
        6.10535 2.73478 6 3 6C3.26522 6 3.51957 6.10535 3.70711 6.29289C3.89464 6.48043 
        4 6.73478 4 7V10.725L7.53125 7.5C9.21123 5.82655 11.3493 4.68833 13.6757 
        4.22903C16.002 3.76974 18.4122 4.00995 20.6022 4.91935C22.7921 5.82876 24.6635 
        7.36657 25.9801 9.33868C27.2968 11.3108 27.9996 13.6288 28 16Z"
        fill={color}
      />
    </svg>
  );
}
