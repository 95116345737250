import {Card, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconLogout from "@/assets/icon/IconLogout.tsx";
import {WrapFormOrderType} from "@/features/guest/types";
/**
 *
 * @param props
 */
export  function WrapFormOrder(props: WrapFormOrderType) {
  const {isSignedIn, onOpenLogin, children} = props
  return (
    <Box maxWidth="md" sx={{ width: '100%' }}  style={{
      paddingLeft: 0,
      paddingRight: 0,
    }}>
      <Card sx={{ display: 'flex', flexDirection: 'column', gap: {
        xs: '24px',
        sm: '29px '
      }, paddingY: {
        xs: '10px',
        sm: '40px'
      },
      boxShadow: {
        xs: "unset !important",
        sm: "0px 0px 9px 0px #00000024 !important"
      },
      borderRadius: '5px'
      , paddingX: {
        xs: '10px',
        sm: '50px'
      } }} >
        <Box display="flex" justifyContent="space-between" width="100%" sx={{
          flexDirection: {
            xs: 'column-reverse',
            sm: 'row'
          },
          gap: {
            xs: '10px',
          }
        }}>
          <Typography display="flex" alignItems="center" fontWeight={600} fontSize={16} sx={{
            paddingX: {
              xs: '18px',
              sm: 0
            }
          }}>
                        商品のお届け先住所を入力してください。
          </Typography>
          {!isSignedIn && (
            <div style={{display: 'flex', justifyContent: 'end'}}>
              <Button
                onClick={onOpenLogin}
                sx={{
                  maxWidth: 112,
                  borderRadius: "4px",
                  height: "35px",
                }}
                variant="outlined"
              >
                <IconLogout
                  color="var(--Button-outlinedBorder)"
                  width="15"
                  height="15"
                  style={{
                    transform: 'rotateY(180deg)'
                  }}
                />
                <Typography sx={{ marginLeft: "10px", display: {
                  xs: 'none',
                  sm: 'block'
                } }} fontSize="12px" fontWeight={600}>
                                    ログイン
                </Typography>
              </Button>
            </div>
          )}
        </Box>
        {children}
      </Card>
    </Box>
  )
}