import { CART_KEY, FAVORITE_IMAGES_KEY, HIDDEN_IMAGES_KEY } from "../constant/constant.ts";
import { User } from "../types/user";
const storagePrefix = "STK_";
export const storage = {
  getToken: () => window.localStorage.getItem(`${storagePrefix}token`) ?? "",
  setToken: (token: string) =>
    window.localStorage.setItem(`${storagePrefix}token`, token),
  getEventToken: () =>
    window.localStorage.getItem(`${storagePrefix}event_token`) ?? "",
  setEventToken: (token: string) =>
    window.localStorage.setItem(`${storagePrefix}event_token`, token),
  getEventRefreshToken: () =>
    window.localStorage.getItem(`${storagePrefix}event_refresh_token`) ?? "",
  setEventRefreshToken: (token: string) =>
    window.localStorage.setItem(`${storagePrefix}event_refresh_token`, token),
  clearUserEvent: () => {
    window.localStorage.removeItem(`${CART_KEY}`);
    window.localStorage.removeItem(`${FAVORITE_IMAGES_KEY}`);
    window.localStorage.removeItem(`${HIDDEN_IMAGES_KEY}`);
    window.localStorage.removeItem(`${storagePrefix}event_token`);
    window.localStorage.removeItem(`${storagePrefix}event_refresh_token`);
  },
  clearUser: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
    window.localStorage.removeItem(`${storagePrefix}user`);
  },
  getProfileUser: () =>
    JSON.parse(window.localStorage.getItem(`${storagePrefix}user`) ?? "{}"),
  setProfileUser: (user: User) =>
    window.localStorage.setItem(`${storagePrefix}user`, JSON.stringify(user)),
};
