import { Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Plus } from "@phosphor-icons/react";
type Props = {
  onClick?: () => void;
  isInCart?: boolean;
};
function ButtonBuyPhoto({ onClick, isInCart }: Props) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack alignItems="center" width="100%">
      <Button
        variant={isInCart ? "outlined" : "contained"}
        fullWidth
        endIcon={
          <Plus size={15} style={{ fontWeight: 700, marginLeft: "10px" }} />
        }
        onClick={onClick}
        sx={{ width: isXs ? "100%" : "300px", height: "40px" }}
      >
        {isInCart ? "カートから削除する" : "カートに入れる"}
      </Button>
    </Stack>
  );
}
export default ButtonBuyPhoto;
