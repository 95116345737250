import {
  Box,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
import { paths } from "@/common/constant/paths";
interface ListItem {
  title?: string;
  subtitle?: string;  
  description: Array<string>;
}
const PClistItem = [
  {
    title: "<<PCの場合>>",
    subtitle: "■Wnidows",
    description:
      ["・Google Chrome 最新版", "Microsoft Edge 最新版"]
  },
  {
    subtitle: "■macOS",
    description:
      ["・Google Chrome 最新版", "Safari 最新版"]
  },
  {
    title: "<<スマーフォンの場合>>",
    subtitle: "■iOS",
    description:
      ["・Google Chrome 最新版"]
  },
  {
    subtitle: "■Android",
    description:
      ["・Google Chrome 最新版", "Safari 最新版"]
  },
] satisfies ListItem[];
const Others = [
  {
    title: "<<その他ご留意事項>>",
    subtitle: "■JavaScript",
    description:
      ["・当ウェブサイトでは、JavaScriptを使用しています。",
        "　お使いのブラウザでJavaScript機能を無効にされている場合、正しく機能しない、または正しく表示されないことがあります。",
        "　ご覧になる際にはブラウザ設定でJavaScriptを有効にしてください。"
      ]
  },
  {
    subtitle: "■Cookie",
    description:
      ["・当ウェブサイトでは、Cookieを使用しています",
        "　Cookieを無効にされている場合、ウェブサイトの一部機能がご利用いただけないことがあります。",
        "　ご覧になる際にはブラウザ設定でCookieを有効にしてください。"
      ]
  },
  {
    subtitle: "■画面解像度",
    description:
      ["・1024×768ピクセル以上"]
  },
  {
    subtitle: "■プラグイン",
    description:
      ["・当ウェブサイトでは、以下のプラグインを使用しています。", "　Adobe Reader (PDFファイルの閲覧)、その他"]
  },
  {
    subtitle: "■補足",
    description:
      ["・当ウェブサイトは、常時SSL/TLSによる暗号化通信に対応しています。",
        "　推奨環境以外でのご利用や、推奨環境下でもお客様のブラウザの設定によっては、正しく表示されない場合がございます。ご了承ください。",
        "　※上記はあくまで推奨環境であり、記載以外のブラウザでもご利用いただける場合がございます。",
        "　最新のブラウザは、セキュリティやパフォーマンスの面で優れているため、常に最新版にアップデートすることをお勧めいたします。",
        "　ご利用の環境についてご不明な点がございましたら、お問い合わせください。"
      ]
  },
] satisfies ListItem[];
function  CheckUenv() {
  return (
    <Box>
      <Breadcumbs
        breadcrumbs={[
          { title: "トップ", href: paths.folder.list },
          { title: "推奨動作環境" },
        ]}
      />
      <Container sx={{ mt: 7, mb: 3, maxWidth: "500px" }}>
        <Typography textAlign="left" variant="h4" mt={2}>
        推奨動作環境
        </Typography>
      </Container>
      <Grid item md={12} xs={12} pb={2}>
        <Container sx={{ mb: 10, maxWidth: "500px" }}>
          <Card
            sx={{
              border: "1px solid var(--mui-palette-secondary-300)",
              borderRadius: 1,
            }}
          >
            <Stack >
              {PClistItem.map((item) => (
                <Box px={4} pt={5} pb={1}>
                  {item.title && (
                    <Typography fontWeight={600} fontSize={18}>
                      {item.title}
                    </Typography>
                  )}
                  <Box>
                    <Typography fontWeight={600} fontSize={18}>
                      {item.subtitle}
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Typography color="text.secondary">
                      {item?.description.join("、")}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Stack>
            <Stack mt={1}>
              {Others.map((item) => (
                <Box px={4} pt={3} pb={3}>
                  <Typography fontWeight={600} fontSize={18}>
                    {item.title}
                  </Typography>
                  <Box>
                    <Typography fontWeight={600} fontSize={18}>
                      {item.subtitle}
                    </Typography>
                  </Box>
                  <Box mt={0}>
                    <Typography color="text.secondary">
                      {item?.description.map((desc) => (
                        <Typography>{desc}</Typography>
                      ))} 
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Card>
        </Container>
      </Grid>
    </Box>
  );
}
export default CheckUenv;
