import {useState} from "react";
import HttpClientUserEvent from "@/common/api/HttpClientUserEvent";
import {USER_ITEM_CART, USER_ITEM_CART_BY_PHOTO_ID} from "@/common/constant/apiConstant";
import {NOT_SUCCESS_RESPONSE_ERROR} from "@/common/constant/constant.ts";
const useRemoveItemCart = () => {
  const [error] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const removeItemCart = async (cartItemId: number | string) => {
    setLoading(true);
    try {
      const response = await HttpClientUserEvent.Delete({
        path: `${USER_ITEM_CART}/${cartItemId}`
      })
      if (response.status === 200) {
        return {result: response.data}
      }
      return {error: NOT_SUCCESS_RESPONSE_ERROR}
    } catch (error) {
      return {error}
    } finally {
      setLoading(false);
    }
  }
  const removeItemCartByPhotoId = async (photoId: number) => {
    setLoading(true);
    try {
      const response = await HttpClientUserEvent.Delete({
        path: `${USER_ITEM_CART_BY_PHOTO_ID}/${photoId}`
      })
      if (response.status === 200) {
        return {result: response.data}
      }
      return {error: NOT_SUCCESS_RESPONSE_ERROR}
    } catch (error) {
      return {error}
    } finally {
      setLoading(false);
    }
  }
  return {
    error,
    loading,
    removeItemCart,
    removeItemCartByPhotoId,
  };
};
export default useRemoveItemCart;
