/**
 * ログ出力メソッド
 * @param {unknown} message メッセージ
 * @param {string} type タイプ
 */
const common = (message: unknown, type: string = "info") => {
  if (type === "error") {
    // eslint-disable-next-line no-console -- ログ出力メソッドのため許可
    console.error(message);
  } else if (type === "warn") {
    // eslint-disable-next-line no-console -- ログ出力メソッドのため許可
    console.warn(message);
  } else if (type === "info") {
    // eslint-disable-next-line no-console -- ログ出力メソッドのため許可
    console.log(message);
  } else {
    // eslint-disable-next-line no-console -- ログ出力メソッドのため許可
    console.log(message);
  }
};
/**
 * 環境に応じてログ出力を抑制するメソッド
 * @param {unknown} message メッセージ
 * @param {string} type タイプ
 */
const logger = (message: unknown, type: string = "info") => {
  if (import.meta.env.VITE_ENV === "pro") {
    return;
  }
  common(message, type);
};
/**
 * 全てのログを出力するメソッド（管理画面などログ出力の抑制が不要な場合に使用）
 * @param {unknown} message メッセージ
 * @param {string} type タイプ
 */
const fullLogger = (message: unknown, type: string = "info") => {
  common(message, type);
};
export default {
  logger,
  fullLogger,
};
