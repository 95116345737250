import { useState } from "react";
import HttpClient from "@/common/api/HttpClient";
import { GET_MY_ACCOUNT_MEMBER } from "@/common/constant/apiConstant";
export interface MemberAccount {
  id?: number;
  email: string;
  name: string;
  furigana_name: string;
  post_code: string;
  prefecture_code: number;
  address: string;
  street_address: string;
  building_address?: string;
  phone_number: string;
  is_authenticated?: boolean;
  organization?: string;
  password?: string;
  confirmpassword?: string;
}
export interface DataMemberAccount {
  id?: number;
  email: string;
  name: string;
  furigana_name: string;
  post_code: string;
  prefecture_code: number;
  address: string;
  street_address: string;
  building_address?: string;
  phone_number: string;
  organization?: string;
  confirmpassword?: string
}
const useEditMyAccount = () => {
  const [data, setData] = useState<MemberAccount | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const editAccount = async (params: Partial<MemberAccount>) => {
    const {confirmpassword, ...props} = params
    console.log("confirmpassword: ", confirmpassword);
    setLoading(true);
    try {
      const response = await HttpClient.Put({
        path: GET_MY_ACCOUNT_MEMBER,
        data: props,
      });
      setData(response.data as MemberAccount);
      return response.data;
    } catch (error) {
      setError((error as Error).message);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  return {
    data,
    error,
    loading,
    editAccount,
  };
};
export default useEditMyAccount;
