import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import type { DataMemberAccount } from "@/features/member/auth/MyAccount/api/useEditMyAccount";
import { getPrefectureName } from "@/common/utils/helper";
import { PropertyItem } from "./PropertyItem";
import { PropertyList } from "./PropertyList";
type Props = {
  data?: DataMemberAccount;
};
function ViewMyAccount({ data }: Props) {
  let prefName = "";
  if (data) {
    prefName = getPrefectureName(data.prefecture_code);
  }
  return (
    <Box>
      <CardHeader title="会員情報" />
      <CardContent>
        <Card sx={{ borderRadius: 0 }} variant="outlined">
          <PropertyList
            divider={<Divider />}
            sx={{ "--PropertyItem-padding": "12px 24px" }}
          >
            {(
              [
                {
                  key: "お名前",
                  value: (
                    <Typography variant="subtitle2">
                      {data?.name}
                    </Typography>
                  ),
                },
                {
                  key: "フリガナ",
                  value: (
                    <>
                      <Typography variant="subtitle2">{data?.furigana_name}</Typography>
                    </>
                  ),
                },
                {
                  key: "住所",
                  value: (
                    <Typography variant="subtitle2">
                      {prefName}{data?.address}{data?.street_address}{data?.building_address}
                    </Typography>
                  ),
                },
                {
                  key: "電話",
                  value: (
                    <Typography variant="subtitle2">
                      {data?.phone_number}
                    </Typography>
                  ),
                },
                {
                  key: "メールアドレス",
                  value: (
                    <Typography variant="subtitle2">
                      {data?.email}
                    </Typography>
                  ),
                },
                // {
                //   key: "イベント/パスワード",
                //   value: (
                //     <Typography variant="subtitle2">
                //         ***********
                //     </Typography>
                //   ),
                // },
              ] satisfies {
                key: string;
                value: React.ReactNode;
              }[]
            ).map(
              (item): React.JSX.Element => (
                <PropertyItem
                  key={item.key}
                  name={item.key}
                  value={item.value}
                />
              )
            )}
          </PropertyList>
        </Card>
      </CardContent>
    </Box>
  );
}
export default ViewMyAccount;
