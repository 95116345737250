/* eslint-disable max-lines-per-function */
import React, { MouseEvent, useContext, useEffect, useState } from "react";
import { Button, Box, FormControl, Typography, Stack, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import cartContext from "@/common/context/CartContext";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import ToastMessage from "@/common/utils/ToastUtils";
import { orderStorage } from "@/common/utils/storage/orderStorage";
import VisaIcon from "../icons/VisaIcon";
import BankIcon from "../icons/BankIcon";
import MasterIcon from "../icons/MasterIcon";
import Bank2Icon from "../icons/Bank2Icon";
import CvcIcon from "../icons/CvcIcon";
import { createPaymentIntent } from "../api/createPaymentIntent";
import { updateStatusPaymentOrder } from "../api/updateStatusPaymentOrder";
import { TypeCheckoutForm } from "../types";
function CheckoutForm(props: TypeCheckoutForm) {
  const {orderId} = props
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { resetContext } = useContext(cartContext);
  const [invalidCardNumber, setInvalidCardNumber] = useState(false);
  const [invalidCVC, setInvalidCVC] = useState(false);
  const [invalidExpiration, setInvalidExpiration] = useState(false);
  const [isDisableButton, setIsDisableButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [cardholderName, setCardholderName] = useState<string>("");
  const [isAllEmpty, setIsAllEmpty] = useState({
    cardNumber: true,
    expiration: true,
    cvc: true,
  });
  const onSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
    const data = orderStorage.getOrderForm();
    event.preventDefault();
    if (!stripe || !elements) return;
    const { error: submitError } = await elements.submit();
    if (submitError) {
      return;
    }
    if(!data) return
    try {
      const resSecret = await createPaymentIntent({
        amount: data?.totalPrice,
        order_id: orderId
      });
      const clientSecret = resSecret.client_secrect;
      const cardElement = elements.getElement(CardNumberElement);
      if (!cardElement){
        ToastMessage.showError(
          "支払いに失敗しました。もう一度お試しください"
        );
        return
      }
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details:{
            name: cardholderName
          }
        },
      });
      setIsLoading(true)
      await updateStatusPaymentOrder({
        payment_intent_id: resSecret.payment_intent_id,
        order_id: orderId,
      });
      setIsLoading(false)
      orderStorage.clearOrderForm();
      resetContext();
      setTimeout(() => {
        navigate(`/guest/carts/complete?completeId=${orderId}`, {
          state: { email: data.email || "" },
        });
      });
    } catch (error) {
      ToastMessage.showError("支払いに失敗しました。もう一度お試しください");
    } 
    finally{
      setIsLoading(false)
    }
  };
  const handleChangeCardNumberEl = (e: StripeCardNumberElementChangeEvent): void => {
    const { error, empty } = e;
    if (error) {
      setInvalidCardNumber(true);
      setIsDisableButton(true);
      return;
    }
    if (empty) {
      setIsDisableButton(true);
      setIsAllEmpty({
        ...isAllEmpty,
        cardNumber: true,
      });
      return;
    }
    setIsAllEmpty({
      ...isAllEmpty,
      cardNumber: false,
    });
    if (
      isAllEmpty.cardNumber === false &&
      isAllEmpty.expiration === false &&
      isAllEmpty.cvc === false
    ) {
      setIsDisableButton(false);
    }
    setInvalidCardNumber(false);
  };
  const handleChangeCVCEl = (e: StripeCardCvcElementChangeEvent): void => {
    const { error, empty } = e;
    if (error) {
      setInvalidCVC(true);
      setIsDisableButton(true);
      setIsAllEmpty((prev) => ({
        ...prev,
        cvc: true,
      }));
      return;
    }
    if (empty) {
      setIsDisableButton(true);
      setIsAllEmpty((prev) => ({
        ...prev,
        cvc: true,
      }));
      return;
    }
    setIsAllEmpty((prev) => ({
      ...prev,
      cvc: false,
    }));
    if (
      isAllEmpty.cardNumber === false &&
      isAllEmpty.expiration === false &&
      isAllEmpty.cvc === false
    ) {
      setIsDisableButton(false);
    }
    setInvalidCVC(false);
  };
  const handleChangeExpirationEl = (e: StripeCardExpiryElementChangeEvent): void => {
    const { error, empty } = e;
    if (error) {
      setInvalidExpiration(true);
      setIsDisableButton(true);
      setIsAllEmpty((prev) => ({
        ...prev,
        expiration: true,
      }));
      return;
    }
    if (empty) {
      setIsDisableButton(true);
      setIsAllEmpty((prev) => ({
        ...prev,
        expiration: true,
      }));
      return;
    }
    setIsAllEmpty((prev) => ({
      ...prev,
      expiration: false,
    }));
    if (
      isAllEmpty.cardNumber === false &&
      isAllEmpty.expiration === false &&
      isAllEmpty.cvc === false
    ) {
      setIsDisableButton(false);
    }
    setInvalidExpiration(false);
  };
  const handleChangeCardName = (e: React.ChangeEvent<HTMLInputElement>)=>{
    setCardholderName(e.target.value)
  }
  useEffect(() => {
    if (
      isAllEmpty.cardNumber === false &&
      isAllEmpty.expiration === false &&
      isAllEmpty.cvc === false
    ) {
      setIsDisableButton(false);
    }
  }, [isAllEmpty]);
  if(isLoading) return <CircularProgress size={20} />
  return (
    <form>
      <Box display="flex" flexDirection="column" gap={2}>
        <Stack gap={1}>
          <Typography>カード名義</Typography>
          <FormControl
            fullWidth
            sx={{
              border: "1px solid #e6e6e6",
              padding: "6px",
              borderRadius: "8px",
            }}
          >
            <input style={{ border: "none" }} placeholder="カード名義" onChange={handleChangeCardName}/>
          </FormControl>
        </Stack>
        <Stack>
          <Typography>カード番号</Typography>
          <FormControl
            fullWidth
            sx={{
              border: "1px solid #e6e6e6",
              padding: "10px",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box width="100%">
              <CardNumberElement onChange={handleChangeCardNumberEl} />
              {invalidCardNumber && (
                <Typography style={{ color: '#eb1c26', fontSize: 14, marginLeft: 2 }}>
                  カード番号が無効です
                </Typography>
              )}
            </Box>
            <Box display="flex">
              <VisaIcon />
              <MasterIcon />
              <BankIcon />
              <Bank2Icon />
            </Box>
          </FormControl>
          <Box display="flex">
            <FormControl
              fullWidth
              sx={{
                border: "1px solid #e6e6e6",
                padding: "10px",
                borderBottomLeftRadius: "8px",
              }}
            >
              <CardExpiryElement onChange={handleChangeExpirationEl} />
              {invalidExpiration && (
                <Typography style={{ color: '#eb1c26', fontSize: 14, marginLeft: 2 }}>
                  有効期限が無効です
                </Typography>
              )}
            </FormControl>
            <FormControl
              fullWidth
              sx={{
                border: "1px solid #e6e6e6",
                padding: "10px",
                borderBottomRightRadius: "8px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box width="100%">
                <CardCvcElement
                  options={{ placeholder: "CVC" }}
                  onChange={handleChangeCVCEl}
                />
                {invalidCVC && (
                  <Typography style={{ color: '#eb1c26', fontSize: 14, marginLeft: 2 }}>
                    CVC が無効です
                  </Typography>
                )}
              </Box>
              <CvcIcon />
            </FormControl>
          </Box>
        </Stack>
        <Box textAlign="center" mt={1}>
          <Button
            variant="contained"
            type="submit"
            onClick={onSubmit}
            disabled={!stripe || !elements || isDisableButton}
          >
            支払いカード情報を入力する
          </Button>
        </Box>
      </Box>
    </form>
  );
}
export default CheckoutForm;
