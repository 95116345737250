export const EVENT_STATUS: { [key: string]: string } = {
  PRE_SALE: "販売予定",
  ON_SALE: "販売中",
  RE_SALE: "再販売中",
  SALE_ENDED: "販売終了"
};
export const SHORT_DAYS: string[] = [
  "SUN",
  "MON",
  "TUE",
  "WED",
  "THU",
  "FRI",
  "SAT",
];
export const LIST_ITEM_CALENDAR: { [key: string]: string }[] = [
  { title: "非公開在庫数", name: "unPublicStorageOfDate" },
  { title: "公開在庫数", name: "publicStorageOfDate" },
  { title: "予約数", name: "bookingOfDate" },
  { title: "人数", name: "numberOfCustomerOfDate" },
];
export const STATUS_INVENTORY = {
  published: { title: "公開済", backgroundColor: "#F99EA5" },
  unpublished: { title: "未公開", backgroundColor: "#594D46" },
  "out of stock": { title: "在庫なし", backgroundColor: "#DDDDDD" },
};
export const ROW_PER_PAGE = 20;
export const VIEW_MODE_OPTION = {
  SMALL: 'small'
}