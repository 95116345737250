/* eslint-disable max-lines-per-function */
import * as React from "react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  IconButton,
  Link as MuiLink,
  Stack,
  Tooltip,
} from "@mui/material";
import { List as ListIcon } from "@phosphor-icons/react/dist/ssr/List";
import { RouterLink } from "@/common/components/Link/link";
import authContext from "@/common/context/AuthContext";
import { navColorStyles } from "@/features/guest/Layout/styles";
import { NavColor } from "@/common/types/settings";
import { MainNavProps } from "@/common/constant/type";
import IconGuide from "@/assets/icon/IconGuide";
import IconInquiry from "@/assets/icon/IconInquiry";
import { Question } from "@phosphor-icons/react";
import { ColorScheme } from "@/features/guest/styles/theme/types";
import { Logo } from "./logo";
import { paths } from "../../constant/paths";
import MobileNav from "./MobileNav";
import IconTitle from "../Icon/IconTitle";
const logoColors: Record<ColorScheme, Record<NavColor, "dark" | "light">> = {
  dark: { blend_in: "light", discrete: "light", evident: "light" },
  light: { blend_in: "dark", discrete: "dark", evident: "light" },
};
const stackStyle = {
  alignItems: "center",
  flex: "1 1 auto",
  justifyContent: "flex-end",
  display: { xs: "none", md: "flex" },
};
export function MainNavLogin({
  color = "blend_in",
  items = [],
  isAuthen = false,
}: MainNavProps): React.JSX.Element {
  const navigate = useNavigate();
  const [openNav, setOpenNav] = useState<boolean>(false);
  const colorScheme: ColorScheme = "light";
  const styles = navColorStyles[colorScheme][color];
  const logoColor = logoColors[colorScheme][color];
  const {
    isSignedIn,
    isUserEventSignedIn,
    setIsOpenAuthUser,
  } = useContext(authContext);
  const setOpenLoginPopup = () => {
    setIsOpenAuthUser(true);
  };
  const cssStyle = {
    ...styles,
    // bgcolor: "var(--MainNav-background)",
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: "var(--MainNav-zIndex)",
    height: {
      xs: "auto",
      md: "86px",
      lg: "86px"
    },
    overflow: "hidden",
    margin: "0 auto",
    display: "flex",
    border: {
      xs: "none",
      md: "2px solid transparent",
      lg: "2px solid transparent"
    },
    borderRadius: "20px",
    backgroundImage:{
      xs:"none",
      md :
      // eslint-disable-next-line max-len
      "linear-gradient(#fff, #fff), linear-gradient(90.47deg, #FA566B -2.5%, #FD7862 17.9%, #FE895D 33.3%, #FFC758 46.32%, #4CC6D7 64.22%, #E64FB8 75.79%, #FA566B 97.22%)",
      lg:
      // eslint-disable-next-line max-len
      "linear-gradient(#fff, #fff), linear-gradient(90.47deg, #FA566B -2.5%, #FD7862 17.9%, #FE895D 33.3%, #FFC758 46.32%, #4CC6D7 64.22%, #E64FB8 75.79%, #FA566B 97.22%)",
    },
    backgroundOrigin: "border-box",
    backgroundClip: "padding-box, border-box",
    padding:{
      xs:"0 10px",
      md:"0 60px",
      lg: "0 60px"
    }
  };
  const handleNavigate = () => {
    navigate("/member/myaccount");
  };
  return (
    <>
      <Box
        component="header"
        paddingX={3}
        sx={{
          marginTop: {
            xs: "20px",
            md: "60px",
            lg: "60px",
          },
        }}
      >
        <Grid
          container
          spacing={2}
          xs={12}
          md={10}
          lg={8}
          maxWidth="xl"
          marginX="auto"
          display="flex"
          height="100%"
          sx={cssStyle}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: "center", flex: "1 1 auto" }}
          >
            <Box
              justifyContent="space-between"
              width="100%"
              flexDirection="row"
              sx={{ display: { lg: "none", md: "none", xs: "flex" } }}
            >
              <Logo color={logoColor} height={27} width={200} />
              <IconButton
                onClick={() => setOpenNav(true)}
                sx={{
                  backgroundColor: "var(--MainNav-background)",
                  borderRadius: "5px",
                }}
              >
                <ListIcon color="var(--mui-palette-primary-main)" />
              </IconButton>
            </Box>
            <Box
              component={RouterLink}
              href={paths.home}
              sx={{ display: { xs: "none", md: "inline-block" } }}
            >
              <Logo color={logoColor} />
            </Box>
          </Stack>
          <Stack direction="row" spacing={2} sx={stackStyle}>
            {isUserEventSignedIn &&
              (!isSignedIn ? (
                <Box>
                  <MuiLink
                    component="button"
                    color="var(--NavItem-color)"
                    onClick={() => setOpenLoginPopup()}
                  >
                    ログイン
                  </MuiLink>
                </Box>
              ) : (
                <MuiLink
                  component="button"
                  color="var(--NavItem-color)"
                  onClick={handleNavigate}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                    }
                  }}
                >
                  マイページ
                </MuiLink>
              ))}
            {isUserEventSignedIn && (
              <Tooltip title="アカウント情報を登録すると、購入時の項目入力ステップが省略できます">
                <Question size={20} color="var(--NavItem-icon-color)" />
              </Tooltip>
            )}
            <IconTitle
              title="利用ガイド"
              icon={<IconGuide />}
              onClick={() => navigate("/usage-guide")}
            />
            <IconTitle
              title="お問い合わせ"
              icon={<IconInquiry />}
              onClick={() => navigate("/contacts")}
            />
          </Stack>
        </Grid>
      </Box>
      <MobileNav
        isAuthen={isAuthen}
        items={items}
        open={openNav}
        onClose={() => setOpenNav(false)}
      />
    </>
  );
}
export default MainNavLogin;
