import React, { useState } from "react";
import { Eye as EyeIcon } from "@phosphor-icons/react/dist/ssr/Eye";
import { EyeSlash as EyeSlashIcon } from "@phosphor-icons/react/dist/ssr/EyeSlash";
import TextFieldForm, { TProps } from "./TextFieldForm.tsx";
function PasswordInput (props: TProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <TextFieldForm
      {...props}
      type={showPassword ? "text" : "password"}
      endAdornment={
        showPassword ? (
          <EyeIcon
            cursor="pointer"
            fontSize="var(--icon-fontSize-md)"
            onClick={(): void => {
              setShowPassword(false);
            }}
          />
        ) : (
          <EyeSlashIcon
            cursor="pointer"
            fontSize="var(--icon-fontSize-md)"
            onClick={(): void => {
              setShowPassword(true);
            }}
          />
        )
      }
    />
  );
};
export default PasswordInput;
