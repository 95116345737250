import React from "react";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
import authContext from "@/common/context/AuthContext";
import {FormOrderInfo} from "./components";
/**
 *
 */
function OrderInfo() {
  const { setIsOpenAuthUser } = React.useContext(authContext);
  /**
   *
   */
  const onOpenLogin = () => {
    setIsOpenAuthUser(true);
  };
  return (
    <>
      {/* <Box gap={1} display="flex" flexDirection="column" sx={{ */}
      {/*  marginBottom: { */}
      {/*    xs: 3, */}
      {/*    sm: "35px", */}
      {/*  }, */}
      {/* }}> */}
      {/*  <Breadcumbs */}
      {/*    breadcrumbs={[ */}
      {/*      { title: "トップ" }, */}
      {/*      { title: "カート" }, */}
      {/*      { title: "お届け先情報入力" }, */}
      {/*    ]} */}
      {/*  /> */}
      {/* </Box> */}
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop={2}>
        <Box maxWidth="742px" display="flex" flexDirection="column" width="100%" sx={{
          gap: {
            xs: '20px',
            sm: "29px",
          },
        }}>
          <Typography variant="h3" textAlign="left" fontWeight={600} sx={{
            fontSize: {
              xs: '22px',
              sm: '24px'
            }
          }}>
                  お届け先情報入力
          </Typography>
          <FormOrderInfo onOpenLogin={onOpenLogin} />
        </Box>
      </Box>
    </>
  );
}
export default OrderInfo;
