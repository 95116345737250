import React, { useState } from "react";
import { isNil } from "lodash";
import ListDataHistory from "./ListDataHistory";
import PopupDetailsHistory from "./PopupDetailsHistory";
function TabOrderHistory() {
  const [indexPreviewHistory, setIndexPreviewHistory] = useState<
    number | undefined
  >(undefined);
  const handleClickDetails = () => {
    setIndexPreviewHistory(2)
  };
  return (
    <>
      <ListDataHistory onClickDetail={handleClickDetails} />
      {!isNil(indexPreviewHistory) && (
        <PopupDetailsHistory
          open={!isNil(indexPreviewHistory)}
          onClose={() => setIndexPreviewHistory(undefined)}
        />
      )}
    </>
  );
}
export default TabOrderHistory;
