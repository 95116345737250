type TypeIconGuide = {
  width?: string | number;
  height?: string | number;
  color?: string;
};
export default function IconClose(props: TypeIconGuide) {
  const { width = "20", height = "20", color = "#ffff" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M19.2504 3.52543C20.0182 2.75761 
        20.0182 1.51067 19.2504 0.742856C18.4825 -0.0249624 
        17.2356 -0.0249624 16.4678 0.742856L9.99967 7.2171L3.52543 
        0.748998C2.75761 -0.01882 1.51067 -0.01882 0.742856 
        0.748998C-0.0249624 1.51682 -0.0249624 2.76375
         0.742856 3.53157L7.2171 9.99967L0.748999 16.4739C-0.0188197 
         17.2417 -0.0188197 18.4887 0.748999 19.2565C1.51682 20.0243 
         2.76375 20.0243 3.53157 19.2565L9.99967 12.7822L16.4739 
         19.2503C17.2417 20.0182 18.4887 20.0182 19.2565 19.2503C20.0243 
         18.4825 20.0243 17.2356 19.2565 16.4678L12.7822 9.99967L19.2504 
         3.52543Z"
        fill={color}
      />
    </svg>
  );
}
