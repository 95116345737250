import {
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  BoxProps,
} from "@mui/material";
import { ChangeEvent } from "react";
import {PaymentMethod} from "@/features/guest/pages/payment/types";
export function RadioMethodPayment({
  methodSelected,
  onChange,
  listMethods
}: {
  methodSelected?: number;
  onChange: (e:ChangeEvent<HTMLInputElement>) => void;
  listMethods: PaymentMethod[]
}) {
  return (
    <RadioGroup
      sx={{
        "& .MuiFormControlLabel-root": {
          border: "1px solid var(--mui-palette-divider)",
          borderRadius: 1,
          gap: 2,
          p: 2,
          position: "relative",
          "&::before": {
            borderRadius: "inherit",
            bottom: 0,
            content: '" "',
            left: 0,
            pointerEvents: "none",
            position: "absolute",
            right: 0,
            top: 0,
          },
        },
        "&.Mui-disabled": {
          bgcolor: "var(--mui-palette-background-level1)",
        },
      }}
      value={methodSelected}
      onChange={onChange}
    >
      {listMethods.map((method: PaymentMethod) => (
        <FormControl
          component="fieldset"
          name="method-of-payment"
          sx={{ width: "100%" }}
        >
          <FormControlLabel
            control={<Radio />}
            key={method.id}
            label={
              <div>
                <Typography variant="inherit" fontWeight={600} fontSize={16}>
                  {method.name}
                </Typography>
                <Typography color="#757575" variant="body2" fontWeight={300}>
                  {method.explaination}
                </Typography>
              </div>
            }
            sx={{
              ...(methodSelected === method.id && {
                "&::before": {
                  boxShadow: "0 0 0 2px var(--mui-palette-primary-main)",
                },
              }),
            } as BoxProps}
            value={method.id}
          />
        </FormControl>
      ))}
    </RadioGroup>
  );
}
