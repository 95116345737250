type TypeIconZoom = {
  width?: string;
  height?: string;
  color?: string;
};
export default function IconZoomOut(props: TypeIconZoom) {
  const {
    width = "16",
    height = "16",
    color = "var(--mui-palette-primary-main)",
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_1534_3979)">
        <path
          d="M15.2809 1.78145C15.5746 1.4877 15.5746 
          1.0127 15.2809 0.722072C14.9871 0.431447 
          14.5121 0.428322 14.2215 0.722072L10.0027 4.94082L9.99961 
          2.2502C9.99961 1.83457 9.66523 1.5002 9.24961 1.5002C8.83398 
          1.5002 8.49961 1.83457 8.49961 2.2502V6.7502C8.49961 7.16582 
          8.83398 7.5002 9.24961 7.5002H13.7496C14.1652 7.5002 14.4996 
          7.16582 14.4996 6.7502C14.4996 6.33457 14.1652 6.0002 13.7496 
          6.0002H11.059L15.2809 1.78145ZM0.718359 14.2189C0.424609 
          14.5127 0.424609 14.9877 0.718359 15.2783C1.01211 15.5689 
          1.48711 15.5721 1.77773 15.2783L5.99648 11.0596V13.7502C5.99648 
          14.1658 6.33086 14.5002 6.74648 14.5002C7.16211 14.5002 7.49648 
          14.1658 7.49648 13.7502V9.2502C7.49648 8.83457 7.16211 8.5002 
          6.74648 8.5002H2.24961C1.83398 8.5002 1.49961 8.83457 1.49961 
          9.2502C1.49961 9.66582 1.83398 10.0002 2.24961 
          10.0002H4.94023L0.718359 14.2189Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1534_3979">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
