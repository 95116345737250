import {useContext, useEffect, useState} from "react";
import HttpClientEvent from "@/common/api/HttpClientEvent";
import HttpClientUserEvent from "@/common/api/HttpClientUserEvent";
import authContext from "@/common/context/AuthContext";
import type {PhotoData} from "../types";
const useGetPhotoDetails = (
  {
    folderId,
    hiddenId
  }: {
    folderId?: string;
    hiddenId?: string;
  } = {}
) => {
  const {isSignedIn} = useContext(authContext);
  const [data, setData] = useState<PhotoData[]>();
  const [param, setParam] = useState({page: 1, rowsPerPage: 24, folderId})
  const [totalPage, setTotalPage] = useState<number>(0);
  const [total, setTotal] = useState<number>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const handleChangePagination = (page: number) => {
    setParam(prev=>({...prev, page}))
  }
  const getListPhotoByFolder = async (page = param.page) => {
    if(!param.folderId) return;
    setLoading(true);
    try {
      let response;
      if (isSignedIn) {
        response = await HttpClientUserEvent.Get({
          path: `user/event/folder/${param.folderId}/photo`,
          params: {page: page ?? param.page, per_page: param.rowsPerPage},
        });
      } else {
        response = await HttpClientEvent.Get({
          path: `user/event/folder/${param.folderId}/photo`,
          params: {page: page ?? param.page, per_page: param.rowsPerPage, hidden_ids: hiddenId || ""},
        });
      }
      setTotal(response.data.total);
      setData(response.data.data as PhotoData[]);
      setTotalPage(response.data.total_pages);
    } catch (error) {
      setError((error as Error).message);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const reFetch = () => {
    getListPhotoByFolder();
  }
  useEffect(() => {
    getListPhotoByFolder();
  }, [param.page, param.rowsPerPage, isSignedIn]);
  useEffect(() => {
    getListPhotoByFolder(1);
    setParam({
      ...param,
      page: 1
    })
  }, [param.folderId]);
  useEffect(() => {
    setParam((param) => ({...param, folderId}))
  }, [folderId]);
  return {
    data,
    total,
    error,
    loading,
    totalPage,
    currentPage: param.page,
    handleChangePagination,
    reFetch
  };
};
export default useGetPhotoDetails;
