import {
  Box,
  CardContent,
  CardHeader,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, useForm } from "react-hook-form";
import { X as XIcon } from "@phosphor-icons/react/dist/ssr/X";
import { Dispatch } from "react";
import SignInStateEnum from "@/features/guest/auth/login/type";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotSchema } from "@/common/utils/schema";
import OutlineInputField from "@/common/components/TextField/OutlineInputField";
type FormMemberLoginProps = {
  onCancel: () => void;
  onSubmit: (values: { email: string }) => Promise<void>;
  setState: Dispatch<SignInStateEnum>;
  loading?: boolean;
  state: SignInStateEnum;
};
function ForgotPasswordForm({
  onCancel,
  onSubmit,
  loading,
}: FormMemberLoginProps) {
  const methods = useForm<{ email: string }>({
    resolver: yupResolver(forgotSchema),
    defaultValues: {
      email: "",
    },
  });
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <Box
          onClick={onCancel}
          width="100%"
          textAlign="right"
          sx={{ cursor: "pointer" }}
        >
          <XIcon fontSize="24px" />
        </Box>
        <CardHeader
          data-testid="forgot-form-title"
          title={<h3>パスワード再設定</h3>}
        />
        <CardContent>
          <Stack spacing={2}>
            <OutlineInputField
              label="メールアドレス"
              inputProps={{ placeholder: "メールアドレス" }}
              name="email"
            />
            <LoadingButton
              variant="contained"
              type="submit"
              data-testid="forgot-button"
              loading={loading}
            >
              パスワード再設定
            </LoadingButton>
          </Stack>
        </CardContent>
      </form>
    </FormProvider>
  );
}
export default ForgotPasswordForm;
