import React from "react";
import { Box, BoxProps, Chip, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  ArrowSquareOut as ArrowSquareOutIcon,
  CaretDown as CaretDownIcon,
} from "@phosphor-icons/react/dist/ssr";
import { Dropdown } from "../Dropdown/Dropdown";
import { DropdownTrigger } from "../Dropdown/DropdownTrigger";
import { DropdownPopover } from "../Dropdown/DropdownPopover";
import isNavItemActive from "./IsNavItemActive";
import { renderDropdownItems } from "./ultil";
import { RouterLink } from "../Link/link";
import { icons } from "./NavIcons";
import { NavItemProps } from "./type";
/**
 *
 * @param root0
 * @param root0.disabled
 * @param root0.external
 * @param root0.items
 * @param root0.href
 * @param root0.icon
 * @param root0.label
 * @param root0.matcher
 * @param root0.pathname
 * @param root0.title
 * @param root0.count
 */
function NavItem({
  disabled,
  external,
  items,
  href,
  icon,
  label,
  matcher,
  pathname,
  title,
  count,
}: NavItemProps): React.JSX.Element {
  const active = isNavItemActive({
    disabled,
    external,
    href,
    matcher,
    pathname,
  });
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const IconComponent = icon ? icons[icon] : null;
  const isBranch = Boolean(items);
  /**
   *
   */
  const getComponentProps = () => {
    if (isBranch) {
      return { role: "button" };
    }
    if (href) {
      return {
        component: external ? "a" : RouterLink,
        href,
        target: external ? "_blank" : undefined,
        rel: external ? "noreferrer" : undefined,
      };
    }
    return { role: "button" };
  };
  const element = (
    <Box component="li" sx={{ userSelect: "none", flex: isXs ? 1 : "inherit" }}>
      <Box
        {...(getComponentProps() as BoxProps)}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: isXs ? 0 : 1,
          padding: isXs ? "5px" : "6px 16px",
          cursor: disabled ? "not-allowed" : "pointer",
          color: active
            ? "var(--NavItem-active-background)"
            : "var(--NavItem-color)",
          textDecoration: "none",
          fontWeight: 600,
          "&:hover": {
            color:
              !disabled && !active
                ? "var(--NavItem-active-background)"
                : undefined,
            svg: {
              fill: "var(--NavItem-active-background)",
            },
          },
          borderBottom: active
            ? "2px solid var(--NavItem-active-background)"
            : undefined,
          flexDirection: isXs ? "column" : "row",
        }}
        tabIndex={0}
      >
        {IconComponent && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexShrink: 0,
            }}
          >
            <IconComponent
              fill={
                active
                  ? "var(--NavItem-active-background)"
                  : "var(--NavItem-icon-color)"
              }
              fontSize="var(--icon-fontSize-md)"
              weight={active ? "thin" : undefined}
            />
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }} display="flex" justifyContent="center">
          <Typography
            component="span"
            sx={{
              fontSize: isXs ? "12px" : "0.875rem",
              fontWeight: 500,
              lineHeight: "28px",
            }}
          >
            {title}
          </Typography>
          {count && (
            <Chip
              label={count}
              size="small"
              variant="soft"
              sx={{ ml: 1 }}
              color="primary"
              style={{
                position: "absolute",
                transform: "translateX(40px)",
                ...(isXs && { transform: "translate(15px,-30px)" }),
              }}
            />
          )}
        </Box>
        {label && <Chip color="primary" label={label} size="small" />}
        {external && (
          <Box sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}>
            <ArrowSquareOutIcon
              color="var(--NavItem-icon-color)"
              fontSize="var(--icon-fontSize-sm)"
            />
          </Box>
        )}
        {isBranch && (
          <Box sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}>
            <CaretDownIcon fontSize="var(--icon-fontSize-sm)" />
          </Box>
        )}
      </Box>
    </Box>
  );
  if (isBranch) {
    return (
      <Dropdown>
        <DropdownTrigger>{element}</DropdownTrigger>
        <DropdownPopover
          PaperProps={{ sx: { minWidth: "200px", padding: 1 } }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          {renderDropdownItems({ pathname, items })}
        </DropdownPopover>
      </Dropdown>
    );
  }
  return element;
}
export default NavItem;
