import { useContext, useEffect, useState } from "react";
import { Box, Typography, Container } from "@mui/material";
import AuthContext from "@/common/context/AuthContext";
// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
import { orderStorage } from "@/common/utils/storage/orderStorage";
import useLocalStorage from "@/common/hooks/use-localStorage";
import { ICartItem } from "@/common/context/CartContext";
import { CART_KEY } from "@/common/constant/constant.ts";
import useGetListCart from "@/features/guest/api/useGetListCart";
import { ConfirmDetail } from "./components";
type CartItemResponse = {
  photo_id: number;
  pricing_id: number;
  quantity: number;
};
/**
 *
 */
function ConfirmPage (){
  const orderFormData = orderStorage.getOrderForm();
  const { isSignedIn } = useContext(AuthContext);
  const [cartItems] = useLocalStorage<ICartItem[]>(CART_KEY, []);
  const [cartItemsFromDatabase, setCartItemsFromDatabase] = useState<ICartItem[]>([]);
  const { getGetListCart } = useGetListCart();
  useEffect(() => {
    const fetchCart = async () => {
      if (isSignedIn) {
        const result = await getGetListCart();
        if (result?.folders) {
          const cartItems: ICartItem[] = result.folders.flatMap((folder: { cart_items?: CartItemResponse[] }) =>
            folder.cart_items?.map((item: CartItemResponse) => ({
              id: item.photo_id,
              pricing_id: item.pricing_id,
              quantity: item.quantity
            })) || []
          );
          setCartItemsFromDatabase(cartItems);
        }
      }
    };
    fetchCart();
  }, [isSignedIn]);
  return (
    <>
      {/* <Breadcumbs
        breadcrumbs={[
          { title: "トップ" },
          { title: "カート" },
          { title: "お届け先情報" },
          { title: "お支払い選択" },
          { title: "配送方法の選択" },
          { title: "注文内容の確認" },
        ]}
      /> */}
      <Container maxWidth="md">
        <Box sx={{width: 863, mx: "auto", maxWidth: "100%"}} mt={3}>
          <Typography variant="h5" textAlign="left" fontWeight="600">
            注文内容の確認
          </Typography>
          <Typography
            variant="subtitle2"
            textAlign="left"
            fontWeight="300"
            mt={4}
          >
            ご注文内容、合計金額をご確認の上、ページ下部のボタンをクリックして下さい。
          </Typography>
          <Typography
            variant="subtitle2"
            textAlign="left"
            fontWeight="bold"
            sx={{ color: "var(--mui-palette-error-dark)" }}
          >
            ※ご注意：まだご注文は完了しておりません。必ず、ページ下部のボタンをクリックし、注文を確定させてください。
          </Typography>
        </Box>
        <ConfirmDetail
          orderFormData={orderFormData}
          cartItems={isSignedIn ? cartItemsFromDatabase : cartItems}
        />
      </Container>
    </>
  );
};
export default ConfirmPage;