import { isEmpty } from "lodash";
import { RequestSetting } from "@/common/types/httpClient";
import { axiosInstanceUserEvent } from "./Axios";
const baseUrl = import.meta.env.VITE_BACKEND_BASE_API_URL;
const request = async ({
  method,
  path,
  params,
  data,
  headers,
}: RequestSetting & {
  method: string;
}) => {
  let reqHeaders = headers;
  if (isEmpty(reqHeaders)) {
    reqHeaders = {
      "Content-Type": "application/json",
    };
  }
  const response = await axiosInstanceUserEvent({
    url: `${baseUrl}${path}`,
    method,
    headers: reqHeaders,
    data: data ? JSON.stringify(data) : {},
    params,
  });
  return response;
};
const getMethod = async ({ path, params, data, headers }: RequestSetting) =>
  request({ method: "GET", path, params, data, headers });
const postMethod = async ({ path, params, data, headers }: RequestSetting) =>
  request({ method: "POST", path, params, data, headers });
const putMethod = async ({ path, params, data, headers }: RequestSetting) =>
  request({ method: "PUT", path, params, data, headers });
const deleteMethod = async ({ path, params, data, headers }: RequestSetting) =>
  request({
    method: "DELETE",
    path,
    params,
    data,
    headers,
  });
export default {
  baseUrl,
  Request: request,
  Get: getMethod,
  Post: postMethod,
  Put: putMethod,
  Delete: deleteMethod,
};
