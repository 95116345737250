import { Box, Button, Stack } from "@mui/material";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import { useRef } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
interface FilterFaceProps {
  handleFilterFace: (url: string) => void;
}
const MOCK_DATA_FILTER_FACE = [
  {
    img: "https://img.freepik.com/free-photo/handsome-man_144627-14183.jpg",
    title: "Breakfast",
  },
  {
    img: "https://s3.envato.com/files/293120942/ckv-830.jpg",
    title: "Burger",
  },
  {
    img: "https://go-models.com/uploads/page-images/differences-between-female-and-male-models.jpg",
    title: "Camera",
  },
  {
    img: "https://r2.erweima.ai/imgcompressed/img/compressed_71e3bcd0b0524f7846d00bc14f64516a.webp",
    title: "Coffee",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuE96k5r5ozrt5bUG2pYYoBXsiowLUYGf5iA&s",
    title: "Breakfast",
  },
  {
    img: "https://go-models.com/uploads/page-images/differences-between-female-and-male-models.jpg",
    title: "Camera",
  },
  {
    img: "https://r2.erweima.ai/imgcompressed/img/compressed_71e3bcd0b0524f7846d00bc14f64516a.webp",
    title: "Coffee",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuE96k5r5ozrt5bUG2pYYoBXsiowLUYGf5iA&s",
    title: "Breakfast",
  },
];
export default function FilterFace({ handleFilterFace }: FilterFaceProps) {
  const swiperRef = useRef<SwiperType | null>(null);
  const renderSwiperItem = () => {
    const result = MOCK_DATA_FILTER_FACE.map((img) => (
      <SwiperSlide>
        <Box
          height={55}
          sx={{
            border: "2px solid transparent",
            borderRadius: 1,
            cursor: "pointer",
            ":hover": {
              borderColor: "#FA566B",
            },
            overflow: "hidden",
          }}
          onClick={() => handleFilterFace(img.img)}
        >
          <img
            src={img.img}
            alt=""
            style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: 1 }}
          />
        </Box>
      </SwiperSlide>
    ));
    return result;
  };
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Button
        size="small"
        startIcon={<CaretLeft />}
        onClick={() => swiperRef.current?.slidePrev()}
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
          color: "#757575",
          padding: "5px 10px",
        }}
      >
        前
      </Button>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          margin: "auto",
          maxWidth: "calc(100% - 120px)",
        }}
      >
        <Swiper
          spaceBetween={2}
          slidesPerView={1}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Navigation]}
          breakpoints={{
            338: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 5,
            },
            1280: {
              slidesPerView: 6,
            },
          }}
        >
          {renderSwiperItem()}
        </Swiper>
      </Stack>
      <Button
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
          color: "#757575",
          padding: "5px 10px",
        }}
        size="small"
        endIcon={<CaretRight />}
        onClick={() => swiperRef.current?.slideNext()}
      >
        次
      </Button>
    </Stack>
  );
}
