import { Box, Typography } from "@mui/material";
import React from "react";
type AccordionItemProps = {
  title: string;
  content: React.ReactNode;
};
function CustomAccordion({ title, content }: AccordionItemProps) {
  return (
    <Box>
      <Typography fontWeight={500}>{title}</Typography>
      <Box marginTop="10px">{content}</Box>
    </Box>
  );
}
export default CustomAccordion;
