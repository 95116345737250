import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import { listLinkNav, listLinkNavGuest } from "@/features/guest/config";
import { useNavigate } from "react-router-dom";
import { paths } from "@/common/constant/paths";
type Props = {
  isAuthen?: boolean;
};
function Footer({ isAuthen }: Props) {
  const navigate = useNavigate();
  const handleNavigation = (href: string) => {
    if (href === paths.const || href === paths.privacy || href === paths.check) {
      window.location.href = href;
    } else {
      navigate(href);
    }
  };
  return (
    <Container maxWidth="xl" sx={{ minHeight: 76 }}>
      <Divider />
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: isAuthen ? "100%" : 76 }}
        gap={1}
        paddingY={1}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          sx={{ columnGap: 4, rowGap: 1 }}
        >
          {isAuthen ? (
            <>
              {listLinkNav.map((item) => (
                <Typography
                  key={item.title}
                  fontSize={{ md: 14, xs: 12 }}
                  color="text.secondary"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => handleNavigation(item.href)}
                >
                  {item.title}
                </Typography>
              ))}
            </>
          ) : (
            <>
              {listLinkNavGuest.map((item) => (
                <Typography
                  key={item.title}
                  fontSize={{ md: 14, xs: 12 }}
                  color="text.secondary"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => navigate(item.href)}
                >
                  {item.title}
                </Typography>
              ))}
            </>
          )}
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography color="text.secondary" fontSize={14}>
            ©rainbow photo All Rights Reserved.
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
}
export default Footer;
