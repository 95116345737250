type TypeIconGuide = {
  width?: string;
  height?: string;
  color?: string;
}
export default function IconGuide(props: TypeIconGuide) {
  const {width="24", height="32", color="#F67484"} = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 32"
      fill="none"
    >
      <g clipPath="url(#clip0_891_478)">
        <path
          d="M4.66314 2.91728C4.12831 2.91728 
          3.69072 3.35487 3.69072 
          3.88971V27.2279C3.69072 27.7628 
          4.12831 28.2004 4.66314 28.2004H20.222C20.7568 
          28.2004 21.1944 27.7628 21.1944 27.2279V3.88971C21.1944 
          3.35487 20.7568 2.91728 20.222 2.91728H4.66314ZM0.773438 
          3.88971C0.773438 1.74429 2.51773 0 4.66314 0H20.222C22.3674 0 
          24.1117 1.74429 24.1117 3.88971V27.2279C24.1117 29.3734 22.3674 
          31.1176 20.222 31.1176H4.66314C2.51773 31.1176 0.773438 29.3734 
          0.773438 27.2279V3.88971ZM8.06664 7.77941H16.8185C17.6268 
          7.77941 18.2771 8.42972 18.2771 9.23805C18.2771 10.0464 17.6268 
          10.6967 16.8185 10.6967H8.06664C7.25831 10.6967 6.608 10.0464 
          6.608 9.23805C6.608 8.42972 7.25831 7.77941 8.06664 7.77941ZM8.06664 
          13.614H16.8185C17.6268 13.614 18.2771 14.2643 18.2771 15.0726C18.2771 
          15.8809 17.6268 16.5312 16.8185 16.5312H8.06664C7.25831 16.5312 6.608 
          15.8809 6.608 15.0726C6.608 14.2643 7.25831 13.614 8.06664 13.614ZM8.06664 
          19.4485H10.9839C11.7922 19.4485 12.4426 20.0988 12.4426 20.9072C12.4426 
          21.7155 11.7922 22.3658 10.9839 22.3658H8.06664C7.25831 22.3658 6.608 
          21.7155 6.608 20.9072C6.608 20.0988 7.25831 19.4485 8.06664 19.4485Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_891_478">
          <rect
            width="23"
            height="31.1176"
            fill="white"
            transform="translate(0.945312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
