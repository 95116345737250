/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { TypeResponseListFavorite } from "@/features/member/auth/MyPage/types";
import useRemoveItemCart from "@/features/guest/pages/FolderPhotoDetail/apis/useRemoveItemCart";
import useAddItemCart from "@/features/guest/pages/FolderPhotoDetail/apis/useAddItemCart";
import AuthContext from "@/common/context/AuthContext";
import cartContext, {ICartItem} from "@/common/context/CartContext";
import { ImageItem } from "@/features/guest/pages/FolderPhotoDetail";
import useGetListCart from "@/features/guest/api/useGetListCart";
import { TypeListCartItem } from "@/features/guest/types";
import ImageCardComponent from "./ImageCardComponent";
type Props = {
  optionViewMode: { md?: number; sm?: number; xs?: number; lg?: number };
  onClickImage?: () => void;
  images: TypeResponseListFavorite[];
  isShowClose?: boolean;
  isShowAddToCart?: boolean;
  isShowFavorite?: boolean;
  isShowHide?: boolean;
  isFavorite?: boolean;
  onClickFavorite?: () => void;
  onClickHidden?: () => void;
  onClickCart?: () => void;
  onClickClose?: (id: string | number) => void;
}
function ListImageComponent({
  optionViewMode,
  images,
  ...props
}: Props) {
  const {removeItemCart} = useRemoveItemCart();
  const {addItemCart} = useAddItemCart();
  const {isSignedIn: isLoginUser} = useContext(AuthContext);
  const {cartItems, setCartItems, setTotalCartItems, totalCartItems} = useContext(cartContext);
  const [listCartDb, setListCartDb] = useState<TypeListCartItem[]>()
  const {getGetListCart, getGetListPricing} = useGetListCart()
  /**
   *
   * @param id
   * @param listCartDb
   */
  const isInCart = (id: number | string, listCartDb: any)=>{
    if (isLoginUser) {
      return !!listCartDb.find((cart:TypeListCartItem)=>cart?.id === id);
    }
    return !!cartItems?.find((photo) => photo.id === id);
  }
  /**
   *
   * @param item
   */
  const handleAddItemCart = async (item: ICartItem | ImageItem) => {
    const listPricing = await getGetListPricing([item.id])
    const itemCart: ICartItem = {
      id: item.id,
      quantity: 1,
      pricing_id: listPricing?.data?.[0]?.pricings?.[0]?.pricing_id,
    };
    if (isLoginUser) {
      if(isInCart(item.id, listCartDb)){
        await removeItemCart(item.id);
        setTotalCartItems(totalCartItems - 1);
      } else {
        const itemsToSend = [
          {
            photo_id: itemCart.id,
            quantity: 1,
          },
        ];
        await addItemCart({items: itemsToSend});
        setTotalCartItems(totalCartItems + 1);
      }
    } else {
      const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);
      const updatedCartItems = existingItem
        ? cartItems.filter((cartItem) => cartItem.id !== item.id)
        : [...cartItems, itemCart];
      setCartItems(updatedCartItems);
    }
  };
  useEffect(() => {
    if (isLoginUser) {
      /**
       *
       */
      const getListCartDB = async () => {
        const response = await getGetListCart();
        if (response) {
          setListCartDb(response?.folders)
        }
      }
      getListCartDB();
    }
  }, [isLoginUser]);
  return (
    <Grid container spacing={{
      xs: 1,
      sm: 4
    }}>
      {images?.map((image) => (
        <Grid item key={image.id} {...optionViewMode}>
          <ImageCardComponent
            isShowFavorite
            isShowAddToCart
            isShowHide
            cartItems={cartItems}
            image={image}
            onClickCart={handleAddItemCart}
            {...props}
          />
        </Grid>
      ))}
    </Grid>
  );
}
export default ListImageComponent;
