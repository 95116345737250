import { Box, FormControl, InputLabel } from "@mui/material";
import TextFieldForm from "@/common/components/TextField/TextFieldForm.tsx";
export function BuildingFields() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          gap: {
            xs: "16px",
            sm: "19px",
          },
        }}
        width="100%"
      >
        <Box width="100%">
          <FormControl fullWidth>
            <InputLabel
              sx={{ fontWeight: 600, fontSize: 13, color: "#757575" }}
            >
              建物名等
            </InputLabel>
            <TextFieldForm
              name="building"
              placeholder="建物名等"
              inputProps={{
                maxLength: 127,
              }}
              fullWidth
            />
          </FormControl>
        </Box>
      </Box>
    </>
  );
}
