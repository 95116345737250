import { Box, Stack } from "@mui/material";
import { X as XIcon } from "@phosphor-icons/react/dist/ssr/X";
import { TypeContentDetailImage } from "../types";
export function ContentDetailImage(props: TypeContentDetailImage) {
  const { detailImage, setDetailImage } = props;
  return (
    <Stack spacing={1} sx={{ display: "flex", p: 1 }}>
      <Box
        onClick={() => setDetailImage("")}
        width="100%"
        textAlign="right"
        sx={{ cursor: "pointer" }}
      >
        <XIcon fontSize="24px" />
      </Box>
      <Box display="flex" justifyContent="center">
        <img
          src={detailImage}
          alt="Detail"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </Box>
    </Stack>
  );
}
