/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {EyeSlash, Heart, Plus, X} from "@phosphor-icons/react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React from 'react';
import type { PhotoData } from "../types";
interface ImageCardCardProps {
    image: PhotoData;
    isShowClose?: boolean;
    isShowFavorite?: boolean;
    isShowHide?: boolean;
    isFavorite?: boolean;
    onClickImage?: () => void;
    onClickFavorite?: (val: any) => void;
    onClickHidden?: (val: any) => void;
    onClickCart?: (item: any) => void;
    onClickClose?: () => void;
    isInCart?: boolean;
}
export function IconWraper({
  children,
  ...props
}: {
    children: React.ReactNode;
    onClick?: () => void;
}) {
  return <Box
    sx={{ cursor: "pointer" }}
    width={24}
    height={24}
    borderRadius="50%"
    bgcolor="#fff"
    display="flex"
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    {children}
  </Box>
}
function ImageCard({
  image,
  isShowClose,
  isShowFavorite,
  isShowHide,
  isFavorite,
  onClickClose,
  onClickFavorite,
  onClickHidden,
  onClickCart,
  onClickImage,
  isInCart,
}: ImageCardCardProps) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Card sx={{ height: "100%", borderRadius: "5px", position: "relative" }}>
      {(isXs && image.is_available_to_buy) && <Button
        variant={isInCart ? "outlined" : "contained"}
        sx={{
          width: "30px",
          height: "30px",
          padding: 0,
          position: "absolute",
          right: "6px",
          borderRadius: "4px",
          top: "6px",
          zIndex: 100
        }}
        onClick={() => onClickCart?.(image)}
      >
        <Plus size={15} style={{ fontWeight: 700 }} />
      </Button>}
      <div
        style={{
          display: "flex",
          position: "relative",
          width: "100%",
          height: "auto",
          aspectRatio: '313/236',
          alignItems: "baseline",
          justifyContent: "center",
        }}
      >
        <Box
          position="relative"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <img
            style={{
              cursor: "pointer",
              height: "100%",
              width: "100%",
              objectFit: "cover",
            }}
            src={image?.presigned_url?.[0]}
            onClick={onClickImage}
            alt="[img]"
          />
          <Box position="absolute" top="14px" right="11px" gap={1} display="flex" flexDirection="column">
            {isShowClose && (
              <IconWraper onClick={onClickClose}>
                <X size={14} />
              </IconWraper>
            )}
            {/* TODO: Phase2以降で対応する */}
            {isShowFavorite && (
              <IconWraper
                onClick={() => {
                  onClickFavorite?.(image.id);
                }}
              >
                {isFavorite ? (
                  <Heart weight="fill" size={16} color="#FC4A6D" />
                ) : (
                  <Heart size={14} />
                )}
              </IconWraper>
            )}
            {isShowHide && (
              <IconWraper onClick={() => onClickHidden?.(image.id)}>
                <EyeSlash size={14} />
              </IconWraper>
            )}
          </Box>
        </Box>
      </div>
      <CardContent
        sx={{
          padding: isXs ? "20px 10px 10px 10px !important" : "14px 16px 23px 16px !important",
          height: isXs ? "100px" : "150px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
          >
            {image.photo_original_file}
          </Typography>
        </Box>
        {!isXs && (
          <Box marginTop={1.5} display="flex" justifyContent="center">
            {
              image.is_available_to_buy ? <Button
                size="small"
                variant={isInCart ? "outlined" : "contained"}
                endIcon={
                  <Plus
                    size={15}
                    style={{ fontWeight: 700, marginLeft: "10px" }}
                  />
                }
                sx={{
                  fontSize: { xs: 12, md: 14 },
                  borderRadius: "6px",
                  width: "100%",
                  ...(!isXs && {
                    height: "40px",
                  }),
                }}
                onClick={() => onClickCart?.(image)}
              >
                {isInCart ? "カートから削除する" : "カートに追加する"}
              </Button> : null
            }
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
export default ImageCard;
