import { Box, Button, Stack, Tab, Tabs } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { TAB_MY_PAGE, tabMyPageKey } from "@/common/constant/constant";
import ButtonChangeViewImage from "@/common/components/Button/ButtonChangeViewImage";
import AuthContext from "@/common/context/AuthContext";
import useUpdateQueryParams from "@/common/hooks/use-update-query-params";
import { useNavigate, useSearchParams } from "react-router-dom";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TabOrderHistory from "@/features/member/auth/MyPage/components/TabOrderHistory";
import { paths } from "@/common/constant/paths";
import ListOrderHistory from "./ListOrderHistory";
import ListImageFavorite from "./ListImageFavorite";
import ListImageHidden from "./ListImageHidden";
import MyAccountMain from "../../MyAccount/components/MyAccountMain";
function MyPageLeft() {
  const [searchParams] = useSearchParams();
  const updateQueryParams = useUpdateQueryParams();
  const tabParam = searchParams.get("tab") || tabMyPageKey.FAVORITE;
  const [tab, setTab] = useState<string>(tabParam);
  const [viewMode, setViewMode] = useState<"small" | "large">("small");
  const { isSignedIn, setIsOpenAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!Object.values(tabMyPageKey).includes(tabParam) ||
        (!isSignedIn && [tabMyPageKey.ORDER_HISTORY, tabMyPageKey.MY_ACCOUNT].includes(tabParam))) {
      updateQueryParams({ tab: tabMyPageKey.FAVORITE });
    } else {
      setTab(tabParam);
    }
  }, [tabParam]);
  const renderIcon = (value: string) => {
    switch (value) {
    case tabMyPageKey.FAVORITE:
      return <FavoriteBorderIcon/>
    case tabMyPageKey.HIDDEN:
      return <VisibilityOffIcon/>
    default:
      return <></>
    }
  }
  const setOpenLoginPopup = () => {
    setIsOpenAuthUser(true);
  };
  return (
    <Box maxWidth="xl" marginX="auto" p={isSignedIn ? {sm: 0} : {sm: 3}}>
      <Box sx={{ display: "flex", width: '100%' }}>
        <Tabs
          className="tab-mypage"
          onChange={(_, value) => {
            if(value === tabMyPageKey.MY_ACCOUNT && !isSignedIn) {
              setOpenLoginPopup()
              return
            }
            setTab(value);
            updateQueryParams({ tab: value });
          }}
          value={tab}
        >
          {TAB_MY_PAGE.map((item, index) => {
            if (!isSignedIn && [tabMyPageKey.ORDER_HISTORY].includes(item.value)) return null;
            return (
              <Tab
                key={item.value}
                label={item.label}
                tabIndex={index}
                iconPosition="start"
                icon={renderIcon(item.value)}
                value={item.value}
              />
            );
          })}
        </Tabs>
      </Box>
      {[tabMyPageKey.FAVORITE, tabMyPageKey.HIDDEN].includes(tab) && (
        <Box
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            display: {
              xs: 'none',
              sm: 'flex'
            }
          }}
        >
          <ButtonChangeViewImage
            setViewMode={setViewMode}
            viewMode={viewMode}
          />
        </Box>
      )}
      <Box>
        {tab === tabMyPageKey.FAVORITE && (
          <ListImageFavorite
            viewMode={viewMode}
            onClickImage={() => {}}
          />
        )}
        {tab === tabMyPageKey.HIDDEN && (
          <ListImageHidden
            viewMode={viewMode}
            onClickImage={() => {}}
          />
        )}
        {tab === tabMyPageKey.ORDER_HISTORY && (
          <Box marginTop={5}>
            <TabOrderHistory />
          </Box>
        )}
        {tab === tabMyPageKey.HISTORY && (
          <Box marginTop={5}>
            <ListOrderHistory />
          </Box>
        )}
        {tab === tabMyPageKey.MY_ACCOUNT && (
          <Box marginTop={5}>
            <MyAccountMain />
          </Box>
        )}
      </Box>
      {tab !== tabMyPageKey.MY_ACCOUNT && (
        <Stack marginTop={5} direction="row" justifyContent="center">
          <Button
            sx={{ width: 200 }}
            variant="contained"
            onClick={() => navigate(paths.home)}
          >
            TOPに戻る
          </Button>
        </Stack>
      )}
    </Box>
  );
}
export default MyPageLeft;
