import { Route, Routes } from "react-router-dom";
import TopIndexPage from "@/features/guest/pages/top";
import CartPage from "@/features/guest/pages/cart";
import DeliveryPage from "@/features/guest/pages/delivery";
import OrderInfo from "@/features/guest/pages/order/OrderInfo";
import FolderPhotoDetail from "@/features/guest/pages/FolderPhotoDetail";
import ConfirmPage from "@/features/guest/pages/confirm";
import CheckUenv from "@/features/guest/pages/check";
import CompleteOrderPage from "@/features/guest/pages/complete-order";
import Payment from "@/features/guest/pages/payment/Payment";
import PriceListPage from "@/features/guest/pages/priceList";
import TokuteiPage from "@/features/guest/pages/tokutei";
import PaymentAndDelivery from "../pages/PaymentAndDelivery";
/**
 * User画面のルーティング
 * @class
 */
function UserRoutes() {
  return (
    <Routes>
      <Route path="" element={<TopIndexPage />} />
      <Route path="/folders" element={<TopIndexPage />} />
      <Route path="/folder/:folderId" element={<FolderPhotoDetail />} />
      <Route path="/carts" element={<CartPage />} />
      <Route path="/carts/delivery" element={<DeliveryPage />} />
      <Route path="/orders/order-info" element={<OrderInfo />} />
      <Route path="/carts/conf" element={<ConfirmPage />} />
      <Route
        path="/carts/complete"
        element={<CompleteOrderPage />}
      />
      <Route path="/carts/payment" element={<Payment />} />
      <Route path="/prices/photo" element={<PriceListPage />} />
      <Route path="/payment-delivery" element={ <PaymentAndDelivery /> } />
      <Route path="/Const/tokutei" element={ <TokuteiPage /> } />
      <Route path="/checks/uenv" element={ <CheckUenv /> } />
    </Routes> 
  );
}
export default UserRoutes;
