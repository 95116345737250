import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import {IProps} from "@/common/utils/interfaces";
import useLocalStorage from "@/common/hooks/use-localStorage";
import usePhotoStatus from "@/features/guest/pages/FolderPhotoDetail/apis/usePhotoStatus";
import AuthContext from "@/common/context/AuthContext";
import {
  TypeResponseListFavorite,
  TypeResponseListHidden, TypeResponseListImageStorage
} from "@/features/member/auth/MyPage/types";
import { FAVORITE_IMAGES_KEY, HIDDEN_IMAGES_KEY } from "../constant/constant.ts";
const Context = createContext<{
  favoriteImages: (string | number)[];
  hiddenImages: (string | number)[];
  dataFavoriteImages: TypeResponseListFavorite[];
  totalPageFavorite: number;
  totalItemFavorite: number;
  currentPageFavorite: number;
  handlePageChangeFavorite: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  handleAddPhotoFavorite: (photoId: number | string)=>void;
  handleDeletePhotoFavorite: (photoId: number | string)=>void;
  dataHiddenImages: TypeResponseListHidden[];
  totalPageHidden: number;
  totalItemHidden: number;
  currentPageHidden: number;
  handlePageChangeHidden: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  handleAddPhotoHidden: (photoId: number | string)=>void;
  handleDeletePhotoHidden: (photoId: number | string)=>void;
  setFavoriteImages: (favoriteImages: (string | number)[]) => void;
  setHiddenImages: (hiddenImages: (string | number)[]) => void;
  initHiddenImages: (hiddenImageFind:(string | number)[]) => void;
  initFavoriteImages: (favoriteImageFind?:(string | number)[]) => void;
    }>({
      favoriteImages: [],
      hiddenImages: [],
      dataFavoriteImages: [],
      dataHiddenImages: [],
      totalPageFavorite: 1,
      totalItemFavorite: 0,
      currentPageFavorite: 1,
      totalPageHidden: 1,
      totalItemHidden: 0,
      currentPageHidden: 1,
      handlePageChangeFavorite: () => null,
      handleAddPhotoFavorite: () => null,
      handleDeletePhotoFavorite: () => null,
      handleAddPhotoHidden: () => null,
      handleDeletePhotoHidden: () => null,
      handlePageChangeHidden: () => null,
      setFavoriteImages: () => null,
      setHiddenImages: () => null,
      initHiddenImages: () => null,
      initFavoriteImages: () => null,
    })
// eslint-disable-next-line max-lines-per-function
export function ImagesContext(props: IProps) {
  const {children} = props;
  const {isSignedIn: isLoginUser} = useContext(AuthContext);
  const {
    getListPhotoByIds,
    getListPhotoFavorite,
    deletePhotoFavorite,
    addPhotoFavorite,
    addPhotoHidden,
    getListPhotoHidden,
    deletePhotoHidden,
  } = usePhotoStatus();
  const [favoriteImages, setFavoriteImages] = useLocalStorage<(string | number)[]>(FAVORITE_IMAGES_KEY, []);
  const [hiddenImages, setHiddenImages] = useLocalStorage<(string | number)[]>(HIDDEN_IMAGES_KEY, []);
  const [dataFavoriteImages, setDataFavoriteImages] = useState<TypeResponseListFavorite[]>([]);
  const [totalPageFavorite, setTotalPageFavorite] = useState<number>(1);
  const [totalItemFavorite, setTotalItemFavorite] = useState<number>(0);
  const [currentPageFavorite, setCurrentPageFavorite] = useState<number>(1);
  const [paginationFavorite, setPaginationFavorite] = useState({ page: 0, rowsPerPage: 10 });
  const [dataHiddenImages, setDataHiddenImages] = useState<TypeResponseListHidden[]>([]);
  const [totalPageHidden, setTotalPageHidden] = useState<number>(1);
  const [totalItemHidden, setTotalItemHidden] = useState<number>(0);
  const [currentPageHidden, setCurrentPageHidden] = useState<number>(1);
  const [paginationHidden, setPaginationHidden] = useState({ page: 0, rowsPerPage: 10 });
  const initFavoriteImages = async (favoriteImageFind:(string | number)[]= favoriteImages) => {
    let responseFavorite = [];
    const params = {
      page: paginationFavorite.page + 1,
      per_page: paginationFavorite.rowsPerPage,
    };
    if (isLoginUser) {
      if(favoriteImageFind?.length) await addPhotoFavorite(favoriteImageFind);
      responseFavorite = await getListPhotoFavorite(params);
      setFavoriteImages([])
      setDataFavoriteImages(responseFavorite?.data);
    }
    else {
      responseFavorite = favoriteImageFind?.length ? await getListPhotoByIds(favoriteImageFind) : []
      setDataFavoriteImages(responseFavorite?.data?.map((image: TypeResponseListImageStorage) => ({
        ...image,
        photo: image.photo_original_file,
        event: '',
        folder: ''
      })));
    }
    setTotalPageFavorite(responseFavorite?.total_pages ?? 1);
    setTotalItemFavorite(responseFavorite?.total ?? 0);
    setCurrentPageFavorite(responseFavorite?.current_page ?? 1);
  }
  const handleAddPhotoFavorite = async (photoId: number | string) => {
    if(isLoginUser){
      await addPhotoFavorite([photoId]);
      await initFavoriteImages()
    }
    else{
      const favoriteImageFind = [...new Set([favoriteImages, photoId].flat())]
      setFavoriteImages(favoriteImageFind)
      setTotalItemFavorite(prev=>prev+1)
      await initFavoriteImages(favoriteImageFind)
    }
  }
  const handleDeletePhotoFavorite = async (photoId: number | string) => {
    if(isLoginUser){
      await deletePhotoFavorite(photoId);
    }
    else{
      setTotalItemFavorite(prev=>prev-1)
    }
    const favoriteImageFind = favoriteImages?.filter(favoriteImage=>favoriteImage !== photoId)
    setFavoriteImages(favoriteImageFind)
    await initFavoriteImages(favoriteImageFind)
  }
  const initHiddenImages = async (hiddenImageFind:(string | number)[]= hiddenImages) => {
    let responseHidden = [];
    const params = {
      page: paginationHidden.page + 1,
      per_page: paginationHidden.rowsPerPage,
    };
    if (isLoginUser) {
      responseHidden = await getListPhotoHidden(params);
      setDataHiddenImages(responseHidden?.data);
    }
    else {
      responseHidden = hiddenImageFind?.length ? await getListPhotoByIds(hiddenImageFind) : []
      setDataHiddenImages((responseHidden?.data?.map((image: TypeResponseListImageStorage) => ({
        ...image,
        photo: image.photo_original_file,
        event: '',
        folder: ''
      }))))
    }
    setTotalPageHidden(responseHidden?.total_pages ?? 1);
    setTotalItemHidden(responseHidden?.total ?? 0);
    setCurrentPageHidden(responseHidden?.current_page ?? 1);
  }
  const handleAddPhotoHidden = async (photoId: number | string) => {
    const hiddenImageFind = [...new Set([hiddenImages, photoId].flat())]
    if(isLoginUser){
      await addPhotoHidden([photoId]);
    } else {
      setTotalItemHidden(prev=>prev+1)
      setHiddenImages(hiddenImageFind)
    }
    await initHiddenImages(hiddenImageFind)
  }
  /**
   *
   * @param photoId
   */
  const handleDeletePhotoHidden = async (photoId: number | string) => {
    if(isLoginUser){
      await deletePhotoHidden(photoId);
    }
    else{
      setTotalItemHidden(prev=>prev-1)
    }
    const hiddenImageFind = hiddenImages?.filter(hiddenImage=>hiddenImage !== photoId)
    setHiddenImages(hiddenImageFind)
    await initHiddenImages(hiddenImageFind)
  }
  const handlePageChangeFavorite = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPaginationFavorite((prev) => ({ ...prev, page: newPage }));
  };
  const handlePageChangeHidden = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPaginationHidden((prev) => ({ ...prev, page: newPage }));
  };
  useEffect(() => {
    initFavoriteImages();
  }, [isLoginUser, paginationFavorite?.page, paginationFavorite?.rowsPerPage]);
  useEffect(() => {
    initHiddenImages();
  }, [isLoginUser, paginationHidden?.page, paginationHidden?.rowsPerPage]);
  const contextValue = useMemo(
    () => ({
      favoriteImages,
      dataFavoriteImages,
      totalPageFavorite,
      totalItemFavorite,
      currentPageFavorite,
      handlePageChangeFavorite,
      handleAddPhotoFavorite,
      handleDeletePhotoFavorite,
      hiddenImages,
      dataHiddenImages,
      totalPageHidden,
      totalItemHidden,
      currentPageHidden,
      handlePageChangeHidden,
      handleAddPhotoHidden,
      handleDeletePhotoHidden,
      setFavoriteImages,
      setHiddenImages,
      initHiddenImages,
      initFavoriteImages
    }),
    [favoriteImages, hiddenImages, dataFavoriteImages, dataHiddenImages]
  );
  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}
export default Context;