import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import { icons } from "./NavIcons";
import isNavItemActive from "./IsNavItemActive";
import { NavItemMobileProps } from "./type";
import { RouterLink } from "../Link/link";
import {
  getNavItemAttributesMobile,
  renderExpandIcon,
  renderExternalIcon,
  renderLabelChip,
} from "./ultil";
function NavItemMobile({
  children,
  depth,
  disabled,
  external,
  forceOpen = false,
  href,
  icon,
  label,
  matcher,
  onClose,
  pathname,
  title,
}: NavItemMobileProps): React.JSX.Element {
  const [open, setOpen] = React.useState<boolean>(forceOpen);
  const active = isNavItemActive({
    disabled,
    external,
    href,
    matcher,
    pathname,
  });
  const IconComponent = icon ? icons[icon] : null;
  const isBranch = Boolean(children && !href);
  const showChildren = Boolean(children && open);
  const handleToggle = (): void => setOpen(!open);
  const handleKeyUp = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.key === "Enter" || event.key === " ") setOpen(!open);
  };
  const renderIcon = () =>
    IconComponent && (
      <Box sx={{ display: "flex", justifyContent: "center", flex: "0 0 auto" }}>
        <IconComponent
          fill={
            active
              ? "var(--NavItem-icon-active-color)"
              : "var(--NavItem-icon-color)"
          }
          fontSize="var(--icon-fontSize-md)"
          weight={forceOpen || active ? "fill" : undefined}
        />
      </Box>
    );
  return (
    <Box component="li" data-depth={depth} sx={{ userSelect: "none" }}>
      <Box
        {...(getNavItemAttributesMobile({
          isBranch,
          href,
          external,
          onClose,
          handleToggle,
          handleKeyUp,
          RouterLink,
        }) as BoxProps)}
        sx={
          {
            alignItems: "center",
            borderRadius: 1,
            color: "var(--NavItem-color)",
            cursor: "pointer",
            display: "flex",
            gap: 1,
            p: "6px 16px",
            textDecoration: "none",
            whiteSpace: "nowrap",
            ...(disabled && {
              bgcolor: "var(--NavItem-disabled-background)",
              color: "var(--NavItem-disabled-color)",
              cursor: "not-allowed",
            }),
            ...(active && {
              bgcolor: "var(--NavItem-active-background)",
              color: "var(--NavItem-active-color)",
              ...(depth > 0 && {
                "&::before": {
                  bgcolor: "var(--NavItem-children-indicator)",
                  borderRadius: "2px",
                  content: '""',
                  height: "20px",
                  left: "-14px",
                  position: "absolute",
                  width: "3px",
                },
              }),
            }),
            ...(open && { color: "var(--NavItem-open-color)" }),
            "&:hover": !disabled &&
              !active && {
              bgcolor: "var(--NavItem-hover-background)",
              color: "var(--NavItem-hover-color)",
            },
          } as BoxProps
        }
        tabIndex={0}
      >
        {renderIcon()}
        <Box sx={{ flex: "1 1 auto" }}>
          <Typography
            component="span"
            sx={{
              color: "inherit",
              fontSize: "0.875rem",
              fontWeight: 500,
              lineHeight: "28px",
            }}
          >
            {title}
          </Typography>
        </Box>
        {renderLabelChip(label)}
        {renderExternalIcon()}
        {renderExpandIcon({ open, isBranch })}
      </Box>
      {showChildren && (
        <Box sx={{ pl: "24px" }}>
          <Box
            sx={{
              borderLeft: "1px solid var(--NavItem-children-border)",
              pl: "12px",
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default NavItemMobile;
