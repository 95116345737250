/**
 * Closes the modal.
 * @param {any} onClose The callback function to be executed when the modal is closed.
 */
const handleCloseModal = (onClose: () => void) =>{
  const modal = document.getElementById('custom-modal')
  const contentElement = document.getElementById('content-modal')
  if (modal && contentElement) {
    contentElement.classList.remove('zoomin')
    modal.classList.remove('colorin')
    contentElement.classList.add('zoomout')
    modal.classList.add('colorout')
    setTimeout(() =>{
      onClose()
    }, 240)
  }
}
export default handleCloseModal