import HttpClientEvent from "@/common/api/HttpClientEvent";
import { UPDATE_STATUS_PAYMENT } from "@/common/constant/apiConstant";
import { AxiosResponse } from "axios";
type ParamsStatusPaymentOrder = {
  payment_intent_id: string;
  order_id: number;
};
type PaymentStatusResponse = {
  data: {
    detail: string;
  };
};
export const updateStatusPaymentOrder = async (
  data: ParamsStatusPaymentOrder
): Promise<AxiosResponse<PaymentStatusResponse>> => {
  const response = await HttpClientEvent.Post({
    path: UPDATE_STATUS_PAYMENT,
    data,
  });
  return response;
};
