import { createContext, Dispatch, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  signIn,
  SignInOutput,
  signOut,
  GetCurrentUserOutput,
  getCurrentUser,
  fetchAuthSession,
  AuthSession,
} from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import HttpClient from "@/common/api/HttpClient";
import { User } from "@/common/types/user";
import { IProps } from "../utils/interfaces";
import Logger from "../utils/Logger";
import { GET_MY_ACCOUNT_MEMBER } from "../constant/apiConstant";
import { storage } from "../utils/storage";
// const API_USERS = "users";
const LOGIN_FAILED = "ログインに失敗しました";
type LoginValues = {
  email: string;
  passcode?: string;
};
const Context = createContext<{
  email: string;
  isSignedIn: boolean;
  isUserEventSignedIn: boolean;
  loading: boolean;
  isOpenAuthUser: boolean;
  userInfo: User;
  message: string;
  setMessage: Dispatch<string>;
  login: (values: LoginValues) => Promise<SignInOutput>;
  logout: () => Promise<void>;
  logoutUserEvent: () => void;
  authHandler: Dispatch<string>;
  setIsOpenAuthUser: Dispatch<boolean>;
    }>({
      email: "",
      isSignedIn: false,
      isUserEventSignedIn: false,
      isOpenAuthUser: false,
      loading: true,
      userInfo: {
        id: "",
        name: "",
        email: "",
      },
      message: "",
      setMessage: () => null,
      login: () =>
        Promise.resolve({ isSignedIn: false, nextStep: { signInStep: "DONE" } }),
      logout: () => Promise.resolve(),
      logoutUserEvent: () => {},
      authHandler: () => null,
      setIsOpenAuthUser: () => null,
    });
export function AuthProvider(props: IProps) {
  const navigate = useNavigate();
  const tokenEvent = storage.getEventToken();
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(true);
  const [isOpenAuthUser, setIsOpenAuthUser] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<User>({ id: "", name: "", email: "", });
  const { data, isLoading: authLoading } = useQuery({
    queryKey: ["checkAuth"],
    queryFn: async (): Promise<AuthSession> => fetchAuthSession(),
  });
  const getUserInfo = async () => {
    try {
      const response = await HttpClient.Get({
        path: `${GET_MY_ACCOUNT_MEMBER}`,
        params: {},
        data: {},
        headers: {},
      });
      if (response.status === 200) {
        const user = response.data;
        if (user) {
          setUserInfo(user);
          storage.setProfileUser(user);
        }
        return { result: { ...response.data } };
      }
      setUserInfo({ id: "", name: "", email: "" });
      return { result: {}, error: LOGIN_FAILED };
    } catch (e) {
      Logger.logger(e);
      return { result: {}, error: e };
    }
  };
  const authHandler = async (userEmail: string) => {
    if (userEmail) {
      setEmail(userEmail);
      const { tokens } = await fetchAuthSession();
      if (tokens) {
        storage.setToken(tokens?.idToken?.toString() ?? "");
      }
      await getUserInfo();
      setIsLogin(true);
      setIsLoading(false);
    } else {
      setUserInfo({ id: "", name: "", email: "" });
      setIsLogin(false);
      setEmail("");
    }
  };
  const login = async (values: LoginValues) => {
    const { isSignedIn, nextStep } = await signIn({
      username: values.email,
      password: values.passcode,
    });
    if (isSignedIn && nextStep.signInStep === "DONE") {
      await authHandler(values.email);
      return { isSignedIn, nextStep };
    }
    return { isSignedIn: false, nextStep };
  };
  const logout = async () => {
    await signOut();
    authHandler("");
    navigate("/auth/login");
  };
  const logoutUserEvent = async () => {
    storage.clearUserEvent();
    navigate("/auth/login");
  };
  useEffect(() => {
    if (!!data && !!data.tokens) {
      getCurrentUser()
        .then(async (user: GetCurrentUserOutput) => {
          const loginId = user.signInDetails?.loginId as string;
          await authHandler(loginId);
          setIsLoading(false);
        })
        .catch((error) => {
          Logger.logger(error);
          setIsLoading(false);
        });
    } else {
      storage.clearUser();
      authHandler("");
      setIsLoading(authLoading);
    }
  }, [data, authLoading]);
  useEffect(() => {
    const handleRightClick = (ev: MouseEvent) => {
      Logger.logger(`右クリック禁止: ${ev}`);
      ev.preventDefault();
    };
    const handleKeyDown = (event: KeyboardEvent) => {
      Logger.logger(event);
      const isClickF12 = event?.keyCode === 12;
      const isClickWin = event.key === "s" && event.shiftKey && event.ctrlKey;
      const isClickMac = event.metaKey && event.shiftKey && event.key === "4";
      const isCtrl = event.ctrlKey && (event.key === "u" || event.key === "s");
      if (isClickF12 || isClickWin || isClickMac || isCtrl) {
        event.preventDefault();
      }
    };
    document.documentElement.addEventListener("keydown", handleKeyDown);
    // block right mouse
    window.addEventListener("contextmenu", handleRightClick);
    return () => {
      window.removeEventListener("contextmenu", handleRightClick);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const contextValue = useMemo(
    () => ({
      email,
      isSignedIn: isLogin,
      isUserEventSignedIn: !!tokenEvent,
      isOpenAuthUser,
      setIsOpenAuthUser,
      login,
      logout,
      loading,
      authHandler,
      userInfo,
      logoutUserEvent,
      message,
      setMessage,
    }),
    [
      email,
      isLogin,
      login,
      logout,
      loading,
      authHandler,
      userInfo,
      tokenEvent,
      isOpenAuthUser,
      setIsOpenAuthUser,
      message,
      setMessage,
    ]
  );
  const { children } = props;
  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}
export default Context;
