import { Dispatch, useContext, useState } from "react";
import authContext from "@/common/context/AuthContext";
import { getEmailWithHost } from "@/common/utils/helper";
import SignInStateEnum from "@/features/guest/auth/login/type";
import ToastMessage from "@/common/utils/ToastUtils";
import {MESSAGE_ERROR_COMMON} from "@/common/utils/message.ts";
import ConfirmSignupForm from "./ConfirmSignupForm";
import SigninForm from "./SigninForm";
import { TLoginForm } from "../../constant/constant";
import { executeConfirmAccount } from "../../api/executeConfirmAccount";
import { executeValidateAccount } from "../../api/executeValidateAccount";
import { executeCheckConfirmedAccount } from "../../api/executeCheckConfirmedAccount";
type Props = {
  onCancel: () => void;
  setState: Dispatch<SignInStateEnum>;
  state: SignInStateEnum;
};
enum StepLogin {
  login = "login",
  confirm = "confirm",
}
function Statelogin({ onCancel, state, setState }: Props) {
  const { login, authHandler } = useContext(authContext);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState<StepLogin>(StepLogin.login);
  const [infoAuth, setInfoAuth] = useState<{
    email: string;
    password: string;
  }>({ email: "", password: "" });
  const onSignin = async (values: TLoginForm) => {
    try {
      setIsLoading(true);
      const res = await executeValidateAccount({
        email: values.email,
        password: values.passcode,
      });
      if (res.data.status) {
        const userIsVerified = await executeCheckConfirmedAccount({
          email: values.email,
        });
        if (!userIsVerified) {
          setInfoAuth({
            email: values.email,
            password: values.passcode,
          });
          setIsLoading(false);
          setStep(StepLogin.confirm);
        } else {
          await login({ ...values, email: getEmailWithHost(values.email) });
          ToastMessage.showSuccess("処理が完了しました。");
          setIsLoading(false);
        }
      } else {
        ToastMessage.showError(
          "メールアドレスまたはパスワードに誤りがあります。"
        );
      }
    } catch (error) {
      setIsLoading(false);
      ToastMessage.showError(
        "メールアドレスまたはパスワードに誤りがあります。"
      );
    }
  };
  const onConfirmSignup = async (values: { code: string }) => {
    try {
      setIsLoading(true);
      await executeConfirmAccount({
        email: infoAuth.email ?? "",
        code: values.code,
      });
      await login({
        email: getEmailWithHost(infoAuth.email),
        passcode: infoAuth?.password,
      });
      setIsLoading(false);
      authHandler(infoAuth.email ?? "");
      ToastMessage.showSuccess("処理が完了しました。");
    } catch (error) {
      ToastMessage.showError(
        MESSAGE_ERROR_COMMON
      );
      setIsLoading(false);
    }
  };
  return step === StepLogin.confirm ? (
    <ConfirmSignupForm
      loading={isLoading}
      onCancel={onCancel}
      onSubmit={onConfirmSignup}
      username={infoAuth?.email}
    />
  ) : (
    <SigninForm
      loading={isLoading}
      onCancel={onCancel}
      onSubmit={onSignin}
      state={state}
      setState={setState}
    />
  );
}
export default Statelogin;
