import SignInStateEnum from "@/features/guest/auth/login/type";
import Logger from "@/common/utils/Logger";
import ToastMessage from "@/common/utils/ToastUtils";
import { Dispatch, useState } from "react";
import {MESSAGE_ERROR_COMMON} from "@/common/utils/message";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ConfirmForgotPassword from "./ConfirmForgotPassword";
import { executeRequestForgotPassword } from "../../api/executeRequestForgotPassword";
import { executeConfirmForgotPassword } from "../../api/executeConfirmForgotPassword";
type Props = {
  onCancel: () => void;
  setState: Dispatch<SignInStateEnum>;
  state: SignInStateEnum;
};
export enum StepForgot {
  forgot = "forgot",
  confirm = "confirm",
}
function StateForgot({ onCancel, setState, state }: Props) {
  const [step, setStep] = useState<StepForgot>(StepForgot.forgot);
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState<string>("");
  const onConfirmForgotPassword = async ({
    resetCode,
    password,
  }: {
    resetCode: string;
    password: string;
  }) => {
    try {
      setIsLoading(true);
      await executeConfirmForgotPassword({
        email: username,
        resetCode,
        password,
      });
      setIsLoading(false);
      ToastMessage.showSuccess("処理が完了しました。");
      onCancel();
    } catch (error) {
      setIsLoading(false);
      ToastMessage.showError(
        "確認コードが間違っています。"
      );
      Logger.logger({ error });
    }
  };
  const onForgotPassword = async ({ email }: { email: string }) => {
    try {
      setIsLoading(true);
      await executeRequestForgotPassword({ email });
      setIsLoading(false);
      setUsername(email);
      setStep(StepForgot.confirm);
    } catch (error) {
      setIsLoading(false);
      ToastMessage.showError(
        MESSAGE_ERROR_COMMON
      );
      Logger.logger({ error });
    }
  };
  return step === StepForgot.confirm ? (
    <ConfirmForgotPassword
      onCancel={onCancel}
      loading={isLoading}
      onSubmit={onConfirmForgotPassword}
      username={username}
      setState={setState}
      setStep={setStep}
    />
  ) : (
    <ForgotPasswordForm
      loading={isLoading}
      onCancel={onCancel}
      onSubmit={onForgotPassword}
      state={state}
      setState={setState}
    />
  );
}
export default StateForgot;
