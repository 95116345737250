import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import * as React from "react";
import authContext from "@/common/context/AuthContext";
import cartContext from "@/common/context/CartContext";
import { useNavigate } from "react-router";
import IconGuide from "@/assets/icon/IconGuide";
import { Button } from "@mui/material";
import IconClose from "@/assets/icon/IconClose";
import { User as UserIcon } from "@phosphor-icons/react/dist/ssr/User";
import IconInquiry from "@/assets/icon/IconInquiry";
import IconLogout from "@/assets/icon/IconLogout";
import { paths } from "@/common/constant/paths";
import IconTitle from "../Icon/IconTitle";
export interface MobileNavProps {
  onClose?: () => void;
  open?: boolean;
  isAuthen?: boolean;
}
function MobileNavV2({
  open,
  isAuthen,
  onClose,
}: MobileNavProps): React.JSX.Element {
  const navigate = useNavigate();
  const { logoutUserEvent, isSignedIn, isUserEventSignedIn, setIsOpenAuthUser } =
    React.useContext(authContext);
  const { resetContext } = React.useContext(cartContext);
  const handleLogout = () => {
    resetContext();
    logoutUserEvent();
  };
  return (
    <Drawer
      anchor="top"
      PaperProps={{
        sx: {
          "--MobileNav-background": "var(--mui-palette-neutral-950)",
          "--MobileNav-color": "var(--mui-palette-common-white)",
          "--NavGroup-title-color": "var(--mui-palette-neutral-400)",
          "--NavItem-color": "var(--mui-palette-neutral-300)",
          "--NavItem-hover-background": "rgba(255, 255, 255, 0.04)",
          "--NavItem-active-background": "var(--mui-palette-primary-main)",
          "--NavItem-active-color": "var(--mui-palette-primary-contrastText)",
          "--NavItem-disabled-color": "var(--mui-palette-neutral-500)",
          "--NavItem-icon-color": "var(--mui-palette-neutral-400)",
          "--NavItem-icon-active-color":
            "var(--mui-palette-primary-contrastText)",
          "--NavItem-icon-disabled-color": "var(--mui-palette-neutral-600)",
          "--NavItem-expand-color": "var(--mui-palette-neutral-400)",
          "--NavItem-children-border": "var(--mui-palette-neutral-700)",
          "--NavItem-children-indicator": "var(--mui-palette-neutral-400)",
          "--Workspaces-background": "var(--mui-palette-neutral-950)",
          "--Workspaces-border-color": "var(--mui-palette-neutral-700)",
          "--Workspaces-title-color": "var(--mui-palette-neutral-400)",
          "--Workspaces-name-color": "var(--mui-palette-neutral-300)",
          "--Workspaces-expand-color": "var(--mui-palette-neutral-400)",
          // bgcolor: "var(--MobileNav-background)",
          color: "var(--MobileNav-color)",
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          minHeight: "240px",
          width: "100%",
          zIndex: "var(--MobileNav-zIndex)",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { display: "none" },
        },
      }}
      onClose={onClose}
      open={open}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="20px 40px"
        marginTop="50px"
      >
        <Box
          onClick={onClose}
          position="absolute"
          right="20px"
          top="20px"
          sx={{ cursor: "pointer" }}
        >
          <IconClose color="var(--mui-palette-primary-main)" width={20} height={20}/>
        </Box>
        {isUserEventSignedIn &&
          (!isSignedIn ? (
            <Box width="100%" display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "100%" }}
                onClick={() => setIsOpenAuthUser(true)}
              >
                ログイン
              </Button>
            </Box>
          ) : (
            <Button
              startIcon={<UserIcon />}
              variant="contained"
              color="primary"
              sx={{ width: "100%" }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
              onClick={() => navigate(paths.myPageTabs.myAccount)}
            >
              マイページ
            </Button>
          ))}
        <Stack
          spacing={2}
          sx={{ paddingTop: 3 }}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Box border="1px solid #EBEBEB" padding="10px" borderRadius="8px">
            <IconTitle
              title="利用ガイド"
              icon={<IconGuide width="17" height="23" color="#757575" />}
              onClick={() => navigate("/usage-guide")}
              fontSize="12px"
              color="black"
            />
          </Box>
          <Box border="1px solid #EBEBEB" padding="10px" borderRadius="8px">
            <IconTitle
              title="お問い合わせ"
              icon={<IconInquiry width="24" height="18" color="#757575" />}
              onClick={() => navigate("/contacts")}
              fontSize="12px"
              color="black"
            />
          </Box>
          {isAuthen && (
            <Box border="1px solid #EBEBEB" padding="10px" borderRadius="8px">
              <IconTitle
                title="ログアウト"
                icon={<IconLogout width="21" height="19" color="#757575" />}
                onClick={handleLogout}
                fontSize="12px"
                color="black"
              />
            </Box>
          )}
        </Stack>
      </Box>
    </Drawer>
  );
}
export default MobileNavV2;
