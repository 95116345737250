export const getOptionViewMode = (mode: "small" | "large") => mode === "small"
  ? {
    md: 6,
    lg: 4,
    sm: 6,
    xs: 6,
  }
  : {
    lg: 3,
    md: 6,
    sm: 6,
    xs: 6,
  }