// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
import { Box, Stack } from "@mui/material";
// import { paths } from "@/common/constant/paths";
import PaymentDelivery from "./components/PaymentDelivery";
/**
 *
 */
function PaymentAndDelivery() {
  return (
    <Stack>
      {/* <Breadcumbs
        breadcrumbs={[
          { title: "トップ", href: paths.folder.list },
          { title: "お支払い・送料" }
        ]}
      />
      <Typography textAlign="left" variant="h4" mt={2}>
          お支払い・送料
      </Typography> */}
      <Box pb={4} mt={3}>
        <PaymentDelivery />
      </Box>
    </Stack>
  );
}
export default PaymentAndDelivery;
