import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Stack,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "@/common/utils/schema";
import TextFieldForm from "@/common/components/TextField/TextFieldForm";
import { useSubmit } from "@/features/guest/auth/hookCustom/useSubmit";
export default function PasswordResetDone({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const methods = useForm({
    resolver: yupResolver(resetPassword),
  });
  const onSubmit = useSubmit({ onSuccess });
  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          minHeight: "70vh",
          p: { xs: 2, md: 3 },
        }}
        component="form"
        noValidate
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box sx={{ maxWidth: "560px", width: "100%" }}>
          <Stack spacing={4}>
            <Card>
              <CardHeader title="パスワードの再設定" />
              <CardContent>
                <Stack mt={-2} mb={2}>
                  <Typography>
                    {" "}
                    ご登録時のメールアドレスと、新しく設定したいパスワードを入力して「パスワードを再設定する」をクリックしてください。
                  </Typography>
                </Stack>
                <Stack spacing={2}>
                  <FormControl>
                    <TextFieldForm
                      fullWidth
                      name="email"
                      label="メールアドレス"
                      required
                      type="email"
                      size="small"
                    />
                  </FormControl>
                  <FormControl>
                    <TextFieldForm
                      fullWidth
                      name="password"
                      label="パスワードを再設定する"
                      required
                      type="password"
                      size="small"
                    />
                  </FormControl>
                  <FormControl>
                    <TextFieldForm
                      fullWidth
                      name="confirm_passcode"
                      label="新しいパスワード（確認用）"
                      required
                      type="password"
                      size="small"
                    />
                  </FormControl>
                  <Button type="submit" variant="contained">
                    パスワードを再設定する
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Box>
      </Box>
    </FormProvider>
  );
}
