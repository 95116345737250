import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
type Pricing = {
    id: number;
    name: string;
    description: string;
    price: number;
    is_download_sale: boolean;
    download_size: number | null;
    sort_by: number;
    created_at: string;
    updated_at: string;
  };
type PricingType = {
  created_by_id: number;
  deleted_by_id: number | null;
  description: string;
  id: number;
  name?: string;
  organization?: string;
  pricings?: Pricing[];
  sort_by?: number;
  updated_at: string;
  updated_by_id: number;
};
export type PricingItemProps = {
  item: PricingType;
};
function PricingItem({ item }: PricingItemProps) {
  const { pricings, name } = item;
  return (
    <Box>
      <Box mt={3}>
        <Typography textAlign="left" variant="h6" mb={1}>
          {name}
        </Typography>
        <Divider />
        <Box mt={2}>
          {pricings?.map((price, index) => (
            <Grid container key={price?.id}>
              <Grid
                item
                md={2}
                xs={12}
                bgcolor="#f2f9e9"
                p={1.5}
                borderLeft="1px solid #ccc"
                borderTop="1px solid #ccc"
                borderBottom={
                  index === pricings.length - 1 ? "1px solid #ccc" : "none"
                }
              >
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  textAlign="left"
                >
                  {price.name}
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={12}
                p={1.5}
                borderTop="1px solid #ccc"
                borderBottom={
                  index === pricings.length - 1 ? "1px solid #ccc" : "none"
                }
              >
                <Typography variant="subtitle2" textAlign="left">
                  {price.price ? `${price.price}円`:""}
                </Typography>
              </Grid>
              <Grid
                item
                md={8}
                xs={12}
                p={1.5}
                borderLeft="1px solid #ccc"
                borderTop="1px solid #ccc"
                borderRight="1px solid #ccc"
                borderBottom={
                  index === pricings.length - 1 ? "1px solid #ccc" : "none"
                }
              >
                <Typography variant="subtitle2" textAlign="left">
                  {price.description}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
export default PricingItem;
