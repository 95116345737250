import {
  Box,
  CardContent,
  CardHeader,
  Link as MuiLink,
  Stack,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import authContext from "@/common/context/AuthContext";
import { confirmForgotSchema } from "@/common/utils/schema";
import { useContext, type Dispatch, type SetStateAction } from "react";
import { Alert, LoadingButton } from "@mui/lab";
import TextFieldForm from "@/common/components/TextField/TextFieldForm";
import { X as XIcon } from "@phosphor-icons/react/dist/ssr/X";
import OutlineInputField from "@/common/components/TextField/OutlineInputField";
import SignInStateEnum from "@/features/guest/auth/login/type";
import { StepForgot } from "./StateForgot";
type ConfirmForgotPasswordProps = {
  username?: string;
  loading?: boolean;
  setState: Dispatch<SignInStateEnum>;
  setStep?: Dispatch<SetStateAction<StepForgot>>;
  onCancel?: () => void;
  onSubmit: (values: { resetCode: string; password: string }) => Promise<void>;
};
function ConfirmForgotPassword({
  onSubmit,
  onCancel,
  loading,
  setState,
  setStep,
}: ConfirmForgotPasswordProps) {
  const { message } = useContext(authContext);
  const methods = useForm<{
    resetCode: string;
    password: string;
  }>({
    resolver: yupResolver(confirmForgotSchema),
  });
  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        noValidate
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box
          width="100%"
          textAlign="right"
          sx={{ cursor: "pointer" }}
          onClick={onCancel}
        >
          <XIcon fontSize="24px" />
        </Box>
        <CardHeader title={<h3>パスワード再設定</h3>} />
        <CardContent>
          {message ? (
            <Alert severity="error" sx={{ margin: "20px 0", width: "100%" }}>
              {message}
            </Alert>
          ) : (
            <></>
          )}
          <Box marginTop={2}>
            <Stack spacing={2}>
              <TextFieldForm
                fullWidth
                name="resetCode"
                label="確認コード"
                placeholder="確認コード"
                size="medium"
                type="tel"
              />
              <Box mt={2}>
                <OutlineInputField
                  label="パスワード"
                  inputProps={{ placeholder: "パスワード" }}
                  inputType="password"
                  name="password"
                />
              </Box>
              <Box mt={2}>
                <OutlineInputField
                  label="パスワードの確認"
                  inputProps={{ placeholder: "パスワードの確認" }}
                  inputType="password"
                  name="confirmpassword"
                />
              </Box>
              <MuiLink
                component="button"
                onClick={() => setState(SignInStateEnum.signIn)}
                sx={{
                  textAlign: "right",
                }}
              >
                パスワードをお忘れですか？
              </MuiLink>
              <Box display="flex" justifyContent="center">
                <LoadingButton
                  sx={{ width: "100%", marginTop: 2 }}
                  variant="contained"
                  type="submit"
                  data-testid="forgot-button"
                  loading={loading}
                >
                  確定
                </LoadingButton>
              </Box>
              <Box>
                <LoadingButton
                  variant="outlined"
                  component="button"
                  sx={{ width: "100%" }}
                  onClick={() => setStep?.(StepForgot.forgot)}
                >
                  コードを再送信
                </LoadingButton>
              </Box>
            </Stack>
          </Box>
        </CardContent>
      </Box>
    </FormProvider>
  );
}
export default ConfirmForgotPassword;
