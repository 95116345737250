import { useEffect, useState } from "react";
import HttpClientEvent from "@/common/api/HttpClientEvent";
import { GET_FOLDER_PATH } from "@/common/constant/apiConstant";
import type { DataEventFolder, ListDataFolder } from "../types";
const useGetListFolder = (
  config: {
    enabled?: boolean;
    param?: { [key: string]: string };
  } = { enabled: false, param: {} }
) => {
  const [data, setData] = useState<ListDataFolder[]>();
  const [total, setTotal] = useState<number>();
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataEvent, setDataEvent] = useState<DataEventFolder | undefined>(
    undefined
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const getListFolder = async (params = {}) => {
    setLoading(true);
    try {
      const response = await HttpClientEvent.Get({
        path: GET_FOLDER_PATH,
        params,
      });
      setTotal(response.data.total);
      setTotalPage(response.data.total_pages);
      setCurrentPage(response.data.current_page);
      setData(response.data.data as ListDataFolder[]);
      setDataEvent(response.data.event);
      return response.data.data;
    } catch (error) {
      setError((error as Error).message);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!config.enabled) return;
    getListFolder(config.param);
  }, [config.enabled, config.param]);
  return {
    data,
    total,
    totalPage,
    currentPage,
    dataEvent,
    error,
    loading,
    getListFolder,
  };
};
export default useGetListFolder;
