type TypeIconNextOutline = {
  width?: string;
  height?: string;
  color?: string;
};
export default function IconNextOutline(props: TypeIconNextOutline) {
  const {
    width = "10",
    height = "16",
    color = "var(--mui-palette-primary-main)",
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 11 18"
      fill="none"
    >
      <path
        d="M10.4455 7.51992C11.0001 8.07457 11.0001 8.97532 
        10.4455 9.52997L3.34595 16.6295C2.79131 17.1841 
        1.89056 17.1841 1.33591 16.6295C0.78126 16.0748 0.78126 
        15.1741 1.33591 14.6194L7.4326 8.52273L1.34035 2.42603C0.785697 
        1.87138 0.785697 0.970635 1.34035 0.415986C1.89499 -0.138662 
        2.79574 -0.138662 3.35039 0.415986L10.4499 7.51549L10.4455 7.51992Z"
        fill={color}
      />
    </svg>
  );
}
