import {Box, Button, Card, FormControl, Typography,} from "@mui/material";
import React, {ChangeEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {DeliveryMethodType} from "@/common/constant/type";
import {orderStorage} from "@/common/utils/storage/orderStorage";
import Grid from "@mui/material/Grid";
import {DeliveryMethodOptions} from "../ultils";
import {getDeliveryMethod} from "../api/GetDeliveryMethod";
/**
 *
 */
function DeliveryMain() {
  const { paymentMethodId, shippingMethodId } = orderStorage.getOrderForm();
  const [selectedValue, setSelectedValue] = useState<number | undefined>(shippingMethodId);
  const [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethodType[]>([]);
  const navigate = useNavigate();
  /**
   *
   * @param event
   */
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(+event.target.value);
  };
  /**
   *
   * @param paymentMethodId
   */
  const handleGetDeliveryMethod = async (paymentMethodId: number) => {
    const response = await getDeliveryMethod(+paymentMethodId);
    setDeliveryMethods(response?.result ?? []);
  };
  useEffect(() => {
    if (!paymentMethodId) return;
    handleGetDeliveryMethod(+paymentMethodId);
  }, [paymentMethodId]);
  return (
    <>
      <Box  sx={{maxWidth: 606, mx: "auto"}} marginTop={2}>
        <Typography variant="h5" textAlign="left" fontWeight="600">
          配送方法の選択
        </Typography>
        <Card
          sx={{
            alignItems: "center",
            my: 5,
            paddingY: {
              xs: '30px',
              sm: '40px'
            },
            paddingX : {
              xs: '28px',
              sm: '50px'
            },
            boxShadow: {
              xs: "unset !important",
              sm: "0px 0px 9px 0px #00000024 !important"
            },
            borderRadius: '5px'
          }}
        >
          <Box>
            <Typography
              sx={{ mb: 4 }}
              variant="subtitle1"
              textAlign="left"
              fontWeight="600"
            >
              商品の配送方法及びお支払い方法を下記より選択してください。
            </Typography>
          </Box>
          <Box sx={{marginBottom: {
            xs: '44px',
            sm: '30px '}
          }}>
            <FormControl component="fieldset" sx={{ width: "100%" }}>
              <DeliveryMethodOptions
                deliveryMethods={deliveryMethods}
                selectedValue={selectedValue}
                handleChange={handleChange}
              />
            </FormControl>
          </Box>
          <Grid
            container
            marginLeft="0"
            width="100%"
            sx={{display: 'flex', flexDirection: {
              xs: 'column-reverse',
              sm: 'row'
            }, alignItems: 'center', gap: '19px', flexWrap: 'noWrap'}}
          >
            <Button
              color="secondary"
              variant="outlined"
              fullWidth
              onClick={() => navigate("/guest/carts/payment")}
            >
              お支払い方法の選択へ進む
            </Button>
            <Button
              variant="contained"
              fullWidth
              disabled={selectedValue === undefined}
              onClick={() => {
                orderStorage.saveOrderForm({
                  shippingMethodId: selectedValue,
                  shippingMethod: deliveryMethods.find(item => item.id === selectedValue)?.name || ''
                });
                navigate("/guest/carts/conf");
              }}
            >
              注文内容の確認へ
            </Button>
          </Grid>
        </Card>
      </Box>
    </>
  );
}
export default DeliveryMain;
