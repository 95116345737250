import { AuthFormProps } from "@/features/guest/auth/constant/constant";
import { Box, CircularProgress, Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useState } from "react";
import LayoutContext from "@/features/guest/auth/layout-context";
import CustomTypography from "@/common/components/Typography/Typography";
import { Alert } from "@mui/lab";
import Button from "@mui/material/Button";
import { Flex } from "@aws-amplify/ui-react";
import { useSubmit } from "@/features/guest/auth/hookCustom/useSubmit";
import { resetPassword } from "@aws-amplify/auth";
import { confirmResetPasswordSchema } from "@/common/utils/schema";
import PasswordInput from "../../../../../common/components/TextField/PasswordInput";
import TextFieldForm from "../../../../../common/components/TextField/TextFieldForm";
enum Confirm_State {
  confirm = "CONFIRM",
  resend = "RESEND",
}
function ConfirmResetPasswordForm({
  onSuccess,
  username,
}: AuthFormProps & { username: string }) {
  const { message, loading, setLoading, setMessage } =
    useContext(LayoutContext);
  const [state, setState] = useState<Confirm_State>(Confirm_State.confirm);
  const methods = useForm({
    resolver: yupResolver(confirmResetPasswordSchema),
  });
  const onSubmit = useSubmit({
    onSuccess,
    handleState: () => setState(Confirm_State.confirm),
  });
  const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      setState(Confirm_State.resend);
      setLoading(true);
      if (!username) throw new Error("There is something wrong");
      await resetPassword({ username });
    } catch (error) {
      if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage("An unknown error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        noValidate
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Grid item xs={12} sx={{ mb: 1 }}>
          <CustomTypography variant="h3" text="パスワードリセット" />
        </Grid>
        {message ? (
          <Alert severity="error" sx={{ margin: "20px 0", width: "100%" }}>
            {message}
          </Alert>
        ) : (
          <></>
        )}
        <Flex direction="column" style={{ rowGap: "20px", width: "100%" }}>
          <TextFieldForm
            fullWidth
            name="code"
            label="確認コード"
            size="small"
          />
          <PasswordInput
            fullWidth
            name="passcode"
            label="新しいパスワード"
            size="small"
          />
          <PasswordInput
            fullWidth
            name="confirm_passcode"
            label="パスワードの確認"
            size="small"
          />
        </Flex>
        <Grid item xs={12} style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            startIcon={
              loading && state === Confirm_State.confirm ? (
                <CircularProgress size={20} />
              ) : (
                <></>
              )
            }
            sx={{ width: "50%" }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            送信
          </Button>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Button
              startIcon={
                loading && state === Confirm_State.resend ? (
                  <CircularProgress size={20} />
                ) : (
                  <></>
                )
              }
              sx={{ width: "50%" }}
              variant="outlined"
              color="primary"
              type="button"
              onClick={onClick}
              disabled={loading}
            >
              コードを再送信
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
}
export default ConfirmResetPasswordForm;
