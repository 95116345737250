import { useEffect, useState } from "react";
import { PAYMENT_METHOD } from "@/common/constant/apiConstant";
import HttpClientEvent from "@/common/api/HttpClientEvent";
import { PaymentMethod } from "../types";
const useGetPaymentMethod = () => {
  const [data, setData] = useState<PaymentMethod[]>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const getListPayment = async () => {
    setLoading(true);
    try {
      const response = await HttpClientEvent.Get({
        path: PAYMENT_METHOD,
      });
      setData(response.data.data as PaymentMethod[]);
      return response.data.data;
    } catch (error) {
      setError((error as Error).message);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getListPayment();
  }, []);
  return {
    data,
    error,
    loading,
    getListPayment,
  };
};
export default useGetPaymentMethod;
