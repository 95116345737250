import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
/**
 * テーマカラー
 */
const THEME = createTheme({
  palette: {
    primary: {
      main: "#174aae",
    },
    secondary: {
      main: "#f29ea4",
    },
    background: {
      default: "#ffffff",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: ["Nunito", "sans-serif"].join(","),
    h1: {
      fontSize: "4rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "3rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.4rem",
      "@media (min-width:600px)": {
        fontSize: "1.6rem",
      },
      "@media (min-width:900px)": {
        fontSize: "1.6rem",
      },
      fontWeight: 500,
    },
    h4: {
      fontSize: "12px",
      "@media (min-width:900px)": {
        fontSize: "1rem",
      },
      fontWeight: 400,
    },
    h5: {
      fontSize: "10px",
      "@media (min-width:900px)": {
        fontSize: "14px",
      },
      fontWeight: 300,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "10px",
          align: "center",
        },
      },
    },
  },
});
export default THEME;
