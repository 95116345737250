import { useEffect, useState } from "react";
import HttpClient from "@/common/api/HttpClient";
import { GET_NOTIFICATIONS_PATH } from "@/common/constant/apiConstant";
import { TypeResponseNotifications } from "../types";
const useGetNotifications = () => {
  const [data, setData] = useState<TypeResponseNotifications[]>([]);
  const [errors, setErrors] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 10 });
  const getListNotifications = async () => {
    const params = {
      page: pagination.page + 1,
      per_page: pagination.rowsPerPage,
    };
    setLoading(true);
    try {
      const response = await HttpClient.Get({
        path: GET_NOTIFICATIONS_PATH,
        params,
      });
      setTotal(response?.data?.total);
      setTotalPage(response?.data?.total_pages);
      setCurrentPage(response?.data?.current_page);
      setData([...data, ...response.data.data]);
    } catch (error) {
      setErrors((error as Error).message);
    } finally {
      setLoading(false);
    }
  };
  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };
  useEffect(() => {
    getListNotifications();
  }, [pagination.page, pagination.rowsPerPage]);
  return {
    data,
    total,
    totalPage,
    currentPage,
    getListNotifications,
    handlePageChange,
    errors,
    loading,
  };
};
export default useGetNotifications;
