type TypeIconZoom = {
  width?: string;
  height?: string;
  color?: string;
};
export default function IconZoom(props: TypeIconZoom) {
  const { width = "16", height = "16", color = "var(--mui-palette-primary-main)" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.22168 5.71875C8.92793 6.0125 8.92793 
        6.4875 9.22168 6.77812C9.51543 7.06875 9.99043 
        7.07187 10.2811 6.77812L14.4998 2.55938V5.25C14.4998 
        5.66563 14.8342 6 15.2498 6C15.6654 6 15.9998 5.66563 
        15.9998 5.25V0.75C15.9998 0.334375 15.6654 0 15.2498 
        0H10.7529C10.3373 0 10.0029 0.334375 10.0029 0.75C10.0029 1.16562 
        10.3373 1.5 10.7529 1.5H13.4436L9.22168 5.71875ZM6.78418 10.2812C7.07793 
        9.9875 7.07793 9.5125 6.78418 9.22188C6.49043 8.93125 6.01543 
        8.92813 5.7248 9.22188L1.50293 13.4406V10.75C1.50293 10.3344 
        1.16855 10 0.75293 10C0.337305 10 0.00292969 10.3344 0.00292969 
        10.75V15.25C0.00292969 15.6656 0.337305 16 0.75293 16H5.25293C5.66855 
        16 6.00293 15.6656 6.00293 15.25C6.00293 14.8344 5.66855 14.5 
        5.25293 14.5H2.5623L6.78418 10.2812Z"
        fill={color}
      />
    </svg>
  );
}
