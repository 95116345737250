import { Box, CircularProgress, Stack } from "@mui/material";
import { ListDataFolder } from "@/features/guest/pages/top/types";
import useGetTimeResellEvent from "@/features/member/auth/MyPage/apis/useGetTimeResellEvent";
import InfoTimeSaleCard from "./InfoTimeSaleCard";
import InfoTimeReSaleCard from "./InfoTimeReSaleCard";
import InfoFolderFavorite from "./InfoFolderFavorite";
import InfoFolderHiden from "./InfoFolderHiden";
import MenuFolderList from "./MenuFolderList";
type Props = {
  folders?: ListDataFolder[];
};
function SidebarMenuFolder({ folders = [] }: Props) {
  const { data, loading } = useGetTimeResellEvent();
  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Stack gap={2}>
      {data?.is_release_period && <InfoTimeSaleCard data={data} />}
      {data?.is_resale && <InfoTimeReSaleCard data={data} />}
      {/* TODO: Phase2以降で対応 */}
      <InfoFolderFavorite />
      <InfoFolderHiden />
      <MenuFolderList folders={folders} />
    </Stack>
  );
}
export default SidebarMenuFolder;
