import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { RouterLink } from "@/common/components/Link/link";
import authContext from "@/common/context/AuthContext";
import cartContext from "@/common/context/CartContext";
import { NavItemConfig } from "@/common/types/nav";
import { Link, Tooltip } from "@mui/material";
import { paths } from "@/common/constant/paths";
import { Logo } from "@/common/components/Nav/logo";
import usePathname from "@/common/hooks/use-pathname";
import { useNavigate } from "react-router";
import { Question } from "@phosphor-icons/react";
import { renderNavGroupsMobile } from "./ultil";
export interface MobileNavProps {
  onClose?: () => void;
  open?: boolean;
  isAuthen?: boolean;
  items?: NavItemConfig[];
}
function MobileNav({
  items = [],
  open,
  isAuthen,
  onClose,
}: MobileNavProps): React.JSX.Element {
  const pathname = usePathname();
  const navigate = useNavigate();
  const {
    logoutUserEvent,
    isSignedIn,
    isUserEventSignedIn,
    setIsOpenAuthUser,
  } = React.useContext(authContext);
  const { resetContext } = React.useContext(cartContext);
  const handleLogout = () => {
    resetContext()
    logoutUserEvent();
  }
  return (
    <Drawer
      PaperProps={{
        sx: {
          "--MobileNav-background": "var(--mui-palette-neutral-950)",
          "--MobileNav-color": "var(--mui-palette-common-white)",
          "--NavGroup-title-color": "var(--mui-palette-neutral-400)",
          "--NavItem-color": "var(--mui-palette-neutral-300)",
          "--NavItem-hover-background": "rgba(255, 255, 255, 0.04)",
          "--NavItem-active-background": "var(--mui-palette-primary-main)",
          "--NavItem-active-color": "var(--mui-palette-primary-contrastText)",
          "--NavItem-disabled-color": "var(--mui-palette-neutral-500)",
          "--NavItem-icon-color": "var(--mui-palette-neutral-400)",
          "--NavItem-icon-active-color":
            "var(--mui-palette-primary-contrastText)",
          "--NavItem-icon-disabled-color": "var(--mui-palette-neutral-600)",
          "--NavItem-expand-color": "var(--mui-palette-neutral-400)",
          "--NavItem-children-border": "var(--mui-palette-neutral-700)",
          "--NavItem-children-indicator": "var(--mui-palette-neutral-400)",
          "--Workspaces-background": "var(--mui-palette-neutral-950)",
          "--Workspaces-border-color": "var(--mui-palette-neutral-700)",
          "--Workspaces-title-color": "var(--mui-palette-neutral-400)",
          "--Workspaces-name-color": "var(--mui-palette-neutral-300)",
          "--Workspaces-expand-color": "var(--mui-palette-neutral-400)",
          // bgcolor: "var(--MobileNav-background)",
          color: "var(--MobileNav-color)",
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          width: "var(--MobileNav-width)",
          zIndex: "var(--MobileNav-zIndex)",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { display: "none" },
        },
      }}
      onClose={onClose}
      open={open}
    >
      <Stack spacing={2} sx={{ p: 2 }}>
        <div>
          <Box
            component={RouterLink}
            href={paths.home}
            sx={{ display: "inline-flex" }}
          >
            <Logo color="light" height={27} width={200} />
          </Box>
        </div>
        {isUserEventSignedIn && (
          <Box display="flex" gap={1}>
            {!isSignedIn ? (
              <Link
                textAlign="start"
                component="button"
                color="var(--NavItem-color)"
                onClick={() => setIsOpenAuthUser(true)}
              >
                ログイン
              </Link>
            ) : (
              <Link
                textAlign="start"
                component="button"
                color="var(--NavItem-color)"
                onClick={() => navigate("/member/myaccount")}
              >
                マイページ
              </Link>
            )}
            <Tooltip title="アカウント情報を登録すると、購入時の項目入力ステップが省略できます" enterTouchDelay={0}>
              <Question size={20} color="var(--NavItem-icon-color)" />
            </Tooltip>
          </Box>
        )}
        <Link
          component="button"
          textAlign="start"
          color="var(--NavItem-color)"
          onClick={() => {
            window.open(paths.guide, "__blank");
          }}
        >
          ご利用ガイド
        </Link>
        <Link
          // component="button"
          textAlign="start"
          color="var(--NavItem-color)"
          onClick={() => {
            window.open("https://docs.google.com/forms", "__blank");
          }}
        >
          お問い合わせ
        </Link>
        {isAuthen && (
          <Link
            component="button"
            textAlign="start"
            color="var(--NavItem-color)"
            onClick={handleLogout}
          >
            ログアウト
          </Link>
        )}
      </Stack>
      {isAuthen && (
        <Box component="nav" sx={{ flex: "1 1 auto", p: 2 }}>
          {renderNavGroupsMobile({ items, onClose, pathname })}
        </Box>
      )}
    </Drawer>
  );
}
export default MobileNav;
