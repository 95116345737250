import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Alert } from "@mui/lab";
import { useContext } from "react";
import { InferType } from "yup";
import * as yup from "yup";
import { loginSchema } from "@/common/utils/schema";
import TextFieldForm from "@/common/components/TextField/TextFieldForm";
import PasswordInput from "@/common/components/TextField/PasswordInput";
import LayoutContext from "@/features/guest/auth/layout-context";
import { useSubmit } from "@/features/guest/auth/hookCustom/useSubmit";
import { AuthFormProps } from "@/features/guest/auth/constant/constant";
type loginType = InferType<typeof loginSchema>;
function SigninForm({ onSuccess }: AuthFormProps) {
  const { message, loading } = useContext(LayoutContext);
  const methods = useForm<loginType>({
    resolver: yupResolver(
      loginSchema.concat(
        yup.object().shape({
          email: yup
            .string()
            .required("ログインIDを入力してください。")
            .max(255),
        })
      )
    ),
    defaultValues: {
      email: "",
      passcode: "",
    },
  });
  const onSubmit = useSubmit({ onSuccess });
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <Stack spacing={2} alignItems="baseline" width="100%">
          <Typography fontSize={16}>
            写真購入はこちらからログインしてください。
          </Typography>
          {message ? (
            <Alert severity="error" sx={{ margin: "20px 0", width: "100%" }}>
              {message}
            </Alert>
          ) : (
            <></>
          )}
          <Box width="100%">
            <TextFieldForm name="email" fullWidth label="ログインID" />
          </Box>
          <Box width="100%">
            <PasswordInput
              name="passcode"
              fullWidth
              label="イベントパスワード"
            />
          </Box>
          <Button
            startIcon={loading ? <CircularProgress size={20} /> : <></>}
            disabled={loading}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: "100%" }}
          >
            ログインする
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
}
export default SigninForm;
