import { Box, Typography } from "@mui/material";
import MyAccountMain from "./components/MyAccountMain";
function MyAccount() {
  return <Box mt={3}>
    <Typography variant="h4" textAlign="left" fontWeight="bold">
        マイページ
    </Typography>
    <MyAccountMain />
  </Box>
}
export default MyAccount;
