import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate } from "react-router";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { paths } from "@/common/constant/paths";
import IconNextOutline from "@/assets/icon/IconNextOutline";
import { type ListDataFolder } from "../types/index";
export interface FolderCardProps {
  folder: ListDataFolder;
}
function FolderCard({ folder }: FolderCardProps): React.JSX.Element {
  const navigate = useNavigate()
  return (
    <Card
      sx={{
        borderRadius: 0,
        borderBottomRightRadius: "10px",
        borderBottomLeftRadius: "10px",
        borderTop: "2px solid var(--mui-palette-primary-main)",
        padding: "20px",
        cursor: "pointer",
        minHeight: "auto",
      }}
      onClick={() => {
        navigate(paths.folder.detail(String(folder?.id)));
      }}
    >
      <CardMedia
        image={folder.presigned_url}
        sx={{
          width: "100%",
          height: "210px",
          borderRadius: "5px",
          overflow: "hidden",
          display: 'block'
        }}
      />
      <CardContent
        style={{
          padding: "0",
          paddingTop: "10px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "relative",
          flexWrap: "wrap",
        }}
      >
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis", display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            textAlign: "start",
            whiteSpace: "normal", overflowWrap: "break-word",
            maxWidth:"50%"
          }}
          variant="h6"
        >
          {folder.sort_by}.{folder?.name}
        </Typography>
        <Typography marginLeft="20px" color="#757575" variant="body1" component="span">{` (${
          folder?.total_photo ?? 0
        }枚)`}</Typography>
        <Box position="absolute" right="0" top="10px">
          <IconNextOutline />
        </Box>
      </CardContent>
      <Box mt={1}>{folder.comment} 123456 45678 12345667 12345667 12345667 12345667 12345667</Box>
    </Card>
  );
}
export default FolderCard;
