/* eslint-disable @typescript-eslint/no-explicit-any */
import {useContext} from "react";
import LayoutContext from "../layout-context";
import {AuthFormProps} from "../constant/constant.ts";
type SubmitProps = AuthFormProps & {
  handleState?: () => void
}
export const useSubmit = ({ onSuccess, handleState = () => null }: SubmitProps ) => {
  const { setMessage, setLoading } = useContext(LayoutContext)
  return async (values: any): Promise<void> => {
    try {
      setLoading(true);
      handleState();
      await onSuccess(values);
    } catch (error) {
      if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage("An unknown error occurred");
      }
    } finally {
      setLoading(false)
    }
  };
}