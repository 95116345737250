/* eslint-disable no-multiple-empty-lines -- Expected blank line between class members.を回避 */
import { OrderFormData, STORAGE_KEYS } from './types';
import Logger from '../Logger';
/**
 * 注文フォームのデータを操作するための共通関数
 */
class OrderStorage {
  private storage: Storage;

  constructor() {
    this.storage = localStorage;
  }

  /**
   * フォームデータの保存
   */
  saveOrderForm(data: Partial<OrderFormData>): void {
    try {
      const currentData = this.getOrderForm();
      const newData = { ...currentData, ...data };
      this.storage.setItem(STORAGE_KEYS.ORDER_FORM, JSON.stringify(newData));
    } catch (error) {
      Logger.logger('Failed to save order form data', "error");
    }
  }

  /**
   * フォームデータの取得
   */
  getOrderForm(): Partial<OrderFormData> {
    try {
      const data = this.storage.getItem(STORAGE_KEYS.ORDER_FORM);
      return data ? JSON.parse(data) : {};
    } catch (error) {
      Logger.logger('Failed to get order form data', "error");
      return {};
    }
  }

  /**
   * フォームデータの削除
   */
  clearOrderForm(): void {
    try {
      this.storage.removeItem(STORAGE_KEYS.ORDER_FORM);
    } catch (error) {
      Logger.logger('Failed to clear order form data', "error");
    }
  }
}

// シングルトンインスタンスをエクスポート
export const orderStorage = new OrderStorage();