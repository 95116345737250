import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
} from "@mui/material";
import {
  PencilSimple as PencilSimpleIcon,
} from "@phosphor-icons/react/dist/ssr";
import { OrderFormData } from "@/common/utils/storage/types";
import { useEffect, useState } from "react";
import { orderStorage } from "@/common/utils/storage/orderStorage";
import {ItemsCartMobile} from "@/features/guest/pages/confirm/components/ItemCartMobile";
import { LineItemsTable } from "./LineItemsTable";
import OrderSummaryDetails from "./OrderSummaryDetails";
import { getShippingFee } from "../api/GetShippingFee";
import { CartItem } from "../type";
type OrderSummaryProps = {
  handleRedirect: () => void;
  orderFormData: Partial<OrderFormData>;
  cartItems: CartItem[];
};
/**
 *
 * @param root0
 * @param root0.handleRedirect
 * @param root0.orderFormData
 * @param root0.cartItems
 */
export function OrderSummary({ handleRedirect, orderFormData, cartItems }: OrderSummaryProps) {
  const subtotal = orderFormData.subTotal || 0;
  const [shippingFee, setShippingFee] = useState(0);
  const fee = orderFormData.paymentFee || 0;
  const [total, setTotal] = useState(0);
  useEffect(() => {
    /**
     *
     */
    const fetchShippingFee = async () => {
      const prefCode = orderFormData.prefecture_code || 0;
      const methodId = orderFormData.shippingMethodId || 0;
      const { result } = await getShippingFee(prefCode, methodId);
      const newShippingFee = result?.fee ?? 0;
      setShippingFee(newShippingFee);
      orderStorage.saveOrderForm({ shippingFee: newShippingFee });
    };
    fetchShippingFee();
  }, [orderFormData.prefecture_code, orderFormData.shippingMethodId]);
  useEffect(() => {
    const newTotal = subtotal + shippingFee + fee;
    setTotal(newTotal);
    orderStorage.saveOrderForm({ totalPrice: newTotal });
  }, [subtotal, shippingFee, fee]);
  const items = [
    { label: "小計（税込）", value: subtotal },
    { label: "送料", value: shippingFee },
    { label: "手数料", value: fee },
    { label: "合計", value: total, isTotal: true },
  ]
  return (
    <Card sx={{ mt: 3, borderRadius:"5px" }}>
      <CardHeader
        title="ご注文商品の確認"
        titleTypographyProps={{
          variant: "subtitle1",
          fontWeight: "600"
        }}
        action={
          <Button
            color="secondary"
            startIcon={<PencilSimpleIcon />}
            onClick={handleRedirect}
          >
          変更する
          </Button>
        }
      />
      <CardContent>
        <Stack spacing={2} sx={{display: {xs: "none", sm: "flex"}}}>
          <Card sx={{ borderRadius: 0.5 }} variant="outlined">
            <Box sx={{ overflowX: "auto" }}>
              <LineItemsTable rows={cartItems} />
            </Box>
          </Card>
          <OrderSummaryDetails items={items} />
        </Stack>
        <Stack spacing={4} sx={{display: {xs: "flex", sm: "none"}}}>
          <ItemsCartMobile rows={cartItems}/>
          <OrderSummaryDetails items={items} />
        </Stack>
      </CardContent>
    </Card>
  );
} 