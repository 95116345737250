import { Box, Grid, Typography, Container, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
import CompleteOrderMain from "./components/CompleteOrderMain";
/**
 *
 */
function CompleteOrderPage() {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  return <>
    {/* <Breadcumbs
      breadcrumbs={[
        { title: "トップ" },
        { title: "カート", isShowLink: true },
        { title: "お届け先情報", isShowLink: true },
        { title: "お支払い選択", isShowLink: true },
        { title: "配送方法の選択", isShowLink: true },
        { title: "注文内容の確認", isShowLink: true },
        { title: "注文完了" },
      ]}
    /> */}
    <Grid container>
      <Grid item md={12} xs={12}>
        <Container maxWidth="lg">
          <Box sx={{maxWidth: 940, mx: "auto"}} mt={3}>
            <Typography variant="h4" textAlign="left" fontWeight="bold" sx={{...(isXs && {fontSize:"24px"})}}>
              ご注文完了
            </Typography>
            <CompleteOrderMain />
          </Box>
        </Container>
      </Grid>
    </Grid>
  </>
}
export default CompleteOrderPage;
