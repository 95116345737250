import {Button} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import IconZoom from "@/assets/icon/IconZoom.tsx";
import IconZoomOut from "@/assets/icon/IconZoomOut.tsx";
type Props = {
  viewMode: "small" | "large";
  setViewMode: Dispatch<SetStateAction<"small" | "large">>;
};
function ButtonChangeViewImage({ setViewMode, viewMode }: Props) {
  return <>
    <Button
      variant="outlined"
      sx={{
        height: "30px",
        width: "30px",
        padding: 0,
        right: 0,
      }}
      onClick={() =>
        setViewMode((prev) => (prev === "small" ? "large" : "small"))
      }
    >
      {viewMode === "small" ? <IconZoom /> : <IconZoomOut />}
    </Button>
  </>
}
export default ButtonChangeViewImage;
