import { useState } from "react";
import IconClose from "@/assets/icon/IconClose";
import { PhotoType } from "@/common/constant/type";
import { ICartItem } from "@/common/context/CartContext";
import { ImageItem } from "@/features/guest/pages/FolderPhotoDetail";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MagnifierGlass from "../MagnifierGlass";
import FilterFace from "./components/FilterFace";
// import { textImageCard } from "./helper/text";
import ButtonBuyPhoto from "./components/ButtonBuyPhoto";
type Props = {
  currentItem?: PhotoType;
  onClose?: () => void;
  onClickCart?: (item: ImageItem | ICartItem) => Promise<void>;
  isInCart?: boolean;
  imageTitle?: string;
  imageUrl: string;
  handleFilterFace: (url: string) => void;
} & DialogProps;
function PreviewImage({
  onClose,
  onClickCart,
  isInCart,
  currentItem,
  imageTitle,
  imageUrl,
  open,
  handleFilterFace,
}: Props) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [isFilterFace, setIsFilterFace] = useState(false);
  const handleChangeWatermark = () => {};
  const handleOpenFilterFace = () => {
    setIsFilterFace(!isFilterFace);
  };
  console.log("デモが終わり次第削除",handleChangeWatermark, handleOpenFilterFace);
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: "800px",
          width: {
            xs: "90%",
            sm: "600px",
            md: "800px",
          },
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          position: "relative",
          padding: 0,
        }}
      >
        <Button
          variant="contained"
          onClick={onClose}
          sx={{
            padding: 0.54,
            borderRadius: "6px",
            position: "absolute",
            right: "20px",
            top: 20,
            zIndex: 999,
          }}
        >
          <IconClose width={isXs ? 14 : 20} height={isXs ? 14 : 20} />
        </Button>
        {/* image display :  */}
        <Box
          display="flex"
          justifyContent="center"
          position="relative"
          height={382}
          overflow="hidden"
        >
          <MagnifierGlass
            src={imageUrl}
            magnifierHeight={150}
            magnifierWidth={150}
            zoomLevel={2}
            alt="Sample Image"
          />
        </Box>
        <Stack
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          paddingBottom="20px"
          px={2}
          gap={1.5}
          pt={1}
          pb={3}
        >
          <Stack>
            {/* btn control  */}
            <Stack flexDirection="row" justifyContent="flex-end" gap={2}>
              {/* TODO: デモ用に一旦コメントアウト、デモがOKになり次第解除 */}
              {/* <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "21px",
                  textAlign: "right",
                  color: "#757575",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    textDecoration: "underline",
                    textDecorationThickness: "2px",
                    textUnderlineOffset: "4px",
                  },
                }}
                onClick={handleChangeWatermark}
              >
                {textImageCard.btn.changeWatermark}
              </Typography> */}
              {/* TODO: デモ用に一旦コメントアウト、デモがOKになり次第解除 */}
              {/* <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "21px",
                  textAlign: "right",
                  color: "#757575",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    textDecoration: "underline",
                    textDecorationThickness: "2px",
                    textUnderlineOffset: "4px",
                  },
                }}
                onClick={handleOpenFilterFace}
              >
                {textImageCard.btn.filterFace}
              </Typography> */}
            </Stack>
            {/* title image */}
            <Typography fontWeight={600} fontSize={23} textAlign="center">
              {imageTitle}
            </Typography>
          </Stack>
          {/* filter face  */}
          {isFilterFace && <FilterFace handleFilterFace={handleFilterFace} />}
          {/* btn buy photo */}
          <ButtonBuyPhoto
            onClick={() =>
              onClickCart?.({
                id: currentItem?.id ?? 0,
                url: currentItem?.presigned_url[0] ?? "",
                title: currentItem?.photo_original_file ?? "",
                quantity: 1,
              })
            }
            isInCart={isInCart}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
export default PreviewImage;
