import { useNavigate, useSearchParams } from "react-router-dom";
function useUpdateQueryParams() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const updateQueryParams = (params: { [key: string]: string }) => {
    const newParams = new URLSearchParams(searchParams);
    Object.keys(params).forEach((key) => {
      if (params[key] !== null && params[key] !== undefined) {
        newParams.set(key, params[key]);
      } else {
        newParams.delete(key);
      }
    });
    navigate({ search: newParams.toString() });
  };
  return updateQueryParams;
}
export default useUpdateQueryParams