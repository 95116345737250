import HttpClientEvent from "@/common/api/HttpClientEvent";
import { GET_DELIVERY_METHOD_PATH } from "@/common/constant/apiConstant";
import { DeliveryMethodType } from "@/common/constant/type";
import Logger from "@/common/utils/Logger";
import { MESSAGE_ERROR_COMMON } from "@/common/utils/message";
export const getDeliveryMethod = async (paymentMethodId: number) => {
  try {
    const response = await HttpClientEvent.Get({
      path: `${GET_DELIVERY_METHOD_PATH}`,
      params: {
        payment_method_id: paymentMethodId,
      },
    });
    if (response.status === 200) {
      return { result: response.data?.data as DeliveryMethodType[] };
    }
    return { result: [], error: MESSAGE_ERROR_COMMON };
  } catch (e) {
    Logger.logger(e);
    return { result: [], error: e };
  }
};
