import { Helmet } from "react-helmet-async";
import favicon from "@/assets/static/favicon.ico";
type Props = {
  description?: string;
};
/**
 * ヘッド
 * @param {Props} props - プロップス
 * @class
 */
function Head({
  description = "ベンジャミンのサンプルサイトです。",
}: Props) {
  return (
    <Helmet>
      <title>Rainbow photo</title>
      <link rel="icon" type="image/png" href={favicon} />
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
  );
}
export default Head;
