import { Box } from "@mui/material";
import MyPageLeft from "@/features/member/auth/MyPage/components/MyPageLeft";
import SidebarMenuFolder from "@/common/components/SidebarMenuFolder";
import useGetListFolder from "@/features/guest/pages/top/apis/useGetListFolder";
/**
 *
 */
function Index() {
  const {data: listFolder} = useGetListFolder({enabled: true});
  return (
    <Box sx={{
      display: "flex",
      width: "100%",
      flexDirection: {
        xs: 'column',
        sm: 'row'
      },
      gap: {
        xs: '44px',
        sm: '53px'
      }
    }}>
      <Box sx={{
        width: "100%",
      }}>
        <MyPageLeft />
      </Box>
      <Box sx={{
        width: "100%",
        maxWidth: {
          xs: '100%',
          sm: '269px'
        },
        paddingTop: {
          sm: '20px'
        }
      }}>
        <SidebarMenuFolder folders={listFolder} />
      </Box>
    </Box>
  );
}
export default Index;
