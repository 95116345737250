// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
import { Box, 
  // Typography 
} from "@mui/material";
import InfoRow from "./components/InfoRow";
function Tokutei() {
  return (
    <div>
      {/* <Breadcumbs
        breadcrumbs={[
          { title: "トップ" },
          { title: "特定商取引法に基づく表記" },
        ]}
      />
      <Typography mt={4} variant="h4" textAlign="left">
        特定商取引法に基づく表記
      </Typography> */}
      <Box mt={2}>
        <Box mt={2}>
          <InfoRow label="販売業者" value="株式会社STK" />
          <InfoRow label="運営責任者" value="代表取締役　伊藤駿" />
          <InfoRow
            label="住所"
            value={
              <>
                〒101-0032
                <br />
                東京都千代田区岩本町３ー５－１４　リベラ岩本町３階
              </>
            }
          />
          <InfoRow label="TEL" value="03-5833-2650" />
          <InfoRow label="FAX" value="03-5833-2651" />
          <InfoRow label="メールアドレス" value="rainbowphoto-info@stk-i.co.jp" />
          <InfoRow label="URL" value="http://stk-i.co.jp" />
          <InfoRow
            label="商品代金以外の必要料金"
            value={
              <>
                注文一回につき
                <br />
                送料　(ゆうメール 一律180円 普通郵便一律400円)
              </>
            }
          />
          <InfoRow label="注文方法" value="フォトブックご注文のお客様はFAXにてご注文下さい。" />
          <InfoRow
            label="支払方法"
            value={
              <>
                注文一回につき
                <br />
                手数料 (コンビ二決済 一律258円　代金引換 一律432円）
                <br />
                尚、ゆうメールご注文のお客様はコンビ二決済、普通郵便ご注文のお客様は代金引換となります）
              </>
            }
          />
          <InfoRow
            label="支払期限"
            value="支払い期限は払込票に明記しております。商品到着から１０日前後となります。代金引換の場合は商品到着時となります。"
          />
          <InfoRow
            label="引き渡し時期"
            value="毎月、８日までのご注文を１５日に、１５日までのご注文を２２日に、２３日までのご注文を末日に、末日までのご注文を８日に発送致します。（弊社からの発送日になります。）"
          />
          <InfoRow
            label="返品・交換について"
            value={
              <>
                ●当社の過失による写真の間違え、不良品について
                <br />
                注文と異なる写真が届いた、写真の汚れなど、当社の過失による場合には、当社実費負担にてお取り替えさせていただきます。
                <br />
                その場合には、予め「お問い合わせフォーム」よりご連絡をお願いします。
                <br />
                「お問い合わせフォーム」には、「交換を希望する」旨、および具体的な内容についてご入力ください。
                (例：注文と異なる写真が届いた、合計枚数と届いた枚数が違う、など。)
                <br />
                その後、着払いにて商品をご返送ください。
                商品到着後、新しい商品をお送りさせていただきます。
                <br />
                <br />
                ●お客様の注文間違えによる写真の交換
                <br />
                対応できかねますのでご了承ください。
                <br />
                ●配送業者による写真の破損や汚損などについて
                <br />
                配送中の事故による写真の破損や汚損については、配送業者に責を置きますので、配送業者に直接お問い合わせください。
              </>
            }
            isLastRow
          />
        </Box>
      </Box>
    </div>
  );
}
export default Tokutei;
