import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Grid,
} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import {Option} from "@/common/components/MenuItem/Option";
import {provinceList, ConvPrefNameToCode} from "@/common/utils/Prefecture";
import RequiredInputField from "@/features/member/auth/MyAccount/components/RequiredInputField";
import type {FormSectionProps} from "@/features/member/auth/MyAccount/types";
import {RequiredMark} from "@/features/guest/pages/order/components/RequiredMark";
import Logger from "@/common/utils/Logger";
export default function AddressFields({
  control,
  errors,
}: FormSectionProps) {
  const {setValue} = useFormContext();
  const handleChange = async (value: string) => {
    const regex = /^\d{3}-\d{4}$/;
    if (regex.test(value)) {
      try {
        const response = await fetch(`https://api.zipaddress.net/?zipcode=${value}`);
        const data = await response.json();
        if (data.code === 200) {
          const prefCode = ConvPrefNameToCode(data?.data?.pref);
          setValue("prefecture_code", prefCode);
          setValue("address", data?.data?.address);
          setValue("street_address", "");
          setValue("building_address", "");
        }
      } catch (error) {
        Logger.logger(error);
      }
    }
  };
  return (
    <Grid container spacing={2} marginLeft="0" width="100%" mt={0.5}>
      <Grid item md={12} xs={12} fontSize="16px" fontWeight="bold">
        住所
      </Grid>
      <Grid item md={6} xs={12} mt={-1}>
        <Grid item md={3} xs={12}>
          <InputLabel sx={{fontSize: 13}}>郵便番号<RequiredMark/></InputLabel>
        </Grid>
        <Grid item md={12} xs={12}>
          <Controller
            name="post_code"
            control={control}
            render={({field}) => (
              <RequiredInputField
                {...field}
                label=""
                isChipStatus={false}
                name="post_code"
                error={errors}
                placeholder="052-4468"
                onChange={(e) => {
                  field.onChange(e);
                  handleChange?.(e.target.value);
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid item md={3} xs={12}>
          <InputLabel sx={{fontSize: 13,}}>都道府県<RequiredMark/></InputLabel>
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth error={!!errors.prefecture_code}>
            <Controller
              name="prefecture_code"
              control={control}
              render={({field}) => (
                <Select
                  {...field}
                  placeholder="選択"
                  value={field.value || ""}
                  MenuProps={{
                    PaperProps: {
                      sx: {maxHeight: 200, width: 150,},
                    },
                  }}
                >
                  {provinceList.map((item) => (
                    <Option key={item.value} value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>
              {errors.prefecture_code ? (errors.prefecture_code.message as string) : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item md={12} xs={12} mt={-1}>
        <Grid item md={3} xs={12}>
          <InputLabel sx={{fontSize: 13,}}>市町村<RequiredMark/></InputLabel>
        </Grid>
        <Controller
          name="address"
          control={control}
          render={({field}) => (
            <RequiredInputField
              label=""
              {...field}
              isChipStatus={false}
              name="address"
              error={errors}
              placeholder="例）名古屋市"
            />
          )}
        />
      </Grid>
      <Grid item md={12} xs={12} mt={-1}>
        <Grid item md={3} xs={12}>
          <InputLabel sx={{fontSize: 13,}}>住所(丁目番地)<RequiredMark/></InputLabel>
        </Grid>
        <Controller
          name="street_address"
          control={control}
          render={({field}) => (
            <RequiredInputField
              label=""
              {...field}
              isChipStatus={false}
              name="street_address"
              error={errors}
              placeholder="例）1-1-1"
            />
          )}
        />
      </Grid>
      <Grid item md={12} xs={12} mt={-1}>
        <Grid item md={3} xs={12}>
          <InputLabel sx={{fontSize: 13,}}>
            建物名
          </InputLabel>
        </Grid>
        <Controller
          name="building_address"
          control={control}
          render={({field}) => (
            <RequiredInputField
              label=""
              {...field}
              isChipStatus={false}
              name="building_address"
              error={errors}
              placeholder="例）●●マンション 205号室"
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
