import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { Box, CardContent, CardHeader, Grid, Stack } from "@mui/material";
import Logger from "@/common/utils/Logger";
import { OrderFormData } from "@/common/utils/storage/types";
import { orderStorage } from "@/common/utils/storage/orderStorage";
import cartContext, { ICartItem } from "@/common/context/CartContext";
import useGetListCart from "@/features/guest/api/useGetListCart";
// import { updateCartStatus } from "@/features/guest/pages/confirm/api/UpdateCartStatus";
// import AuthContext from "@/common/context/AuthContext";
import { loadStripe, StripeElementsOptionsMode } from "@stripe/stripe-js";
import CustomModal from "@/common/components/Modal/CustomModal";
import { X as XIcon } from "@phosphor-icons/react/dist/ssr/X";
import ToastMessage from "@/common/utils/ToastUtils";
import { checkValidateProduct } from "@/common/utils/helper";
import { MESSAGE_ERROR_TO_RELOAD} from "@/common/utils/message.ts";
import ConfirmationActions from "./ConfirmAction";
import ConfirmationMessage from "./ConfirmMessage";
import { OrderSummary } from "./OrderSummary";
import { UserInfo } from "./UserInfo";
import { ShippingAndPayment } from "./ShippingAndPayment";
import { createOrder } from "../api/CreateOrder";
import { convertOrderDataToAPIFormat } from "../utils/orderDataConverter";
import { CartItem } from "../type";
import { formatCartItems } from '../utils';
import CheckoutForm from "../../payment/components/CheckoutForm";
const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY;
type ConfirmDetailProps = {
  orderFormData: Partial<OrderFormData>;
  cartItems: ICartItem[];
};
enum STRIPE_MODE {
  payment = "payment",
  setup = "setup",
  subscription = "subscription",
}
const stripePromise = loadStripe(stripePublicKey, {
  locale: "ja",
});
const options: StripeElementsOptionsMode = {
  mode: STRIPE_MODE.payment,
  currency: "jpy",
  appearance: {
    labels: "above",
    theme: "stripe",
  },
};
export function ConfirmDetail({
  orderFormData,
  cartItems
}: ConfirmDetailProps) {
  const { resetContext } = useContext(cartContext);
  // const { isSignedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formattedCartItems, setFormattedCartItems] = useState<CartItem[]>([]);
  const [isCredit, setIsCredit] = useState(false);
  const [orderId, setOrderId] = useState<number>(0);
  const { getGetListPricing } = useGetListCart();
  useEffect(() => {
    const fetchPricingAndFormat = async () => {
      if (cartItems.length > 0) {
        const idArray = cartItems.map(item => String(item.id));
        Logger.logger(idArray);
        const pricingResult = await getGetListPricing(idArray);
        const pricingData = pricingResult.data;
        const formatted = formatCartItems(cartItems, pricingData);
        setFormattedCartItems(formatted);
        Logger.logger(formatted);
      } else {
        setFormattedCartItems([]);
      }
    };
    fetchPricingAndFormat();
  }, [cartItems]);
  const handleRedirectOrderSumary = () => {
    navigate("/guest/carts");
  };
  const handleRedirectAddressDetails = () => {
    navigate("/guest/orders/order-info");
  };
  const redirectDelivery = () => {
    navigate("/guest/carts/delivery");
  };
  const redirectPayment = () => {
    navigate("/guest/carts/payment");
  };
  const redirectConfirm = async () => {
    const totalQuantity =  cartItems.reduce((total, item) => total + (item?.quantity || 0), 0);
    const listCarItemConvert = cartItems?.map((cartItem) => ({
      pricing_id: Number(cartItem.pricing_id),
      photo_id: Number(cartItem?.id),
      quantity: Number(cartItem.quantity) ||0,
      id: cartItem.id
    }));
    const isValid = await checkValidateProduct(listCarItemConvert,totalQuantity, orderFormData?.subTotal || 0)
    if(!isValid) {
      ToastMessage.showError(
        MESSAGE_ERROR_TO_RELOAD
      );
      return
    }
    const savedOrderData = orderStorage.getOrderForm();
    const updatedOrderFormData = {
      ...orderFormData,
      shippingFee: savedOrderData?.shippingFee,
      totalPrice: savedOrderData?.totalPrice,
      orderDetails: formattedCartItems
    };
    const apiOrderData = convertOrderDataToAPIFormat(updatedOrderFormData);
    try {
      const createResponse = await createOrder(apiOrderData);
      if(createResponse?.error){
        ToastMessage.showError(createResponse?.error);
      }
      if(savedOrderData.useStripe){
        setIsCredit(true)
        setOrderId(createResponse.result.id)
        return
      }
      orderStorage.clearOrderForm();
      resetContext();
      // if (isSignedIn && createResponse.result.cart_id) {
      //   await updateCartStatus(createResponse.result.cart_id, "PAID");
      // }
      navigate(`/guest/carts/complete?completeId=${createResponse.result.id}`, {
        state: { email: orderFormData.email || '' },
      });
    } catch (error) {
      Logger.logger(error);
    }
  };
  const handleClose = () => {
    setIsCredit(false);
  };
  const content = (
    <>
      <Box
        onClick={handleClose}
        width="100%"
        textAlign="right"
        sx={{ cursor: "pointer" }}
      >
        <XIcon fontSize="24px" />
      </Box>
      <CardHeader sx={{ paddingY: 1 }} title={<h3>支払い情報</h3>} />
      <CardContent>
        <Stack spacing={2}>
          <Elements stripe={stripePromise} options={{...options, amount: orderStorage.getOrderForm()?.totalPrice}}>
            <CheckoutForm orderId={orderId}/>
          </Elements>
        </Stack>
      </CardContent>
    </>
  );
  return (
    <>
      <CustomModal
        open={isCredit}
        handleClose={handleClose}
        content={content}
        sx={{
          width: {md: '40vw', xs: '80vw', xl: '25vw', sm: '90vw'} 
        }}
      />
      <Grid item md={12} xs={12}>
        <Stack spacing={4}>
          {OrderSummary({
            handleRedirect: handleRedirectOrderSumary,
            orderFormData,
            cartItems: formattedCartItems,
          })}
          {UserInfo({
            handleRedirect: handleRedirectAddressDetails,
            orderFormData,
          })}
          {ShippingAndPayment({
            paymentMethod: orderFormData.paymentMethod || "",
            redirectDelivery,
            redirectPayment,
            shippingContent: orderFormData.shippingMethod || "",
          })}
        </Stack>
        <ConfirmationMessage />
        <ConfirmationActions
          handleNavigate={redirectConfirm}
        />
      </Grid>
    </>
  );
}
export default ConfirmDetail;
