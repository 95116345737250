import React, { ReactElement, ReactNode } from "react";
import { Box, Modal, Typography } from "@mui/material";
import './styles.scss'
import handleCloseModal from "./helper";
type TProps = {
  open: boolean,
  handleClose: () => void,
  content: ReactNode,
  title?: string | undefined,
  sx?: {[key: string]: string | number | object},
  isModalDelete?:boolean
}
export default function CustomModal(props: TProps):ReactElement {
  const {open, handleClose, content, sx={}, title, isModalDelete} = props
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 600,
  };
  const handleOnClose = (_: React.MouseEvent | React.TouchEvent, reason: string) => {
    const contentElement = document.getElementById('content-modal')
    if (reason && reason === "backdropClick" && !isModalDelete) {
      if (contentElement) {
        contentElement.classList.remove('zoomin')
        contentElement.classList.add('zoombig')
        setTimeout(() =>{
          contentElement.classList.remove('zoombig')
        }, 110)
      }
    } else handleCloseModal(handleClose)
  }
  return (
    <Modal id='custom-modal' open={open} onClose={handleOnClose} className="colorin"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box id='content-modal' className='zoomin' sx={{
          backgroundColor: 'white',
          boxShadow: 24,
          py: 1,
          px: 1.5,
          borderRadius: "0.25rem",
          width: 600,
          ...sx
        }}
        >
          {title && <Typography color="#000" fontWeight={500} mb={2} pt={1} pl={2}>{title}</Typography>}
          {content}
        </Box>
      </Box>
    </Modal>
  )
}