import { Card, CardContent, Divider, Typography } from "@mui/material";
import { PropertyList, PropertyItem } from "@/features/guest/pages/confirm/components";
import * as React from "react";
import { ShippingMethod } from "../types";
interface ShippingMethodListProps {
    methods: ShippingMethod[];
}
/**
 *
 * @param root0
 * @param root0.methods
 */
function ShippingMethodList({ methods }: ShippingMethodListProps) {
  return (
    <Card>
      <CardContent>
        <PropertyList
          divider={<Divider />}
          sx={{ "--PropertyItem-padding": "12px 24px" }}
        >
          {methods.map((method) => (
            <PropertyItem
              key={method.id}
              name={<Typography  color="text.primary">{method.name}</Typography>}
              value={<Typography variant="subtitle2" color="text.secondary">{method.remark}</Typography>}
            />
          ))}
        </PropertyList>
      </CardContent>
    </Card>
  );
}
export default ShippingMethodList;
