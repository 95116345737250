import { Box, Typography, Container } from "@mui/material";
// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
import { useEffect } from "react";
import FaqMain from "./components/FaqMain";
function FAQ() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <Box>
      {/* <Breadcumbs breadcrumbs={[{ title: "トップ" }, { title: "FAQ" }]} /> */}
      <Container maxWidth="md" sx={{ mt: 7, mb: 5 }}>
        <Typography mt={3} variant="h4" textAlign="left" fontWeight="bold">
          FAQ
        </Typography>
      </Container>
      <FaqMain />
    </Box>
  );
}
export default FAQ;
