import { useEffect, useState } from "react";
import HttpClientEvent from "@/common/api/HttpClientEvent";
import { GET_TIME_RESALE_TIME_PATH } from "@/common/constant/apiConstant";
import { TypeResponseTimeResaleEvent } from "../types";
const useGetTimeResellEvent = () => {
  const [data, setData] = useState<TypeResponseTimeResaleEvent>();
  const [errors, setErrors] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const getTimeResellEvent = async () => {
    setLoading(true);
    try {
      const response = await HttpClientEvent.Get({
        path: GET_TIME_RESALE_TIME_PATH,
      });
      setData(response.data);
    } catch (error) {
      setErrors((error as Error).message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getTimeResellEvent();
  }, []);
  return {
    data,
    getTimeResellEvent,
    errors,
    loading,
  };
};
export default useGetTimeResellEvent;
