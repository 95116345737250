import Typography from "@mui/material/Typography";
import { Box, Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ToastMessage from "@/common/utils/ToastUtils";
import { orderStorage } from "@/common/utils/storage/orderStorage";
import useGetListCart from "@/features/guest/api/useGetListCart";
import { TypeListCartItem } from "@/features/guest/types";
import { isNaN, isNumber } from "lodash";
import { formatComma } from "@/common/utils/amount";
import { MESSAGE_ERROR_TO_RELOAD} from "@/common/utils/message.ts";
import { checkValidateProduct } from "@/common/utils/helper";
const isMoreThanZero = (value?: string | number) => {
  let v = value;
  if (!isNumber(v)) v = Number(v);
  if (isNaN(v)) v = 0;
  return v > 0;
};
export function CartOverview(props: {
  totalValue?: string | number;
  totalPriceValue?: string | number;
  listCarItem: TypeListCartItem[];
}) {
  const { totalValue, totalPriceValue, listCarItem } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const { compareListCart } = useGetListCart();
  const handleClickProceedCheckout = async () => {
    const listCarItemConvert = listCarItem?.map((cartItem) => ({
      pricing_id: Number(cartItem.pricing_id) || 0,
      photo_id: cartItem.photo_id || 0,
      quantity: Number(cartItem.quantity) || 0,
      id: cartItem.id || 0,
    }));
    const isValidQuantity =
        await checkValidateProduct(listCarItemConvert, Number(totalValue) || 0, Number(totalPriceValue) || 0)
    const response = await compareListCart(listCarItemConvert)
    if(isValidQuantity) {
      orderStorage.saveOrderForm({ subTotal: response?.total_price });
      navigate("/guest/orders/order-info");
      return;
    }
    ToastMessage.showError(
      MESSAGE_ERROR_TO_RELOAD
    );
  };
  return (
    <Box>
      <Typography textAlign="left" fontSize="24px" fontWeight="600" padding="16px 16px 0 16px">
        現在の合計
      </Typography>
      <Box textAlign="left" marginTop="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            margin: "0 30px 20px 30px",
            paddingBottom: "5px",
            borderBottom: `2px solid ${theme.palette.primary.light}`,
          }}
        >
          <Typography
            component="span"
            fontWeight="600"
            sx={{ color: theme.palette.primary.light }}
            marginBottom="5px"
            fontSize="17px"
          >
            数量
          </Typography>
          <Typography component="span" fontWeight="600" fontSize="22px">{formatComma(totalValue)}枚</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            margin: "0 30px 10px 30px",
            paddingBottom: "5px",
            borderBottom: `2px solid ${theme.palette.primary.light}`,
          }}
        >
          <Typography
            component="span"
            fontWeight="600"
            sx={{ color: theme.palette.primary.light }}
            marginBottom="5px"
            fontSize="17px"
          >
            商品小計
          </Typography>
          <Typography component="span" fontWeight="600" fontSize="22px">
            {formatComma(totalPriceValue)}円
          </Typography>
        </Box>
      </Box>
      <Box p={2} textAlign="center">
        {isMoreThanZero(totalValue) ? (
          <Button
            variant="contained"
            fullWidth
            onClick={handleClickProceedCheckout}
          >
            レジへ進む
          </Button>
        ) : null}
      </Box>
    </Box>
  );
}
