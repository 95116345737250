// import moment from "moment";
// import { YYYYMMDD } from "../constant/constant";
import { differenceInDays, parseISO, format } from "date-fns";
/**
 * カレンダーの初期年月(yyyy-mm)を取得する
 * @returns {string} カレンダーの初期月
 */
const getCurrentYYYYMM = (): string => {
  const now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;
  const day = now.getDate();
  if (day >= 10) {
    month += 1;
    if (month > 12) {
      month = 1;
      year += 1;
    }
  }
  return `${year}-${month.toString().padStart(2, "0")}`;
};
/**
 * YYYY-MMを年月表記に変換する
 * @param {string} yyyyMM -- 年月
 * @returns {string} 年月表記
 */
const convertYYYYMMtoJapaneseFormat = (yyyyMM: string): string => {
  const [year, month] = yyyyMM.split("-");
  return `${year}年${month}月`;
};
/**
 * YYYY-MM-DDを年月日表記に変換する
 * @param {string} yyyyMMDD -- 年月日
 * @returns {string} 年月日表記
 */
const convertYYYYMMDDtoJapaneseFormat = (yyyyMMDD: string): string => {
  const [year, month, day] = yyyyMMDD.split("-");
  return `${year}年${month}月${day}日`;
};
/**
 * 翌月(yyyy-mm)を計算する
 * @param {string} currentYYYYMM -- 現在の年月
 * @returns {string} 翌月
 */
const getNextMonth = (currentYYYYMM: string): string => {
  let [year, month] = currentYYYYMM.split("-").map(Number);
  month += 1;
  if (month > 12) {
    month = 1;
    year += 1;
  }
  return `${year}-${month.toString().padStart(2, "0")}`;
};
/**
 * 前月(yyyy-mm)を計算する
 * @param {string} currentYYYYMM -- 現在の年月
 * @returns {string} 前月
 */
const getBeforeMonth = (currentYYYYMM: string): string => {
  let [year, month] = currentYYYYMM.split("-").map(Number);
  month -= 1;
  if (month < 1) {
    month = 12;
    year -= 1;
  }
  return `${year}-${month.toString().padStart(2, "0")}`;
};
/**
 * YYYY-MMから月を取得する
 * @param {string} targetYYYYMM -- 対象年月
 * @returns {number} 月
 */
const getMonthFromYYYYMM = (targetYYYYMM: string): number => {
  const [, month] = targetYYYYMM.split("-");
  return Number(month);
};
/**
 * YYYY-MM-DDから日を取得する
 * @param {string} targetYYYYMMDD -- 対象年月日
 * @returns {number} 日
 */
const getDayFromYYYYMMDD = (targetYYYYMMDD: string): number => {
  const [, , day] = targetYYYYMMDD.split("-");
  return Number(day);
};
/**
 * YYYY-MM-DDから曜日を判定する
 * @param {string} targetDate -- 対象日
 * @returns {string} 曜日
 */
const getGetWeekName = (targetDate: string): string => {
  const date = new Date(targetDate);
  const dayOfWeekNum = date.getDay();
  const daysOfWeek = ["日", "月", "火", "水", "木", "金", "土"];
  return daysOfWeek[dayOfWeekNum];
};
export const convertToDateFormat = (dateString?: string) => {
  if (!dateString) return "";
  // ISO 8601形式の文字列をDateオブジェクトに変換
  const date = parseISO(dateString);
  // 指定された形式にフォーマット
  return format(date, "yyyy/MM/dd HH:mm");
};
// 残り日数を取得する
export const getDaysRemaining = (dateString?: string) => {
  if (!dateString) return 0;
  // 現在の日時
  const now = new Date();
  // 文字列をDate オブジェクトに変換
  const targetDate = parseISO(dateString);
  // 日数の差を計算
  const daysRemaining = differenceInDays(targetDate, now);
  return daysRemaining;
};
/**
 * export
 */
export default {
  getCurrentYYYYMM,
  convertYYYYMMtoJapaneseFormat,
  convertYYYYMMDDtoJapaneseFormat,
  getNextMonth,
  getBeforeMonth,
  getMonthFromYYYYMM,
  getDayFromYYYYMMDD,
  getGetWeekName,
  getDaysRemaining,
};
