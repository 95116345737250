import HttpClientUserEvent from "@/common/api/HttpClientUserEvent";
import Logger from "@/common/utils/Logger";
const SHIPPING_API_COMMON_PATH = "user/shipping-fees";
const GET_SHIPPING_FEE_FAILED = "配送料の取得に失敗しました";
type GetShippingFeeResponse = {
    fee: number;
};
export const getShippingFee = async (prefectureCode: number, shippingMethodId: number) => {
  try {
    const response = await HttpClientUserEvent.Get({
      path: `${SHIPPING_API_COMMON_PATH}/prefecture/${prefectureCode}/method/${shippingMethodId}`,
    });
    if (response.status === 200) {
      return { result: response.data[0] as GetShippingFeeResponse };
    }
    return { result: { items: [] }, error: GET_SHIPPING_FEE_FAILED };
  } catch (e) {
    Logger.logger(e);
    return { result: { fee: 0 }, error: e };
  }
}; 