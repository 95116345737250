import HttpClient from "@/common/api/HttpClient";
import { CHECK_USER_IS_CONFIRMED } from "@/common/constant/apiConstant";
import { RequestForgotPasswordType } from "@/common/constant/type";
export const executeCheckConfirmedAccount = async ({
  email,
}: RequestForgotPasswordType) => {
  const checkUserIsConfirm = await HttpClient.Post({
    path: `${CHECK_USER_IS_CONFIRMED}`,
    data: {
      email,
    },
  });
  return checkUserIsConfirm?.data?.is_verified;
};
