import moment from "moment";
import Cookies from "universal-cookie";
import {ICartItem} from "@/common/context/CartContext.tsx";
import useGetListCart from "@/features/guest/api/useGetListCart.tsx";
import {VIEW_MODE_OPTION} from "@/common/utils/constant.ts";
import { provinceList } from './Prefecture';
/**
 * getDayOfWeek
 * @param {Date} start .
 * @param {Date} end .
 * @param {number[]} days .
 * @returns {Date[]} .
 */
export const getDayOfWeek = (start: Date, end: Date, days: number[]): string[] => {
  const dates: string[] = []
  while (start <= end) {
    if (days.includes(start.getDay())) dates.push(moment(start).format('YYYY-MM-DD'))
    start.setDate(start.getDate() + 1)
  }
  return dates
}
/**
 * ショップIDを取得する
 * @returns {string} . shop_id
 */
export const getShopId = () => {
  const cookies = new Cookies(null, { path: '/' });
  return cookies.get("user")?.shop_id
}
/**
 * addMinutes
 * @param {string} time .
 * @param {number} minutesToAdd .
 * @returns {string} .
 */
export const addMinutes = (time: string | undefined, minutesToAdd: number | undefined) => {
  if (time && minutesToAdd) {
    let [hours, minutes] = time.split(":").map(Number)
    minutes += minutesToAdd
    hours += Math.floor(minutes/60)
    minutes %= 60
    hours %= 24
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`
  }
  return ""
}
/**
 * Combine many array into one
 * @param {number} maxLength 0 will return full length
 * @param {...any} arrays example func(5, ['1', '2'], ['1', '3', '4'])
 * @returns {string[]} return ['1', '2', '3', '4']
 */
export const combineUniqueStringArr= (maxLength: number, ...arrays: (string | number)[][]): (string | number)[]=> {
  // Combine all arrays and flatten them into a single array
  const combinedArray = [...new Set(arrays.flat())];
  // Return array with specific length or not
  return maxLength ? combinedArray.slice(0, maxLength) : combinedArray;
}
/**
 *
 * @param username
 */
export const getEmailFromUsernameCognito = (username: string) => {
  const nameSplited = username.split("_")
  nameSplited.shift()
  return nameSplited.join("_")
}
/**
 *
 * @param email
 */
export const getEmailWithHost = (email: string) => {
  const { host } = window.location
  return `${host}_${email}`
}
/**
 *
 * @param prefectureCode
 */
export const getPrefectureName = (prefectureCode: number): string => {
  const prefecture = provinceList.find(p => p.value === prefectureCode);
  return prefecture?.label || '';
};
/**
 *
 * @param listCarItem
 * @param totalQuantity
 * @param totalPriceValue
 */
export const checkValidateProduct = async (listCarItem: ICartItem[], totalQuantity: number, totalPriceValue: number)=> {
  const { compareListCart } = useGetListCart();
  const response = await compareListCart(listCarItem)
  return (response?.total_quantity === totalQuantity &&
      response?.total_price === totalPriceValue)
}
/**
 *
 * @param maxQuantity
 * @param minQuantity
 */
export const genderOptionSelectQuantityOrder = (maxQuantity: number | undefined, minQuantity: number | undefined) => {
  const options2: { label: string; value: string }[] = [];
  const checkMax = (maxQuantity) ? maxQuantity + 1 : 99 + 1
  const checkMin = (minQuantity) || 1
  for (let index = checkMin; index < checkMax; index += 1)
    options2.push({label: `${index}`, value: `${index}`});
  return options2
}
/**
 *
 * @param options
 * @param value
 */
export const genderStyleBorderError =
    (options: { label: string; value: string }[], value: number | undefined) =>
      options?.find((item) => item.value.toString() ===
        value?.toString()) ? '' : '1px solid #fa566b !important;'
/**
 *
 * @param viewMode
 */
export const optionViewMode = (viewMode: string) => {
  switch (viewMode) {
  case VIEW_MODE_OPTION.SMALL:
    return {
      md: 4,
      lg: 3,
      xs: 6,
    }
  default:
    return  {
      md: 4,
      sm: 6,
      xs: 12,
    }
  }
}