import {Button, Grid} from "@mui/material";
// ボタン部分
/**
 *
 * @param root0
 * @param root0.onNavigate
 */
export function ActionButtons({
  onNavigate,
}: {
  onNavigate: (path: string) => void;
}) {
  return (
    <Grid
      container
      marginLeft="0"
      width="100%"
      sx={{display: 'flex', flexDirection: {
        xs: 'column-reverse',
        sm: 'row'
      }, alignItems: 'center', gap: '19px', flexWrap: 'noWrap'}}
    >
      <Button
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={() => onNavigate("/guest/carts")}
      >
            カートへ戻る
      </Button>
      <Button type="submit" fullWidth variant="contained">
        お支払い方法選択へ進む
      </Button>
    </Grid>
  );
}
