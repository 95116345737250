import HttpClientEvent from "@/common/api/HttpClientEvent";
import HttpClientUserEvent from "@/common/api/HttpClientUserEvent";
import {
  COMPARE_LIST_CART,
  GET_LIST_PRICING,
  USER_ITEM_CART,
} from "@/common/constant/apiConstant";
import { TypeListCartItem } from "@/features/guest/types";
import {ICartItem} from "@/common/context/CartContext.tsx";
type PhotoId = string | number;
const useGetListCart = () => {
  const getGetListCart = async () => {
    try {
      const response = await HttpClientUserEvent.Get({
        path: USER_ITEM_CART,
      });
      return response.data;
    } catch (error) {
      return [];
    }
  };
  const updateListCart = async (dataBody: TypeListCartItem[]) => {
    try {
      const response = await HttpClientUserEvent.Put({
        path: USER_ITEM_CART,
        data: { items: dataBody },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  };
  const compareListCart = async (dataBody: TypeListCartItem[] | ICartItem[]) => {
    try {
      const response = await HttpClientEvent.Post({
        path: COMPARE_LIST_CART,
        data: { items: dataBody },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  };
  const getGetListPricing = async (photoId: PhotoId[]) => {
    try {
      const response = await HttpClientEvent.Get({
        path: GET_LIST_PRICING,
        params: {
          photo_ids: photoId.map((id) => id.toString()).join(","),
        },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  };
  return {
    getGetListCart,
    getGetListPricing,
    updateListCart,
    compareListCart,
  };
};
export default useGetListCart;
