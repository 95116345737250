import {useContext, useMemo} from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import {useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import AuthContext from "@/common/context/AuthContext";
import {genderOptionSelectQuantityOrder, genderStyleBorderError} from "@/common/utils/helper.ts";
import {CartItemProps} from "../types";
/**
 *
 * @param props
 */
export function CartItem(props: CartItemProps) {
  const { item, pricing,category, onOpenDelete, onOpenImage, calculTotal, quantity,
    maxQuantity, minQuantity, calculTotalPrice, listCarItem } = props;
  const {isSignedIn: isLoginUser} = useContext(AuthContext);
  const { photo_original_file: photoName , photo_presigned_url: photoPresigned, presigned_url: presignedUrl  } = item;
  const photo = photoPresigned || presignedUrl;
  const theme = useTheme();
  const options2 = useMemo(() => genderOptionSelectQuantityOrder(maxQuantity, minQuantity), [maxQuantity, minQuantity]);
  return (
    <>
      <Card
        sx={{
          height: {xs: "360px", md: "146px",
          },
          borderTopLeftRadius: 0, borderBottomLeftRadius: 0,
          borderTopRightRadius: "4px", borderBottomRightRadius: "4px",
          borderLeft:`3px solid ${theme.palette.primary.light}`
        }}
      >
        <Grid container p={1.25} columnSpacing={2} rowSpacing={{md: 4, xs: 1}} pt={{md: 0.7}}>
          <Grid item md={3.5} xs={12} xl={2.5}>
            <Box
              onClick={onOpenImage}
              display="flex"
              justifyContent="center"
              sx={{
                cursor: "pointer",
              }}
            ><img src={`${photo?.[0]}`} alt={photoName} style={{ maxWidth: 200, maxHeight: 135 }}/>
            </Box>
          </Grid>
          <Grid item md={8.5} xs={12} xl={9.5}>
            <Typography fontWeight={700} textAlign="left" mb={1} pt={{md: 2}}>
              {photoName}
            </Typography>
            <Grid container display="flex" spacing={2}>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <Select
                    sx={{
                      "& .MuiSelect-select": {
                        maxWidth: "90%",
                        textOverflow: "ellipsis",
                        paddingRight: 0,
                      },
                    }}
                    name={`${item.pricing_price}`}
                    variant="outlined"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '10%',
                          overflow: 'hidden',
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        },
                      },
                    }}
                    defaultValue={(() => {
                      if (!isLoginUser) {
                        const cartItemFind = listCarItem?.find(
                          (cartItem) => cartItem.id === item.id
                        );
                        return cartItemFind?.pricing_id?.toString();
                      }
                      return (
                        pricing?.find(
                          (pricingItem) =>
                            pricingItem?.pricing_id === item?.pricing_id
                        )?.pricing_id || 0
                      ).toString();
                    })()}
                    onChange={(val: SelectChangeEvent<string>) =>
                      calculTotalPrice?.(val, item.id, category)
                    }
                  >
                    {pricing?.map((option) => (
                      <MenuItem
                        key={option.pricing_id}
                        value={option.pricing_id}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: 'block',
                        }}
                      >
                        {`${option?.pricing_name} ${
                          option?.pricing_price
                            ? `(${option?.pricing_price}円) `
                            : ""
                        }`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2.5} xs={12}>
                <FormControl fullWidth>
                  <Select
                    name={item?.id?.toString()}
                    variant="outlined"
                    sx={{width: {
                      xs: "100%",
                      border: genderStyleBorderError(options2, quantity || item.quantity)
                    }}}
                    defaultValue={(() => {
                      if (!isLoginUser) {
                        return quantity?.toString()
                      }
                      return options2[
                        item?.quantity?.toString() ? item.quantity - 1 : 0
                      ]?.value;
                    })()}
                    onChange={(val: SelectChangeEvent<string>) =>
                      calculTotal?.(val, item.id)
                    }
                  >
                    {options2.map(
                      (option: { value?: string; label?: string }) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button fullWidth variant="outlined" onClick={onOpenDelete}
                  sx={{ maxWidth: {
                    xs: "100%",
                  }}}>
                  削除
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
