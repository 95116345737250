import { isNaN } from "lodash";
const baseFormatter = new Intl.NumberFormat("ja-JP");
const formatterWithJpyUnit = new Intl.NumberFormat("ja-JP", {
  style: "currency",
  currency: "JPY",
});
/**
 * ex1) 1000 => 1,000
 * ex2) 1234567 => 1,234,567
 * @param amount
 * @returns カンマ区切りの数字
 */
export const formatAmountComma = (amount: number): string => baseFormatter.format(amount);
/**
 * ex1) 1000 => ¥1,000
 * ex2) 1234567 => ¥1,234,567
 * @param amount
 * @returns ¥ & カンマ区切りの数字
 */
export const formatAmountCommaWithUnit = (amount: number): string => formatterWithJpyUnit.format(amount);
const STR_ZERO = "0";
/**
 * パフォーマンスを考慮する場合に使用する
 * toLocaleString()より早い場合がある
 * ex1) 1000 => 1,000
 * ex2) 1234567 => 1,234,567
 * @param amount 
 * @returns カンマ区切りの数字
 */
export const formatComma = (amount?: number | string) => {
  if(!amount) return STR_ZERO;
  const val = Number(amount)
  if(isNaN(val)) return STR_ZERO;
  return formatAmountComma(val);
};
