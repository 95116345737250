import { MouseEvent, useState } from "react";
interface ImageMagnifierProps {
  src: string;
  className?: string;
  magnifierHeight: number;
  magnifierWidth: number;
  zoomLevel: number;
  alt:string
}
function ImageMagnifier({
  src,
  className,
  alt,
  magnifierHeight = 150,
  magnifierWidth = 150,
  zoomLevel = 2,
}: ImageMagnifierProps) {
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [[x, y], setXY] = useState([0, 0]);
  const mouseEnter = (e: MouseEvent<HTMLImageElement>) => {
    const el = e.currentTarget;
    const { width, height } = el.getBoundingClientRect();
    setSize([width, height]);
    setShowMagnifier(true);
  };
  const mouseLeave = (e: MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    setShowMagnifier(false);
  };
  const mouseMove = (e: MouseEvent<HTMLImageElement>) => {
    const el = e.currentTarget;
    const { top, left } = el.getBoundingClientRect();
    const x = e.pageX - left - window.scrollX;
    const y = e.pageY - top - window.scrollY;
    setXY([x, y]);
  };
  return (
    <div style={{ position: "relative", cursor: "none", width: "100%" }}>
      <img
        src={src}
        className={className}
        width="100%"
        height="100%"
        style={{ maxHeight: "100%", position: "absolute", top: 0 }}
        alt={alt}
        onMouseEnter={(e) => mouseEnter(e)}
        onMouseLeave={(e) => mouseLeave(e)}
        onMouseMove={(e) => mouseMove(e)}
      />
      <div
        style={{
          display: showMagnifier ? "" : "none",
          position: "absolute",
          pointerEvents: "none",
          height: `${magnifierHeight}px`,
          width: `${magnifierWidth}px`,
          opacity: "1",
          border: "1px solid lightgrey",
          backgroundColor: "white",
          borderRadius: "50%",
          backgroundImage: `url('${src}')`,
          backgroundRepeat: "no-repeat",
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifierWidth / 2}px`,
          backgroundSize: `${imgWidth * zoomLevel}px ${
            imgHeight * zoomLevel
          }px`,
          backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
        }}
      />
    </div>
  );
}
export default ImageMagnifier;
