import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import {IProps} from "@/common/utils/interfaces";
import useLocalStorage from "@/common/hooks/use-localStorage";
import authContext from "@/common/context/AuthContext";
import useAddItemCart from "@/features/guest/pages/FolderPhotoDetail/apis/useAddItemCart";
import useGetListCart from "@/features/guest/api/useGetListCart";
import {CART_KEY} from "../constant/constant.ts";
export type ICartItem = {
  id: number | string;
  pricing_id?: number;
  quantity?: number;
  photo_id?: number;
}
const Context = createContext<{
  cartItems: ICartItem[];
  setCartItems: (items: ICartItem[]) => void;
  totalCartItems: number;
  handleChangeCartLocal: (item: ICartItem) => void;
  resetContext: () => void;
  setTotalCartItems: (val: number) => void;
    }>({
      cartItems: [],
      setCartItems: () => null,
      totalCartItems: 0,
      handleChangeCartLocal: () => null,
      setTotalCartItems: () => null,
      resetContext: () => null
    })
export function CartContext({children}: IProps) {
  const {isSignedIn} = useContext(authContext);
  const {getGetListCart} = useGetListCart();
  const [cartItems, setCartItems] = useLocalStorage<ICartItem[]>(CART_KEY, []);
  const [totalCartItems, setTotalCartItems] = useState<number>(0)
  const resetContext = () => {
    setTotalCartItems(0)
    setCartItems([])
  }
  const handleChangeCartLocal = (newItem: ICartItem) => {
    setCartItems(prev => prev.map((oldItem: ICartItem) => {
      if (newItem.id === oldItem.id) {
        return {
          ...oldItem,
          pricing_id: newItem.pricing_id ? newItem.pricing_id : oldItem.pricing_id,
          quantity: newItem.quantity ? newItem.quantity : oldItem.quantity
        }
      }
      return oldItem;
    }));
  }
  const {addItemCart, total: totalItemsDatabase} = useAddItemCart();
  const syncDataLocalToDatabase = async () => {
    const dataSyncLocal = cartItems.map((localItem: ICartItem) => ({
      photo_id: localItem.id,
      quantity: localItem.quantity,
    }))
    if (dataSyncLocal.length) {
      const data = await addItemCart({
        items: dataSyncLocal
      });
      if (data) {
        setCartItems([]);
      }
    }
  }
  const getListCartDB = async () => {
    const response = await getGetListCart();
    if (response) {
      setTotalCartItems(response.total_item)
    }
  }
  useEffect(() => {
    (async () => {
      if (isSignedIn) {
        await syncDataLocalToDatabase();
        await getListCartDB();
      }
    })()
  }, [isSignedIn]);
  useEffect(() => {
    if (!isSignedIn) {
      setTotalCartItems(cartItems.length)
    } else {
      setTotalCartItems(totalItemsDatabase || 0);
    }
  }, [cartItems, totalItemsDatabase]);
  const contextValue = useMemo(
    () => ({
      cartItems,
      setCartItems,
      totalCartItems,
      handleChangeCartLocal,
      setTotalCartItems,
      resetContext
    }),
    [cartItems, setCartItems, totalCartItems, handleChangeCartLocal, setTotalCartItems]
  );
  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}
export default Context;