import { tabMyPageKey } from "@/common/constant/constant";
const MY_PAGE_PATH = "/member/mypage";
export const paths = {
  home: "/guest/",
  folder: {
    list: "/guest/folders",
    detail: (folderId: string) => `/guest/folder/${folderId}`,
  },
  price: {
    photo: "/guest/prices/photo",
    pay: "/guest/payment-delivery",
  },
  cart: {
    cart: "/guest/carts",
  },
  myPageTabs:{
    favorite:`${MY_PAGE_PATH}?tab=${tabMyPageKey.FAVORITE}`,
    hidden:`${MY_PAGE_PATH}?tab=${tabMyPageKey.HIDDEN}`,
    history:`${MY_PAGE_PATH}?tab=${tabMyPageKey.HISTORY}`,
    myAccount:`${MY_PAGE_PATH}?tab=${tabMyPageKey.MY_ACCOUNT}`,
    orderHistory:`${MY_PAGE_PATH}?tab=${tabMyPageKey.ORDER_HISTORY}`,
  },
  privacy: "/privacy-policy",
  const: "/guest/Const/tokutei",
  guide: "/usage-guide",
  contact: "/contacts",
  contactSuccess: "/contact-success",
  check: "/checks/uenv",
  memberMyaAcount: "/member/myaccount",
} as const;
export const publicUrls: string[] = [paths.contact, paths.guide, paths.privacy];
