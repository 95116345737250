import { RouterLink } from "@/common/components/Link/link";
import { paths } from "@/common/constant/paths";
import { Box, Card, Chip, Stack, Typography } from "@mui/material";
import { EyeSlash } from "@phosphor-icons/react";
import {useContext} from "react";
import appContext from "@/common/context/ImagesContext.tsx";
function InfoFolderHiden() {
  const {totalItemHidden} = useContext(appContext);
  return (
    <Card>
      <Box
        padding="10px 15px"
        display="flex"
        justifyContent="space-between"
        sx={{
          color: "text.primary",
          ":hover": {
            color: "text.primary",
          },
        }}
        component={RouterLink}
        href={`${paths.myPageTabs.hidden}`}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <EyeSlash size={16} />
          <Typography>非表示リスト</Typography>
        </Stack>
        <Chip color="primary" label={`${totalItemHidden}/100`} size="small" variant="soft" />
      </Box>
    </Card>
  );
}
export default InfoFolderHiden;
