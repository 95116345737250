import HttpClient from "@/common/api/HttpClient";
import { USER_CONFIRM_FORGOT_PASSWORD } from "@/common/constant/apiConstant";
import { ConfirmForgotPasswordType } from "@/common/constant/type";
export const executeConfirmForgotPassword = async ({
  email,
  password,
  resetCode,
}: ConfirmForgotPasswordType) => {
  const res = await HttpClient.Post({
    path: USER_CONFIRM_FORGOT_PASSWORD,
    data: {
      email,
      reset_code: resetCode,
      password,
    },
  });
  return res;
};
