import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useContext } from "react";
import AuthContext from "@/common/context/AuthContext";
import CartNonItem from "@/features/guest/pages/cart/components/CartNonItem";
import { CartItem } from "./CartItem";
import { CartDetailsProps } from "../types";
/**
 *
 * @param props
 */
export function CartDetails(props: CartDetailsProps) {
  const { isSignedIn } = useContext(AuthContext);
  const { data, calculTotal,listCarItem, calculTotalPrice, onOpenImage, openDelete } =
    props;
  return (
    <Box>
      {data?.length <= 0 && <CartNonItem />}
      {data?.map((category, index) => (
        <Box mt={3} key={`${index + 1}`}>
          <Typography textAlign="left" variant="h5" mb={1}>
            {category?.name}
          </Typography>
          <Box mt={2} display="flex" flexDirection="column" gap={{ xs: 2, md: 4 }}>
            {isSignedIn ? (
              <>
                {category?.cart_items?.map((item) => (
                  <CartItem
                    key={item.id}
                    item={item}
                    maxQuantity={data[0]?.max_quantity}
                    minQuantity={data[0]?.min_quantity}
                    pricing={category.pricings}
                    category={category}
                    onOpenDelete={() =>
                      openDelete({ category: item.id, id: item.id })
                    }
                    onOpenImage={() => onOpenImage("")}
                    calculTotal={calculTotal}
                    calculTotalPrice={calculTotalPrice}
                    listCarItem={listCarItem}
                  />
                ))}
              </>
            ) : (
              <>
                {category?.photo?.map((item) => {
                  const quantity = Number(listCarItem.find((cart) =>  cart.id === item.id)?.quantity)
                  return <CartItem
                    key={item.id}
                    item={item}
                    pricing={category.pricings}
                    maxQuantity={data[0]?.max_quantity}
                    minQuantity={data[0]?.min_quantity }
                    quantity={quantity}
                    category={category}
                    onOpenDelete={() =>
                      openDelete({ category: (item.id).toString(), id: (item.id) })
                    }
                    onOpenImage={() => onOpenImage("")}
                    calculTotal={calculTotal}
                    listCarItem={listCarItem}
                    calculTotalPrice={calculTotalPrice}
                  />
                })}
              </>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
}
