import HttpClient from "@/common/api/HttpClient";
import { USER_REQUEST_FORGOT_PASSWORD } from "@/common/constant/apiConstant";
import { RequestForgotPasswordType } from "@/common/constant/type";
export const executeRequestForgotPassword = async ({
  email,
}: RequestForgotPasswordType) => {
  const res = await HttpClient.Post({
    path: USER_REQUEST_FORGOT_PASSWORD,
    data: {
      email,
    },
  });
  return res;
};
