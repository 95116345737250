import { ControllerRenderProps, FieldErrors } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
  Stack,
} from "@mui/material";
type InputProps = OutlinedInputProps & {
  field: ControllerRenderProps;
  errors: FieldErrors;
  name: string;
};
/**
 *
 * @param props
 */
function Input(props: InputProps) {
  const { field, name, label, errors, type, fullWidth, ...others } =
    props;
  return (
    <FormControl error={Boolean(errors?.[name]?.message)} fullWidth={fullWidth}>
      <Stack>
        {label ? (
          <InputLabel htmlFor={name}>
            {label}{" "}
            {others.required ? <span style={{ color: "red" }}>*</span> : <></>}
          </InputLabel>
        ) : (
          <></>
        )}
        <OutlinedInput
          id={name}
          fullWidth={fullWidth}
          {...field}
          {...others}
          type={type ?? "text"}
        />
        {errors?.[name]?.message ? (
          <FormHelperText sx={{ marginLeft: 0 }}>
            {errors?.[name]?.message as string}
          </FormHelperText>
        ) : null}
      </Stack>
    </FormControl>
  );
}
export default Input;
