import { Box } from "@mui/material";
function BankIcon() {
  return (
    <Box width={24} height="18px">
      <svg
        viewBox="0 0 24 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="presentation"
        focusable="false"
        className="p-Logo p-Logo--md p-CardBrandIcon"
      >
        <rect fill="#016fd0" height="16" rx="2" width="24" />
        <path
          d="M13.764 13.394V7.692l10.148.01v1.574l-1.173 1.254 1.173 1.265v1.608h-1.873l-.995-1.098-.988 1.102z"
          fill="#fffffe"
        />
        <path
          d="M14.442 12.769v-4.45h3.772v1.026h-2.55v.695h2.49v1.008h-2.49v.684h2.55v1.037z"
          fill="#016fd0"
        />
        <path
          d="m18.195 12.769 2.088-2.227-2.088-2.222h1.616l1.275 1.41 
          1.28-1.41h1.546v.035l-2.043 2.187 2.043 2.164v.063H22.35l-1.298-1.424-1.285 1.424z"
          fill="#016fd0"
        />
        <path
          d="M14.237 2.632h2.446l.86 1.95v-1.95h3.02l.52 1.462.523-1.462h2.306v5.701H11.725z"
          fill="#fffffe"
        />
        <g fill="#016fd0">
          <path d="m14.7 3.251-1.974 4.446h1.354l.373-.89h2.018l.372.89h1.387L16.265
           3.25zm.17 2.558.592-1.415.592 1.415z" />
          <path d="M18.212 7.696V3.25l1.903.006.98
           2.733.985-2.74h1.832v4.446l-1.179.01V4.653L21.62 7.696h-1.075l-1.136-3.054v3.054z" />
        </g>
      </svg>
    </Box>
  );
}
export default BankIcon
