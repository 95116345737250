import { ChangeEvent } from "react";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { DeliveryMethodType } from "@/common/constant/type";
interface DeliveryMethodOptionsProps {
  deliveryMethods?: DeliveryMethodType[];
  selectedValue?: number;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
function DeliveryMethodOptions({
  selectedValue,
  handleChange,
  deliveryMethods = [],
}: DeliveryMethodOptionsProps) {
  return (
    <RadioGroup
      sx={{
        "& .MuiFormControlLabel-root": {
          border: "1px solid var(--mui-palette-divider)",
          borderRadius: 1,
          gap: 2,
          p: 2,
          position: "relative",
          "&::before": {
            borderRadius: "inherit",
            bottom: 0,
            content: '" "',
            left: 0,
            pointerEvents: "none",
            position: "absolute",
            right: 0,
            top: 0,
          },
        },
        "&.Mui-disabled": {
          bgcolor: "var(--mui-palette-background-level1)",
        },
      }}
      value={selectedValue}
      onChange={handleChange}
    >
      {deliveryMethods?.map((delivery) => (
        <FormControlLabel
          key={delivery.id}
          control={<Radio />}
          label={
            <div>
              <Typography variant="inherit" fontWeight={600} fontSize={16}>
                {delivery.name}
              </Typography>
              <Typography color="#757575" variant="body2" fontWeight={300}>
                {delivery.remark}
              </Typography>
            </div>
          }
          sx={{
            ...(selectedValue === delivery.id && {
              "&::before": {
                boxShadow: "0 0 0 2px var(--mui-palette-primary-main)",
              },
            }),
          }}
          value={delivery.id}
        />
      ))}
    </RadioGroup>
  );
}
export { DeliveryMethodOptions };
