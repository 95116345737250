// common data so must use type any
import { RequiredMark } from "@/features/guest/pages/order/components/RequiredMark";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
type TProps = {
  label: string;
  name: string;
  options: { value: number | string; label: string }[];
  required?: boolean;
    styleLabel?: object;
};
/**
 * SelectForm
 * @param {TProps} props .
 * @returns {ReactElement} .
 */
export default function SelectForm({ label, name, options, styleLabel ,required = false}: TProps): ReactElement {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <FormControl error={Boolean(errors?.[name]?.message)} variant="outlined" fullWidth>
      <InputLabel id={label} error={!!errors[name]} sx={styleLabel}>
        {label}
        {required && <RequiredMark />}
      </InputLabel>
      <Controller
        render={({ field: { value, onChange } }) => (
          <Select
            labelId={label}
            label={label}
            error={!!errors[name]}
            value={value || ""}
            onChange={onChange}
            style={{ marginTop: 0 }}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
        name={name}
        control={control}
      />
      {errors?.[name]?.message && (
        <FormHelperText sx={{ marginLeft: 0 }}>
          {errors?.[name]?.message as string}
        </FormHelperText>
      )}
    </FormControl>
  );
}
