import { OrderFormData } from "@/common/utils/storage/types";
import { provinceList } from "@/common/utils/Prefecture";
import { CreateOrderRequest } from "../type";
export const convertOrderDataToAPIFormat = (formData: Partial<OrderFormData>): CreateOrderRequest => {
  const prefectureName = provinceList.find(item => item.value === formData.prefecture_code)?.label;
  return {
    ...(formData.userId ? { user_id: formData.userId } : {}),
    ...(formData.cartId ? { cart_id: formData.cartId } : {}),
    status: "pending",
    order_details: JSON.stringify(formData.orderDetails || []).trim(),
    name: formData.name || '',
    furigana_name: formData.furigana || '',
    post_code: formData.postCode || '',
    address: `${prefectureName || ''}${formData.address || ''}${formData.streetAddress || ''}${
      formData.buildingAddress || ''
    }`.trim(),
    email: formData.email || '',
    phone_number: formData.phoneNumber || '',
    ...(formData.coupon_id ? { coupon_id: formData.coupon_id } : {}),
    payment_method_id: formData.paymentMethodId || 0,
    shipping_method_id: formData.shippingMethodId || 0,
    shipping_fee: formData.shippingFee || 0,
    shipping_method: formData.shippingMethod || '',
    handling_fee: formData.paymentFee || 0,
    payment_method: formData.paymentMethod || '',
    subtotal_price: formData.subTotal || 0,
    discount_amount: 0,
    total_price: formData.totalPrice || 0,
    delete_flg: false,
  }};