/* eslint-disable no-underscore-dangle */
import Logger from "@/common/utils/Logger";
import axios, { AxiosHeaders } from "axios";
import { storage } from "../utils/storage";
import { USER_EVENT_REFRESH_TOKEN } from "../constant/apiConstant";
const baseUrl = import.meta.env.VITE_BACKEND_BASE_API_URL;
const axiosInstanceEvent = axios.create();
axiosInstanceEvent.interceptors.request.use(async (config) => {
  try {
    const tokenEvent = storage.getEventToken();
    if (tokenEvent) {
      const updatedConfig = {
        ...config,
        headers: AxiosHeaders.from(config.headers || {}),
      };
      updatedConfig.headers.set("X-Event-Token", `Bearer ${tokenEvent}`);
      return updatedConfig;
    }
    return config;
  } catch (e) {
    Logger.logger("error");
    Logger.logger(e);
    throw e;
  }
});
axiosInstanceEvent.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshTokenEvent = storage.getEventRefreshToken();
        if (refreshTokenEvent) {
          const responseRefresh = await axios.post(
            `${baseUrl}${USER_EVENT_REFRESH_TOKEN}`,
            { refresh_token: refreshTokenEvent }
          );
          storage.setEventRefreshToken(responseRefresh.data.refresh_token);
          storage.setEventToken(responseRefresh.data.access_token);
          originalRequest.headers[
            "X-Event-Token"
          ] = `Bearer ${responseRefresh.data.access_token}`;
          return axios(originalRequest);
        }
      } catch (tokenError) {
        storage.clearUserEvent();
        window.location.href = "/auth/login";
        return Promise.reject(tokenError);
      }
    }
    return Promise.reject(error);
  }
);
const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(async (config) => {
  try {
    const tokenUser = storage.getToken();
    if (tokenUser) {
      const updatedConfig = {
        ...config,
        headers: AxiosHeaders.from(config.headers || {}),
      };
      updatedConfig.headers.set("Authorization", `Bearer ${tokenUser}`);
      return updatedConfig;
    }
    return config;
  } catch (e) {
    // If refresh token failed then kick out user to page login
    Logger.logger("error");
    Logger.logger(e);
    throw e;
    // window.location.href = "/admin/" + login;
    // return config; // Add this line to always return the config
  }
});
const axiosInstanceUserEvent = axios.create();
axiosInstanceUserEvent.interceptors.request.use(async (config) => {
  try {
    const tokenEvent = storage.getEventToken();
    const tokenUser = storage.getToken();
    const updatedConfig = {
      ...config,
      headers: AxiosHeaders.from(config.headers || {}),
    };
    if (tokenUser) {
      updatedConfig.headers.set("Authorization", `Bearer ${tokenUser}`);
    }
    if (tokenEvent) {
      updatedConfig.headers.set("X-Event-Token", `Bearer ${tokenEvent}`);
      return updatedConfig;
    }
    return updatedConfig;
  } catch (e) {
    Logger.logger("error");
    Logger.logger(e);
    throw e;
  }
});
axiosInstanceUserEvent.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshTokenEvent = storage.getEventRefreshToken();
        if (refreshTokenEvent) {
          const responseRefresh = await axios.post(
            `${baseUrl}${USER_EVENT_REFRESH_TOKEN}`,
            { refresh_token: refreshTokenEvent }
          );
          storage.setEventRefreshToken(responseRefresh.data.refresh_token);
          storage.setEventToken(responseRefresh.data.access_token);
          originalRequest.headers[
            "X-Event-Token"
          ] = `Bearer ${responseRefresh.data.access_token}`;
          const res = await axios(originalRequest);
          return res
        }
      } catch (tokenError) {
        storage.clearUserEvent();
        window.location.href = "/auth/login";
        return Promise.reject(tokenError);
      }
    }
    return Promise.reject(error);
  }
);
export { axiosInstanceEvent, axiosInstanceUserEvent };
export default axiosInstance;
