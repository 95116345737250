import {Typography, Grid} from "@mui/material"
import {OrderSummaryDetailsProps} from "../type"
function OrderSummaryDetails({items}: OrderSummaryDetailsProps) {
  return (
    <Grid container sx={{ justifyContent: { xs: "center", sm: "flex-end" } }}>
      <Grid item xs={8} sm={6} md={4}>
        {items.map((item) => (
          <Grid container key={item.label}>
            <Grid item xs={6}>
              <Typography
                variant={item.isTotal ? "subtitle1" : "caption"}
                fontSize="14px"
                color="#757575"
              >
                {item.label}
              </Typography>
            </Grid>
            <Grid item xs sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography
                variant={item.isTotal ? "h5" : "subtitle1"}
                fontWeight={600}
                color={item.isTotal ? "var(--mui-palette-primary-main)" : ""}
              >
                {item.value.toLocaleString("en-US")}円
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
export default OrderSummaryDetails