import HttpClientEvent from "@/common/api/HttpClientEvent";
import HttpClientUserEvent from "@/common/api/HttpClientUserEvent";
import {
  FAVORITE_PHOTO_API,
  GET_LIST_PHOTO_BY_IDS,
  HIDDEN_PHOTO_API,
} from "@/common/constant/apiConstant";
type PhotoId = string | number;
type TypePhotoFavorite = {
  page: number;
  per_page: number;
};
const usePhotoStatus = () => {
  const getListPhotoFavorite = async (params: TypePhotoFavorite) => {
    try {
      const response = await HttpClientUserEvent.Get({
        path: FAVORITE_PHOTO_API,
        params,
      });
      return response?.data;
    } catch (error) {
      return [];
    }
  };
  const getListPhotoByIds = async (photoId: PhotoId[]) => {
    const response = await HttpClientEvent.Get({
      path: GET_LIST_PHOTO_BY_IDS,
      params: {
        ids: photoId.map((id) => id.toString()).join(","),
      },
    });
    return response?.data;
  };
  const getPhotoFavoriteById = async (photoId: PhotoId[]) => {
    const response = await HttpClientUserEvent.Get({
      path: FAVORITE_PHOTO_API,
      data: {
        photoIds: photoId.map((id) => id.toString()).join(","),
      },
    });
    return response.data.data;
  };
  const addPhotoFavorite = async (photoId: PhotoId[]) => {
    await HttpClientUserEvent.Post({
      path: FAVORITE_PHOTO_API,
      data: {
        photo_ids: photoId.map((id) => id.toString()).join(","),
      },
    });
  };
  const deletePhotoFavorite = async (photoId: PhotoId) => {
    await HttpClientUserEvent.Delete({
      path: `${FAVORITE_PHOTO_API}/${photoId}`,
    });
  };
  const getListPhotoHidden = async (params: TypePhotoFavorite) => {
    try {
      const response = await HttpClientUserEvent.Get({
        path: HIDDEN_PHOTO_API,
        params,
      });
      return response.data;
    } catch (error) {
      return [];
    }
  };
  const getPhotoHidden = async () => {
    try {
      const response = await HttpClientUserEvent.Get({
        path: HIDDEN_PHOTO_API,
      });
      return response.data;
    } catch (error) {
      return [];
    }
  };
  const addPhotoHidden = async (photoId: PhotoId[]) => {
    await HttpClientUserEvent.Post({
      path: HIDDEN_PHOTO_API,
      data: {
        photo_ids: photoId.map((id) => id.toString()).join(","),
      },
    });
  };
  const deletePhotoHidden = async (photoId: PhotoId) => {
    await HttpClientUserEvent.Delete({
      path: `${HIDDEN_PHOTO_API}/${photoId}`,
    });
  };
  return {
    getListPhotoFavorite,
    getPhotoFavoriteById,
    addPhotoFavorite,
    deletePhotoFavorite,
    getPhotoHidden,
    addPhotoHidden,
    deletePhotoHidden,
    getListPhotoByIds,
    getListPhotoHidden,
  };
};
export default usePhotoStatus;
