import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { memberMyAccountSchema, myAccountSchema } from "@/common/utils/schema";
import React, { useContext, useEffect, useState } from "react";
import ToastMessage from "@/common/utils/ToastUtils";
import Loading from "@/common/components/Loading";
import authContext from "@/common/context/AuthContext";
import EditIcon from '@mui/icons-material/Edit';
import { getEmailWithHost } from "@/common/utils/helper";
import CustomModal from "@/common/components/Modal/CustomModal";
import {MESSAGE_ERROR_COMMON} from "@/common/utils/message.ts";
import FormMyAccount from "./FormMyAccount";
import useEditMyAccount, { type MemberAccount } from "../api/useEditMyAccount";
import useGetMyMemberAccount from "../api/useGetMyMemberAccount";
import useCreateMyAccount from "../api/useRegisterAccount";
import ConfirmSignupForm from "../../login/components/ConfirmSignupForm";
import { executeConfirmAccount } from "../../api/executeConfirmAccount";
import FormEditMyAccount from "./FormEditMyAccount";
function MyAccountMain() {
  const { login, userInfo } = useContext(authContext);
  const [isWaitingConfirmUser, setIsWaitingConfirmUser] = useState(false);
  const { data, loading, getList } = useGetMyMemberAccount();
  const { editAccount } = useEditMyAccount();
  const { registerAccount, loading : loadingSignup} = useCreateMyAccount();
  const [hasErrorConfirm, setHasErrorConfirm] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const methods = useForm({
    resolver: yupResolver(data ? myAccountSchema : memberMyAccountSchema),
    defaultValues: {
      email: "",
      name: "",
      furigana_name: "",
      post_code: "",
      prefecture_code: 13,
      address: "",
      street_address: "",
      building_address: "",
      phone_number: "",
      password: "",
      confirmpassword: "",
    },
  });
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = methods;
  const onSubmit = async (formData: Partial<MemberAccount>) => {
    try {
      if (!data) {
        const responseRegister = await registerAccount(formData);
        if (responseRegister) {
          ToastMessage.showSuccess("更新しました。");
          setIsWaitingConfirmUser(true);
        }
      } else {
        const { ...bodyRequest } = formData;
        if (bodyRequest.email) delete bodyRequest?.email;
        const response = await editAccount(bodyRequest);
        if (response) {
          ToastMessage.showSuccess("更新しました。");
          setIsOpenModal(false)
          await getList();
        }
      }
    } catch (error) {
      ToastMessage.showError(
        MESSAGE_ERROR_COMMON
      );
    }
  };
  const handleSubmitConfirm = async (values: { code: string }) => {
    const formValues = methods.getValues();
    const response = await executeConfirmAccount({ email: formValues.email, code: values.code });
    if(response){
      await login({
        email: getEmailWithHost(formValues.email),
        passcode: formValues.password,
      });
      await getList();
      setIsWaitingConfirmUser(false);
    } else {
      setHasErrorConfirm(true);
    }
  };
  useEffect(() => {
    if (data) {
      reset({
        email: data.email,
        name: data.name,
        furigana_name: data.furigana_name,
        post_code: data.post_code,
        prefecture_code: data.prefecture_code,
        address: data.address,
        street_address: data.street_address,
        building_address: data.building_address,
        phone_number: data.phone_number,
      });
    }
  }, [data, reset]);
  useEffect(() => {
    if (userInfo.id) {
      getList()
    }
  }, [userInfo.id]);
  if (loading) {
    return <Loading />;
  }
  if (isWaitingConfirmUser)
    return <ConfirmSignupForm
      username={methods?.getValues()?.email}
      onSubmit={handleSubmitConfirm}
      hasError={hasErrorConfirm}
    />;
  return (
    <FormProvider {...methods}>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <FormMyAccount data={data}/>
          </CardContent>
          <CardActions sx={{ justifyContent: "center" }}>
            <Button sx={{width: {md: '25%', xs: '85%'}}} variant="contained" disabled={loadingSignup} 
              onClick={() => setIsOpenModal(true)}
              startIcon={<EditIcon fontSize="small"  />}
            >
              変更する
            </Button>
          </CardActions>
        </Card>
      </Box>
      <CustomModal
        open={isOpenModal}
        handleClose={() => setIsOpenModal(false)}
        content={
          <FormEditMyAccount errors={errors} control={control} data={data} 
            onCancel={() => setIsOpenModal(false)} onSubmit={handleSubmit(onSubmit)}/>
        }
        sx={{ width: '95vw' , maxWidth: 742 }}
        data-testid="custom-modal"
      />
    </FormProvider>
  );
}
export default MyAccountMain;
