import { Box, CardContent, CardHeader, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import authContext from "@/common/context/AuthContext";
import { confirmSignupSchema } from "@/common/utils/schema";
import {useContext, useEffect} from "react";
import { Alert, LoadingButton } from "@mui/lab";
import TextFieldForm from "@/common/components/TextField/TextFieldForm";
import { X as XIcon } from "@phosphor-icons/react/dist/ssr/X";
type ConfirmSignupFormProps = {
  username?: string;
  loading?: boolean;
  onCancel?: () => void;
  onSubmit: (values: { code: string }) => Promise<void>;
  hasError?: boolean;
};
const transformDestination = (username = "") => {
  const [name, domain] = username.split("@");
  const firstInName = name?.[0];
  const firstInDomain = domain?.[0];
  const sub = "***";
  return `${firstInName}${sub}@${firstInDomain}${sub}`;
};
function ConfirmSignupForm({
  onSubmit,
  username,
  onCancel,
  loading,
  hasError
}: ConfirmSignupFormProps) {
  const { message } = useContext(authContext);
  const destination = transformDestination(username);
  const methods = useForm({
    resolver: yupResolver(confirmSignupSchema),
  });
  useEffect(() => {
    if(hasError){
      methods.setError("code", {
        message: "コードが無効です。"
      })
    }
  }, [hasError]);
  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        noValidate
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {onCancel && (
          <Box
            width="100%"
            textAlign="right"
            sx={{ cursor: "pointer" }}
            onClick={onCancel}
          >
            <XIcon fontSize="24px" />
          </Box>
        )}
        <CardHeader title={<h3>コードを送信しました</h3>} />
        <CardContent>
          <Typography textAlign="left">
            メールに記載されたコードを入力してください。送信先: {destination}.
            到着するまでに 1 分かかることがあります。.
          </Typography>
          {message ? (
            <Alert severity="error" sx={{ margin: "20px 0", width: "100%" }}>
              {message}
            </Alert>
          ) : (
            <></>
          )}
          <Box marginTop={4}>
            <TextFieldForm
              fullWidth
              name="code"
              label="確認コード"
              placeholder="確認コード"
              size="medium"
              type="tel"
            />
            <Box display="flex" justifyContent="center">
              <LoadingButton
                sx={{ marginTop: 2, minWidth: 90 }}
                loading={loading}
                variant="contained"
                type="submit"
                color="primary"
              >
                確定
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
      </Box>
    </FormProvider>
  );
}
export default ConfirmSignupForm;
