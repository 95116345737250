import { Chip, FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import type { FieldErrors } from "react-hook-form";
import PasswordInput from "@/common/components/TextField/PasswordInput";
import type React from "react";
function RequiredInputField({
  label,
  placeholder,
  type = "text",
  error,
  name,
  onChange,
  isChipStatus = true,
  ...props
}: {
  label: string;
  placeholder: string;
  name: string;
  isChipStatus?: boolean
  type?: string;  
  error: FieldErrors;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <FormControl fullWidth error={!!error[name]}>
      <InputLabel>
        {label}
        {isChipStatus && <Chip
          color="error"
          label="必須"
          size="small"
          variant="soft"
          sx={{ml: 1}}
        />}
      </InputLabel>
      {type === "password" ?
        <PasswordInput {...props} placeholder={placeholder} name={name} onChange={onChange} /> :
        <OutlinedInput {...props} type={type} placeholder={placeholder} onChange={onChange} />}
      {!!error[name] && (
        <FormHelperText>
          {error[name]?.message ? (error[name].message as string) : ""}
        </FormHelperText>
      )}
    </FormControl>
  );
}
export default RequiredInputField;
