import {Box, Button, Card, Grid, Link, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
function CompleteOrderMain() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const rawCompleteId = searchParams.get('completeId') || '';
  const completeId = rawCompleteId.padStart(7, '0');
  const email = location.state?.email || '';
  return (
    <>
      <Helmet>
        <title>ご注文ありがとうございます</title>
      </Helmet>
      <Card sx={{ mt: 4, borderRadius:"5px" }}>
        <Box sx={{ p: { xs: 3, md: 7 } }}>
          <Grid container spacing={8}>
            <Grid item md={7} xs={12}>
              <Typography
                color="var(--mui-palette-primary-main)"
                variant="h4"
                fontWeight="600"
                display="flex"
                justifyContent="center"
                sx={{
                  fontSize: {
                    xs: "20px",
                    md: "30px",
                    lg: "34px",
                  },
                }}
              >
                ご注文ありがとうございます
              </Typography>
              <Box
                sx={{
                  background: "#F7F7F7",
                  px: { xs: 2, md: 4 },
                  py: 4,
                  display: "flex",
                  gap: { xs: 3, md: 6 },
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  mt: isXs ? 3 : 6,
                }}
              >
                <Typography
                  color="#757575"
                  fontWeight="600"
                  sx={{ fontSize: { xs: 14, md: 19 } }}
                >
                  ご注文番号
                </Typography>
                <Typography
                  fontWeight="600"
                  sx={{ fontSize: { xs: 24, md: 30 } }}
                >
                  {completeId}
                </Typography>
              </Box>
              <Box sx={{ mt: isXs ? 2 : 4 }}>
                <Typography variant="subtitle2" color="#757575">
                  ご注文完了メールを「{email}
                  」に送信しましたのでご確認ください。メールが届かない場合はお手数ではございますが、下記連絡先まで「ご注文番号」をご連絡ください。
                  <br />
                  またのご利用をお待ちしております。
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              md={5}
              xs={12}
              sx={{ ...(isXs && { paddingTop: "24px !important" }) }}
            >
              <Stack spacing={1}>
                <Typography variant="h5" color="#222" fontWeight="600">
                  お問い合わせ先
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#757575"
                  fontWeight="600"
                >
                  担当者
                </Typography>
                <Typography variant="subtitle1" color="#222" fontWeight="300">
                  Rainbow photoサポートデスク
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#757575"
                  fontWeight="600"
                >
                  TEL
                </Typography>
                <Typography variant="subtitle1" color="#222" fontWeight="300">
                  03-5833-2650
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#757575"
                  fontWeight="600"
                >
                  メールアドレス
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight="300"
                  sx={{
                    ...(isXs && { fontSize: "20px" }),
                  }}
                >
                  <Link color="#2243FE">rainbowphoto-info@stk-i.co.jp</Link>
                </Typography>
              </Stack>
              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                sx={{ height: 56, mt: 4 }}
                onClick={() => navigate("/guest/folders")}
              >
                <Typography variant="h6" fontWeight="600" sx={{color:"#757575"}}>
                  TOPへ戻る
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
}
export default CompleteOrderMain;