import { NavItemConfig } from "@/common/types/nav";
import { paths } from "../../common/constant/paths";
// NOTE: We did not use React Components for Icons, because
//  you may one to get the config from the server.
// NOTE: First level elements are groups.
export interface LayoutConfig {
  navItems: NavItemConfig[];
}
export const navItemsConfig = (countCart: number = 0) => [
  {
    key: "dashboards",
    title: "Dashboards",
    items: [
      {
        key: "folders",
        title: "トップ",
        href: paths.folder.list,
        icon: "house",
      },
      {
        key: "price",
        title: "料金表",
        href: paths.price.photo,
        icon: "money",
      },
      {
        key: "price-pay",
        title: "お支払い・送料",
        href: paths.price.pay,
        icon: "truck",
      },
      {
        key: "cart",
        title: "カート",
        href: paths.cart.cart,
        icon: "cart",
        count: countCart || undefined,
      },
    ],
  }
];
export const listLinkNav: { title: string; href: string }[] = [
  {
    title: "トップ",
    href: paths.folder.list,
  },
  {
    title: "カート",
    href: paths.cart.cart,
  },
  {
    title: "料金表",
    href: paths.price.photo,
  },
  {
    title: "お支払い・送料",
    href: paths.price.pay,
  },
  {
    title: "個人情報保護方針",
    href: paths.privacy,
  },
  {
    title: "特定商取引法に基づく表記",
    href: paths.const,
  },
  {
    title: "推奨動作環境",
    href: paths.check,
  },
];
export const listLinkNavGuest: { title: string; href: string }[] = [
  {
    title: "個人情報保護方針",
    href: paths.privacy,
  },
  {
    title: "特定商取引法に基づく表記",
    href: paths.const,
  },
  {
    title: "推奨動作環境",
    href: paths.check,
  },
];