import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CircularProgress, Grid } from "@mui/material";
import { Alert } from "@mui/lab";
import Button from "@mui/material/Button";
import LayoutContext from "@/features/guest/auth/layout-context";
import CustomTypography from "@/common/components/Typography/Typography";
import { AuthFormProps } from "@/features/guest/auth/constant/constant";
import { useSubmit } from "@/features/guest/auth/hookCustom/useSubmit";
import { resetPasswordSchema } from "@/common/utils/schema";
import TextFieldForm from "@/common/components/TextField/TextFieldForm";
function ResetPasswordForm({ onSuccess }: AuthFormProps) {
  const { message, loading } =
    useContext(LayoutContext);
  const methods = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });
  const onSubmit = useSubmit({ onSuccess });
  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        noValidate
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Grid item xs={12} sx={{ mb: 1 }}>
          <CustomTypography variant="h3" text="パスワードリセットbb" />
        </Grid>
        {message ? (
          <Alert severity="error" sx={{ margin: "20px 0", width: "100%" }}>
            {message}b
          </Alert>
        ) : (
          <></>
        )}
        <TextFieldForm
          fullWidth
          name="email"
          label="メールアドレス"
          required
          size="small"
        />
        <Grid item xs={12} style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            startIcon={loading ? <CircularProgress size={20} /> : <></>}
            sx={{ width: "50%" }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            コードを送信
          </Button>
        </Grid>
      </Box>
    </FormProvider>
  );
}
export default ResetPasswordForm;
