import TextFieldForm from "@/common/components/TextField/TextFieldForm";
import {Box, FormControl, InputLabel, Typography} from "@mui/material";
import SelectForm from "@/common/components/Select/SelectForm";
import {CONTACT_TYPES} from "@/common/constant/constant.ts";
import React from "react";
import {RequiredMark} from "./RequiredMark";
export function AddressFields() {
  return (
    <>
      <Box width="100%" display="flex" flexDirection="column" gap="20px">
        <Box display="flex" flexDirection="column" width="100%" sx={{gap: '19px'}}>
          <Typography fontSize="16px" fontWeight={600}>連絡先</Typography>
          <Box sx={{display: "flex", flexDirection: {
            xs:"column",
            sm: "row"
          }, gap: {
            xs: '16px',
            sm: '19px'}
          }} width="100%">
            <Box width="100%">
              <InputLabel sx={{ fontWeight: 600, fontSize: 13, color:"#757575" }}>
                          連絡先
                <RequiredMark />
              </InputLabel>
              <FormControl fullWidth>
                <TextFieldForm
                  name="telnum"
                  fullWidth
                  required
                  placeholder="0120-000-000"
                />
              </FormControl>
            </Box>
            <Box width="100%">
              <InputLabel  sx={{ fontWeight: 600, fontSize: 13, color:"#757575" }}>
                          メールアドレス
                <RequiredMark />
              </InputLabel>
              <FormControl fullWidth>
                <TextFieldForm
                  name="email"
                  fullWidth
                  required
                  placeholder="abcd@gmail.com"
                />
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="100%" sx={{gap: '19px'}}>
          <Typography fontSize="16px" fontWeight={600}>内容</Typography>
          <Box sx={{display: "flex", flexDirection: {
            xs:"column",
            sm: "row"
          }, gap: {
            xs: '16px',
            sm: '19px'}
          }} width="100%">
            <Box width="100%">
              <FormControl fullWidth sx={{ mb: 1 }}>
                <InputLabel sx={{ fontSize: 13,}}>
                              お問い合わせ種別
                  <RequiredMark />
                </InputLabel>
                <SelectForm options={CONTACT_TYPES} name="contact_type" label="" />
              </FormControl>
            </Box>
            <Box width="100%" />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="100%" sx={{gap: '19px'}}>
          <Box sx={{display: "flex", flexDirection: {
            xs:"column",
            sm: "row"
          }, gap: {
            xs: '16px',
            sm: '19px'}
          }} width="100%">
            <Box width="100%">
              <InputLabel sx={{ fontSize: 13 }}>
                          お問い合わせ内容
                <RequiredMark />
              </InputLabel>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <TextFieldForm
                  required
                  minRows={3}
                  multiline
                  name="message"
                  fullWidth
                  placeholder={`サービスに関する詳細情報を教えてください。\nまた、料金プランについても知りたいです。`}
                />
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
