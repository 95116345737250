type TypeIconLogout = {
  width?: string;
  height?: string;
  color?: string;
  style?: object
};
/**
 *
 * @param props
 */
export default function IconLogout(props: TypeIconLogout) {
  const { width = "23", height = "19", color = "#FFC758", style } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 23 19"
      style={style}
      fill="none"
    >
      <path
        d="M0.868013 10.221C0.469352 9.82232 0.469352 
        9.17768 0.868013 8.78326L6.29658 3.35045C6.69525 
        2.95179 7.33989 2.95179 7.73431 3.35045C8.12873 
        3.74911 8.13297 4.39375 7.73431 4.78817L4.04458 
        8.4779L12.5057 8.48214C13.0698 8.48214 13.5236 
        8.93594 13.5236 9.5C13.5236 10.0641 13.0698 10.5179 
        12.5057 10.5179H4.04458L7.73431 14.2076C8.13297 
        14.6063 8.13297 15.2509 7.73431 15.6453C7.33565 
        16.0397 6.691 16.044 6.29658 15.6453L0.868013 10.221ZM15.1604 
        2.03571C14.5964 2.03571 14.1426 1.58192 14.1426 1.01786C14.1426 
        0.453795 14.5964 0 15.1604 0H18.5533C20.6144 0 22.2854 
        1.67098 22.2854 3.73214V15.2679C22.2854 17.329 20.6144 
        19 18.5533 19H15.1604C14.5964 19 14.1426 18.5462 14.1426 
        17.9821C14.1426 17.4181 14.5964 16.9643 15.1604 
        16.9643H18.5533C19.4906 16.9643 20.2497 16.2051 
        20.2497 15.2679V3.73214C20.2497 2.79487 19.4906 
        2.03571 18.5533 2.03571H15.1604Z"
        fill={color}
      />
    </svg>
  );
}
