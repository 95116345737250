import ListImageComponent from "@/common/components/ImageCard/ListImageComponent";
import {useContext} from "react";
import imagesContext from "@/common/context/ImagesContext";
import {Stack, Box} from "@mui/material";
import ButtonChangePageView from "@/common/components/Button/ButtonChangePageView";
import {optionViewMode} from "@/common/utils/helper";
type Props = {
  viewMode: string;
  onClickImage: () => void;
};
function ListImageHidden({ viewMode, onClickImage }: Props) {
  const {
    dataHiddenImages,
    handleDeletePhotoHidden,
    totalPageHidden,
    currentPageHidden,
    handlePageChangeHidden,
  } = useContext(imagesContext);
  const handleCloseHidden = async (imageId: string | number) => {
    handleDeletePhotoHidden(imageId);
  };
  return (
    <Box marginTop={3}>
      <ListImageComponent
        images={dataHiddenImages}
        onClickImage={onClickImage}
        optionViewMode={optionViewMode(viewMode)}
        onClickClose={handleCloseHidden}
        isShowClose
        isShowFavorite={false}
        isShowHide={false}
      />
      <Stack marginTop={3} direction="row" justifyContent="center">
        <ButtonChangePageView
          totalPage={totalPageHidden}
          currentPage={currentPageHidden}
          handlePageChange={handlePageChangeHidden}
        />
      </Stack>
    </Box>
  );
}
export default ListImageHidden;
