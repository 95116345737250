enum SignInStateEnum {
  newPasswordRequires = "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED",
  customChallenge = "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE",
  totpCode = "CONFIRM_SIGN_IN_WITH_TOTP_CODE",
  totpSetup = "CONTINUE_SIGN_IN_WITH_TOTP_SETUP",
  smsCode = "CONFIRM_SIGN_IN_WITH_SMS_CODE",
  mfaSelection = "CONTINUE_SIGN_IN_WITH_MFA_SELECTION",
  resetPassword = "RESET_PASSWORD",
  confirmSignup = "CONFIRM_SIGN_UP",
  signIn = "SIGN_IN",
  signUp = "SIGN_UP",
  forgot = "FORGOT",
  done = "DONE",
}
export default SignInStateEnum;
