import HttpClientUserEvent from "@/common/api/HttpClientUserEvent";
import Logger from "@/common/utils/Logger";
import { CreateOrderRequest } from "../type";
const ORDER_API_COMMON_PATH = "user/order";
const CREATE_ORDER_FAILED = "注文の登録に失敗しました";
export const createOrder = async (data: CreateOrderRequest) => {
  try {
    const response = await HttpClientUserEvent.Post({
      path: `${ORDER_API_COMMON_PATH}/create`,
      data,
    });
    if (response.status === 201) {
      return { result: response.data };
    }
    return { result: {}, error: CREATE_ORDER_FAILED };
  } catch (e) {
    Logger.logger(e);
    return { result: {}, error: CREATE_ORDER_FAILED };
  }
};
