const emailRegex = /^\S+@\S+$/i;
const phoneNumberRegex = /^(?=.*\d)[\d-]{10,14}$/;
const katanakaRegex = /^([ァ-ン]|ー)+$/;
const urlRegex = /https:\/\/[^\s/$.?#].[^\s]*$/i;
const numberRegex = /^-?\d*\.?\d+$/;
const passwordRegex =
  /^(?!\s+)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]).{8,256}(?<!\s)$/;
const postCode = /^\d{3}-\d{4}$/;
const phoneNumber = /^(?=.*\d)[\d-]{10,14}$/;
const password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
const couponRegex = /^[0-9]*$/;
export {
  emailRegex,
  phoneNumberRegex,
  katanakaRegex,
  urlRegex,
  numberRegex,
  passwordRegex,
  postCode,
  phoneNumber,
  password,
  couponRegex,
};
