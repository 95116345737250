import { CartItem } from "@/features/guest/pages/confirm/type";
// 保存するデータの型定義
export interface OrderFormData {
  userId: number;
  cartId: number;
  orderDetails: CartItem[];
  name: string;
  furigana: string;
  postCode: string;
  prefecture_code: number;
  address: string;
  streetAddress: string;
  buildingAddress?: string;
  phoneNumber: string;
  email: string;
  coupon_id?: number;
  shippingMethodId: number;
  shippingMethod: string;
  shippingFee: number;
  paymentMethodId: number;
  paymentMethod: string;
  paymentFee: number;
  subTotal: number;
  totalPrice: number;
  useStripe: boolean
}
// ストレージキーの定義
export const STORAGE_KEYS = {
  ORDER_FORM: 'orderForm',
} as const; 