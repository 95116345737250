import React from "react";
import {
  Box,
  Breadcrumbs as BreadcrumbsMaterial,
  BreadcrumbsProps,
  // Link,
  Typography,
} from "@mui/material";
// import { BreadcrumbsSeparator } from "@/common/components/Breadcumbs/BreadcrumbsSeparator";
// import { RouterLink } from "@/common/components/Link/link";
import BreadcrumbsSeparator from "./BreadcrumbsSeparator";
interface CustomBreadcrumbsProps extends BreadcrumbsProps {
  breadcrumbs: {
    title: string;
    href?: string;
    isShowLink?: boolean;
    icon?: React.ReactElement;
  }[];
}
function Breadcumbs({ breadcrumbs }: CustomBreadcrumbsProps) {
  return breadcrumbs ? (
    <div>
      <BreadcrumbsMaterial separator={<BreadcrumbsSeparator />}>
        {breadcrumbs.map((breadcrumb, index) => {
          if (breadcrumbs.length - 1 === index || breadcrumb.isShowLink) {
            return (
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                key={breadcrumb.title}
              >
                {!!breadcrumb.icon && breadcrumb.icon}
                <Typography
                  color="text.secondary"
                  key={breadcrumb.title}
                  variant="subtitle2"
                >
                  {breadcrumb.title}
                </Typography>
              </Box>
            );
          }
          return (
            // TODO:リンクは一旦不要
            // <Link
            //   color="text.primary"
            //   component={RouterLink}
            //   href={breadcrumb.href!}
            //   key={breadcrumb.title}
            //   variant="subtitle2"
            // >
            //   {breadcrumb.title}
            // </Link>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              key={breadcrumb.title}
            >
              {!!breadcrumb.icon && breadcrumb.icon}
              <Typography
                color="text.secondary"
                key={breadcrumb.title}
                variant="subtitle2"
              >
                {breadcrumb.title}
              </Typography>
            </Box>
          );
        })}
      </BreadcrumbsMaterial>
    </div>
  ) : null;
}
export default Breadcumbs;
