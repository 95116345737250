import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import {
  PencilSimple as PencilSimpleIcon
} from "@phosphor-icons/react/dist/ssr";
interface ShippingAndPaymentProps {
  redirectDelivery: () => void;
  redirectPayment: () => void;
  shippingContent: string;
  paymentMethod: string;
}
export function ShippingAndPayment({
  redirectDelivery,
  redirectPayment,
  shippingContent,
  paymentMethod,
}: ShippingAndPaymentProps) {
  return (
    <>
      <Card style={{ borderRadius: "5px" }}>
        <CardHeader
          titleTypographyProps={{
            variant: "subtitle1",
            fontWeight: "600",
          }}
          title="配送方法"
          action={
            <Button
              color="secondary"
              startIcon={<PencilSimpleIcon />}
              onClick={redirectDelivery}
            >
              変更する
            </Button>
          }
        />
        <CardContent>
          <Typography
            style={{
              border: "1px solid #EBEBEB",
              borderRadius: "4px",
              padding: "10px",
            }}
          >
            {shippingContent}
          </Typography>
        </CardContent>
      </Card>
      <Card style={{ borderRadius: "5px" }}>
        <CardHeader
          titleTypographyProps={{
            variant: "subtitle1",
            fontWeight: "600",
          }}
          title="お支払い方法"
          action={
            <Button
              color="secondary"
              startIcon={<PencilSimpleIcon />}
              onClick={redirectPayment}
            >
              変更する
            </Button>
          }
        />
        <CardContent>
          <Typography
            style={{
              border: "1px solid #EBEBEB",
              borderRadius: "4px",
              padding: "10px",
            }}
          >
            {paymentMethod}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
} 