import { Button, CircularProgress } from "@mui/material";
import React from "react";
type Props = {
  isLoading?: boolean;
  text?: string;
  disabled?: boolean;
};
/**
 *
 * @param root0
 * @param root0.isLoading
 * @param root0.text
 * @param root0.disabled
 */
function ButtonSubmit({ isLoading, text = "ログインする", disabled }: Props) {
  return (
    <Button
      startIcon={isLoading ? <CircularProgress size={20} /> : null}
      disabled={disabled || isLoading}
      variant="contained"
      color="primary"
      type="submit"
      sx={{ width: "100%" }}
    >
      {text}
    </Button>
  );
}
export default ButtonSubmit;
