/* eslint-disable max-lines-per-function */
import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import IconNext from "@/assets/icon/IconNext";
import Carouse from "@/common/components/Carousel/Carouse";
import { convertToDateFormat } from "@/common/utils/Date";
import CustomAccordion from "./components/CustomAccordion";
import useGetNotifications from "./apis/useGetNotifications";
type Props = {
  children: React.ReactNode;
};
function AuthCommon({ children }: Props) {
  const { data, handlePageChange, loading, totalPage, currentPage } = useGetNotifications();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const handleNavigation = (href: string) => {
    window.location.href = href;
  };
  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }
  const navMenuPublic = [
    {
      title:"利用規約",
      url: "/term-of-services",
    },
    {
      title:"Q&A",
      url: "/faq",
    }
  ]
  const imageSrc = isXs
    ? "/assets/top/titleTopMobile.png" : "/assets/top/titleTop.png"
  return (
    <Box position="relative">
      <Box paddingX={3} marginTop="40px">
        <Grid
          container
          spacing={2}
          xs={12}
          md={10}
          lg={8}
          marginX="auto"
          display="flex"
          sx={{
            overflow: "hidden",
            position: "relative",
            margin: "0 auto",
            border: "2px solid transparent",
            borderRadius: "20px",
            height: {
              xs: "100%",
              sm: "500px",
              md: "660px",
              lg: "660px",
            },
            ...(!isXs && {backgroundImage:
              // eslint-disable-next-line max-len
              "linear-gradient(#fff, #fff), linear-gradient(90.47deg, #FA566B -2.5%, #FD7862 17.9%, #FE895D 33.3%, #FFC758 46.32%, #4CC6D7 64.22%, #E64FB8 75.79%, #FA566B 97.22%)",
            backgroundOrigin: "border-box",
            backgroundClip: "padding-box, border-box",}),
          }}
        >
          <Grid
            xs={12}
            sm={6}
            md={6}
            lg={6}
            sx={{
              height: {
                xs: "200px",
                sm: "100%",
                md: "100%",
                lg: "100%",
              },
              ...(isXs && {borderRadius:"20px", overflow:"hidden"})
            }}
          >
            <Carouse>
              {Array.from(Array(4).keys()).map((index) => (
                <Box className="embla__slide" key={index} height="100%">
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    // src="/assets/top/top_img10.jpg"
                    src={`/img/top_img0${index+1}.png`}
                    alt="Your alt text"
                  />
                </Box>
              ))}
            </Carouse>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={6}
            lg={6}
            sx={{
              padding: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ...(isXs && {
                marginTop:"30px !important",
                overflow: "hidden",
                position: "relative",
                margin: "0 auto",
                border: "2px solid transparent",
                borderRadius: "20px",
                backgroundImage:
                // eslint-disable-next-line max-len
                "linear-gradient(#fff, #fff), linear-gradient(90.47deg, #FA566B -2.5%, #FD7862 17.9%, #FE895D 33.3%, #FFC758 46.32%, #4CC6D7 64.22%, #E64FB8 75.79%, #FA566B 97.22%)",
                backgroundOrigin: "border-box",
                backgroundClip: "padding-box, border-box",}),
            }}
          >
            <Box width="340px">
              {children}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box paddingX={3} marginTop="40px">
        <Grid
          container
          spacing={2}
          xs={12}
          md={10}
          lg={8}
          marginX="auto"
          display="flex"
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
              lg: "row",
            },
          }}
        >
          <Grid
            item
            display="flex"
            alignItems="center"
            flexDirection="column"
            sx={{
              width: {
                xs: "260px",
                md: "220px",
                lg: "220px",
              },
              margin: isXs ? "0 auto": "30px 0"
            }}
            paddingLeft="0 !important"
          >
            {navMenuPublic?.map((nav) => (
              <Button
                variant="contained"
                key={`${nav?.title}-${nav?.url}`}
                sx={{
                  padding: "10px 15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  borderRadius: "10px",
                  marginBottom: "18px",
                }}
                onClick={() => handleNavigation(nav?.url)}
              >
                <Typography>{nav?.title}</Typography>
                <IconNext />
              </Button>
            ))}
          </Grid>
          <Grid
            item
            flex="1"
            marginBottom={5}
            sx={{ paddingLeft: "0 !important" }}
          >
            <Stack
              marginTop={4}
              gap={2}
              sx={{
                flex: "1",
                backgroundColor: "var(--mui-palette-background-default)",
                marginLeft: {
                  xs: 0,
                  sm: 0,
                  md: 6,
                  lg: 6,
                },
                borderRadius: "20px",
                padding: {
                  xs: "15px",
                  sm: "15px",
                  md: "50px",
                  lg: "50px",
                },
              }}
              minHeight="300px"
              position="relative"
            >
              <Box
                sx={{
                  backgroundColor: "var(--mui-palette-background-default)",
                  top: isXs ? "-30px" : "-35px",
                  left: 0,
                  height: isXs ? "45px" : "70px",
                  width: isXs ? "145px" : "220px",
                  borderRadius: "10px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
                position="absolute"
              >
                <img style={{marginTop:isXs ? "15px" : "25px"}} src={imageSrc} alt="img"/> 
              </Box>
              {data?.map((item) => (
                <CustomAccordion
                  key={item?.id}
                  title={`${convertToDateFormat(item?.start_date)} ${
                    item?.title
                  }`}
                  content={
                    <Typography
                      color="text.secondary"
                      sx={{ whiteSpace: "pre-wrap" }}
                    >
                      {item?.content}
                    </Typography>
                  }
                />
              ))}
              {currentPage < totalPage && (
                <Button
                  disabled={currentPage <= totalPage}
                  onClick={() => handlePageChange(2)}
                >
                  さらに表示
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
export default AuthCommon;
