import { useRoutes, RouteObject } from "react-router-dom";
import EVENT_USER_ROUTES from "./eventUser";
import MEMBER_ROUTES from "./member";
/**
 * ルート
 * @returns {Element} .
 */
function AppRoutes() {
  const ROUTES: RouteObject[] = [...EVENT_USER_ROUTES, ...MEMBER_ROUTES];
  // useRoutesフックを使用してルーティングを設定
  const ELEMENT = useRoutes([...ROUTES]);
  return <>{ELEMENT}</>;
}
export default AppRoutes;
