import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from "@mui/material";
import { FieldValues, useFormContext } from "react-hook-form";
interface FormContextType extends FieldValues {
  [key: string]: unknown;
}
type Props = {
  id?: string;
  name: string;
  label: string;
  inputType?: string;
  inputProps?: OutlinedInputProps;
};
function OutlineInputField({
  id,
  name,
  label,
  inputType = "text",
  inputProps = {},
}: Props) {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormContextType>();
  return (
    <FormControl fullWidth error={!!errors[name]}>
      <InputLabel>{label}</InputLabel>
      <OutlinedInput type={inputType} {...register(name)} id={id ?? name} {...inputProps} />
      <FormHelperText>{errors?.[name]?.message}</FormHelperText>
    </FormControl>
  );
};
export default OutlineInputField