import { Box, Card, Container, Grid, Typography } from "@mui/material";
// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
import { CONTENTS, SECTION_TITLES } from "./constant";
// Section titles
function SectionTitle({ title }: { title: string }) {
  return (
    <Typography
      sx={{ fontWeight: 600, fontSize: "1.5rem", lineHeight: 1.2 }}
      mt={3}
    >
      {title}
    </Typography>
  );
}
function TermOfServices() {
  return (
    <Box>
      {/* <Breadcumbs breadcrumbs={[{ title: "トップ" }, { title: "利用規約" }]} /> */}
      <Container maxWidth="md" sx={{ mt: 7, mb: 5 }}>
        <Typography mt={3} variant="h4" textAlign="left" fontWeight="bold">
          利用規約
        </Typography>
      </Container>
      <Grid item md={12} xs={12} pb={2}>
        <Container maxWidth="md" sx={{ mt: 7, mb: 6 }}>
          <Card sx={{mt: 2, p: '32px', border: "1px solid var(--mui-palette-secondary-300)", borderRadius: 1,}}>
            {/* Section 1 */}
            <Typography mt={2}>
              この規約は、当社が提供するウェブ写真販売サービス（Rainbow photo）をご利用頂く際の、利用の条件を定めるものです。
              本サービスをご利用になる際は、必ず本規約、Q＆Aをお読み頂き、全ての条項にご同意頂いた上でご利用下さい。本サービスをご
              利用された場合には、本規約、Q＆A記載事項にご同意頂いたものとみなされます。
            </Typography>
            <Typography mt={2}>
              また利用規約は予告無く変更することがございます。
            </Typography>
            <Typography mt={2}>
              会員は、登録時期に関わらず変更後の規約が適用されます。
            </Typography>
            <SectionTitle title={SECTION_TITLES.USE} />
            {CONTENTS.USE.map((text) => (
              <Typography key={`${text}`} mt={2}>
                {text}
              </Typography>
            ))}
            {/* Section 2 */}
            <SectionTitle title={SECTION_TITLES.SERVICE_CONTENT} />
            {CONTENTS.SERVICE_CONTENT.map((text) => (
              <Typography key={`${text}`} mt={2}>
                {text}
              </Typography>
            ))}
            {/* Section 3 */}
            <SectionTitle title={SECTION_TITLES.PROHIBITED_ACTS} />
            {CONTENTS.PROHIBITED_ACTS.map((text) => (
              <Typography key={`${text}`} mt={2}>
                {text}
              </Typography>
            ))}
            {/* Section 4 */}
            <SectionTitle title={SECTION_TITLES.MEMBERSHIP_REGISTRATION} />
            {CONTENTS.MEMBERSHIP_REGISTRATION.map((text) => (
              <Typography key={`${text}`} mt={2}>
                {text}
              </Typography>
            ))}
            <SectionTitle title={SECTION_TITLES.PERSON_INFO} />
            {CONTENTS.PERSON_INFO.map((text) => (
              <Typography key={`${text}`} mt={2} ml={text.indented ? 3 : 0}>
                {text?.text}
              </Typography>
            ))}
            <SectionTitle title={SECTION_TITLES.CANCEL_SERVICE} />
            {CONTENTS.CANCEL_SERVICE.map((text) => (
              <Typography key={`${text}`} mt={2} ml={text.indented ? 3 : 0}>
                {text?.text}
              </Typography>
            ))}
            <SectionTitle title={SECTION_TITLES.PROHIBITIONS} />
            {CONTENTS.PROHIBITIONS.map((text) => (
              <Typography key={`${text}`} mt={2} ml={text.indented ? 3 : 0}>
                {text?.text}
              </Typography>
            ))}
            <SectionTitle title={SECTION_TITLES.PROHIBITION_SERVICES} />
            {CONTENTS.PROHIBITION_SERVICES.map((text) => (
              <Typography key={`${text}`} mt={2} ml={text.indented ? 3 : 0}>
                {text?.text}
              </Typography>
            ))}
            <SectionTitle title={SECTION_TITLES.DISCLAIMER} />
            {CONTENTS.DISCLAIMER.map((text) => (
              <Typography key={`${text}`} mt={2}>
                {text?.text}
              </Typography>
            ))}
            <SectionTitle title={SECTION_TITLES.GOVERNING_LAW} />
            {CONTENTS.GOVERNING_LAW.map((text) => (
              <Typography key={`${text}`} mt={2}>
                {text}
              </Typography>
            ))}
            <SectionTitle title={SECTION_TITLES.COMPENSATION_DAMAGES} />
            {CONTENTS.COMPENSATION_DAMAGES.map((text) => (
              <Typography key={`${text}`} mt={2}>
                {text}
              </Typography>
            ))}
            <SectionTitle title={SECTION_TITLES.TERM_CONDITION} />
            {CONTENTS.TERM_CONDITION.map((text) => (
              <Typography key={`${text}`} mt={2}>
                {text}
              </Typography>
            ))}
            <SectionTitle title={SECTION_TITLES.PRODUCT_CANCEL} />
            {CONTENTS.PRODUCT_CANCEL.map((text) => (
              <Typography key={`${text}`} mt={2}>
                {text}
              </Typography>
            ))}
          </Card>
        </Container>
      </Grid>
    </Box>
  );
}
export default TermOfServices;
