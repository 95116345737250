import React, { useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, Link } from "@mui/material";
import { RouterLink } from "@/common/components/Link/link";
function ConfirmationActions({
  handleNavigate,
}: {
  handleNavigate: () => void;
}) {
  const [isAgreed, setIsAgreed] = useState(false);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgreed(event.target.checked);
  };
  const handleConfirm = () => {
    if (isAgreed) {
      handleNavigate();
    }
  };
  return (
    <>
      <Box mt={2} sx={{ display: "flex", justifyContent: "center" }}>
        <Link
          fontWeight={600}
          variant="subtitle1"
          color="#000"
          component={RouterLink}
          href="/term-of-services"
          target="_blank"
          rel="noopener noreferrer"
          sx={{textDecoration: "underline"}}
        >
          利用規約
        </Link>
      </Box>
      <Box mt={1} sx={{ display: "flex", justifyContent: "center" }}>
        <FormControlLabel control={<Checkbox checked={isAgreed}
          onChange={handleCheckboxChange}/>} label="利用規約に同意" />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }} mt={6} pb={7}>
        <Button variant="contained" sx={{ p: 1, width: 320 }} onClick={handleConfirm} disabled={!isAgreed}>
          注文を確定する
        </Button>
      </Box>
    </>
  );
}
export default ConfirmationActions;
