import ListImageComponent from "@/common/components/ImageCard/ListImageComponent";
import {useContext} from "react";
import imagesContext from "@/common/context/ImagesContext";
import {Stack, Box} from "@mui/material";
import ButtonChangePageView from "@/common/components/Button/ButtonChangePageView";
import {optionViewMode} from "@/common/utils/helper";
type Props = {
  onClickImage: () => void;
  viewMode: string
};
function ListImageFavorite({ viewMode, onClickImage }: Props) {
  const {
    dataFavoriteImages,
    handleDeletePhotoFavorite,
    totalPageFavorite,
    currentPageFavorite,
    handlePageChangeFavorite,
  } = useContext(imagesContext);
  const handleCloseFavorite = async (imageId: string | number) => {
    handleDeletePhotoFavorite(imageId);
  };
  return (
    <Box marginTop={3}>
      <ListImageComponent
        images={dataFavoriteImages}
        onClickImage={onClickImage}
        optionViewMode={optionViewMode(viewMode)}
        isShowClose
        isShowFavorite={false}
        isShowHide={false}
        onClickClose={handleCloseFavorite}
      />
      <Stack marginTop={3} direction="row" justifyContent="center">
        <ButtonChangePageView
          totalPage={totalPageFavorite}
          currentPage={currentPageFavorite}
          handlePageChange={handlePageChangeFavorite}
        />
      </Stack>
    </Box>
  );
}
export default ListImageFavorite;
