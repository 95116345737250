import { paths } from "@/common/constant/paths";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
function CartNonItem() {
  const navigate = useNavigate();
  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      minHeight="35vh"
    >
      <Grid item xs={6}>
        <Typography align="left">カートに商品がありません。</Typography>
        <Typography align="left">商品を追加すると表示されます。</Typography>
      </Grid>
      <Button
        sx={{ marginTop: 3 }}
        variant="contained"
        onClick={() => {
          navigate(paths.home);
        }}
      >
        TOPに戻る
      </Button>
    </Grid>
  );
}
export default CartNonItem;
