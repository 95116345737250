import { Card } from "@mui/material";
import { ListDataFolder } from "@/features/guest/pages/top/types";
import MenuFolderItem from "./MenuFolderItem";
type Props = {
  folders: ListDataFolder[];
};
function MenuFolderList({ folders }: Props) {
  return <Card sx={{borderRadius: '7px'}}>
    {folders.map((item, index) => (
      <MenuFolderItem key={item.id} index={index + 1} folder={item} />
    ))}
  </Card>
}
export default MenuFolderList;
