import authContext from "@/common/context/AuthContext";
import Logger from "@/common/utils/Logger";
import { FormOrderInfoSchema } from "@/common/utils/schema";
import { orderStorage } from "@/common/utils/storage/orderStorage";
import { useSubmit } from "@/features/guest/auth/hookCustom/useSubmit";
import { getInitialValues } from "@/features/guest/pages/order/helper.ts";
import { FormData, FormOrderInfoProps } from "@/features/guest/types";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ActionButtons } from "./ActionButtons";
import { AddressFields } from "./AddressFields";
import { ContactFields } from "./ContactFields";
import { OrderFormFields } from "./OrderFormFields";
import { WrapFormOrder } from "./WrapFormOrder";
/**
 *
 * @param root0
 * @param root0.onOpenLogin
 * @param root0.onSuccess
 */
export function FormOrderInfo({
  onOpenLogin,
  onSuccess = async () => {},
}: FormOrderInfoProps) {
  const { isSignedIn, userInfo } = useContext(authContext);
  Logger.logger(`userInfo: ${JSON.stringify(userInfo)}`);
  const form = useForm<FormData>({
    resolver: yupResolver(FormOrderInfoSchema),
    defaultValues: getInitialValues(orderStorage.getOrderForm(), userInfo),
  });
  const navigate = useNavigate();
  const onSubmit = useSubmit({
    onSuccess,
    /**
     *
     */
    handleState: () => {
      const data = form.getValues();
      orderStorage.saveOrderForm({
        name: data.name,
        furigana: data.nameFurigana,
        postCode: data.code,
        prefecture_code: data.province,
        address: data.address,
        streetAddress: data.streetAddress,
        buildingAddress: data.building,
        phoneNumber: data.phoneNumber,
        email: data.email,
        // coupon_id: Number(data.coupon),
        userId: Number(userInfo.id),
      });
      navigate("/guest/carts/payment");
    },
  });
  useEffect(() => {
    const savedData = orderStorage.getOrderForm();
    const hasAnyField = Boolean(
      savedData &&
        (savedData.name ||
          savedData.furigana ||
          savedData.postCode ||
          savedData.address ||
          savedData.streetAddress ||
          savedData.buildingAddress ||
          savedData.prefecture_code ||
          savedData.phoneNumber ||
          savedData.email)
      // savedData.coupon_id
    );
    if (!hasAnyField && userInfo && userInfo.id) {
      form.reset({
        name: userInfo.name || "",
        nameFurigana: userInfo.furigana_name || "",
        code: userInfo.post_code || "",
        address: userInfo.address || "",
        streetAddress: userInfo.street_address || "",
        building: userInfo.building_address || "",
        province: userInfo.prefecture_code || undefined,
        phoneNumber: userInfo.phone_number || "",
        email: userInfo.email || "",
      });
    }
  }, [userInfo, form]);
  return (
    <WrapFormOrder isSignedIn={isSignedIn} onOpenLogin={onOpenLogin}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              paddingBottom: {
                xs: "20px",
                sm: 0,
              },
              paddingX: {
                xs: "18px",
                sm: 0,
              },
            }}
          >
            <Box
              width="100%"
              display="flex"
              sx={{
                flexDirection: "column",
                gap: {
                  xs: "24px",
                  sm: "25px ",
                },
              }}
            >
              <OrderFormFields />
              <AddressFields />
              <ContactFields />
            </Box>
            <ActionButtons onNavigate={navigate} />
          </Box>
        </form>
      </FormProvider>
    </WrapFormOrder>
  );
}
