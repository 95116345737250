import {useContext, useEffect, useState} from "react";
import HttpClient from "@/common/api/HttpClient";
import {GET_MY_ACCOUNT_MEMBER} from "@/common/constant/apiConstant";
import authContext from "@/common/context/AuthContext";
export interface MemberAccount {
  id?: number;
  email: string;
  username?: string;
  name: string;
  furigana_name: string;
  post_code: string;
  prefecture_code: number;
  address: string;
  street_address: string;
  building_address?: string;
  phone_number: string;
  is_authenticated?: boolean;
  organization?: string;
}
const useGetMyMemberAccount = () => {
  const {isSignedIn} = useContext(authContext);
  const [data, setData] = useState<MemberAccount | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const getList = async () => {
    setLoading(true);
    try {
      const response = await HttpClient.Get({
        path: GET_MY_ACCOUNT_MEMBER,
      });
      setData(response.data as MemberAccount);
      return response.data
    } catch (error) {
      setError((error as Error).message);
      throw error
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isSignedIn) {
      getList();
    }
  }, [isSignedIn]);
  return {
    getList,
    data,
    error,
    loading,
  };
};
export default useGetMyMemberAccount;