import { Box } from "@mui/material";
function Bank2Icon() {
  return (
    <Box width={24} height="18px">
      <svg
        viewBox="0 0 24 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="presentation"
        focusable="false"
        className="p-Logo p-Logo--md p-CardBrandIcon"
      >
        <path
          d="M21.997 15.75H22c.955.008 1.74-.773 1.751-1.746V2.006a1.789 1.789 0 
          0 0-.52-1.25A1.72 1.72 0 0 0 21.997.25H2.001A1.718 1.718 0 0 0 .77.757c-.33.33-.517.779-.521 
          1.247v11.99c.004.47.191.92.52 1.25.329.328.771.51 1.233.506h19.994Zm0 .5h-.002.002Z"
          stroke="#ddd"
          fill="#fff"
        />
        <path
          d="M10.002 2.052v-.016h4v.016a6 6 0 0 1 0 11.968v.016h-4v-.016a6 6 0 0 1 0-11.968Z"
          fill="#0165AC"
        />
        <path
          d="M11.602 11.428a3.6 3.6 0 0 0 0-6.784v6.784Zm-2.4-6.784a3.6 3.6 0 0 0 0 6.784V4.644Zm1.2
           8.592a5.2 5.2 0 1 1 0-10.4 5.2 5.2 0 0 1 0 10.4Z"
          fill="#FFF"
        />
      </svg>
    </Box>
  );
}
export default Bank2Icon;
