import { Grid, Typography } from "@mui/material";
import React from "react";
function InfoRow({ label, value, isLastRow }:{
  label: string;
  value: React.ReactNode;
  isLastRow?: boolean;
}) {
  return (
    <Grid container>
      <Grid
        item
        md={2}
        xs={12}
        bgcolor="#f2f9e9"
        p={1.5}
        borderLeft="1px solid #ccc"
        borderTop="1px solid #ccc"
        borderBottom={isLastRow ? "1px solid #ccc" : "none"}
      >
        <Typography variant="subtitle2" textAlign="left">
          {label}
        </Typography>
      </Grid>
      <Grid
        item
        md={10}
        xs={12}
        p={1.5}
        borderLeft="1px solid #ccc"
        borderTop="1px solid #ccc"
        borderRight="1px solid #ccc"
        borderBottom={isLastRow ? "1px solid #ccc" : "none"}
      >
        <Typography variant="body1" textAlign="left">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}
export default InfoRow;