export const GET_MY_ACCOUNT_MEMBER = "user/my-account/profile";
export const REGISTER_MY_ACCOUNT_MEMBER = "user/auth/my-account/register";
export const GET_FOLDER_PATH = "user/event/folder";
export const LOGIN_EVENT_PATH = "user/event/auth/login";
export const CHECK_USER_IS_CONFIRMED = "user/auth/my-account/is-confirmed";
export const USER_CONFIRM_PATH = "user/auth/my-account/confirmation";
export const USER_VALIDATE_LOGIN_PATH = "user/auth/my-account/validate-account";
export const USER_REQUEST_FORGOT_PASSWORD = "user/auth/my-account/forgot-password";
export const USER_CONFIRM_FORGOT_PASSWORD = "user/auth/my-account/confirm-forgot-password";
export const GET_NOTIFICATIONS_PATH = "user/event/notifications";
export const FAVORITE_PHOTO_API = 'user/favourite-photo';
export const HIDDEN_PHOTO_API = 'user/hidden-photo';
export const GET_TIME_RESALE_TIME_PATH = "user/event/event-resale-time";
export const PAYMENT_METHOD = 'user/event/payment-method';
export const GET_DELIVERY_METHOD_PATH = 'user/event/shipping-method';
export const USER_ITEM_CART = 'user/my-account/cart';
export const USER_ITEM_CART_BY_PHOTO_ID = 'user/my-account/cart/photo';
export const GET_LIST_PHOTO_BY_IDS = 'user/event/photo';
export const USER_EVENT_REFRESH_TOKEN = 'user/event/auth/refresh';
export const GET_LIST_EVENT_PRICING = 'user/event/pricing';
export const GET_LIST_PRICING = 'user/event/folder/list-pricing';
export const COMPARE_LIST_CART = 'user/event/cart/compare';
export const CONTACT_FORM_ENDPOINT = "user/contact-form/send-mail";
export const CREATE_PAYMENT_INTENT = "user/event/cart/stripe/create-payment-intent"
export const UPDATE_STATUS_PAYMENT = "user/event/cart/stripe/finalize-order"
