import { useContext, useEffect, useState } from "react";
import SignInStateEnum from "@/features/guest/auth/login/type";
import authContext from "@/common/context/AuthContext";
import StateForgot from "./components/StateForgot";
import Statelogin from "./components/Statelogin";
type FormMemberLoginProps = {
  onCancel: () => void;
};
function FormMemberLogin({ onCancel }: FormMemberLoginProps) {
  const { userInfo, isSignedIn } = useContext(authContext);
  const [state, setState] = useState<SignInStateEnum>(SignInStateEnum.signIn);
  useEffect(() => {
    if (isSignedIn && !!userInfo.id) {
      onCancel();
    }
  }, [isSignedIn, userInfo.id]);
  switch (state) {
  case SignInStateEnum.forgot:
    return (
      <StateForgot onCancel={onCancel} state={state} setState={setState} />
    );
  default:
    return (
      <Statelogin onCancel={onCancel} state={state} setState={setState} />
    );
  }
}
export default FormMemberLogin;
