import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import React from "react";
type TypeButtonChangePageViewProps = {
  totalPage: number;
  currentPage: number;
  handlePageChange: (_event:  React.MouseEvent<HTMLButtonElement>, page: number) => void
};
function ButtonChangePageView(props: TypeButtonChangePageViewProps) {
  const theme = useTheme()
  const { totalPage, currentPage, handlePageChange } = props;
  return (
    <Box
      sx={{
        marginTop: "10px",
        bottom: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
      }}
    >
      <Stack direction="row" spacing={3} sx={{ alignItems: "center", p: 1 }}>
        <Button
          size="small"
          startIcon={<CaretLeft size={18} fontWeight={700} />}
          disabled={currentPage === 1}
          onClick={(e) => {
            if (currentPage > 0) {
              handlePageChange(e, currentPage - 1);
            }
          }}
          sx={{
            fontSize: "14px",
            fontWeight: 700,
            background: theme.palette.background.default,
            color: "#757575",
            padding: "5px 10px",
            boxShadow:"0px 2px 11px 0px rgba(0, 0, 0, 0.06), 0px 0px 3px 0px rgba(0, 0, 0, 0.05)"
          }}
        >
          前
        </Button>
        <Typography
          color="text.secondary"
          variant="subtitle2"
          fontWeight="600"
          fontSize="20px"
        >
          {`${currentPage}/${totalPage}`}
        </Typography>
        <Button
          size="small"
          endIcon={<CaretRight size={18} fontWeight={700} />}
          disabled={currentPage === totalPage}
          onClick={(e) => {
            if (currentPage < totalPage) {
              handlePageChange(e, currentPage + 1);
            }
          }}
          sx={{
            fontSize: "14px",
            fontWeight: 700,
            background: theme.palette.background.default,
            color: "#757575",
            padding: "5px 10px",
            boxShadow:"0px 2px 11px 0px rgba(0, 0, 0, 0.06), 0px 0px 3px 0px rgba(0, 0, 0, 0.05)"
          }}
        >
          次
        </Button>
      </Stack>
    </Box>
  );
}
export default ButtonChangePageView;
