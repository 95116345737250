import { Controller } from "react-hook-form";
import RequiredInputField from "@/features/member/auth/MyAccount/components/RequiredInputField";
import type { DataMemberAccount } from "@/features/member/auth/MyAccount/api/useEditMyAccount";
import type { FormSectionProps } from "@/features/member/auth/MyAccount/types";
import { Grid, InputLabel } from "@mui/material";
import { RequiredMark } from "@/features/guest/pages/order/components/RequiredMark";
type ContactFieldsProps = FormSectionProps & {
  data?: DataMemberAccount;
};
export default function ContactFields({
  control,
  errors,
  data,
}: ContactFieldsProps) {
  return (
    <Grid container spacing={2} marginLeft="0" width="100%" mt={0.5}>
      <Grid item md={12} xs={12} fontSize="16px" fontWeight="bold">
        連絡先
      </Grid>
      <Grid item md={6} xs={12} mt={-1}>
        <Grid item md={3} xs={12}>
          <InputLabel sx={{ fontSize: 13 }}>
            電話番号
            <RequiredMark />
          </InputLabel>
        </Grid>
        <Controller
          name="phone_number"
          control={control}
          render={({ field }) => (
            <RequiredInputField
              label=""
              {...field}
              type="tel"
              isChipStatus={false}
              name="phone_number"
              error={errors}
              placeholder="例）012-345-6789"
            />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12} mt={-1}>
        <Grid item md={4} xs={12}>
          <InputLabel sx={{ fontSize: 13 }}>
            メールアドレス
            <RequiredMark />
          </InputLabel>
        </Grid>
        <Grid item md={12} xs={12}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <RequiredInputField
                label=""
                disabled={!!data}
                {...field}
                isChipStatus={false}
                type="tel"
                name="email"
                error={errors}
                placeholder="例）hoge@hogehoge.com"
              />
            )}
          />
        </Grid>
      </Grid>
      {!data && (
        <Grid item md={6} xs={12} mt={-1}>
          <Grid item md={4} xs={12}>
            <InputLabel sx={{ fontSize: 13 }}>
              パスワード
              <RequiredMark />
            </InputLabel>
          </Grid>
          <Grid item md={12} xs={12}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <RequiredInputField
                  label=""
                  {...field}
                  isChipStatus={false}
                  name="password"
                  type="password"
                  error={errors}
                  placeholder=""
                />
              )}
            />
          </Grid>
        </Grid>
      )}
      {!data && (
        <Grid item md={6} xs={12} mt={-1}>
          <Grid item md={6} xs={12}>
            <InputLabel sx={{ fontSize: 13 }}>
              パスワード再入力
              <RequiredMark />
            </InputLabel>
          </Grid>
          <Grid item md={12} xs={12}>
            <Controller
              name="confirmpassword"
              control={control}
              render={({ field }) => (
                <RequiredInputField
                  label=""
                  {...field}
                  isChipStatus={false}
                  name="confirmpassword"
                  type="password"
                  error={errors}
                  placeholder=""
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
