/* eslint-disable consistent-return */
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
// import { verticalMarginals } from "@/features/guest/auth/constant/constant";
import Head from "@/common/components/Head/Head";
import { useLocation } from "react-router-dom";
import { IProps } from "@/common/utils/interfaces";
const Context = createContext<{
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}>({
  message: "",
  setMessage: () => "",
  loading: false,
  setLoading: () => false,
});
export function LayoutContextProvider({ children }: IProps) {
  const { pathname } = useLocation();
  // const title  = verticalMarginals[pathname]?.title || "";
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!message) return;
    const timer = setTimeout(() => {
      setMessage("");
    }, 5000);
    return () => clearTimeout(timer);
  }, [message]);
  useEffect(() => {
    setMessage("");
  }, [pathname]);
  const value = useMemo(() => ({ message, setMessage, loading, setLoading }), [message, loading]);
  return (
    <Context.Provider value={value}>
      <>
        <Head />
        {children}
      </>
    </Context.Provider>
  );
}
export default Context;
