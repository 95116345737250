import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../../../../../common/context/AuthContext.tsx";
function PublicRoute() {
  const { isUserEventSignedIn } = useContext(AuthContext);
  if (isUserEventSignedIn) {
    return <Navigate to="/guest" />;
  }
  return <Outlet />;
}
export default PublicRoute;
