import * as React from "react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Stack,
  Button,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { RouterLink } from "@/common/components/Link/link";
import authContext from "@/common/context/AuthContext";
import cartContext from "@/common/context/CartContext";
import { navColorStyles } from "@/features/guest/Layout/styles";
import { NavColor } from "@/common/types/settings";
import { List as ListIcon } from "@phosphor-icons/react/dist/ssr/List";
import usePathname from "@/common/hooks/use-pathname";
import { MainNavProps } from "@/common/constant/type";
import IconGuide from "@/assets/icon/IconGuide";
import IconInquiry from "@/assets/icon/IconInquiry";
import IconLogout from "@/assets/icon/IconLogout";
import { ColorScheme } from "@/features/guest/styles/theme/types";
import { User as UserIcon } from "@phosphor-icons/react/dist/ssr/User";
import { Logo } from "./logo";
import { paths } from "../../constant/paths";
import { renderNavGroups } from "./ultil";
import IconTitle from "../Icon/IconTitle";
import MobileNavV2 from "./MobileNavV2";
const logoColors: Record<ColorScheme, Record<NavColor, "dark" | "light">> = {
  dark: { blend_in: "light", discrete: "light", evident: "light" },
  light: { blend_in: "dark", discrete: "dark", evident: "light" },
};
const stackStyle = {
  alignItems: "center",
  flex: "1 1 auto",
  justifyContent: "flex-end",
  display: { xs: "none", sm: "flex" },
};
export function MainNav({
  color = "blend_in",
  items = [],
  isAuthen = false,
}: MainNavProps): React.JSX.Element {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const pathname = usePathname();
  const navigate = useNavigate();
  const [openNav, setOpenNav] = useState<boolean>(false);
  const colorScheme: ColorScheme = "light";
  const styles = navColorStyles[colorScheme][color];
  const logoColor = logoColors[colorScheme][color];
  const {
    logoutUserEvent,
    isSignedIn,
    isUserEventSignedIn,
    setIsOpenAuthUser,
  } = useContext(authContext);
  const { resetContext } = useContext(cartContext);
  const handleLogout = () => {
    resetContext()
    logoutUserEvent();
  }
  const setOpenLoginPopup = () => {
    setIsOpenAuthUser(true);
  };
  const cssStyle = {
    ...styles,
    bgcolor: "var(--MainNav-background)",
    border: "none",
    color: "var(--MainNav-color)",
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: "var(--MainNav-zIndex)",
  };
  const headerStyle = {
    display: "flex",
    flex: "1 1 auto",
    minHeight: "var(--MainNav-height, 72px)",
    py: 1,
    maxWidth: isAuthen ? "unset" : 1440,
    marginX: "auto", height: isXs ? "80px" : "90px"
  };
  // const redirectUserGuide = () =>
  //   window.open(paths.guide, "__blank", "noreferrer");
  // const redirectInQuiry = () =>
  //   window.open(paths.contact, "__blank", "noreferrer");
  const handleNavigate = () => {
    navigate(paths.myPageTabs.myAccount)
  }
  return (
    <>
      <Box component="header" sx={cssStyle}>
        <Box sx={headerStyle}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: "center", flex: "1 1 auto" }}
          >
            <IconButton
              onClick={() => setOpenNav(true)}
              sx={{ display: { sm: "none" }, position:"absolute", right:0, width: "50px" }}
            >
              <ListIcon color="var(--mui-palette-primary-main)" width="30px" height="30px"/>
            </IconButton>
            <Box
              component={RouterLink}
              href={paths.home}
            >
              <Logo color={logoColor} height={isXs ? 26 : 36} width={isXs ? 200 : 270} />
            </Box>
          </Stack>
          <Stack direction="row" spacing={3} sx={stackStyle}>
            <IconTitle
              title="利用ガイド"
              fontSize="12px"
              icon={<IconGuide width="18" height="23" />}
              onClick={() => navigate("/usage-guide")}
            />
            <IconTitle
              fontSize="12px"
              title="お問い合わせ"
              icon={<IconInquiry width="24" height="18" />}
              onClick={() => navigate("/contacts")}
            />
            {isAuthen && (
              <IconTitle
                fontSize="12px"
                title="ログアウト"
                icon={<IconLogout width="24" height="18" />}
                onClick={handleLogout}
              />
            )}
            {isUserEventSignedIn &&
              (!isSignedIn ? (
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenLoginPopup()}
                  >
                    ログイン
                  </Button>
                </Box>
              ) : (
                <Button
                  startIcon={<UserIcon />}
                  variant="contained"
                  color="primary"
                  onClick={handleNavigate}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                    }
                  }}
                >
                  マイページ
                </Button>
              ))}
          </Stack>
        </Box>
        {isAuthen && (
          <Box
            component="nav"
            sx={{
              display: { md: "block" },
              overflowX: "auto",
              padding:{
                xs:"0 20px",
                sm:"0",
                md:"0",
                lg:"0",
              }
            }}
          >
            {renderNavGroups({ items, pathname })}
          </Box>
        )}
      </Box>
      {isXs && openNav && <MobileNavV2
        isAuthen={isAuthen}
        open={openNav}
        onClose={() => setOpenNav(false)}
      />}
    </>
  );
}
export default MainNav;
