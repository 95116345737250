import { Box, Typography } from "@mui/material";
/**
 *
 */
function ConfirmationMessage() {
  return <>
    <Box sx={{ display: "flex", justifyContent: "center", textAlign: "center" }} mt={5}>
      <Typography variant="subtitle1" fontWeight="300" paddingLeft="20px" paddingRight="20px">
        確定後は追加・変更・キャンセルができませんのでよくご確認ください。
      </Typography>
    </Box>
    <Box sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
      <Typography variant="subtitle1" fontWeight="300" paddingLeft="20px" paddingRight="20px">
        （ボタンがクリックされるまで、注文は確定されません。）
      </Typography>
    </Box>
  </>
}
export default ConfirmationMessage