/* eslint-disable max-lines-per-function */
import { useContext, useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import CustomModal from "@/common/components/Modal/CustomModal";
import type { SelectChangeEvent } from "@mui/material";
import AuthContext from "@/common/context/AuthContext";
import cartContext, { ICartItem } from "@/common/context/CartContext";
import useRemoveItemCart from "@/features/guest/pages/FolderPhotoDetail/apis/useRemoveItemCart";
import { orderStorage } from "@/common/utils/storage/orderStorage";
import Loading from "@/common/components/Loading";
import ToastMessage from "@/common/utils/ToastUtils";
import { TypeListCartItem } from "@/features/guest/types";
import useGetListCart from "@/features/guest/api/useGetListCart";
import { 
  // CartBreadcrumbs, 
  CartOverview, CartDetails } from "./components";
import { ContentDetailImage } from "./components/ContentDetailImage";
import { ContentDeleteCartItem } from "./components/ContentDeleteCartItem";
import {
  CartItems,
  TypeDeleteCartItem,
  TypeFolderCartItem,
} from "./types";
function CartPage() {
  const { isSignedIn: isLoginUser } = useContext(AuthContext);
  const { handleChangeCartLocal, setCartItems, cartItems, setTotalCartItems } =
    useContext(cartContext);
  const { removeItemCart } = useRemoveItemCart();
  const [items, setItems] = useState<CartItems[]>([]);
  const [listCarItem, setListCarItem] = useState<TypeListCartItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleting, setDeleting] = useState<TypeDeleteCartItem>({
    category: "",
    id: 0,
    status: false,
  });
  const { isSignedIn } = useContext(AuthContext);
  const [itemFolder, setItemFolder] = useState([]);
  const [detailImage, setDetailImage] = useState<string>("");
  const { getGetListCart, getGetListPricing, updateListCart } =
    useGetListCart();
  const [totalPrice, setTotalPrice] = useState<number | string>(0);
  const [totalItem, setTotalItem] = useState<number>();
  const handleClose = () => setDeleting({ category: "", id: 0, status: false });
  const handleOpen = ({
    id,
    category,
  }: {
    id: number;
    category: string | number;
  }) => {
    setDeleting({ id, category, status: true });
  };
  const onOpenImage = (url: string) => setDetailImage(url);
  const fetchMemberUserApi = async () => {
    setIsLoading(true)
    const data = await getGetListCart();
    orderStorage.saveOrderForm({ cartId: data?.id });
    setListCarItem(
      data?.folders
        ?.map((folder: CartItems) =>
          folder?.cart_items?.map((cartItem) => ({
            id: cartItem?.id,
            pricing_id: cartItem?.pricing_id,
            photo_id: cartItem?.photo_id,
            pricing_price: cartItem?.pricing_price,
            quantity: cartItem?.quantity,
          }))
        )
        .flat()
    );
    setIsLoading(false)
    setItems(data?.folders);
    setTotalCartItems(data?.total_item)
    setTotalPrice(data?.total_price);
    setTotalItem(data?.total_quantity);
  };
  const fetchEventUserApiPricing = async () => {
    setIsLoading(true)
    const cartItems = JSON.parse(
      localStorage.getItem("cartItems") || "[]"
    ) as TypeListCartItem[];
    const idArray = cartItems?.map((item: { id?: number }) => String(item.id));
    if (idArray.length) {
      const data = await getGetListPricing(idArray);
      const listCartItems: TypeListCartItem[] = [];
      data?.data?.forEach((folder: TypeFolderCartItem) => {
        folder?.photo?.forEach((pto) => {
          const pricingFind = folder?.pricings?.find(
            (pricing) =>
              pricing?.pricing_id ===
              cartItems?.find((cartItem) => cartItem?.id === pto?.id)
                ?.pricing_id
          );
          const cartItemFind = cartItems?.find(
            (cartItem) => cartItem?.id === pto?.id
          );
          if(cartItemFind && !cartItemFind.photo_id){
            cartItemFind.photo_id = cartItemFind.id || 0
          }
          if (pricingFind && cartItemFind) {
            listCartItems.push({
              ...cartItemFind,
              pricing_price: pricingFind?.pricing_price,
            });
          } else{
            listCartItems.push({
              ...cartItemFind,
              pricing_id: data?.data?.[0]?.pricings?.[0]?.pricing_id,
              pricing_price: data?.data?.[0]?.pricings?.[0]?.pricing_price,
            });
          }
        });
      });
      const totalPrice = listCartItems.reduce(
        (price, currentPrice) =>
          price +
          Number(currentPrice.pricing_price) * Number(currentPrice.quantity),
        0
      );
      const totalItem = listCartItems?.reduce(
        (price, currentPrice) => price + Number(currentPrice?.quantity),
        0
      );
      setIsLoading(false)
      setItemFolder(data?.data);
      setListCarItem(listCartItems);
      setTotalItem(totalItem);
      setTotalPrice(totalPrice);
    } else {
      setItemFolder([]);
      setListCarItem([]);
      setTotalItem(0);
      setTotalPrice(0);
      setIsLoading(false)
    }
  };
  /**
   * Handles the deletion of an item from the cart.
   */
  const handleOk = async () => {
    if (isSignedIn) {
      const {error} = await removeItemCart(deleting.id);
      if (error) {
        await fetchMemberUserApi();
        handleClose();
        ToastMessage.showError("カート項目が見つかりません。");
        return
      }
      await fetchMemberUserApi();
      handleClose();
      ToastMessage.showSuccess("カートから写真を正常に削除しました。");
    } else {
      const updatedCart = cartItems.filter((item) => item.id !== deleting.id);
      setCartItems(updatedCart);
      await fetchEventUserApiPricing();
      handleClose();
      ToastMessage.showSuccess("カートから写真を正常に削除しました。");
    }
  };
  const content = useMemo(
    () => (
      <ContentDeleteCartItem handleClose={handleClose} handleOk={handleOk} />
    ),
    [deleting]
  );
  useEffect(() => {
    if (isSignedIn) {
      fetchMemberUserApi();
    } else {
      fetchEventUserApiPricing();
    }
  }, [isSignedIn]);
  const calculTotalPrice = async (
    val: SelectChangeEvent<string>,
    cartItemId: string | number,
    category?: CartItems
  ) => {
    const listCartItemClone = [...listCarItem];
    const cartItemFindIndex = listCartItemClone.findIndex(
      (cartItem: TypeListCartItem) => cartItem?.id === cartItemId
    );
    const pricingItem = category?.pricings?.find(
      (pricing) => pricing?.pricing_id === Number(val?.target.value)
    )?.pricing_price;
    if (listCartItemClone[cartItemFindIndex]) {
      listCartItemClone[cartItemFindIndex].pricing_id = val.target.value;
      listCartItemClone[cartItemFindIndex].pricing_price = pricingItem;
    }
    if (!isLoginUser) {
      handleChangeCartLocal(
        listCartItemClone.find(
          (cartItem: TypeListCartItem) => cartItem?.id === cartItemId
        ) as ICartItem
      );
    }
    const totalPrice = listCartItemClone.reduce(
      (price, currentPrice) =>
        price +
        Number(currentPrice.pricing_price) * Number(currentPrice.quantity),
      0
    );
    const totalItem = listCartItemClone.reduce(
      (price, currentPrice) => price + Number(currentPrice.quantity),
      0
    );
    setTotalItem(totalItem);
    setTotalPrice(totalPrice);
    setListCarItem(listCartItemClone);
    if (isLoginUser) {
      await updateListCart(listCartItemClone);
    }
  };
  const calculTotal = async (
    val: SelectChangeEvent<string>,
    cartItemId: string | number
  ) => {
    const listCartItemClone = [...listCarItem];
    const cartItemFindIndex = listCartItemClone.findIndex(
      (cartItem: TypeListCartItem) => cartItem?.id === cartItemId
    );
    if (listCartItemClone[cartItemFindIndex])
      listCartItemClone[cartItemFindIndex].quantity = val.target.value
        ? +val.target.value
        : 0;
    const totalPrice = listCartItemClone.reduce(
      (price, currentPrice) =>
        price +
        Number(currentPrice.pricing_price) * Number(currentPrice.quantity),
      0
    );
    const totalItem = listCartItemClone.reduce(
      (price, currentPrice) => price + Number(currentPrice.quantity),
      0
    );
    if (!isLoginUser) {
      handleChangeCartLocal(
        listCartItemClone.find(
          (cartItem: TypeListCartItem) => cartItem?.id === cartItemId
        ) as ICartItem
      );
    }
    setTotalItem(totalItem);
    setTotalPrice(totalPrice);
    setListCarItem(listCartItemClone);
    if (isLoginUser) {
      await updateListCart(listCartItemClone);
    }
  };
  const contentDetailImage = useMemo(
    () => (
      <ContentDetailImage
        detailImage={detailImage}
        setDetailImage={setDetailImage}
      />
    ),
    [detailImage]
  );
  return (
    <>
      {" "}
      {/* <CartBreadcrumbs /> */}
      <Grid container columnSpacing={10}>
        <Grid item xs={12} md={9}>
          {!isLoading ? <CartDetails
            data={isSignedIn ? items : itemFolder}
            openDelete={handleOpen}
            listCarItem={listCarItem}
            onOpenImage={onOpenImage}
            calculTotal={calculTotal}
            calculTotalPrice={calculTotalPrice}
          /> : <Loading />}
        </Grid>
        <Grid item xs={12} md={3} mt={4}>
          <CartOverview
            totalValue={totalItem}
            totalPriceValue={totalPrice}
            listCarItem={listCarItem}
          />
        </Grid>
      </Grid>
      <CustomModal
        open={deleting.status}
        handleClose={handleClose}
        content={content}
        sx={{ width: 365 }}
      />
      <CustomModal
        open={!!detailImage}
        handleClose={() => setDetailImage("")}
        content={contentDetailImage}
        sx={{ width: 560 }}
      />
    </>
  );
}
export default CartPage;
