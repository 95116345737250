// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
import { Box, Button, Card, Container, Grid, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactSchema } from "@/common/utils/schema";
import { useNavigate } from "react-router";
import { paths } from "@/common/constant/paths";
import { useContext, useEffect, useState } from "react";
import authContext from "@/common/context/AuthContext";
import HttpClient from "@/common/api/HttpClient";
import { CONTACT_FORM_ENDPOINT } from "@/common/constant/apiConstant";
import ToastMessage from "@/common/utils/ToastUtils";
import CheckboxForm from "@/common/components/Checkbox/CheckboxForm";
import ButtonSubmit from "@/common/components/Button/ButtonSubmit.tsx";
import { OrderFormFields } from "./components/OrderFormFields";
import { AddressFields } from "./components/AddressField";
type FormData = {
  email: string;
  name: string;
  kana: string;
  telnum?: string;
  contact_type?: string;
  message: string;
  policy: boolean;
};
/**
 *
 */
function ContactPage() {
  const navigate = useNavigate();
  const { userInfo, isSignedIn } = useContext(authContext);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<FormData>({
    resolver: yupResolver(contactSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
      name: "",
      kana: "",
      telnum: "",
      contact_type: "",
      message: "",
      policy: false,
    },
  });
  const { watch } = methods;
  const policyAccepted = watch("policy");
  useEffect(() => {
    if (isSignedIn && userInfo) {
      const currentFormValues = methods.getValues();
      methods.reset({
        email: userInfo.email || "",
        name: userInfo.name || "",
        kana: userInfo.furigana_name || "",
        telnum: userInfo.phone_number || "",
        contact_type: currentFormValues.contact_type,
        message: currentFormValues.message,
        policy: currentFormValues.policy,
      });
    }
  }, [isSignedIn, userInfo, methods]);
  /**
   *
   * @param formData
   */
  const onSubmit = async (formData: FormData) => {
    try {
      setIsLoading(true);
      await HttpClient.Post({
        path: CONTACT_FORM_ENDPOINT,
        data: {
          name: formData.name,
          kana: formData.kana,
          telnum: formData.telnum,
          email: formData.email,
          contact_type: formData.contact_type,
          content: formData.message,
        },
      });
      navigate(paths.contactSuccess);
    } catch (error) {
      ToastMessage.showError("エラーが発生しました。もう一度お試しください。");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      {/* <Breadcumbs
        breadcrumbs={[
          {title: "トップ", href: paths.home},
          {title: "お問い合わせ"},
        ]}
      /> */}
      <Container maxWidth="md" sx={{ mb: 7, mt: 2}}>
        <Typography mt={3} variant="h4" textAlign="left" fontWeight="bold">
                  お問い合わせ
        </Typography>
        <Box marginTop={4}>
          <Typography>Rainbowphotoのお問い合わせフォームです。</Typography>
          <Typography>ご不明な点は下記までお問い合わせください。</Typography>
          <Typography>後日担当者から連絡いたします。</Typography>
        </Box>
      </Container>
      <Container maxWidth="md" sx={{ mt: 7, mb: 7}}>
        <Card sx={{ borderRadius: '4px', padding: {
          xs: '16px',
          md: 0
        } }}>
          <Stack justifyContent="center" alignItems="center" width={850}
            marginY={4}
            maxWidth="100%"
            marginX="auto"
          >
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                noValidate
                style={{
                  width: "100%",
                }}
              >
                <Stack spacing={1}>
                  <Grid
                    container
                    spacing={2}
                    marginLeft="0"
                    width="100%"
                    sx={{
                      padding: {
                        xs: 0,
                        md: "25px",
                      },
                    }}
                  >
                    <OrderFormFields />
                    <AddressFields />
                    <Box width="100%" marginBottom={3} marginTop={2}>
                      <CheckboxForm label="個人情報保護方針に同意する" name="policy"/>
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="100%" gap="16px" sx={{
                      flexDirection:{
                        xs: 'column',
                        sm: 'row'
                      },
                      marginTop: {
                        xs: '20px',
                        sm: '30px'
                      }
                    }}>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate(-1)}
                      >
                        戻る
                      </Button>
                      <ButtonSubmit
                        text="送信する"
                        isLoading={isLoading}
                        disabled={!policyAccepted}
                      />
                    </Box>
                  </Grid>
                </Stack>
              </form>
            </FormProvider>
          </Stack>
        </Card>
      </Container>
    </div>
  );
}
export default ContactPage;
