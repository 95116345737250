import { useState } from "react";
import HttpClientUserEvent from "@/common/api/HttpClientUserEvent";
import {USER_ITEM_CART} from "@/common/constant/apiConstant";
import type { PhotoData } from "../types";
type CartItem = {
  photo_id?: number | string;
  quantity?: number | string;
};
type AddItemCartParams = {
  items?: CartItem[];
};
const useAddItemCart = () => {
  const [data, setData] = useState<PhotoData[]>();
  const [total, setTotal] = useState<number>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const addItemCart = async (params: AddItemCartParams) => {
    setLoading(true);
    try {
      const response = await HttpClientUserEvent.Post({
        path: USER_ITEM_CART,
        data: {
          items: params.items,
        },
      });
      setTotal(response.data.total);
      setData(response.data as PhotoData[]);
      return response.data;
    } catch (error) {
      setError((error as Error).message);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  return {
    data,
    total,
    error,
    loading,
    addItemCart,
  };
};
export default useAddItemCart;
