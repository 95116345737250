import React from "react";
import {Box, Typography} from "@mui/material";
type BoxItemFormOrderType = {
    children: React.ReactNode,
    title: string,
}
/**
 *
 * @param props
 */
export function BoxItemFormOrder(props: BoxItemFormOrderType) {
  const { children, title } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      sx={{ gap: "19px" }}
    >
      <Typography fontSize="16px" fontWeight={600}>
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          gap: {
            xs: "16px",
            sm: "19px",
          },
        }}
        width="100%"
      >
        {children}
      </Box>
    </Box>
  );
}
