import React, {useEffect} from "react";
import {Box, Card, CardHeader, CircularProgress, Stack} from "@mui/material";
import useGetPaymentAndDeliveryMethods from "../api/useGetPaymentAndDeliveryMethods";
import PaymentMethodList from "./PaymentMethodList";
import ShippingMethodList from "./ShippingMethodList";
/**
 *
 */
function PaymentDelivery() {
  const {
    paymentMethods,
    shippingMethods,
    loading,
    getPaymentMethods,
    getShippingMethods
  } = useGetPaymentAndDeliveryMethods();
  useEffect(() => {
    getPaymentMethods();
    getShippingMethods();
  }, []);
  if (loading) {
    return <CircularProgress/>;
  }
  return (
    <Stack spacing={3}>
      <Card>
        <CardHeader title="お支払い方法"/>
        <Box p={2}>
          {paymentMethods && <PaymentMethodList methods={paymentMethods}/>}
        </Box>
      </Card>
      <Card>
        <CardHeader title="送料"/>
        <Box p={2}>
          {shippingMethods && <ShippingMethodList methods={shippingMethods}/>}
        </Box>
      </Card>
    </Stack>
  );
}
export default PaymentDelivery;
