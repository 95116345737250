import {Box, Container, GlobalStyles} from "@mui/material";
import * as React from "react";
import {HelmetProvider} from "react-helmet-async";
import {MainNav} from "@/common/components/Nav/MainNav";
import {publicUrls} from "@/common/constant/paths";
import usePathname from "@/common/hooks/use-pathname";
import CustomModal from "@/common/components/Modal/CustomModal";
import authContext from "@/common/context/AuthContext";
import FormMemberLogin from "@/features/member/auth/login";
import cartContext from "@/common/context/CartContext";
import MainNavLogin from "@/common/components/Nav/MainNavLogin";
import {useMemo} from "react";
import {navItemsConfig} from "@/features/guest/config";
import {mainBackground} from "@/features/guest/styles/theme/colors";
import Footer from "./Footer";
type MainLayoutProps = {
  children: React.ReactNode;
  isAuthen: boolean;
};
/**
 * guest側の共通ヘッダー
 * @param {React.ReactNode} children - 子要素
 * @class
 */
function MainLayout({children, isAuthen}: MainLayoutProps) {
  const pathName = usePathname();
  const {setIsOpenAuthUser, isOpenAuthUser} = React.useContext(authContext);
  const {totalCartItems} = React.useContext(cartContext);
  const navItems = useMemo(() => navItemsConfig(totalCartItems), [totalCartItems])
  const isMatchLogin = pathName.includes("auth/login")
  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            "--MainNav-zIndex": 1000,
            "--MobileNav-width": "320px",
            "--MobileNav-zIndex": 1100,
          },
        }}
      />
      <HelmetProvider>
        <Box
          sx={{
            bgcolor: "var(--mui-palette-background-default)",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            minHeight: "100%",
            ...(isMatchLogin && {
              width: "100vw",
              backgroundImage: 'url("/assets/top/backgroundTop.jpg")',
              backgroundSize: "cover",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }),
          }}
        >
          {isMatchLogin ? (
            <MainNavLogin
              color="blend_in"
              items={navItems}
              isAuthen={isAuthen}
            />
          ) : (
            <Container maxWidth="xl">
              <MainNav color="blend_in" items={navItems} isAuthen={isAuthen} />
            </Container>
          )}
          <Box
            component="main"
            sx={{
              "--Content-margin": "0 auto",
              "--Content-paddingX": "24px",
              "--Content-padding":
                "var(--Content-paddingY) var(--Content-paddingX)",
              "--Content-width": "100%",
              display: "flex",
              flex: "1 1 auto",
              flexDirection: "column",
              background: mainBackground.light
            }}
          >
            {isAuthen || publicUrls.includes(pathName) ? (
              <Container sx={{ paddingY: {
                xs: '18px',
                sm: "24px"
              },
              paddingBottom: {
                xs: '60px',
                sm: "80px"
              }}} maxWidth="xl">
                {children}
              </Container>
            ) : (
              <Box>{children}</Box>
            )}
          </Box>
          <Footer isAuthen={isAuthen} />
          <CustomModal
            open={isOpenAuthUser}
            handleClose={() => setIsOpenAuthUser(false)}
            content={
              <FormMemberLogin onCancel={() => setIsOpenAuthUser(false)} />
            }
            sx={{ width: 365 }}
            data-testid="custom-modal"
          />
        </Box>
      </HelmetProvider>
    </>
  );
}
export default MainLayout;
