import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';
export interface PropertyItemProps {
  name: string | React.ReactNode;
  value: string | React.ReactNode;
}
/**
 *
 * @param root0
 * @param root0.name
 * @param root0.value
 */
export function PropertyItem({ name, value }: PropertyItemProps): React.JSX.Element {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'grid',
        gridGap: 'var(--PropertyItem-gap, 8px)',
        gridTemplateColumns: isXs ? '120px minmax(0, 1fr)' : "var(--PropertyItem-columns)",
        p: 'var(--PropertyItem-padding, 8px)',
      }}
    >
      <div>
        { typeof name === 'string' ? ( <Typography color="text.secondary" variant="body2">
          {name}
        </Typography>) : (
          <>{name}</>
        )}
      </div>
      <div>
        {typeof value === 'string' ? (
          <Typography color={value ? 'text.primary' : 'text.secondary'} variant="subtitle2">
            {value || 'None'}
          </Typography>
        ) : (
          <>{value}</>
        )}
      </div>
    </Box>
  );
}
