import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import {
  PencilSimple as PencilSimpleIcon
} from "@phosphor-icons/react/dist/ssr";
import {
  PropertyItem,
  PropertyList,
} from "@/features/guest/pages/confirm/components";
import { OrderFormData } from "@/common/utils/storage/types";
import { provinceList } from "@/common/utils/Prefecture";
export function UserInfo({ 
  handleRedirect, 
  orderFormData 
}: { 
  handleRedirect: () => void;
  orderFormData: Partial<OrderFormData>;
}) {
  const prefectureName = provinceList.find(item => item.value === orderFormData.prefecture_code)?.label;
  return (
    <Card style={{borderRadius:"5px"}}>
      <CardHeader
        titleTypographyProps={{
          variant: "subtitle1",
          fontWeight: "600"
        }}
        title="お届け先情報"
        action={
          <Button
            color="secondary"
            startIcon={<PencilSimpleIcon />}
            onClick={handleRedirect}
          >
          変更する
          </Button>
        }
      />
      <CardContent>
        <Card sx={{ borderRadius: 0.5 }} variant="outlined">
          <PropertyList
            divider={<Divider />}
            sx={{ "--PropertyItem-padding": "12px 24px" }}
          >
            {[
              {
                key: "お名前",
                value: (
                  <Typography variant="subtitle2"
                    sx={{wordBreak: "break-word"}}>
                    {orderFormData.name}
                  </Typography>
                ),
              },
              {
                key: "フリガナ",
                value: <Typography variant="subtitle2" sx={{wordBreak: "break-word"}}>
                  {orderFormData.furigana}
                </Typography>,
              },
              {
                key: "住所",
                value: (
                  <Typography variant="subtitle2" sx={{wordBreak: "break-word"}}>
                    {prefectureName}
                    {orderFormData.address}
                    {orderFormData.streetAddress}
                    {orderFormData.buildingAddress ? ` ${orderFormData.buildingAddress}` : ""}
                  </Typography>
                ),
              },
              {
                key: "電話番号",
                value: (
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ alignItems: "center" }}
                  >
                    <Typography variant="subtitle2" sx={{wordBreak: "break-word"}}>
                      {orderFormData.phoneNumber}
                    </Typography>
                  </Stack>
                ),
              },
              {
                key: "メールアドレス",
                value: (
                  <Typography variant="subtitle2" sx={{wordBreak: "break-word"}}>
                    {orderFormData.email}
                  </Typography>
                ),
              },
            ].map((item) => (
              <PropertyItem key={item.key} name={item.key} value={item.value} />
            ))}
          </PropertyList>
        </Card>
      </CardContent>
    </Card>
  );
} 