import { Route, Routes } from "react-router-dom";
import Index from "@/features/member/auth/MyPage";
import ForgotPassword from "@/features/member/auth/password-email";
import PasswordReset from "@/features/member/auth/password-reset";
import MyAccount from "../MyAccount";
import PublicRoute from "./components/PublicRoute";
import ProtectedRoute from "./components/ProtectedRoute";
/**
 * メンバーユーザーのルーティング
 * @class
 */
function MemberRoutes() {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="/myaccount" element={<MyAccount />} />
        {/* TODO:Phase2以降で対応 */}
        <Route path="/mypage" element={<Index />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/password/email" element={<ForgotPassword />} />
        <Route path="/password/reset" element={<PasswordReset />} />
      </Route>
    </Routes>
  );
}
export default MemberRoutes;
