import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
export interface PropertyItemProps {
  name: string;
  value: string | React.ReactNode;
}
/**
 *
 * @param root0
 * @param root0.name
 * @param root0.value
 * @param root0.third
 */
export function PropertyItem({
  name,
  value,
}: PropertyItemProps): React.JSX.Element {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "grid",
        gridGap: "var(--PropertyItem-gap, 8px)",
        gridTemplateColumns: "1fr 1fr 1fr",
        p: "var(--PropertyItem-padding, 8px)",
      }}
    >
      <div>
        <Typography variant="subtitle2" fontWeight={700}>
          {name}
        </Typography>
      </div>
      <div>
        <Typography color="text.secondary">{value}</Typography>
      </div>
    </Box>
  );
}
