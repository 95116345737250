import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "@/common/context/AuthContext.tsx";
function ProtectedRoute() {
  const { isUserEventSignedIn } = useContext(AuthContext);
  if (!isUserEventSignedIn) {
    return <Navigate to="/auth/login" />;
  }
  return <Outlet />;
}
export default ProtectedRoute;
