import React from "react";
import { Box, Card, Container, Grid, Stack, Typography } from "@mui/material";
// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
// import { paths } from "@/common/constant/paths";
function PrivacyPolicy() {
  return (
    <Box>
      {/* <Breadcumbs
        breadcrumbs={[{ title: "トップ", href: paths.folder.list }, { title: "個人情報保護方針" }]}
      />
      <Typography textAlign="left" variant="h4" mt={2}>
        個人情報保護方針
      </Typography> */}
      <Grid item md={12} xs={12} pb={2} mt={2}>
        <Stack spacing={4}>
          <Card sx={{ mt: 2, p: 3 }}>
            <Container maxWidth="xl">
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                1.個人情報保護方針について
              </Typography>
              <Typography mt={2}>
                {`■■■■■(以下、「弊社」といいます)は、Rainbow photoを利用してお客様より依頼された写真注文サービス (以下「サービス」
                といいます)実施のため、 お客様に個人情報（氏名、住所、性別、年齢、電話番号、メールアドレス、画像その他お客様の識別を可
                能とする情報をいいます）の登録 （書面記入又はネット上の入力）をお願いすることがあります。 ご登録戴いた個人情報の取扱いに
                関し、弊社では個人情報に適用される日本の法令・ガイドラインを遵守し、ご登録戴いた個人情報の保護に努めるものとします。 なお、
                本指針は弊社にリンクする他のウェブサイトにおける個人情報の管理・取扱いには 適用されませんのでご注意下さい。`}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 600 }} mt={3}>
              2.個人情報の登録
              </Typography>
              <Typography mt={2}>
              お客様による個人情報の登録時には、その利用目的と利用範囲を明示致しますので、必ずご確認下さい。 お客様は、
              ご自身の個人情報のみ登録してください。未成年者の個人情報につきましては、保護者の方が登録してください。 
              登録に際しては必ずしも全ての事項を登録する必要はありませんが、未登録事項によってはサービスの提供に差し支える場合もございますので御了承ください。
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 600 }} mt={3}>
                3.個人情報の利用目的及び利用方法について
              </Typography>
              <Typography mt={2}>
                弊社は、次の目的のために必要な範囲において個人情報を利用するものとし、お客様はこれを了承するものとします。
              </Typography>
              <Typography mt={2}>
                （1）サービス及びメンテナンスの実施のため
              </Typography>
              <Typography mt={2}>
                （2）弊社のサービス向上のための参考資料として使用するため（但し個人識別 不可能な統計的情報の利用に限る
              </Typography>
              <Typography mt={2}>
                （3）銀行口座やクレジットカードその他お客様の支払方法の正当性、有効性を確認するため
              </Typography>
              <Typography mt={2}>
                （4）各種キャンペーン、アンケート、アップデート情報のお知らせの発送のため
              </Typography>
              <Typography mt={2}>
              また弊社は、法令の定めにより開示が求められた場合、及びお客様の安全その他重大な利益を保護する場合には、個人情報を開示できるものとします。 上記以外の目的で利用する場合には、別途本サービス上でお知らせします。
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 600 }} mt={3}>
                4．個人情報の改定・抹消
              </Typography>
              <Typography mt={2}>
                お客様は、別途写真館がご案内する方法により、何時でも登録した個人情報の改定・抹消を求めることが出来ます。 弊社はできるだけ速やかに当該改定・抹消を反映致します。
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 600 }} mt={3}>
                5．第三者への開示等
              </Typography>
              <Typography mt={2}>
              弊社が、サービス実施のためにビジネスパートナーを使用する場合、当該パートナーに個人情報を開示することがあります。 但し、その場合には弊社は当該パートナーと機密保持契約を締結し、本書所定の条件を遵守致します。
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 600 }} mt={3}>
                6．本ポリシーの改定について
              </Typography>
              <Typography mt={2}>
                弊社は、弊社のサービス内容の変更又は社会情勢の変更に応じ、適宜改定することが出来るものとします。
              </Typography>
            </Container>
          </Card>
        </Stack>
      </Grid>
    </Box>
  );
}
export default PrivacyPolicy;
