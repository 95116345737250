import { convertToDateFormat, getDaysRemaining } from "@/common/utils/Date";
import { TypeResponseTimeResaleEvent } from "@/features/member/auth/MyPage/types";
import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
type TypeInfoTimeSaleCardProps = {
  data?: TypeResponseTimeResaleEvent;
};
function InfoTimeSaleCard(props: TypeInfoTimeSaleCardProps) {
  const { data } = props;
  return (
    <Card style={{ borderRadius: "7px", minHeight: "100px" }}>
      <CardContent style={{padding:"15px 20px"}}>
        <Box display="flex" marginBottom="10px" alignItems="center">
          <Typography
            variant="h6"
            fontWeight="600"
            color="var(--mui-palette-primary-main)"
          >
            販売期間
          </Typography>
          {data?.status === "ON_SALE" && (
            <Box
              display="flex"
              alignItems="flex-end"
              fontSize="12px"
              marginLeft="10px"
              marginBottom="5px"
            >
              残り
              <Typography
                fontSize="26px"
                color="var(--mui-palette-primary-main)"
                fontWeight="700"
                lineHeight="1"
                padding="0 5px"
              >{`${getDaysRemaining(data?.order_end_date)}`}</Typography>
              日
            </Box>
          )}
        </Box>
        <Stack>
          {/* <Box>{data?.status ? EVENT_STATUS[data.status] : ""} </Box> */}
          <Box display="flex">
            <Typography fontSize="12px" width="70px">
              販売開始
            </Typography>
            <Typography>
              {convertToDateFormat(data?.order_start_date)}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography fontSize="12px" width="70px">
              販売終了
            </Typography>
            <Typography>{convertToDateFormat(data?.order_end_date)}</Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}
export default InfoTimeSaleCard;
