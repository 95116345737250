// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { paths } from "@/common/constant/paths";
function ContactSuccessPage() {
  const navigate = useNavigate();
  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      minHeight="35vh"
    >
      <Grid item xs={6}>
        {/* <Breadcumbs
        breadcrumbs={[
          { title: "トップ", href: paths.home },
          { title: "お問い合わせ完了" },
        ]}
      />
      <Typography mt={4} variant="h4" textAlign="left" fontWeight="bold">
        お問い合わせ完了
      </Typography> */}
        <Box marginTop={4}>
          <Typography>お問い合わせメールが正常に送信されました。</Typography>
          <Typography>ありがとうございました。</Typography>
          <Typography>
            後日、担当者から連絡させていただきますので、いましばらくお待ちください。
          </Typography>
        </Box>
      </Grid>
      <Button
        sx={{ marginTop: 3 }}
        variant="contained"
        onClick={() => {
          navigate(paths.home);
        }}
      >
        TOPに戻る
      </Button>
    </Grid>
  );
}
export default ContactSuccessPage;
