// common data so must use type any
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
type TProps = {
  label: string;
  name: string;
};
export default function CheckboxForm(props: TProps): ReactElement {
  const { label, name } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <FormControl>
      <Controller
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={value ?? false}
                name={name}
                onChange={(event) => {
                  onChange(event.target.checked);
                }}
              />
            }
            label={label}
          />
        )}
        name={name}
        control={control}
      />
      {errors?.[name]?.message && (
        <FormHelperText sx={{ color: "error.main" }}>
          {errors?.[name]?.message as string}
        </FormHelperText>
      )}
    </FormControl>
  );
}
