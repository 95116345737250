import { Route, Routes } from "react-router-dom";
import FAQ from "@/features/guest/pages/faq";
import UsageGuide from "@/features/guest/pages/UsageGuide";
import PrivacyPolicy from "@/features/guest/pages/PrivacyPolicy";
import TermOfServices from "@/features/guest/pages/TermOfServices";
import ContactPage from "@/features/guest/pages/contact";
import CheckUenv from "@/features/guest/pages/check";
import ContactSuccessPage from "@/features/guest/pages/contact-success";
/**
 * User画面のルーティング
 * @class
 */
function PublicRoutes() {
  return (
    <Routes>
      <Route path="/faq" element={<FAQ />} />
      <Route path="/usage-guide" element={<UsageGuide />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/term-of-services" element={<TermOfServices />} />
      <Route path="/checks/uenv" element={<CheckUenv />} />
      <Route path="/contacts" element={<ContactPage />} />
      <Route path="/contact-success" element={<ContactSuccessPage />} />
      {/* <Route path="/const/tokutei" element={ <TokuteiPage /> } /> */}
    </Routes> 
  );
}
export default PublicRoutes;
