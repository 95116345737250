type TypeIconNext = {
  width?: string;
  height?: string;
  color?: string;
};
export default function IconNext(props: TypeIconNext) {
  const { width = "13", height = "15", color = "white" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13 15"
      fill="none"
      //   style="&#10;    /* background: rebeccapurple; */&#10;"
    >
      <path
        d="M11.75 8.79904C12.75 8.22169 12.75 6.77831 11.75 
        6.20096L3.5 1.43782C2.5 0.860472 1.25 1.58216 1.25 
        2.73686V12.2631C1.25 13.4178 2.5 14.1395 3.5 13.5622L11.75 8.79904Z"
        fill="white"
        stroke={color}
      />
    </svg>
  );
}
