export const SECTION_TITLES = {
  USE: "第１条（Rainbow photo利用の許諾）",
  SERVICE_CONTENT: "第２条（ID等）",
  PROHIBITED_ACTS: "第３条（対価）",
  MEMBERSHIP_REGISTRATION: "第４条（権利の帰属）",
  PERSON_INFO: "第５条（個人情報）",
  CANCEL_SERVICE: "第６条（本サービスの中止等）",
  PROHIBITIONS: "第７条（禁止事項）",
  PROHIBITION_SERVICES: "第８条（本サービスの利用禁止）",
  DISCLAIMER: "第９条（免責事項等）",
  GOVERNING_LAW: "第１０条（準拠法）",
  COMPENSATION_DAMAGES: "第１１条(損害賠償)",
  TERM_CONDITION: "第１２条(商品未払いに関しての規約)",
  PRODUCT_CANCEL: "第１３条（商品キャンセルについて）",
};
export const CONTENTS = {
  USE: [
    "Rainbow photo利用者は、本規約の全条項及びQ＆A記載事項に同意したとみなします。",
  ],
  SERVICE_CONTENT: [
    "1. 当社は利用者に、ID（「ユーザID」）及びパスワード（「ユーザパスワード」）を提供致します。",
    "2. 利用者は当社から提供されたID及びパスワードを、厳重に管理するものとし、これらを第三者に譲渡、貸与しないものとします。",
  ],
  PROHIBITED_ACTS: [
    "1. 利用者は、本サービスで写真を購入する対価として、別途定める対価を支払うものとします。",
    "2. 理由の如何を問わず、一旦支払われた対価については返還されないものとします。",
  ],
  MEMBERSHIP_REGISTRATION: [
    `本サービスに掲載された全ての写真の著作権は、当社もしくは当社に著作権管理を委託している写真家に帰属し、日
      本および各国の著作権法の保護を受けています。写真に関するいかなる著作権、その他知的財産権も利用者に譲渡されるものではありません。`,
    `当サイトの写真画像は写真情報への明記以外、被写体の「肖像権」「商標権」「特許権」「著作権」「利用権」その他の諸権利
      を有していません。画像被写体、又はその使用形態により利用者において別途、これらの権利者の使用許諾が必要となる場合
      があります。写真購入料金には、これら諸権利の使用許諾料金は含まれていません。`,
    `写真画像の被写体のイメージを損なう方法（公序良俗に反する使用、写真の被写体の名誉や信用を毀損する
      使用や誹謗中傷、その他不法な用途への使用。及び次のいずれかに関わる使用：風俗産業・ポルノ、重大な身体並びに精神
      的障害や疾病、老化に伴う諸症状、肉体的及び精神的暴力、不妊・避妊・妊娠中絶、性転換・同性愛、ギャンブル、消費者金
      融、美容外科）での使用することを禁止します。これらの諸権利に関する紛争 について、当社は一切の責任を負わないものとします。`,
  ],
  PERSON_INFO: [
    {
      text: "1. 利用者は、利用者情報をプライバシーポリシーに従い当社が利用することに同意するものとします。",
    },
    {
      text: "2. 当社は、利用者が登録した利用者情報に関し、利用者のプライバシー保護に十分留意するものとします。",
    },
    {
      text: `3. 当社は、利用者情報について、次に該当する場合を除き第三者に開示しないものとします。`,
    },
    { text: `(1) 当会員が開示に同意している場合`, indented: true },
    {
      text: `(2) クレジットカードの有効性を確認するため、当社と当該カード会社間で会員の個人情報を交換する場合`,
      indented: true,
    },
    {
      text: `(3) 法律に基づき、裁判所・検察・警察等から開示を求められた場合`,
      indented: true,
    },
  ],
  CANCEL_SERVICE: [
    {
      text: "1. 当社は、次の場合には、本サービスの提供を中止することがあります。",
    },
    {
      text: "(1) システムの保守を、定期的にもしくは緊急に行う場合",
      indented: true,
    },
    {
      text: "(2) 天災、地変、その他の非常事態が発生、もしくは発生する恐れがある場合",
      indented: true,
    },
    {
      text: "(3) その他、当社が本サービスの運用の全部、または一部を中止することが望ましいと判断した場合",
      indented: true,
    },
    {
      text: "2. 当社は前項に基づく本サービスの提供の中止によって生じた利用者その他の第三者の損害につき、一切責任を負わないものとします。",
    },
  ],
  PROHIBITIONS: [
    {
      text: "1. 契約者及び登録者は、本サービスの利用にあたって以下の行為を行ってはならないものとします。",
    },
    {
      text: "(1) 他の利用者、当社その他の第三者の財産権、プライバシー、名誉その他の権利を侵害し、または侵害する恐れのある行為",
      indented: true,
    },
    {
      text: "(2) いかなる理由においても本サービスの運営を妨げる行為",
      indented: true,
    },
    { text: "(3) ID及びパスワード等を不正に使用する行為", indented: true },
    {
      text: "(4) コンピューターウィルス等有害なプログラムを本サービスを通じて、または本サービスに関連して使用、もしくは提供する行為",
      indented: true,
    },
    { text: "(5) その他、弊社が不適切と判断する行為", indented: true },
  ],
  PROHIBITION_SERVICES: [
    {
      text: "利用者に次の各号の事由が一つでも生じた場合には、当社からの事前の通知なく、利用者に対して、本サービスの利用を禁止にできるものとします。",
    },
    { text: "(1) 本規約の条項の一つにでも違反したとき", indented: true },
    { text: "(2) 支払が不履行になったとき", indented: true },
    {
      text: "(3) 本サービスの運営を妨げ、その他本サービスに支障のきたすおそれのあるとき",
      indented: true,
    },
    { text: "(4) 利用者情報について虚偽の申請をしたとき", indented: true },
    {
      text: "(5) その他当社が利用者として不適当と判断したとき",
      indented: true,
    },
  ],
  DISCLAIMER: [
    {
      text: `1. 本サービスは、現状のまま提供されるものであり、本利用規約その他当社が本サービスに関して定める
        規定等において明示的に定めるほかは、完全性、確実性、有用性、特定の目的への適合性、その他について保証するものではありません。`,
    },
    {
      text: `2. 利用者はインターネットを利用した送信行為が、クレジットカード番号等を含む個人情報の漏洩等の危険性を含む
        ことを十分認識し自己の責任のもとに管理、送信を行うものとし、当社はかかる損害について、一切の責任を負わないものとします。`,
    },
    {
      text: "3. 会員が本規約等に違反したことによって生じた損害については、当社は一切責任を負いません。",
    },
    {
      text: "4. 当社は本サービスの内容の変更、中止、廃止、その他本サービスの利用により利用者に生じた損害、紛争について、いかなる責任も負わず、一切の損害賠償義務を負いません。",
    },
  ],
  GOVERNING_LAW: [
    "本規約は日本法を準拠法とし、日本法に従って解釈されるものとします。",
  ],
  COMPENSATION_DAMAGES: [
    `本会員規約に特に定めるほか、会員は、故意若しくは過失により、本会員規約違反により、又は不正若しくは違法に本会員サー
      ビスを利用することにより弊社に損害を被らせた場合、弊社に対し、その全ての損害を直ちに賠償する責任を負うものとします。`,
  ],
  TERM_CONDITION: [
    `本会員規約に特に定めるほか、会員は、故意若しくは過失により、本会員規約違反により、又は不正若しくは違法に本会員サー
      ビスを利用することにより弊社に損害を被らせた場合、弊社に対し、その全ての損害を直ちに賠償する責任を負うものとします。`,
  ],
  PRODUCT_CANCEL: [
    "１）未払い者への対応",
    `商品を弊社から発送後、10日間を経過しても、お客様からご連絡がない場合はお客様が商品を受け取ったものとし、いかな
      る場合でも料金を回収させて頂きます。弊社へのご連絡なき返品に関しては、商品がお客様のお手元に有る無しに関わらず、料金の回収対象となります。`,
    "２）内容証明による督促",
    `納品より6ヶ月を経過しても、入金が確認されない場合、内容証明郵便にて督促をいたします。その際に発生する手数料のす
      べてを、お客様にご負担いただきます。内容証明に記載されました支払期日以内に、指定どおりに支払いがなされない、もしくは
      何のご連絡も無い場合はまた簡易裁判所にて『支払い督促・少額訴訟』の手続きをさせていただき、民事訴訟として未払い代金の
      回収をさせていただくことがございます。なお、訴訟申請が簡易裁判所側に受理されますと、審理する期日が弊社（原告）と代金
      未払い者の双方に通知されます。弊社が訴訟申請の手続きを始めてから、いかなる時点で、お客様が代金をお支払いいただいても、
      それまでに発生した訴訟手続きの諸経費を加算して代金未払い者に請求し、代金未払い者は、その請求金額を弊社（原告）に支払う
      事と致します。裁判後、判決に基づいて未払い代金をお支払い頂く場合、弊社（原告）は裁判にかかった全ての費用・経費を未払い
      代金に加算して代金未払い者（被告）に請求し、代金未払い者（被告）はその請求金額を弊社（原告）に支払う事と致します。
      訴訟の対象になった代金未払い者、および弊社からの支払い要請に対して応じない場合、転居等により弊社からの支払い要請が不可
      能な場合において警視庁ハイテク犯罪対策センター、通販同業各社、第三者機関（民間債権回収会社・信用機関・被害対策機関等）
      へ個人情報を含めた情報開示、および通報をやむを得ず行うものとさせて頂きます。これに伴い起きうる事象すべてにおいて弊社は一切関知いたしません。`,
  ],
};
