import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import {Typography} from "@mui/material";
import { DataTable, type ColumnDef } from './DataTable';
import { CartItem } from '../type';
const columns = [
  {
    formatter: (row): React.JSX.Element => (
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        <Box
          sx={{
            backgroundImage: `url(${row.image})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            bgcolor: 'var(--mui-palette-background-level2)',
            flex: '0 0 auto',
            height: '135px',
            width: '200px',
          }}
        />
      </Stack>
    ),
    name:"ファイル",
    width: '',
    isAlignHeader: true,
    align: "left"
  },
  {
    formatter: (row): React.JSX.Element => (
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        <Link color="text.primary" variant="h6" fontWeight={600}>
          <Box>{row.productName}</Box>
          <Box>{row.productSize}</Box>
        </Link>
      </Stack>
    ),
    name:"ファイル名 / サイズ",
    align:"left",
    width: '170px',
  },
  {
    formatter: (row): React.JSX.Element => <Typography variant="h6" fontWeight={600}>{row.quantity}</Typography>,
    name: '数量',
    width: '100px',
    align: "right"
  },
  {
    formatter: (row): React.JSX.Element => <Typography variant="h6" fontWeight={600}>{row.pricePerItem}円</Typography>,
    name: '単価',
    width: '120px',
    align: "center"
  },
  {
    formatter: (row): React.JSX.Element => <Typography variant="h6" fontWeight={600}>{row.subtotalPrice}円</Typography>,
    name: '金額（税込）',
    width: '150px',
    align: 'center',
  },
] satisfies ColumnDef<CartItem>[];
export interface LineItemsTableProps {
  rows: CartItem[];
}
export function LineItemsTable({ rows }: LineItemsTableProps): React.JSX.Element {
  return <DataTable<CartItem> columns={columns} rows={rows} />;
}
