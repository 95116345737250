import { Avatar, Button, Stack, Typography } from "@mui/material";
import { Warning as WarningIcon } from "@phosphor-icons/react/dist/ssr/Warning";
import { TypeContentDeleteCartItem } from "../types";
export function ContentDeleteCartItem(props: TypeContentDeleteCartItem) {
  const { handleClose, handleOk } = props;
  return (
    <Stack direction="row" spacing={2} sx={{ display: "flex", p: 2 }}>
      <Avatar
        sx={{
          bgcolor: "var(--mui-palette-error-50)",
          color: "var(--mui-palette-error-main)",
        }}
      >
        <WarningIcon fontSize="var(--Icon-fontSize)" />
      </Avatar>
      <Stack spacing={3}>
        <Typography variant="h5">本当に削除しますか？</Typography>
        <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end" }}>
          <Button color="secondary" onClick={handleClose}>
            キャンセル
          </Button>
          <Button color="error" variant="contained" onClick={handleOk}>
            OK
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
