import { useState } from "react";
// import { useLocation } from "react-router-dom";
import Head from "@/common/components/Head/Head";
import Logger from "@/common/utils/Logger";
import PasswordResetForm from "./components/PasswordResetForm";
import PasswordResetDone from "./components/PasswordResetDone";
/**
 * 予約画面のメインページ
 * @class
 */
export default function PasswordReset() {
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const token = searchParams.get("token") || "";
  const [showForm, setShowForm] = useState(true);
  const [showDone, setShowDone] = useState(false);
  /**
   * 認証成功時の処理
   */
  const handleSuccess = () => {
    setShowForm(false);
    setShowDone(true);
  };
  /**
   *
   */
  const handleResetDone = () => {
    Logger.logger("asdas");
  };
  return (
    <>
      <Head />
      {showForm && (
        <PasswordResetForm onSuccess={handleSuccess} />
      )}
      {showDone && <PasswordResetDone onSuccess={handleResetDone} />}
    </>
  );
}
