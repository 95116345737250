import * as yup from "yup";
// import {ValidationError} from "yup";
import {
  // couponRegex,
  emailRegex,
  numberRegex,
  password,
  passwordRegex,
  phoneNumber,
  phoneNumberRegex,
  postCode,
} from "./regex";
// import { katanakaRegex, numberRegex } from "./regex";
const emailSchema = yup
  .string()
  .required("メールアドレスを入力してください")
  .max(255)
  .matches(
    emailRegex,
    "E-mailを正しいメールアドレスの形式で入力してください。"
  );
const passcodeSchema = yup
  .string()
  .max(20)
  .required("イベントパスワードを入力してください。")
  .matches(
    passwordRegex,
    "パスワードは8文字以上で、大文字、小文字、数字、特殊文字を含めてください"
  );
const passcodeSchemaLogin = yup
  .string()
  .required("ログインIDまたは、パスワードが違います")
  .matches(
    passwordRegex,
    "ログインIDまたは、パスワードが違います"
  );
const createOrEditTmpBooking = yup.object().shape({
  reserve_date: yup.string().required(),
  reserve_time: yup
    .string()
    .max(8)
    .matches(
      /\d\d(:\d\d){2}/,
      "予約停止開始は 00:00:00 の形式で入力してください。"
    ),
  reserve_time_end: yup
    .string()
    .max(8)
    .matches(
      /\d\d(:\d\d){2}/,
      "予約停止終了時間は 00:00:00 の形式で入力してください。"
    ),
});
const accountSchema = yup.object().shape({
  last_name: yup.string().max(20),
  first_name: yup.string().max(20),
  phone: yup
    .string()
    .matches(phoneNumberRegex, "正しい電話番号を入力してください"),
  email: yup
    .string()
    .max(100)
    .matches(
      emailRegex,
      "E-mailを正しいメールアドレスの形式で入力してください。"
    ),
  memo: yup.string().max(200).nullable(),
});
const signinSchema = yup.object().shape({
  email: emailSchema,
  passcode: passcodeSchema,
});
const forgotSchema = yup.object().shape({
  email: emailSchema,
});
const signupSchema = yup.object().shape({
  name: yup.string().required("お名前を入力してください").max(20),
  furigana_name: yup.string().required("フリガナを入力してください").max(20),
  phone_number: yup
    .string()
    .nullable()
    .transform((value, originalValue) => (originalValue ? value : null))
    .matches(phoneNumberRegex, "正しい電話番号を入力してください"),
  email: emailSchema,
  building_address: yup
    .string()
    .required("建物名等を入力してください")
    .max(255),
  address: yup.string().required("住所を入力してください").max(255),
  street_address: yup.string().required("住所(丁目番地)を入力してください").max(255),
  prefecture: yup.string().required("選択を入力してください").max(255),
  post_code: yup.string().required("郵便番号を入力してください").max(255),
  passcode: passcodeSchema,
});
const confirmSignupSchema = yup.object().shape({
  code: yup.string().required("確認コードを入力してください").max(8),
});
const confirmForgotSchema = yup.object().shape({
  resetCode: yup.string().required("確認コードを入力してください").max(8),
  password: passcodeSchema,
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("password")], "同じパスワードを入力する必要があります")
});
const loginSchema = yup.object().shape({
  email: emailSchema,
  passcode: passcodeSchemaLogin,
});
const resetPasswordSchema = yup.object().shape({
  email: emailSchema,
});
const resetPassword = yup.object().shape({
  password: passcodeSchema,
  confirm_passcode: yup
    .string()
    .required("パスワードを入力してください")
    .oneOf([yup.ref("password")], "新しいパスワードと一致しません"),
});
const confirmResetPasswordSchema = yup.object().shape({
  code: yup
    .string()
    .required("確認コードを入力してください")
    .max(6)
    .matches(numberRegex, "確認コードは数字のみで入力してください"),
  passcode: passcodeSchema,
  confirm_passcode: yup
    .string()
    .required("パスワードを入力してください")
    .oneOf([yup.ref("passcode")], "新しいパスワードと一致しません"),
});
const contactSchema = yup.object().shape({
  email: emailSchema,
  telnum: yup
    .string()
    .matches(phoneNumberRegex, "正しい電話番号を入力してください"),
  name: yup.string().required("名前を入力してください"),
  kana: yup.string().required("フリガナを入力してください"),
  contact_type: yup.string(),
  message: yup.string().required("問い合わせ内容を入力してください"),
  policy: yup.boolean().required()
});
const memberMyAccountSchema = yup.object().shape({
  name: yup
    .string()
    .required("お名前は必須入力です。")
    .max(50, "お名前は入力形式が異なります。"),
  furigana_name: yup
    .string()
    .required("フリガナは必須入力です。")
    .max(50, "フリガナは入力形式が異なります。"),
  post_code: yup
    .string()
    .required("郵便番号は必須入力です。")
    .matches(postCode, "郵便番号は入力形式が異なります。"),
  prefecture_code: yup.number().required("都道府県は必須入力です。"),
  address: yup
    .string()
    .required("住所は必須入力です。")
    .max(127, "住所は入力形式が異なります。"),
  street_address: yup
    .string()
    .required("住所(丁目番地)は必須入力です。")
    .max(127, "住所(丁目番地)は入力形式が異なります。"),
  phone_number: yup
    .string()
    .required("電話番号は必須入力です。")
    .matches(phoneNumber, "電話番号は入力形式が異なります。"),
  building_address: yup
    .string()
    .max(127, "建物名等は入力形式が異なります。"),
  email: yup
    .string()
    .required("メールアドレスは必須入力です。")
    .matches(emailRegex, "メールアドレスは入力形式が異なります。"),
  password: yup
    .string()
    .required("イベントパスワードは必須入力です。")
    .matches(password, "イベントパスワードは入力形式が異なります。"),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("password")], "同じパスワードを入力する必要があります")
    .required("同じパスワードを入力する必要があります")
});
const myAccountSchema = yup.object().shape({
  name: yup
    .string()
    .required("お名前は必須入力です。")
    .max(50, "お名前は入力形式が異なります。"),
  furigana_name: yup
    .string()
    .required("フリガナは必須入力です。")
    .max(50, "フリガナは入力形式が異なります。"),
  post_code: yup
    .string()
    .required("郵便番号は必須入力です。")
    .matches(postCode, "郵便番号は入力形式が異なります。"),
  prefecture_code: yup.number().required("都道府県は必須入力です。"),
  address: yup
    .string()
    .required("住所は必須入力です。")
    .max(127, "住所は入力形式が異なります。"),
  street_address: yup
    .string()
    .required("住所(丁目番地)は必須入力です。")
    .max(127, "住所(丁目番地)は入力形式が異なります。"),
  phone_number: yup
    .string()
    .required("電話番号は必須入力です。")
    .matches(phoneNumber, "電話番号は入力形式が異なります。"),
  building_address: yup
    .string()
    .max(127, "建物名等は入力形式が異なります。"),
  email: yup
    .string()
    .required("メールアドレスは必須入力です。")
    .matches(emailRegex, "メールアドレスは入力形式が異なります。"),
  password: yup
    .string()
    .matches(password, "イベントパスワードは入力形式が異なります。"),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("password")], "同じパスワードを入力する必要があります")
});
const FormOrderInfoSchema = yup.object().shape({
  name: yup
    .string()
    .required("お名前は必須入力です。")
    .max(50, "お名前は入力形式が異なります。"),
  nameFurigana: yup.string().required("フリガナは必須入力です。"),
  code: yup
    .string()
    .required("郵便番号は必須入力です。")
    .matches(postCode, "郵便番号は入力形式が異なります。"),
  address: yup
    .string()
    .required("住所は必須入力です。")
    .max(127, "住所は入力形式が異なります。"),
  streetAddress: yup
    .string()
    .required("住所(丁目番地)は必須入力です。")
    .max(127, "住所(丁目番地)は入力形式が異なります。"),
  building: yup
    .string()
    .max(127, "建物名等は入力形式が異なります。"),
  province: yup.number().required("都道府県は必須入力です。"),
  phoneNumber: yup.string().required("電話番号は必須入力です。").matches(phoneNumber, "電話番号は入力形式が異なります。"),
  email: emailSchema,
  // coupon: yup.string().matches(couponRegex, "クーポンコードは半角数字で入力してください。"),
});
export {
  createOrEditTmpBooking,
  accountSchema,
  loginSchema,
  signupSchema,
  confirmSignupSchema,
  resetPasswordSchema,
  confirmResetPasswordSchema,
  resetPassword,
  contactSchema,
  memberMyAccountSchema,
  FormOrderInfoSchema,
  myAccountSchema,
  signinSchema,
  forgotSchema,
  confirmForgotSchema,
};
