type TypeIconInquiry = {
  width?: string;
  height?: string;
  color?: string
}
export default function IconInquiry(props: TypeIconInquiry) {
  const {width="34", height="26", color="#4CC6D7"} = props 
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 34 26"
      fill="none"
    >
      <path
        d="M4.46767 3.77836C3.90412 3.77836 3.44304 
        4.23945 3.44304 4.803V6.21827L14.4899 15.2863C15.8155 
        16.3749 17.7174 16.3749 19.0431 15.2863L30.0835 
        6.21827V4.803C30.0835 4.23945 29.6224 3.77836 29.0588 
        3.77836H4.46767ZM3.44304 10.1951V21.1971C3.44304 21.7607 
        3.90412 22.2217 4.46767 22.2217H29.0588C29.6224 22.2217 
        30.0835 21.7607 30.0835 21.1971V10.1951L20.9899 17.6621C18.5307 
        19.6794 14.9894 19.6794 12.5366 17.6621L3.44304 10.1951ZM0.369141 
        4.803C0.369141 2.5424 2.20707 0.704468 4.46767 
        0.704468H29.0588C31.3194 0.704468 33.1574 2.5424 
        33.1574 4.803V21.1971C33.1574 23.4577 31.3194 25.2956 
        29.0588 25.2956H4.46767C2.20707 25.2956 0.369141 23.4577 
        0.369141 21.1971V4.803Z"
        fill={color}
      />
    </svg>
  );
}
