import * as React from "react";
import Box from "@mui/material/Box";
// import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
// import { CaretLeft as CaretLeftIcon } from "@phosphor-icons/react/dist/ssr/CaretLeft";
// import { CaretRight as CaretRightIcon } from "@phosphor-icons/react/dist/ssr/CaretRight";
import type { EmblaCarouselType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import Fade from "embla-carousel-fade";
import Autoplay from "embla-carousel-autoplay";
// import { useTheme } from "@mui/material";
type Props = {
  children: React.ReactNode;
};
function Carouse({ children }: Props) {
  // const theme = useTheme();
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, duration: 30, align: "start" },
    [Fade(), Autoplay({ playOnInit: true, delay: 5000 })]
  );
  const [, setPrevBtnDisabled] = React.useState(true);
  const [, setNextBtnDisabled] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);
  // const scrollPrev = React.useCallback(() => {
  //   emblaApi?.scrollPrev();
  // }, [emblaApi]);
  // const scrollNext = React.useCallback(() => {
  //   emblaApi?.scrollNext();
  // }, [emblaApi]);
  const scrollTo = React.useCallback(
    (index: number) => {
      emblaApi?.scrollTo(index);
    },
    [emblaApi]
  );
  const onInit = React.useCallback((api: EmblaCarouselType) => {
    setScrollSnaps(api.scrollSnapList());
  }, []);
  const onSelect = React.useCallback((api: EmblaCarouselType) => {
    setSelectedIndex(api.selectedScrollSnap());
    setPrevBtnDisabled(!api.canScrollPrev());
    setNextBtnDisabled(!api.canScrollNext());
  }, []);
  React.useEffect(() => {
    if (!emblaApi) return;
    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);
  return (
    <Box height="100%">
      <Stack spacing={8} height="100%">
        <Stack
          spacing={3}
          sx={{
            position: "relative",
            ".embla__slide": {
              flex: "0 0 100%",
            },
          }}
          height="100%"
        >
          <Box
            ref={emblaRef}
            sx={{ overflow: "hidden", position: "absolute", top: 0, bottom: "0" }}
          >
            <Box
              sx={{
                backfaceVisibility: "hidden",
                display: "flex",
                touchAction: "pan-y",
                ml: "calc(var(--slide-spacing) * -1)",
                position: "relative",
              }}
              height="100%"
            >
              {children}
            </Box>
          </Box>
          {/* <Box
            sx={{
              position: "absolute",
              left: 20,
              top: "50%",
              transform: "translateY(-50%)",
              bgcolor: theme.palette.secondary.contrastText,
              borderRadius: 1,
            }}
          >
            <IconButton disabled={prevBtnDisabled} onClick={scrollPrev}>
              <CaretLeftIcon />
            </IconButton>
          </Box> */}
          {/* <Box
            sx={{
              position: "absolute",
              right: 20,
              top: "50%",
              transform: "translateY(-50%)",
              bgcolor: theme.palette.secondary.contrastText,
              borderRadius: 1,
            }}
          >
            <IconButton disabled={nextBtnDisabled} onClick={scrollNext}>
              <CaretRightIcon />
            </IconButton>
          </Box> */}
          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: "end", marginBottom:"20px" }}
            height="100%"
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{ flex: "1 1 auto", justifyContent: "center"}}
            >
              {scrollSnaps.map((_, index) => (
                <Box
                  // eslint-disable-next-line react/no-array-index-key -- index is unique
                  key={index}
                  onClick={() => {
                    scrollTo(index);
                  }}
                  zIndex="100"
                  sx={{
                    bgcolor:
                      index === selectedIndex
                        ? "var(--mui-palette-background-default)"
                        : "var(--mui-palette-action-selected)",
                    borderRadius: "50%",
                    cursor: "pointer",
                    mx: "0.25rem",
                    width: {
                      xs: '10px',
                      sm: '10px',
                      md: '13px',
                      lg: '13px',
                    },
                    height: {
                      xs: '10px',
                      sm: '10px',
                      md: '13px',
                      lg: '13px',
                    },
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
export default Carouse;
