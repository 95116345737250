import { Card, Container, Grid, Typography } from "@mui/material";
function FaqMain() {
  return (
    <Grid item md={12} xs={12} pb={2}>
      <Container maxWidth="md" sx={{ mt: 7, mb: 6 }}>
        <Card sx={{mt: 2, p: "32px", border: "1px solid var(--mui-palette-secondary-300)", borderRadius: 1,}}>
          <Typography
            sx={{ fontWeight: 600, fontSize: "1.5rem", lineHeight: 1.2 }}
          >
            Q1.ログインができません。
          </Typography>
          <Typography mt={2}>
          ｢ログインができません。」とのお問い合わせをいただくことがございますが、原因は、「写真の販売期間がまだ開始されていない。」「写真の販売期間が終了している。」もしくは、ID・パスワードの入力間違いが主な原因です。
          </Typography>
          <Typography
            sx={{ fontWeight: 600, fontSize: "1.5rem", lineHeight: 1.2 }}
            mt={3}
          >
            Q2.注文が本当に完了したのか不安です。
          </Typography>
          <Typography mt={2}>
            ご注文が完了しましたら、ご入力いただいたメールアドレス宛に「注文確認メール」が届きます。
          </Typography>
          <Typography mt={1.7}>注文したにもかかわらず「注文確認メール」が届かない場合には、お手数ですが「お問い合わせフォーム」よりご連絡ください。</Typography>
          <Typography mt={1.7}>
          ※ドメイン指定や、迷惑メール対策を設定されている場合、メールが送信できませんのでご注意下さい。@stk-i.co.jpをドメイン指定し受信してください。
          </Typography>
          <Typography
            sx={{ fontWeight: 600, fontSize: "1.5rem", lineHeight: 1.2 }}
            mt={3}
          >
            Q3.注文してから何日ぐらいで届きますか？
          </Typography>
          <Typography mt={2}>※発送日に関しまして・・・８日までのご注文を１５日までに、１５日までのご注文を２２日までに、２３日までのご注
            文を末日までに、末日までのご注文を７日までに発送致します。</Typography>
          <Typography mt={1.7}>
            発送日から７日以上経ってもお手元に届かない場合には、お手数ですが「お問い合わせフォーム」よりご連絡ください。
          </Typography>
          <Typography mt={1.7}>
            お電話では対応しておりません。
          </Typography>
          <Typography mt={1.7}>
            土日祝は発送業務を行っておりませんので、上記日程と重なる場合、翌平日発送となります。
          </Typography>
          <Typography mt={1.7}>
            また弊社発送システム上、発送が2,3日遅れることが稀にございますので、ご了承下さい。
          </Typography>
          <Typography mt={1.7}>
            ※購入価格が20000円以上でコンビニ決済を選択されている場合、代引きにて送付させて頂きます。
          </Typography>
          <Typography
            sx={{ fontWeight: 600, fontSize: "1.5rem", lineHeight: 1.2 }}
            mt={3}
          >
            Q4.返品は可能ですか?
          </Typography>
          <Typography mt={1.7}>
            注文と異なる写真が届いた、写真の汚れなど、当社の過失による場合には、当社実費負担にてお取り替えさせていただきます。
          </Typography>
          <Typography mt={1.7}>
            その場合には、予め「お問い合わせフォーム」よりご連絡いただいた上、着払いにて当社宛に商品をご返送ください。
          </Typography>
          <Typography mt={1.7}>
            商品到着後、新しい商品をお送りさせていただきます。
          </Typography>
          <Typography mt={1.7}>
            なお、お客様のご都合による返品、注文変更（サイズ変更、枚数変更等）は承りかねますので、ご了承ください。
          </Typography>
          <Typography
            sx={{ fontWeight: 600, fontSize: "1.5rem", lineHeight: 1.2 }}
            mt={3}
          >
            Q5.料金を支払っていないのに商品が届いたのですが?
          </Typography>
          <Typography mt={1.7}>
            弊社はより早く、皆様のお写真をお手元に届けたいため、納品後のお支払いになります。（代引きも有）
          </Typography>
          <Typography mt={1.7}>
            お写真と共に同封させていただいている、払込み票でお近くのコンビニエンスストアで料金をお支払い下さい。
          </Typography>
          <Typography mt={1.7}>
            また、お支払いが確認できない場合は弊社からご連絡がいきますのでご了承下さい。
          </Typography>
          <Typography mt={1.7}>
            尚、料金お支払いの催促にも応じていただけない場合は、法的手段の方を取らせていただきます。
          </Typography>
          <Typography
            sx={{ fontWeight: 600, fontSize: "1.5rem", lineHeight: 1.2 }}
            mt={3}
          >
            Q6.ログインIDとPASSが分からないのですが?
          </Typography> 
          <Typography mt={1.7}>
            ログインIDとPASSの方が分からない場合は、お手数ですが、ツアー名、ツアー開催日、氏名を「お問い合わせフォーム」からご連絡下さい。
          </Typography>
          <Typography
            sx={{ fontWeight: 600, fontSize: "1.5rem", lineHeight: 1.2 }}
            mt={3}
          >
            Q7.発送方法と支払い方法、支払い期限は？
          </Typography> 
          <Typography mt={1.7}>
          弊社ではゆうメールを選択していただきますと、コンビニ払い。普通郵便を選択していただきますと代引きになります。また、
          フォトフレームご注文のお客様はゆうメールを選択されましても、ゆうメールでは大きさや重さの都合上配送不可能ですので、自動的に
          普通郵便代引きにて発送となります。その際は表示されております送料、手数料と実際の請求額が異なりますので何卒ご了承ください。
          </Typography>
          <Typography mt={1.7}>
            コンビニ払いを選択されましたお客様は、お写真に同封されています、コンビニ払い込み票をお近くのコンビニに持参していただき、レジにてお支払いとなります。
          </Typography>
          <Typography mt={1.7}>
            コンビニ払いの支払い期限の方は発送日から１０日後までとなっております。コンビニ払い込み票にも記載されておりますので、ご確認下さい。
          </Typography>
          <Typography mt={1.7}>
            代引きを選択されたお客様は、お写真をお届けする際に配達ドライバーに料金の方をお支払い下さい。
          </Typography>
          <Typography sx={{ fontWeight: 600, fontSize: "1.5rem", lineHeight: 1.2 }} mt={3}
          >Q8.掲載写真の削除は可能ですか？</Typography> 
          <Typography mt={1.7}>可能です。</Typography>
          <Typography mt={1.7}>ツアー名、削除して欲しい写真No.の方を「お問い合わせフォーム」からご連絡下さい。</Typography>
          <Typography sx={{ fontWeight: 600, fontSize: "1.5rem", lineHeight: 1.2 }} mt={3}>
            Q9.コンビニ払い込み票を紛失してしまったのですが？代引きを受け取ることができなかったのですが？
          </Typography>
          <Typography mt={1.7}>
            コンビニ払い込み票の再発行には、申し訳御座いませんが、再発行手数料３００円と送料１８０円の４８０円が掛かります。
          </Typography>
          <Typography mt={1.7}>紛失された場合は「お問い合わせフォーム」もしくはお電話にてご連絡下さい。</Typography>
          <Typography mt={1.7}>また、代引きの再発送の場合は再発送料４００円と代引き手数料４４０円を頂きます。</Typography>
          <Typography mt={1.7}>ご了承下さい。</Typography>
          <Typography mt={1.7}>尚、いかなる場合でもその他の支払方法は受け付けておりません。（銀行振込等）</Typography>
          <Typography sx={{ fontWeight: 600, fontSize: "1.5rem", lineHeight: 1.2 }} mt={3}>Q10.文字入れとは？</Typography>
          <Typography mt={1.7}>ツアー名、日付が記載されます。ツアーごとに自動で文字入れを行っておりますので注文後、
            文字入れに関する変更はできません。注文時、備考欄に必ず希望文字入れの内容を記載してください。</Typography>
          <Typography sx={{ fontWeight: 600, fontSize: "1.5rem", lineHeight: 1.2 }} mt={3}>
            Q11.以前見た写真が見れなくなっています。</Typography>
          <Typography mt={1.7}>
          ツアーごとに販売期間を設定しております。必ずチラシ記載の販売期間をご参照ください。
            尚、販売を終了したツアーはいかなる場合でも再販売を行いません。
            予めご了承下さい。</Typography>
        </Card>
      </Container>
    </Grid>
  );
}
export default FaqMain;
