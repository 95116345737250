import { useState } from "react";
import { LoginResponse } from "@/common/constant/type";
import { LOGIN_EVENT_PATH } from "@/common/constant/apiConstant";
import { RequestData } from "@/common/types/httpClient";
import HttpClientEvent from "@/common/api/HttpClientEvent";
import ToastMessage from "@/common/utils/ToastUtils";
const useLoginEvent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<LoginResponse | undefined>(undefined);
  const login = async ({
    username,
    password,
    succesCallback,
    errorCallback,
  }: {
    username: string;
    password: string;
    succesCallback?: (response: RequestData) => void;
    errorCallback?: () => void;
  }) => {
    setLoading(true);
    setError(null);
    try {
      const response = await HttpClientEvent.Post({
        path: LOGIN_EVENT_PATH,
        data: { username, password },
      });
      if (!response.data) {
        const errorMessage = response.data?.message || "Login failed";
        throw new Error(errorMessage);
      }
      setData(response.data);
      succesCallback?.(response.data);
    } catch (err: unknown) {
      ToastMessage.showError("ログインIDまたは、パスワードが違います");
      setError(
        err instanceof Error ? err.message : "An unexpected error occurred"
      );
      errorCallback?.();
    } finally {
      setLoading(false);
    }
  };
  return { login, loading, error, data };
};
export default useLoginEvent;
