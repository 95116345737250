import { BrowserRouter as Router } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "./App.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "sonner";
import { ErrorBoundary } from "react-error-boundary";
import AppErrorFallback from "@/common/components/ErrorFallback/AppErrorFallback";
import {ImagesContext} from "@/common/context/ImagesContext";
import {CartContext} from "@/common/context/CartContext";
import AppRoutes from "./routes/index";
import { AuthProvider } from "./common/context/AuthContext";
const queryClient = new QueryClient();
/**
 * Main App component
 * @class
 */
function App() {
  return (
    <ErrorBoundary FallbackComponent={AppErrorFallback}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ImagesContext>
              <CartContext>
                <>
                  <AppRoutes />
                  <Toaster richColors />
                </>
              </CartContext>
            </ImagesContext>
          </AuthProvider>
        </QueryClientProvider>
      </Router>
    </ErrorBoundary>
  );
}
export default App;
