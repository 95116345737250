import { convertToDateFormat } from "@/common/utils/Date";
import { TypeResponseTimeResaleEvent } from "@/features/member/auth/MyPage/types";
import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
type TypeInfoTimeReSaleCardProps = {
  data?: TypeResponseTimeResaleEvent;
};
function InfoTimeReSaleCard(props: TypeInfoTimeReSaleCardProps) {
  const { data } = props;
  const theme = useTheme();
  return (
    <Card style={{ borderRadius: "7px", minHeight: "140px" }}>
      <CardContent style={{padding:"15px 20px"}}>
        <Typography
          variant="h6"
          fontWeight="600"
          color="var(--mui-palette-primary-main)"
          marginBottom="15px"
        >
          再販期間
        </Typography>
        <Stack>
          <Box display="flex">
            <Typography fontSize="12px" width="70px">
              販売開始
            </Typography>
            <Typography>
              {convertToDateFormat(data?.resale_start_date)}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography fontSize="12px" width="70px">
              販売終了
            </Typography>
            <Typography>
              {convertToDateFormat(data?.resale_end_date)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography fontSize="12px" width="70px">
              補足
            </Typography>
            <Box>
              <Typography
                fontSize={18}
                sx={{ color: theme.palette.primary.dark }}
                component="span"
                fontWeight="600"
              >
                {data?.resale_increase_percentage
                  ? `${data?.resale_increase_percentage}%`
                  : "0%"}
              </Typography>
              <Typography marginLeft={1} component="span" fontSize="14px">
                増しにて販売
              </Typography>
            </Box>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}
export default InfoTimeReSaleCard;
