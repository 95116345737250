import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {useContext, useEffect, useMemo, useState} from "react";
import SidebarMenuFolder from "@/common/components/SidebarMenuFolder";
// import {paths} from "@/common/constant/paths";
import {isNil} from "lodash";
// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
import ButtonChangePageView from "@/common/components/Button/ButtonChangePageView";
import IconZoomOut from "@/assets/icon/IconZoomOut";
import PreviewImage from "@/common/components/ImageCard/PreviewImage";
import imagesContext from "@/common/context/ImagesContext";
import AuthContext from "@/common/context/AuthContext";
import cartContext, {ICartItem} from "@/common/context/CartContext";
import {getOptionViewMode} from "@/features/guest/pages/FolderPhotoDetail/components/utils";
import IconZoom from "@/assets/icon/IconZoom";
import useRemoveItemCart from "@/features/guest/pages/FolderPhotoDetail/apis/useRemoveItemCart";
import IconRefresh from "@/assets/icon/IconRefresh";
import useGetListCart from "@/features/guest/api/useGetListCart";
import useGetPhotoDetails from "./apis/useGetPhotoDetails";
import ImageCard from "./components/ImageCard";
import useAddItemCart from "./apis/useAddItemCart";
import useGetListFolder from "../top/apis/useGetListFolder";
import {ListDataFolder} from "../top/types";
export interface ImageItem {
  id: number;
  url: string;
  title: string;
  quantity?: number;
}
// eslint-disable-next-line max-lines-per-function
export default function FolderPhotoDetail() {
  const {
    favoriteImages,
    hiddenImages,
    dataFavoriteImages,
    handleDeletePhotoFavorite,
    dataHiddenImages,
    handleAddPhotoFavorite,
    initFavoriteImages,
    handleAddPhotoHidden,
  } = useContext(imagesContext);
  const {data: listFolder} = useGetListFolder({enabled: true});
  const {getGetListPricing} = useGetListCart()
  const {cartItems, setCartItems, setTotalCartItems, totalCartItems} = useContext(cartContext);
  const {isSignedIn: isLoginUser} = useContext(AuthContext);
  const {initHiddenImages} = useContext(imagesContext);
  const {folderId} = useParams<{ folderId: string }>();
  const {addItemCart} = useAddItemCart();
  const {removeItemCartByPhotoId} = useRemoveItemCart();
  const [viewMode, setViewMode] = useState<"small" | "large">("large");
  const [listFavoriteDB, setListFavoriteDB] = useState<number[]>([]);
  const [listCartDB, setListCartDB] = useState<number[]>([]);
  const [urlFilterFace, setUrlFilterFace] = useState<string | null>("");
  const {data: dataList, totalPage, currentPage, reFetch, handleChangePagination} = useGetPhotoDetails({
    folderId,
    hiddenId: hiddenImages.join(", ")
  });
  useEffect(() => {
    const listFavorite = dataList ?
      dataList.filter(item => item.user_photo_info?.is_in_favourite).map(item => item.id) : [];
    setListFavoriteDB(listFavorite);
    const listCart = dataList ?
      dataList.filter(item => item.user_photo_info?.is_in_cart).map(item => item.id) : [];
    setListCartDB(listCart);
  }, [dataList]);
  const [previewImageIndex, setPreviewImageIndex] = useState<
    number | undefined
  >(undefined);
  const isFavorite = (id: number) => {
    if (isLoginUser) {
      return listFavoriteDB.includes(id);
    }
    return favoriteImages.includes(id) ||
      !!dataFavoriteImages?.find((photo) => photo.id === id);
  }
  const isHidden = (id: number) => {
    if(isLoginUser) {
      return !!dataHiddenImages?.find((photo) => photo.id === id)
    } 
    return hiddenImages.includes(id)
  }
  const isInCart = (id: number | string)=>{
    if (isLoginUser) {
      return listCartDB.includes(Number(id));
    }
    return !!cartItems?.find((photo) => photo.id === id);
  }
  const handleAddItemCart = async (item: ICartItem | ImageItem) => {
    const photoId = item.id
    const listPricing = await getGetListPricing([photoId])
    const itemCart: ICartItem = {
      id: photoId,
      quantity: 1,
      pricing_id: listPricing?.data?.[0]?.pricings?.[0]?.pricing_id,
    };
    if (isLoginUser) {
      if(isInCart(photoId)){
        await removeItemCartByPhotoId(Number(photoId));
        setTotalCartItems(totalCartItems - 1);
        await reFetch();
      } else {
        const itemsToSend = [
          {
            photo_id: photoId,
            quantity: 1,
          },
        ];
        await addItemCart({items: itemsToSend});
        setTotalCartItems(totalCartItems + 1);
        await reFetch();
      }
    } else {
      const existingItem = cartItems.find((cartItem) => cartItem.id === photoId);
      const updatedCartItems = existingItem
        ? cartItems.filter((cartItem) => cartItem.id !== photoId)
        : [...cartItems, itemCart];
      setCartItems(updatedCartItems);
    }
  };
  const handleClickHidden = async (photoId: string) => {
    const hiddenImageFind = [...hiddenImages, photoId]
    await handleAddPhotoHidden(photoId)
    reFetch()
    await initHiddenImages(hiddenImageFind);
    if(!isLoginUser) {
      await handleDeletePhotoFavorite(photoId)
    } else {
      initFavoriteImages()
    }
  };
  const handleClickFavorite = async (photoId: number) => {
    if (!isFavorite(photoId)) {
      await handleAddPhotoFavorite(photoId);
      setListFavoriteDB(prev => [...prev, photoId]);
    }
  };
  const folderInfo = listFolder?.find(
    (item: ListDataFolder) => item.id.toString() === folderId
  );
  const optionViewMode = getOptionViewMode(viewMode);
  const listImageViewable = useMemo(() =>
    dataList?.filter((image) => !isHidden(image.id)) || [], [hiddenImages, dataList, dataHiddenImages]);
  const handleFilterFace = (url: string) => {
    setUrlFilterFace(url);
    setPreviewImageIndex(undefined);
  };
  const handleRefreshFilterFace = () => {
    setUrlFilterFace(null);
  };
  return (
    <Stack>
      {/* <Breadcumbs
        breadcrumbs={[
          {title: "トップ", href: paths.folder.list},
          {title: folderInfo?.name ?? ""},
        ]}
      /> */}
      <Grid container columnSpacing={10} marginTop={2}>
        <Grid item xs={12} md={9}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography textAlign="center" variant="h5">
              {folderInfo?.name}
            </Typography>
            <Button
              variant="outlined"
              sx={{
                height: "30px",
                width: "30px",
                padding: 0,
                right: 0,
              }}
              onClick={() =>
                setViewMode((prev) => (prev === "small" ? "large" : "small"))
              }
            >
              {viewMode === "small" ? <IconZoom /> : <IconZoomOut />}
            </Button>
          </Box>
          {/* filter face  */}
          {urlFilterFace && (
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                background: "#FFFFFF",
                border: "1px solid #EBEBEB",
                borderRadius: "4px",
                padding: "10px",
                alignItems: "center",
              }}
              marginTop={3}
            >
              <Stack direction="row" alignItems="center" gap={3}>
                <Box width={50} height={50}>
                  <img src={urlFilterFace} alt="" width="100%" height="100%" />
                </Box>
                <Typography textAlign="center">顔で検索 1件の結果</Typography>
              </Stack>
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    padding: "3px 25px",
                    right: 0,
                  }}
                  onClick={handleRefreshFilterFace}
                  startIcon={<IconRefresh />}
                >
                  画像の全リストに戻る
                </Button>
              </Box>
            </Stack>
          )}
        </Grid>
      </Grid>
      <Grid container columnSpacing={10} rowSpacing={2} marginTop={3}>
        <Grid item xs={12} md={9} position="relative" paddingTop="0 !important">
          <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 4 }}>
            {listImageViewable &&
              listImageViewable?.map((image, index) => (
                <Grid
                  item
                  key={image.id}
                  {...optionViewMode}
                >
                  <ImageCard
                    isShowFavorite
                    isInCart={isInCart(image.id)}
                    isShowHide
                    onClickHidden={handleClickHidden}
                    onClickFavorite={handleClickFavorite}
                    onClickCart={handleAddItemCart}
                    image={image}
                    onClickImage={() => setPreviewImageIndex(index)}
                    isFavorite={isFavorite(image.id)}
                  />
                </Grid>
              ))}
          </Grid>
          <Box
            marginTop={4}
            marginBottom={2}
            display="flex"
            justifyContent="center"
          >
            <ButtonChangePageView
              totalPage={totalPage}
              handlePageChange={(e, newPage) => {
                handleChangePagination(newPage);
              }}
              currentPage={currentPage}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <SidebarMenuFolder folders={listFolder} />
        </Grid>
      </Grid>
      {!isNil(previewImageIndex) && (
        <PreviewImage
          isInCart={isInCart(listImageViewable[previewImageIndex].id)}
          onClickCart={handleAddItemCart}
          currentItem={listImageViewable[previewImageIndex]}
          open={!isNil(previewImageIndex)}
          onClose={() => setPreviewImageIndex(undefined)}
          imageUrl={listImageViewable[previewImageIndex]?.presigned_url[0]}
          imageTitle={listImageViewable[previewImageIndex].photo_original_file}
          handleFilterFace={handleFilterFace}
        />
      )}
    </Stack>
  );
}
