import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
type Props = {
  onClickDetail: () => void;
};
function ListDataHistory({ onClickDetail }: Props) {
  return (
    <Card sx={{ borderRadius: 1, p: 8 }}>
      <Grid container>
        <Grid item xs={8} sx={{ mb: 1 }}>
          <Box display="flex" gap={2}>
            <Grid container>
              <Grid item xs={4}>
                <Typography
                  sx={{ fontWeight: 600, fontSize: 14, color: "#757575" }}
                >
                  注文日
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: 14 }}>2024/7/18</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" gap={2} mt={1}>
            <Grid container>
              <Grid item xs={4}>
                <Typography
                  sx={{ fontWeight: 600, fontSize: 14, color: "#757575" }}
                >
                  注文枚数
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: 14 }}>30枚</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" gap={2} mt={1}>
            <Grid container>
              <Grid item xs={4}>
                <Typography
                  sx={{ fontWeight: 600, fontSize: 14, color: "#757575" }}
                >
                  注文金額合計
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: 14 }}>4,500円</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Stack direction="row" justifyContent="end">
            <Button
              sx={{ width: 98 }}
              variant="contained"
              onClick={() => onClickDetail()}
            >
              詳細
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: "#757575" }} />
    </Card>
  );
}
export default ListDataHistory;
