import { Box, Stack } from "@mui/material";
import type { Control, FieldErrors } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import NameFields from "./NameFields";
import AddressFields from "./AddressFields";
import ContactFields from "./ContactFields";
import type { DataMemberAccount, MemberAccount } from "../api/useEditMyAccount";
type TypeProps = {
  data?: DataMemberAccount | undefined;
  errors: FieldErrors;
  control: Control<MemberAccount>;
  onCancel?: () => void;
  onSubmit: () => void;
};
function FormEditMyAccount(props: TypeProps) {
  const { errors, control, data, onCancel, onSubmit } = props;
  return (
    <Box py={4} px={3} sx={{ maxHeight: '90vh', overflowY: 'auto' }}>
      <Stack spacing={2}>
        <NameFields control={control} errors={errors} />
        <AddressFields
          control={control}
          errors={errors}
        />
        <ContactFields control={control} errors={errors} data={data} />
      </Stack>
      <Box display="flex" justifyContent="center" gap={2} mt={6} px={1}>
        <LoadingButton
          sx={{ width: "50%" }}
          variant="outlined"
          onClick={() => onCancel?.()}
        >
          キャンセル
        </LoadingButton>
        <LoadingButton
          sx={{ width: "50%" }}
          variant="contained"
          type="submit"
          onClick={onSubmit}
        >
          登録
        </LoadingButton>
      </Box>
    </Box>
  );
}
export default FormEditMyAccount;
