import {useState} from "react";
function useLocalStorage<T> (key: string, defaultValue: T): [T, (valueOrFn: T | ((prevValue: T) => T)) => void]{
  // Create state variable to store
  // localStorage value in state
  const [localStorageValue, setLocalStorageValue] = useState<T>(() => {
    try {
      const value = localStorage.getItem(key)
      // If value is already present in
      // localStorage then return it
      // Else set default value in
      // localStorage and then return it
      if (value) {
        return JSON.parse(value) as T;
      }
      localStorage.setItem(key, JSON.stringify(defaultValue));
      return defaultValue
    } catch (error) {
      localStorage.setItem(key, JSON.stringify(defaultValue));
      return defaultValue
    }
  })
  // this method update our localStorage and our state
  const setLocalStorageStateValue = (valueOrFn: T | ((prevValue: T) => T)) => {
    const newValue = typeof valueOrFn === 'function'
      ? (valueOrFn as (prevValue: T) => T)(localStorageValue)
      : valueOrFn;
    localStorage.setItem(key, JSON.stringify(newValue));
    setLocalStorageValue(newValue);
  };
  return [localStorageValue, setLocalStorageStateValue]
}
export default useLocalStorage;
