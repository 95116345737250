import { Box, Typography } from "@mui/material";
import React, { ReactElement } from "react";
type TypeIconTitle = {
  icon: ReactElement | string;
  title?: string;
  onClick?: () => void;
  fontSize?: string;
  color?: string;
};
export default function IconTitle(props: TypeIconTitle) {
  const { icon, title, onClick, ...rest } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      onClick={onClick}
      sx={{ cursor: "pointer" }}
      height="45px"
    >
      {icon}
      <Typography
        sx={{
          color: "#72513D",
          fontSize: "14px",
          fontWeight: "700",
          paddingTop: "5px",
          ...rest
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}
