import { OutlinedInputProps } from "@mui/material";
import { ReactElement } from "react";
import {
  Controller,
  useFormContext,
  FieldValues,
  FieldErrors,
} from "react-hook-form";
import { Property } from "csstype";
import Input from "./Input.tsx";
export type TProps = {
  name: string;
  defaultValue?: string;
  direction?: Property.FlexDirection;
} & OutlinedInputProps;
interface FormContextType extends FieldValues {
  [key: string]: unknown;
}
/**
 * TextFieldForm
 * @param {TProps} props .
 * @returns {ReactElement} .
 */
function TextFieldForm(props: TProps): ReactElement {
  const { name, defaultValue, ...others } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext<FormContextType>();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue ?? ""}
      render={({ field }) => (
        <Input
          name={name}
          field={field}
          errors={errors as FieldErrors<FormContextType>}
          {...others}
        />
      )}
    />
  );
}
export default TextFieldForm;
