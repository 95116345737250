import {
  Box,
  CardContent,
  CardHeader,
  Stack,
  Link as MuiLink,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, useForm } from "react-hook-form";
import { LoginType } from "@/common/constant/type";
import ToastMessage from "@/common/utils/ToastUtils";
import { X as XIcon } from "@phosphor-icons/react/dist/ssr/X";
import { Dispatch, useContext, useState } from "react";
import SignInStateEnum from "@/features/guest/auth/login/type";
import { yupResolver } from "@hookform/resolvers/yup";
import { memberMyAccountSchema, myAccountSchema, signinSchema } from "@/common/utils/schema";
import OutlineInputField from "@/common/components/TextField/OutlineInputField";
import PasswordInput from "@/common/components/TextField/PasswordInput";
import CustomModal from "@/common/components/Modal/CustomModal";
import FormEditMyAccount from "@/features/member/auth/MyAccount/components/FormEditMyAccount";
import useGetMyMemberAccount from "@/features/member/auth/MyAccount/api/useGetMyMemberAccount";
import useCreateMyAccount from "@/features/member/auth/MyAccount/api/useRegisterAccount";
import { type MemberAccount } from "@/features/member/auth/MyAccount/api/useEditMyAccount";
import { getEmailWithHost } from "@/common/utils/helper";
import authContext from "@/common/context/AuthContext";
import { executeConfirmAccount } from "@/features/member/auth/api/executeConfirmAccount";
import {MESSAGE_ERROR_COMMON} from "@/common/utils/message.ts";
import ConfirmSignupForm from "./ConfirmSignupForm";
type FormMemberLoginProps = {
  onCancel: () => void;
  onSubmit: (values: LoginType) => Promise<void>;
  setState: Dispatch<SignInStateEnum>;
  state: SignInStateEnum;
  loading?: boolean;
};
function SigninForm({
  onCancel,
  onSubmit,
  setState,
  loading,
}: FormMemberLoginProps) {
  const { login } = useContext(authContext);
  const { data , getList } = useGetMyMemberAccount();
  const { registerAccount } = useCreateMyAccount();
  const methods = useForm<LoginType>({
    resolver: yupResolver(signinSchema),
    defaultValues: {
      email: "",
      passcode: "",
    },
  });
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [hasErrorConfirm, setHasErrorConfirm] = useState<boolean>(false);
  const [isWaitingConfirmUser, setIsWaitingConfirmUser] = useState(false);
  const methodsMyAccount = useForm({
    resolver: yupResolver(data ? myAccountSchema : memberMyAccountSchema),
    defaultValues: {
      email: "",
      name: "",
      furigana_name: "",
      post_code: "",
      prefecture_code: 13,
      address: "",
      street_address: "",
      building_address: "",
      phone_number: "",
      password: "",
      confirmpassword: "",
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methodsMyAccount;
  const onSubmitRegister = async (formData: Partial<MemberAccount>) => {
    try {
      if (!data) {
        const responseRegister = await registerAccount(formData);
        if (responseRegister) {
          ToastMessage.showSuccess("新規登録しました。");
          setIsWaitingConfirmUser(true);
        }
      }
    } catch (error) {
      ToastMessage.showError(
        MESSAGE_ERROR_COMMON
      );
    }
  };
  const handleSubmitConfirm = async (values: { code: string }) => {
    const formValues = methodsMyAccount.getValues();
    const response = await executeConfirmAccount({ email: formValues.email, code: values.code });
    if(response){
      await login({
        email: getEmailWithHost(formValues.email),
        passcode: formValues.password,
      });
      await getList();
      setIsWaitingConfirmUser(false);
    } else {
      setHasErrorConfirm(true);
    }
  };
  if (isWaitingConfirmUser)
    return <ConfirmSignupForm
      username={methodsMyAccount?.getValues()?.email}
      onSubmit={handleSubmitConfirm}
      hasError={hasErrorConfirm}
    />;
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Box
            className="btn-cancel"
            onClick={onCancel}
            width="100%"
            textAlign="right"
            sx={{ cursor: "pointer" }}
          >
            <XIcon fontSize="24px" />
          </Box>
          <CardHeader data-testid="login-title" title={<h3>ログイン</h3>} />
          <CardContent>
            <Stack spacing={2}>
              <>
                <OutlineInputField
                  label="メールアドレス"
                  inputProps={{ placeholder: "メールアドレス" }}
                  name="email"
                />
                <PasswordInput
                  label="パスワード"
                  placeholder="パスワード"
                  name="passcode"
                />
              </>
              <Button
                onClick={() => {
                  setIsOpenModal(true);
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
              新規登録
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit"
                data-testid="login-button"
              >
              ログイン
              </LoadingButton>
              <MuiLink
                component="button"
                onClick={() => setState(SignInStateEnum.forgot)}
                sx={{
                  textAlign: "center",
                }}
              >
              パスワード再設定
              </MuiLink>
            </Stack>
          </CardContent>
        </form>
      </FormProvider>
      <FormProvider {...methodsMyAccount}>
        <CustomModal
          open={isOpenModal}
          handleClose={() => setIsOpenModal(false)}
          content={
            <FormEditMyAccount errors={errors} control={control} data={data} 
              onCancel={() => setIsOpenModal(false)} onSubmit={handleSubmit(onSubmitRegister)}/>
          }
          sx={{ width: '95vw' , maxWidth: 742 }}
          data-testid="custom-modal"
        />
      </FormProvider>
    </>
  );
}
export default SigninForm;
