import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Stack,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordSchema } from "@/common/utils/schema";
import TextFieldForm from "@/common/components/TextField/TextFieldForm";
import { useSubmit } from "@/features/guest/auth/hookCustom/useSubmit";
interface PasswordResetFormProps {
  // token: string;
  onSuccess: () => void;
}
export default function PasswordResetForm({
  onSuccess,
}: PasswordResetFormProps) {
  const methods = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });
  const onSubmit = useSubmit({ onSuccess });
  return (
    <>
      <FormProvider {...methods}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: "70vh",
            p: { xs: 2, md: 3 },
          }}
          component="form"
          noValidate
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Box sx={{ maxWidth: "560px", width: "100%" }}>
            <Stack spacing={4}>
              <Card>
                <CardHeader title="パスワードの再設定" />
                <CardContent>
                  <Stack mt={-2} mb={2}>
                    <Typography>
                      {" "}
                      パスワード再設定用のメールを送信します。ご登録時のメールアドレスを入力し「コードを送信する」をクリックしてください。
                    </Typography>
                  </Stack>
                  <Stack spacing={2}>
                    <FormControl>
                      <TextFieldForm
                        fullWidth
                        name="email"
                        label="メールアドレス"
                        required
                        size="small"
                      />
                    </FormControl>
                    <Button type="submit" variant="contained">
                      コードを送信
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          </Box>
        </Box>
      </FormProvider>
    </>
  );
}
