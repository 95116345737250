import { Stack } from "@mui/material";
import type { DataMemberAccount } from "../api/useEditMyAccount";
import ViewMyAccount from "./ViewMyAccount";
type TypeProps = {
  data?: DataMemberAccount | undefined;
};
function FormMyAccount(props: TypeProps) {
  const {  data } = props;
  return (
    <Stack spacing={3}>
      <ViewMyAccount data={data} />
    </Stack>
  );
}
export default FormMyAccount;
