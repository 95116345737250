import { Box, Grid, Popover, Stack, Typography } from "@mui/material";
import SidebarMenuFolder from "@/common/components/SidebarMenuFolder";
import React, { useEffect, useState } from "react";
// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
import { useNavigate } from "react-router-dom";
import ButtonChangePageView from "@/common/components/Button/ButtonChangePageView";
// import { House } from "@phosphor-icons/react";
import FolderCard from "./components/FolderCard";
import useGetListFolder from "./apis/useGetListFolder";
export default function TopIndexPage() {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 12 });
  const { data, dataEvent, totalPage, getListFolder, currentPage } = useGetListFolder();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const fetchData = async () => {
    const params = {
      page: pagination.page,
      per_page: pagination.rowsPerPage,
    };
    try {
      await getListFolder(params);
    } catch (err) {
      console.log("err: ", err);
      // setError(true);
    }
  };
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };
  // const handleRowsPerPageChange = (
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   setPagination({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  // };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    fetchData()
  }, [pagination.page, pagination.rowsPerPage]);
  return (
    <Stack>
      {/* <Breadcumbs
        breadcrumbs={[
          {
            title: "トップ",
            icon: (
              <>
                <House />
              </>
            ),
          },
        ]}
      /> */}
      {/* <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" flexDirection="row-reverse" marginTop={2}>
          <Button
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            マイページ
          </Button>
        </Box>
        <Typography align="right">
          アカウント情報を登録すると、購入時の項目入力ステップが省略できます
        </Typography>
      </Box> */}
      <Grid container columnSpacing={10} rowSpacing={2} marginTop={2}>
        <Grid item xs={12} md={9}>
          <Grid item xs={12} md={9} paddingLeft="10px !important" paddingRight="8px">
            <Typography fontWeight={700} fontSize={20} textAlign="left">
              {dataEvent?.name}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "left" }}>
              <Typography
                mt={1}
                width="100%"
                fontWeight={500}
                fontSize={18}
                textAlign="left"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                {dataEvent?.top_page_comment}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <></>
          </Grid>
          {data && data?.length > 0 && (
            <Box>
              <Box sx={{ overflowX: "auto", paddingBottom: "10px" }}>
                <Grid container spacing={4} p={1}>
                  {data?.map((list) => (
                    <Grid item key={list.id} md={4} lg={4} sm={6} xs={12}>
                      <FolderCard folder={list} />
                    </Grid>
                  ))}
                </Grid>
                <ButtonChangePageView
                  totalPage={totalPage}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <SidebarMenuFolder folders={data} />
        </Grid>
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{ p: 2, cursor: "pointer" }}
          onClick={() => navigate("/member/mypage")}
        >
          マイページ
        </Typography>
        {/* <Typography
          sx={{ p: 2, cursor: "pointer" }}
          onClick={() => navigate("/member/password/email")}
        >
          パスワード再設定
        </Typography> */}
      </Popover>
    </Stack>
  );
}
