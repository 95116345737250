export const dataOrders = [
  {
    id: "POST-004",
    title: "Building a Design ",
    url: "https://mameta-photo.com/dt/photos/1/1562/04adea/2656136/600.jpg",
    event: "238746 928736492873",
    subEvent: "287364 92873469234",
    amount: 4500,
    quantity: 3,
    date: "2024-16-08",
  },
  {
    id: "POST-003",
    title: "Passive Income ",
    url: "https://mameta-photo.com/dt/photos/1/1562/04adea/2656135/600.jpg",
    event: "238746 928736492873",
    subEvent: "287364 92873469234",
    amount: 4500,
    quantity: 3,
    date: "2024-16-08",
  },
  {
    id: "POST-002",
    title: "Five Ways to Improve ",
    url: "https://mameta-photo.com/dt/photos/1/1562/04adea/2656134/600.jpg",
    event: "238746 928736492873",
    subEvent: "287364 92873469234",
    amount: 4500,
    quantity: 3,
    date: "2024-16-08",
  },
  {
    id: "POST-001",
    title: "How to Create",
    url: "https://picsum.photos/seed/picsum/200/300",
    event: "238746 928736492873",
    subEvent: "287364 92873469234",
    amount: 4500,
    quantity: 3,
    date: "2024-16-08",
  },
];
