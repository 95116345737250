import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {Card, Typography,} from "@mui/material";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
import useGetPaymentMethod from "@/features/guest/pages/payment/api/useGetPaymentMethod";
import {orderStorage} from "@/common/utils/storage/orderStorage";
import {RadioMethodPayment} from "./components";
/**
 *
 */
function Payment() {
  const navigate = useNavigate();
  const { data: dataPaymentMethod } = useGetPaymentMethod();
  const { paymentMethodId } = orderStorage.getOrderForm();
  const [method, setMethod] = useState<number | undefined>(paymentMethodId);
  /**
   *
   * @param event
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMethod(Number(event.target.value));
  };
  /**
   *
   */
  const handleNext = () => {
    orderStorage.saveOrderForm({
      paymentMethodId: method,
      useStripe: dataPaymentMethod?.find((item) => item.id === method)?.use_stripe || false,
      paymentMethod:
        dataPaymentMethod?.find((item) => item.id === method)?.name || "",
      paymentFee:
        dataPaymentMethod?.find((item) => item.id === method)?.fee || 0,
    });
    navigate("/guest/carts/delivery");
  };
  return (
    <>
      {/* <Breadcumbs
        breadcrumbs={[
          { title: "トップ" },
          { title: "カート" },
          { title: "お届け先情報" },
          { title: "お支払い選択" },
        ]}
      /> */}
      <Box  sx={{maxWidth: 606, mx: "auto"}} marginTop={2}>
        <Typography variant="h5" textAlign="left" fontWeight="600">
          お支払い方法の選択
        </Typography>
        <Card
          sx={{
            alignItems: "center",
            my: 5,
            paddingY: {
              xs: '30px',
              sm: '40px'
            },
            paddingX : {
              xs: '28px',
              sm: '50px'
            },
            boxShadow: {
              xs: "unset !important",
              sm: "0px 0px 9px 0px #00000024 !important"
            },
            borderRadius: '5px'
          }}
        >
          <Box>
            <Typography
              sx={{ mb: 4 }}
              variant="subtitle1"
              textAlign="left"
              fontWeight="600"
            >
              お支払い方法を下記より選択してください。
            </Typography>
          </Box>
          <Box sx={{marginBottom: {
            xs: '44px',
            sm: '30px '}
          }}>
            <RadioMethodPayment
              listMethods={dataPaymentMethod || []}
              methodSelected={method}
              onChange={handleChange}
            />
          </Box>
          <Grid
            container
            marginLeft="0"
            width="100%"
            sx={{display: 'flex', flexDirection: {
              xs: 'column-reverse',
              sm: 'row'
            }, alignItems: 'center', gap: '19px', flexWrap: 'noWrap'}}
          >
            <Button
              color="secondary"
              onClick={() => navigate("/guest/orders/order-info")}
              variant="outlined"
              fullWidth
            >
              お届け情報へ戻る
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={handleNext}
              disabled={method === undefined}
            >
              配送方法の選択へ
            </Button>
          </Grid>
        </Card>
      </Box>
    </>
  );
}
export default Payment;
