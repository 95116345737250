import HttpClient from "@/common/api/HttpClient";
import { USER_VALIDATE_LOGIN_PATH } from "@/common/constant/apiConstant";
import { ValidateAccountLoginType } from "@/common/constant/type";
export const executeValidateAccount = async ({
  email,
  password,
}: ValidateAccountLoginType) => {
  const res = await HttpClient.Post({
    path: USER_VALIDATE_LOGIN_PATH,
    data: {
      email,
      password,
    },
  });
  return res;
};
