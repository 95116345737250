import { useState } from "react";
import HttpClientEvent from "@/common/api/HttpClientEvent";
import { PAYMENT_METHOD, GET_DELIVERY_METHOD_PATH } from "@/common/constant/apiConstant";
import { ShippingMethod } from "../types";
import {PaymentMethod} from "../../payment/types";
/**
 *
 */
const useGetPaymentAndDeliveryMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  /**
   *
   */
  const getPaymentMethods = async () => {
    setLoading(true);
    try {
      const response = await HttpClientEvent.Get({
        path: PAYMENT_METHOD,
      });
      setPaymentMethods(response.data.data);
      return response.data.data;
    } catch (error) {
      setError((error as Error).message);
      throw error;
    } finally {
      setLoading(false);
    }
  };
    /**
     *
     */
  const getShippingMethods = async () => {
    setLoading(true);
    try {
      const response = await HttpClientEvent.Get({
        path: GET_DELIVERY_METHOD_PATH,
      });
      setShippingMethods(response.data.data);
      return response.data.data;
    } catch (error) {
      setError((error as Error).message);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  return {
    paymentMethods,
    shippingMethods,
    loading,
    error,
    getPaymentMethods,
    getShippingMethods
  };
};
export default useGetPaymentAndDeliveryMethods;
