import { Box, Card } from "@mui/material";
import { Order } from "@/common/types/order";
import { dataOrders } from "@/features/guest/mock/orders.data";
import { ColumnDef, DataTable } from "@/common/components/Table/data-table";
import React from "react";
import { formatMoney } from "@/common/utils/formatMoney";
const columns = [
  {
    formatter: (row): React.JSX.Element => (
      <Box
        sx={{
          alignItems: "center",
          bgcolor: "var(--mui-palette-background-level2)",
          backgroundImage: `url(${row.url})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderRadius: 1,
          display: "flex",
          height: "80px",
          justifyContent: "center",
          overflow: "hidden",
          width: "80px",
        }}
      />
    ),
    name: "",
  },
  { field: "title", name: "イベント名", align: "left" },
  { field: "event", name: "注文日", align: "left" },
  { field: "subEvent", name: "詳細", align: "left" },
  { field: "quantity", name: "合計枚数", align: "right" },
  {
    field: "amount",
    name: "合計金額",
    align: "right",
    formatter: (row) => `${formatMoney(row.amount ?? 0)  }円`,
  },
] satisfies ColumnDef<Order>[];
function ListOrderHistory() {
  return <Card>
    <DataTable<Order> columns={columns} rows={dataOrders} />
  </Card>
}
export default ListOrderHistory;
