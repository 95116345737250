// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
// import { Typography } from "@mui/material";
import React, { useEffect } from "react";
// import { paths } from "@/common/constant/paths";
import useGetPriceList from "./apis/useGetPriceList";
import PricingItem from "./components/PricingItem";
function PriceListPage() {
  const { data: dataList, getGetListPricing } = useGetPriceList();
  const fetchApi = async () => {
    try {
      await getGetListPricing();
    } catch (err) {
      console.log("err: ", err);
      // setError(true);
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);
  return (
    <>
      {/* <Breadcumbs breadcrumbs={[{ title: "トップ", href: paths.folder.list }, { title: "料金表" }]} />
      <Typography mt={4} variant="h4" textAlign="left">
        料金表
      </Typography> */}
      {dataList?.map((item) => (
        <PricingItem item={item} />
      ))}
    </>
  );
}
export default PriceListPage;
