import { useNavigate } from "react-router-dom";
import { LoginType } from "@/common/constant/type";
import { storage } from "@/common/utils/storage";
import SigninForm from "./components/SigninForm";
import useLoginEvent from "../hookCustom/useLoginEvent";
function Login() {
  const navigate = useNavigate();
  const { login } = useLoginEvent();
  const onSignin = async (values: LoginType) => {
    login({
      username: values.email,
      password: values.passcode,
      succesCallback: ({
        access_token: accessToken,
        refresh_token: refreshToken,
      }) => {
        storage.setEventToken(accessToken as string);
        storage.setEventRefreshToken(refreshToken as string);
        navigate("/guest/folders");
      },
    });
  };
  return <SigninForm onSuccess={onSignin} />;
}
export default Login;
