import HttpClient from "@/common/api/HttpClient";
import { USER_CONFIRM_PATH } from "@/common/constant/apiConstant";
import { ConfirmAccountType } from "@/common/constant/type";
export const executeConfirmAccount = async ({
  email,
  code,
}: ConfirmAccountType) => {
  try {
    const res = await HttpClient.Post({
      path: USER_CONFIRM_PATH,
      data: {
        email,
        confirmation_code: code,
      },
    });
    return res;
  } catch (error) {
    return null;
  }
};
