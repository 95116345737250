import TextFieldForm from "@/common/components/TextField/TextFieldForm";
import {Box, FormControl, InputLabel} from "@mui/material";
import {BoxItemFormOrder} from "@/features/guest/pages/order/components/BoxItemFormOrder.tsx";
import {styleLabelItemForm} from "@/features/guest/pages/order/helper.ts";
import {RequiredMark} from "./RequiredMark";
/**
 *
 */
export function ContactFields() {
  return (
    <>
      <BoxItemFormOrder title="その他">
        <Box width="100%">
          <InputLabel
            sx={styleLabelItemForm}>
            電話番号
            <RequiredMark />
          </InputLabel>
          <FormControl fullWidth>
            <TextFieldForm
              name="phoneNumber"
              // type="number"
              required
              placeholder="電話番号"
            />
          </FormControl>
        </Box>
        <Box width="100%">
          <InputLabel
            sx={styleLabelItemForm}
          >
            メールアドレス
            <RequiredMark />
          </InputLabel>
          <FormControl fullWidth>
            <TextFieldForm
              name="email"
              type="email"
              required
              placeholder="メールアドレス"
            />
          </FormControl>
        </Box>
      </BoxItemFormOrder>
    </>
  );
}
