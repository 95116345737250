export const USER_STATUS = {
  DISABLED: 0,
  ENABLED: 1,
  PHONE_NUMBER_UNAPPROVED: 10,
  INITIAL_PASSWORD_UNCHANGED: 11,
  PHONE_NUMBER_CHANGED_UNAPPROVED: 12,
};
export const TMP_USER_STATUS = {
  UNAPPROVED: 0,
  APPROVED: 1,
};
export const CREATE_USER_STATUS = {
  SUCCESS: 200,
  PHONE_ALREADY_EXISTS: 597,
  EMAIL_ALREADY_EXISTS: 598,
  OTHER_ERRORS: 599,
};
export const RESET_PASSWORD_TOKEN_RESULT_CODE = {
  SUCCESS: 200,
  USER_NOT_EXISTS: 598,
  OTHER_ERRORS: 599,
};
export const RESET_PASSWORD_EMAIL_STATUS = {
  SUCCESS: 200,
  OTHER_ERRORS: 599,
};
export const RESET_PASSWORD_STATUS = {
  SUCCESS: 200,
  TOKEN_EXPIRED: 597,
  TOKEN_INVALID: 598,
  OTHER_ERRORS: 599,
};
// 予約ページ外部のトップページのURL
// TODO 9月下旬に変更する
export const COMMON_TOP_URL = "https://benjamin.co.jp/";
export const COMMON_CONTACT_URL = "https://benelic.com/contact/corporation/";
export const tabMyPageKey = {
  FAVORITE: "favorite",
  HIDDEN: "hidden",
  MY_ACCOUNT: "myAccount",
  HISTORY: "history",
  ORDER_HISTORY: "orderHistory"
};
export const TAB_MY_PAGE = [
  { label: "お気に入り", value: tabMyPageKey.FAVORITE, },
  { label: "非表示 ", value: tabMyPageKey.HIDDEN },
  // { label: "注文履歴", value: tabMyPageKey.ORDER_HISTORY },
  { label: "マイページ", value: tabMyPageKey.MY_ACCOUNT},
  // { label: "購入履歴", value: tabMyPageKey.HISTORY },
];
export const MAX_FAVORITE = 100;
export const MAX_HIDDEN = 100;
export const YYYYMMDD = 'YYYY.MM.DD'
export const YYYYMMDD_HH_MM = 'YYYY/MM/DD hh:mm'
export const FAVORITE_IMAGES_KEY = "favoriteImages"
export const HIDDEN_IMAGES_KEY = "hiddenImages"
export const CART_KEY = "cartItems"
export const NOT_SUCCESS_RESPONSE_ERROR = "処理に失敗しました。";
export const CONTACT_TYPES = [
  {value: "写真販売について", label: "写真販売について"},
  {value: "商品の発送について", label: "商品の発送について"},
  {value: "その他", label: "その他"},
];
export const UPDATE_STATUS_PAYMENT = {
  PAYMENT_NOT_MATCHED : "PAYMENT_NOT_MATCHED",
  PAYMENT_NOT_CONFIRM : "PAYMENT_NOT_CONFIRM",
  STRIPE_ERROR : "STRIPE_ERROR",
};