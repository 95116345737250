import { Outlet } from "react-router-dom";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import { CssBaseline, ThemeProvider } from "@mui/material";
import MainLayout from "@/features/guest/Layout/MainLayout";
import AuthRoutes from "@/features/guest/auth/routes/index";
import UserRoutes from "@/features/guest/routes/index";
import "@/features/guest/styles/global.css";
import { createThemeExtendVarCss } from "@/features/guest/styles/theme/create-theme";
import THEME from "@/features/guest/theme";
import AuthContext from "@/common/context/AuthContext";
import { useContext } from "react";
import ProtectedRoute from "@/features/guest/auth/routes/components/ProtectedRoute";
import PublicRoute from "@/features/guest/auth/routes/components/PublicRoute";
import PublicRoutes from "@/common/routes";
/**
 * イベントユーザ画面のルート
 * @class
 */
function APP() {
  const theme = createThemeExtendVarCss();
  const { isUserEventSignedIn } = useContext(AuthContext);
  return (
    <ThemeProvider theme={THEME}>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <MainLayout isAuthen={isUserEventSignedIn}>
          <Outlet />
        </MainLayout>
      </CssVarsProvider>
    </ThemeProvider>
  );
}
const EVENT_USER_ROUTES = [
  {
    path: "/",
    element: <APP />,
    children: [
      {
        path: "/",
        element: <ProtectedRoute />,
        children: [{ path: "/guest/*", element: <UserRoutes /> }],
      },
      {
        path: "/",
        element: <PublicRoute />,
        children: [{ path: "/auth/*", element: <AuthRoutes /> }],
      },
      {
        path: "/",
        children: [{ path: "/*", element: <PublicRoutes /> }],
      },
    ],
  },
];
export default EVENT_USER_ROUTES;
