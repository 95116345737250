import { useContext, useState } from "react";
import { resetPassword, confirmResetPassword } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import LayoutContext from "@/features/guest/auth/layout-context";
import { TLoginForm } from "@/features/guest/auth/constant/constant";
import ConfirmResetPasswordForm from "./components/ConfirmResetPasswordForm";
import ResetPasswordForm from "./components/ResetPasswordForm";
enum ForgotPassword_State {
  resetPassword = "RESET_PASSWORD",
  confirmResetPassword = "CONFIRM_RESET_PASSWORD_WITH_CODE",
}
function ForgotPassword() {
  const navigate = useNavigate();
  const { setLoading } = useContext(LayoutContext);
  const [state, setState] = useState<ForgotPassword_State>(
    ForgotPassword_State.resetPassword
  );
  const [username, setUsername] = useState<string>("");
  const onResetPassword = async (values: TLoginForm) => {
    const { isPasswordReset, nextStep } = await resetPassword({
      username: values.email,
    });
    if (
      !isPasswordReset &&
      nextStep.resetPasswordStep === ForgotPassword_State.confirmResetPassword
    ) {
      setUsername(values.email);
      setState(ForgotPassword_State.confirmResetPassword);
    }
  };
  const onConfirmResetPassword = async (values: TLoginForm ) => {
    await confirmResetPassword({
      confirmationCode: values?.code as string,
      newPassword: values?.passcode as string,
      username,
    });
    navigate("/auth/login");
    setLoading(false);
  };
  switch (state) {
  case ForgotPassword_State.confirmResetPassword:
    return username ? (
      <ConfirmResetPasswordForm
        onSuccess={onConfirmResetPassword}
        username={username}
      />
    ) : (
      <></>
    );
  default:
    return <ResetPasswordForm onSuccess={onResetPassword} />;
  }
}
export default ForgotPassword;
