import React from "react";
import {Box, FormControl, InputLabel, Typography} from "@mui/material";
import TextFieldForm from "@/common/components/TextField/TextFieldForm";
import {RequiredMark} from "./RequiredMark";
export function OrderFormFields() {
  return (
    <>
      {/**/}
      <Box display="flex" flexDirection="column" width="100%" sx={{gap: '20px', marginBottom: '20px'}}>
        <Typography fontSize="16px" fontWeight={600}> 氏名</Typography>
        <Box sx={{display: "flex", flexDirection: {
          xs:"column",
          sm: "row"
        }, gap: {
          xs: '16px',
          sm: '19px'}
        }} width="100%">
          <Box width="100%">
            <InputLabel sx={{ fontWeight: 600, fontSize: 13, color:"#757575" }}>
              お名前
              <RequiredMark />
            </InputLabel>
            <FormControl fullWidth>
              <TextFieldForm
                name="name"
                placeholder="写真 太郎"
                inputProps={{ maxLength: 50 }}
              />
            </FormControl>
          </Box>
          <Box width="100%">
            <InputLabel  sx={{ fontWeight: 600, fontSize: 13, color:"#757575" }}>
              フリガナ
              <RequiredMark />
            </InputLabel>
            <FormControl fullWidth>
              <TextFieldForm
                name="kana"
                placeholder="シャシン タロウ"
                inputProps={{ maxLength: 50 }}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
    </>
  );
}
