import { Route, Routes } from "react-router-dom";
import Login from "@/features/guest/auth/login/index";
import { LayoutContextProvider } from "@/features/guest/auth/layout-context";
import AuthCommon from "../authCommon";
/**
 * イベントユーザーログイン画面のルーティング
 * @class
 */
function AuthRoutes() {
  return (
    <LayoutContextProvider>
      <Routes>
        <Route
          path="/login"
          element={
            <AuthCommon>
              <Login />
            </AuthCommon>
          }
        />
      </Routes>
    </LayoutContextProvider>
  );
}
export default AuthRoutes;
