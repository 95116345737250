import { Controller } from "react-hook-form";
import RequiredInputField from "@/features/member/auth/MyAccount/components/RequiredInputField";
import type { FormSectionProps } from "@/features/member/auth/MyAccount/types";
import { Grid, InputLabel } from "@mui/material";
import { RequiredMark } from "@/features/guest/pages/order/components/RequiredMark";
export default function NameFields({ control, errors }: FormSectionProps) {
  return (
    <Grid
      container
      spacing={2}
      marginLeft="0"
      width="100%"
    >
      <Grid item md={12} xs={12} fontSize="24px" fontWeight="bold">
        お客様の情報を入力してください。
      </Grid>
      <Grid item md={12} xs={12} fontSize="16px" fontWeight="bold">
        氏名
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid item md={3} xs={12}>
          <InputLabel sx={{ fontSize: 13 }}>
            お名前
            <RequiredMark />
          </InputLabel>
        </Grid>
        <Grid item md={12} xs={12}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <RequiredInputField
                label=""
                {...field}
                isChipStatus={false}
                name="name"
                error={errors}
                placeholder="例）山田 太郎"
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid item md={3} xs={12}>
          <InputLabel sx={{ fontSize: 13 }}>
            フリガナ
            <RequiredMark />
          </InputLabel>
        </Grid>
        <Grid item md={12} xs={12}>
          <Controller
            name="furigana_name"
            control={control}
            render={({ field }) => (
              <RequiredInputField
                label=""
                {...field}
                isChipStatus={false}
                name="furigana_name"
                error={errors}
                placeholder="例）ヤマダ タロウ"
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
