import { Typography, TypographyProps } from "@mui/material";
interface CustomTypographyProps extends TypographyProps {
    text: string;
}
/**
 * カスタムテキスト
 * @param {CustomTypographyProps} props - テキストのプロパティ
 * @class
 */
function CustomTypography({ text, ...props }: CustomTypographyProps) {
  return <Typography {...props}>{text}</Typography>;
}
export default CustomTypography;
