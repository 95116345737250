import { OrderFormData } from "@/common/utils/storage/types.ts";
import { User } from "@/common/types/user.ts";
import Logger from "@/common/utils/Logger.ts";
/**
 *
 * @param savedData
 * @param userInfo
 */
export const getInitialValues = (
  savedData: Partial<OrderFormData>,
  userInfo: User
) => {
  Logger.logger(`savedData: ${JSON.stringify(savedData)}`);
  const hasAnyField: boolean = Boolean(
    savedData &&
      (savedData.name ||
        savedData.furigana ||
        savedData.postCode ||
        savedData.address ||
        savedData.streetAddress ||
        savedData.buildingAddress ||
        savedData.prefecture_code ||
        savedData.phoneNumber ||
        savedData.email)
      // savedData.coupon_id
  );
  Logger.logger(`hasAnyField: ${hasAnyField}`);
  if (hasAnyField) {
    return {
      name: savedData?.name || "",
      nameFurigana: savedData?.furigana || "",
      code: savedData?.postCode || "",
      address: savedData?.address || "",
      streetAddress: savedData?.streetAddress || "",
      building: savedData?.buildingAddress || "",
      province: savedData?.prefecture_code || undefined,
      phoneNumber: savedData?.phoneNumber || "",
      email: savedData?.email || "",
      // coupon: savedData?.coupon_id?.toString() || '',
    };
  }
  if (userInfo.id) {
    return {
      name: userInfo.name || "",
      nameFurigana: userInfo.furigana_name || "",
      code: userInfo.post_code || "",
      address: userInfo.address || "",
      streetAddress: userInfo.street_address || "",
      building: userInfo.building_address || "",
      province: userInfo.prefecture_code || undefined,
      phoneNumber: userInfo.phone_number || "",
      email: userInfo.email || "",
    };
  }
  return {
    name: "",
    nameFurigana: "",
    code: "",
    address: "",
    streetAddress: "",
    building: "",
    province: undefined,
    phoneNumber: "",
    email: "",
  };
};
export const styleLabelItemForm = { fontWeight: 600, fontSize: 13, color: "#757575" }