import { Box, Card, Container, Stack, Typography } from "@mui/material";
// import Breadcumbs from "@/common/components/Breadcumbs/Breadcumbs";
// import { paths } from "@/common/constant/paths";
interface ListItem {
  title: string;
  description: string;
  description2?: string;
  description3?: string;
}
const listItem = [
  {
    title: "1: イベントパスワードの準備",
    description:
      "配布されたチラシなどで、所属名とイベントパスワードをご用意ください。",
  },
  {
    title: "2: ログイン",
    description:
      "ログイン画面へアクセスし、「所属名」「イベントパスワード」を入力します。",
    description2:
      "※販売期間が設定されている場合、期間外にログインしても注文できません。",
  },
  {
    title: "3: 写真の選定",
    description: "写真一覧で、注文したい商品をカートに入れていきます。",
    description2: "写真をクリックすると、画像が拡大表示されます。",
  },
  {
    title: "4: サイズ・数量の設定",
    description:
      "選定した「写真の商品（プリントサイズ）」と「数量」を選定します。商品・数量の変更に合わせて小計も表示されます。",
    description2:
      "注文をやめたい場合は、削除ボタンをクリックするとリストから除外できます。",
  },
  {
    title: "5: お届け先入力・お支払方法の選択",
    description:
      "ご注文する写真が決定したら、お届け先住所の入力と、お支払い方法の選択を行います。",
    description2:
      "お支払方法と配送方法は、ご利用ガイドにて必ずご確認ください。",
  },
  {
    title: "6: 注文完了",
    description: "すべて入力後、ご注文内容確認画面へ進みます。",
    description2:
      "内容に間違いがなければ、「ご注文を確定する」ボタンをクリックし、ご注文完了となります。",
    description3:
      "ご注文完了後、注文内容の確認メールが入力されたメールアドレスへ自動配信されます。",
  },
] satisfies ListItem[];
function UsageGuide() {
  return (
    <div>
      {/* <Breadcumbs
        breadcrumbs={[
          { title: "トップ", href: paths.folder.list },
          { title: "ご利用ガイド" },
        ]}
      /> */}
      <Container maxWidth="md" sx={{ mb: 7, mt: 2 }}>
        <Typography mt={3} variant="h4" textAlign="left" fontWeight="bold">
          ご利用ガイド
        </Typography>
      </Container>
      <Container maxWidth="md" sx={{ mt: 2, mb: 7 }}>
        <Card
          sx={{
            border: "1px solid var(--mui-palette-secondary-300)",
            borderRadius: 1,
          }}
        >
          <Stack marginY={4}
            maxWidth="100%"
            marginX="auto">
            {listItem.map((item) => (
              <Box px={4} pt={5} pb={1}>
                <Box>
                  <Typography fontWeight={600} fontSize={18}>
                    {item.title}
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography color="text.secondary">
                    {item?.description}
                  </Typography>
                  {item?.description2 && (
                    <>
                      <Typography color="text.secondary">
                        {item?.description2}
                      </Typography>
                    </>
                  )}
                  {item?.description3 && (
                    <>
                      <Typography color="text.secondary">
                        {item?.description3}
                      </Typography>
                      <br />
                    </>
                  )}
                </Box>
              </Box>
            ))}
          </Stack>
        </Card>
      </Container>
    </div>
  );
}
export default UsageGuide;
