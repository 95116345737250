import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {Box, Button} from "@mui/material";
import {EyeSlash, Heart} from "@phosphor-icons/react";
import IconClose from "@/assets/icon/IconClose.tsx";
import { TypeResponseListFavorite } from "@/features/member/auth/MyPage/types";
import React from "react";
import {ICartItem} from "@/common/context/CartContext.tsx";
interface ImageCardCardProps {
  image: TypeResponseListFavorite;
  isShowClose?: boolean;
  isShowFavorite?: boolean;
  isShowHide?: boolean;
  isFavorite?: boolean;
  cartItems?: ICartItem[];
  onClickFavorite?: (id: string | number) => void;
  onClickHidden?: (id: string | number) => void;
  onClickCart?: (item: ICartItem) => void;
  onClickClose?: (id: string | number) => void;
}
function IconWraper({
  children,
  ...props
}: {
  children: React.ReactNode;
  onClick?: () => void;
}) {
  return <Box
    sx={{cursor: "pointer"}}
    width={24}
    height={24}
    borderRadius="50%"
    bgcolor="#fff"
    display="flex"
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    {children}
  </Box>
}
function ImageCardComponent({
  image,
  isShowClose,
  isShowFavorite,
  isShowHide,
  isFavorite,
  cartItems,
  onClickClose,
  onClickFavorite,
  onClickHidden,
  onClickCart,
}: ImageCardCardProps) {
  const isInCart = cartItems?.some((cartItem: ICartItem) => cartItem.id === image.id);
  return (
    <Card sx={{height: "100%", borderRadius: '5px'}}>
      <div
        style={{
          display: "flex",
          position: "relative",
          width: "100%",
          height: "auto",
          aspectRatio: '313/236',
          alignItems: "baseline",
          justifyContent: "center",
        }}
      >
        <Box position="relative" height="100%" width="100%">
          <img
            style={{
              cursor: "pointer",
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            src={image?.presigned_url?.[0]}
            alt="[img]"
          />
          <Box position="absolute" top="14px" right="11px" gap={1}>
            {isShowClose && (
              <Button variant="contained" sx={{padding: '9px'}} onClick={() => onClickClose?.(image?.id)}>
                <IconClose width={14} height={14}/>
              </Button>
            )}
            {isShowFavorite && (
              <IconWraper
                onClick={() => onClickFavorite?.(image?.id)}
              >
                {isFavorite ? (
                  <Heart weight="fill" size={16} color="#FC4A6D"/>
                ) : (
                  <Heart size={14}/>
                )}
              </IconWraper>
            )}
            {isShowHide && (
              <IconWraper onClick={() => onClickHidden?.(image.id)}>
                <EyeSlash size={14}/>
              </IconWraper>
            )}
          </Box>
        </Box>
      </div>
      <CardContent sx={{padding: "14px 16px 23px 16px !important"}}>
        <Typography variant="subtitle1" className="truncate-multiline" sx={{fontSize: {
          xs: '15px',
          sm: '20px',
        }}}>{image?.photo}</Typography>
        <Typography variant="body2" color="text.secondary">
          {image?.event}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {image?.folder}
        </Typography>
        <Box marginTop={1.5} display="flex" justifyContent="center">
          <Button
            fullWidth
            variant={isInCart ? "outlined" : "contained"}
            sx={{fontSize: {xs: 12, md: 14}, borderRadius: '6px'}}
            onClick={() => onClickCart?.(image)}
          >
            <Box display="flex" justifyContent="center" gap="4px">
              {isInCart ? "カートから削除する" : "カートに追加する"}{
                <span style={{fontSize: '22px', lineHeight: '22px'}}>+</span>
              }
            </Box>
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
export default ImageCardComponent;
