import IconClose from "@/assets/icon/IconClose";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
type Props = {
  onClose?: () => void;
} & DialogProps;
function PopupDetailsHistory({ onClose, open }: Props) {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          height: "100%",
          width: "100%",
          maxHeight: "350px",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
          p: 0,
          height: "100%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box zIndex="100" display="flex" justifyContent="flex-end" padding={2}>
          <Button
            variant="contained"
            onClick={onClose}
            sx={{
              width: "35px",
              height: "35px",
              padding: 0,
              borderRadius: "6px",
            }}
          >
            <IconClose width={17} height={17} />
          </Button>
        </Box>
        <Box sx={{ px: 6, py: 2 }}>
          <Grid container>
            <Grid item xs={8} sx={{ mb: 1 }}>
              <Box display="flex" gap={2}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 14, color: "#757575" }}
                    >
                      注文日
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={{ fontSize: 14 }}>2024/7/18</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box display="flex" gap={2} mt={1}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 14, color: "#757575" }}
                    >
                      注文枚数
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={{ fontSize: 14 }}>30枚</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box display="flex" gap={2} mt={1}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 14, color: "#757575" }}
                    >
                      注文金額合計
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={{ fontSize: 14 }}>4,500円</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ borderColor: "#757575", mt: 1 }} />
          <Box mt={2}>
            <Typography fontSize={16} color="#222222" fontWeight={600} lineHeight={2}>
              1. AZZZZ0001.jpg 2L版 集合写真 2枚</Typography>
            <Typography fontSize={16} color="#222222" fontWeight={600} lineHeight={2}>
              1. AZZZZ0001.jpg 2L版 集合写真 2枚</Typography>
            <Typography fontSize={16} color="#222222" fontWeight={600}lineHeight={2}>
              1. AZZZZ0001.jpg 2L版 集合写真 2枚</Typography>
            <Typography fontSize={16} color="#222222" fontWeight={600}lineHeight={2} >
              1. AZZZZ0001.jpg 2L版 集合写真 2枚</Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
export default PopupDetailsHistory;
