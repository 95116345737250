import { ICartItem } from "@/common/context/CartContext";
import { CartItem } from "../type";
type PhotoInfo = {
  id: number;
  photo_original_file: string;
  presigned_url: string[];
};
type PricingInfo = {
  pricing_id: number;
  pricing_name: string;
  pricing_price: number;
};
type FolderData = {
  id: number;
  name: string;
  photo: PhotoInfo[];
  pricings: PricingInfo[];
  group_pricing: {
    id: number;
    name: string;
  };
};
export const formatCartItems = (
  cartItems: ICartItem[],
  pricingData: FolderData[]
): CartItem[] => cartItems.map(item => {
  // フォルダーを検索
  const folderData = pricingData.find(folder => 
    folder.photo.some(photo => photo.id === item.id)
  );
    // 写真情報を検索
  const photoInfo = folderData?.photo.find(photo => photo.id === item.id);
  // 価格情報を検索
  const pricingInfo = folderData?.pricings.find(
    pricing => pricing.pricing_id === item.pricing_id
  ) || folderData?.pricings[0];
  return {
    id: String(item.id),
    productName: photoInfo?.photo_original_file || '',
    productSize: pricingInfo ? pricingInfo.pricing_name : '',
    pricingId: pricingInfo ? pricingInfo.pricing_id : '',
    image: photoInfo?.presigned_url[0] || '',
    quantity: item.quantity || 0,
    pricePerItem: pricingInfo?.pricing_price || 0,
    subtotalPrice: (pricingInfo?.pricing_price || 0) * (item.quantity || 0)
  };
}); 