import TOAST_MESSAGE from "@/common/constant/toastMessage";
import { ExternalToast, toast } from "sonner";
const showError = (msg: string = TOAST_MESSAGE.ERROR) => toast.error(msg);
const showSuccess = (msg: string = TOAST_MESSAGE.OK) => toast.success(msg);
const showInfo = (msg: string = TOAST_MESSAGE.INFO, data: ExternalToast = {}) =>
  toast.info(msg, data);
const showPromise = (
  callback: Promise<unknown>,
  okMsg: string = TOAST_MESSAGE.OK,
  errorMsg: string = TOAST_MESSAGE.ERROR,
  loadingMsg: string = TOAST_MESSAGE.LOADING
) =>
  toast.promise(callback, {
    success: okMsg,
    error: errorMsg,
    loading: loadingMsg,
  });
const showLoading = (msg: string = TOAST_MESSAGE.LOADING) => toast.loading(msg);
const closeAll = () => toast.dismiss();
const closeOne = (id: string | number) => toast.dismiss(id);
export default {
  showError,
  showSuccess,
  showInfo,
  showPromise,
  showLoading,
  closeAll,
  closeOne,
};
export const sampleToastFunctions = {
  samplePromiseSuccess: async () => {
    // 3秒待つ
    await new Promise((resolve) => {
      setTimeout(resolve, 3000);
    });
    alert("Sample SetTimeout Success");
  },
  samplePromiseError: async () => {
    await new Promise((resolve) => {
      setTimeout(resolve, 3000);
    });
    alert("Sample SetTimeout Error");
    throw new Error("Sample Error");
  },
  sampleLoadingToOneClose: async () => {
    const toastId = showLoading();
    showSuccess();
    alert(`Loading ID:${toastId}`);
    // 3秒待つ
    await new Promise((resolve) => {
      setTimeout(resolve, 3000);
    });
    alert("end");
    closeOne(toastId);
  },
  sampleLoadingToAllClose: async () => {
    showLoading();
    alert(`start`);
    // 3秒待つ
    await new Promise((resolve) => {
      setTimeout(resolve, 3000);
    });
    showInfo();
    closeAll();
  },
};
