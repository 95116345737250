import TextFieldForm from "@/common/components/TextField/TextFieldForm";
import { FormControl, Grid, Box,InputLabel } from "@mui/material";
import { ConvPrefNameToCode, provinceList } from "@/common/utils/Prefecture";
import SelectForm from "@/common/components/Select/SelectForm";
import { useFormContext } from "react-hook-form";
import {BoxItemFormOrder} from "@/features/guest/pages/order/components/BoxItemFormOrder.tsx";
import {styleLabelItemForm} from "@/features/guest/pages/order/helper.ts";
import Logger from "@/common/utils/Logger";
import { RequiredMark } from "./RequiredMark";
import { BuildingFields } from "./BuildingFields";
/**
 *
 */
export function AddressFields() {
  const { setValue } = useFormContext();
  /**
   *
   * @param value
   */
  const handleChange = async (value: string) => {
    const regex = /^\d{3}-\d{4}$/;
    if (regex.test(value)) {
      try {
        const response = await fetch(
          `https://api.zipaddress.net/?zipcode=${value}`
        );
        const data = await response.json();
        if (data.code === 200) {
          const prefCode = ConvPrefNameToCode(data?.data?.pref);
          setValue("province", prefCode);
          setValue("address", data?.data?.address);
          setValue("streetAddress", "");
          setValue("building", "");
        }
      } catch (error) {
        Logger.logger(error);
      }
    }
  };
  return (
    <>
      <BoxItemFormOrder title="住所">
        <Box width="100%" display="flex" flexDirection="column" gap="8px">
          <Box  sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            gap: {
              xs: "16px",
              sm: "19px",
            },
          }}
          width="100%">
            <Box  width="100%">
              <InputLabel sx={styleLabelItemForm}>
                郵便番号
                <RequiredMark />
              </InputLabel>
              <FormControl fullWidth>
                <TextFieldForm
                  required
                  name="code"
                  placeholder="052-4468"
                  inputProps={{
                    maxLength: 127,
                  }}
                  onChange={(e) => {
                    setValue("code", e.target.value);
                    handleChange(e.target.value);
                  }}
                />
              </FormControl>
            </Box>
            <Box  width="100%">
              <FormControl fullWidth>
                <SelectForm
                  label="都道府県"
                  name="province"
                  styleLabel={styleLabelItemForm}
                  options={provinceList}
                  required
                />
              </FormControl>
            </Box>
          </Box>
          <Grid item md={12} xs={12}>
            <FormControl fullWidth sx={{ mb: 1 }}>
              <InputLabel sx={styleLabelItemForm}>
                住所 <RequiredMark />
              </InputLabel>
              <TextFieldForm
                required
                name="address"
                placeholder="住所"
                inputProps={{
                  maxLength: 127,
                }}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl fullWidth sx={{ mb: 1 }}>
              <InputLabel sx={styleLabelItemForm}>
                住所(丁目番地)
                <RequiredMark />
              </InputLabel>
              <TextFieldForm
                required
                name="streetAddress"
                placeholder="住所(丁目番地)"
                inputProps={{
                  maxLength: 127,
                }}
                fullWidth
              />
            </FormControl>
          </Grid>
          <BuildingFields />
        </Box>
      </BoxItemFormOrder>
    </>
  );
}
