import { Box, Stack, Typography } from "@mui/material";
import { CaretRight } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { paths } from "@/common/constant/paths";
import { ListDataFolder } from "@/features/guest/pages/top/types";
import favicon from "../../../../public/favicon.ico";
type Props = {
  index: number;
  folder: ListDataFolder;
};
function MenuFolderItem({ index, folder }: Props) {
  const navigate = useNavigate();
  return (
    <Box
      padding="10px 15px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        cursor: "pointer",
        borderTop: index !== 1 ? "1px dotted #dedede" : "none",
        borderBottom: "1px solid #dedede",
      }}
      pl={3}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        onClick={() => {
          navigate(paths.folder.detail(folder.id.toString()));
        }}
      > 
        <Box width={16} height={16}>
          <img src={favicon} width="20" height={20} alt="[logo]" />
        </Box>
        <Typography fontSize={14} fontWeight="bold" ml={1}>
          {index}.{folder.name}
        </Typography>
        <Typography whiteSpace="pre" fontSize={12} color="#757575">{`(${folder.total_photo}枚)`}</Typography>
      </Stack>
      <CaretRight size={18} color="#318BBD" fontWeight="bold"/>
    </Box>
  );
}
export default MenuFolderItem;
