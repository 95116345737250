import { RouterLink } from "@/common/components/Link/link";
import { paths } from "@/common/constant/paths";
import { Box, Card, Chip, Stack, Typography } from "@mui/material";
import { Heart } from "@phosphor-icons/react";
import {useContext} from "react";
import appContext from '@/common/context/ImagesContext';
function InfoFolderFavorite() {
  const {totalItemFavorite} = useContext(appContext);
  return (
    <Card>
      <Box
        padding="10px 15px"
        display="flex"
        justifyContent="space-between"
        sx={{
          color: "text.primary",
          ":hover": {
            color: "text.primary",
          },
        }}
        component={RouterLink}
        href={`${paths.myPageTabs.favorite}`}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Heart weight="fill" size={16} color="#FC4A6D" />
          <Typography>お気に入り</Typography>
        </Stack>
        <Chip color="primary" label={`${totalItemFavorite}/100`} size="small" variant="soft" />
      </Box>
    </Card>
  );
}
export default InfoFolderFavorite;
