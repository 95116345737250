import HttpClientEvent from "@/common/api/HttpClientEvent";
import { CREATE_PAYMENT_INTENT } from "@/common/constant/apiConstant";
type RequestDataPayment = {
  payment_intent_id: string;
  client_secrect: string;
};
type ParamsPaymentIntent = {
  amount?: number;
  order_id?: number;
};
export const createPaymentIntent = async (
  data: ParamsPaymentIntent
): Promise<RequestDataPayment> => {
  const response = await HttpClientEvent.Post({
    path: CREATE_PAYMENT_INTENT,
    data,
  });
  return response.data;
};
