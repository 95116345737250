import Logger from "@/common/utils/Logger";
import { AppBar, Button, Typography } from "@mui/material";
type Props = {
  error: Error;
  resetErrorBoundary: () => void;
};
/**
 * エラーが画面が表示されるコンポーネント
 * TODO:エラー画面をきれいにする
 * @param {Props} props
 * @returns {JSX.Element}
 */
function AppErrorFallback({ error, resetErrorBoundary }: Props) {
  const message = error.message || "Crashed!";
  Logger.logger(error, "error");
  return (
    <>
      <AppBar position="static">
        <Typography
          variant="h1"
          sx={{
            color: "red",
            textAlign: "center",
          }}
        >
          エラーが発生しました。
        </Typography>
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
          }}
        >
          {message}
        </Typography>
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
          }}
        >
          システム管理者にお問い合わせください。
        </Typography>
      </AppBar>
      <Button
        type="button"
        onClick={resetErrorBoundary}
        sx={{
          display: "block",
          margin: "0 auto",
          height: 100,
        }}
      >
        Try again
      </Button>
    </>
  );
}
export default AppErrorFallback;
