import {  Card, CardContent, Divider, Typography } from "@mui/material";
import { PropertyList} from "@/features/guest/pages/confirm/components";
import { PropertyItem } from "./PropertyItem";
import {PaymentMethod} from "../../payment/types";
interface PaymentMethodListProps {
    methods: PaymentMethod[];
}
/**
 *
 * @param root0
 * @param root0.methods
 */
function PaymentMethodList({ methods }: PaymentMethodListProps) {
  return (
    <Card>
      <CardContent>
        <PropertyList
          divider={<Divider />}
          sx={{ "--PropertyItem-padding": "12px 24px" }}
        >
          <PropertyItem
            key="header"
            name="名称"
            value={
              <Typography variant="subtitle2" color="text.primary">
                          手数料・配送方法・利用条件
              </Typography>
            }
            third={
              <Typography variant="subtitle2" color="text.primary">説明</Typography>
            }
          />
          {methods.map((method) => (
            <PropertyItem
              key={method.id}
              name={method.name}
              value={
                <Typography variant="subtitle2">
                  {`手数料:${method.fee}円`}
                </Typography>
              }
              third={method.explaination}
            />
          ))}
        </PropertyList>
      </CardContent>
    </Card>
  );
}
export default PaymentMethodList;
