import { Outlet } from "react-router-dom";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import { CssBaseline, ThemeProvider } from "@mui/material";
import MainLayout from "@/features/guest/Layout/MainLayout";
import MemberRoutes from "@/features/member/auth/routes/index";
import { createThemeExtendVarCss } from "@/features/guest/styles/theme/create-theme";
import "@/features/guest/styles/global.css";
import THEME from "@/features/guest/theme";
import AuthContext from "@/common/context/AuthContext";
import { useContext } from "react";
/**
 * イベントユーザ画面のルート
 * @class
 */
function APP() {
  const theme = createThemeExtendVarCss();
  const { isUserEventSignedIn } = useContext(AuthContext);
  return (
    <ThemeProvider theme={THEME}>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <MainLayout isAuthen={isUserEventSignedIn}>
          <Outlet />
        </MainLayout>
      </CssVarsProvider>
    </ThemeProvider>
  );
}
const MEMBER_ROUTES = [
  {
    path: "/",
    element: <APP />,
    children: [{ path: "/member/*", element: <MemberRoutes /> }],
  },
];
export default MEMBER_ROUTES;
