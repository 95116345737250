import { NavItemConfig } from "@/common/types/nav";
import { Box, Chip, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { ArrowSquareOut as ArrowSquareOutIcon } from "@phosphor-icons/react/dist/ssr/ArrowSquareOut";
import { CaretDown as CaretDownIcon } from "@phosphor-icons/react/dist/ssr/CaretDown";
import { CaretRight as CaretRightIcon } from "@phosphor-icons/react/dist/ssr/CaretRight";
import DropdownItem from "../DropDownItem";
import NavItem from "../NavItem";
import NavItemMobile from "../NavItemMobile";
import { NavItemAttributesProps } from "../type";
function renderDropdownItems({
  items = [],
  pathname,
}: {
  items?: NavItemConfig[];
  pathname: string;
}): React.JSX.Element {
  return (
    <Stack
      component="ul"
      spacing={1}
      sx={{ listStyle: "none", padding: 0, margin: 0 }}
    >
      {items.map((item) => (
        <DropdownItem pathname={pathname} {...item} />
      ))}
    </Stack>
  );
}
function renderNavItems({
  items = [],
  pathname,
}: {
  items?: NavItemConfig[];
  pathname: string;
}): React.JSX.Element {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const children = items.map(({ key, ...curr }: NavItemConfig) => (
    <NavItem pathname={pathname} key={key} {...curr} />
  ));
  return (
    <Stack
      component="ul"
      direction="row"
      spacing={isXs ? 0.5 : 2}
      width="100%"
      sx={{
        listStyle: "none",
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: isXs ? "space-between": "flex-start",
      }}
    >
      {children}
    </Stack>
  );
}
function renderNavGroups({
  items = [],
  pathname,
}: {
  items?: NavItemConfig[];
  pathname: string;
}): React.JSX.Element {
  const children = items.map((curr: NavItemConfig) => (
    <Box component="li" key={curr.key} sx={{ flex: "0 0 auto" }} width="100%">
      {renderNavItems({ items: curr.items, pathname })}
    </Box>
  ));
  return (
    <Stack
      component="ul"
      direction="row"
      spacing={2}
      sx={{ listStyle: "none", margin: 0, pt: 1 }}
    >
      {children}
    </Stack>
  );
}
function renderNavItemsMobile({
  depth = 0,
  items = [],
  onClose,
  pathname,
}: {
  depth: number;
  items?: NavItemConfig[];
  onClose?: () => void;
  pathname: string;
}): React.JSX.Element {
  const children = items.reduce(
    (acc: React.ReactNode[], curr: NavItemConfig): React.ReactNode[] => {
      const { items: childItems, key, ...item } = curr;
      const forceOpen = childItems
        ? Boolean(
          childItems.find(
            (childItem) =>
              childItem.href && pathname.startsWith(childItem.href)
          )
        )
        : false;
      acc.push(
        <NavItemMobile
          depth={depth}
          forceOpen={forceOpen}
          key={key}
          onClose={onClose}
          pathname={pathname}
          {...item}
        >
          {childItems
            ? renderNavItemsMobile({
              depth: depth + 1,
              items: childItems,
              onClose,
              pathname,
            })
            : null}
        </NavItemMobile>
      );
      return acc;
    },
    []
  );
  return (
    <Stack
      component="ul"
      data-depth={depth}
      spacing={1}
      sx={{ listStyle: "none", m: 0, p: 0 }}
    >
      {children}
    </Stack>
  );
}
function renderNavGroupsMobile({
  items,
  onClose,
  pathname,
}: {
  items: NavItemConfig[];
  onClose?: () => void;
  pathname: string;
}): React.JSX.Element {
  const children = items.reduce(
    (acc: React.ReactNode[], curr: NavItemConfig): React.ReactNode[] => {
      acc.push(
        <Stack component="li" key={curr.key} spacing={1.5}>
          {curr.title ? (
            <div>
              <Typography
                sx={{
                  color: "var(--NavGroup-title-color)",
                  fontSize: "0.875rem",
                  fontWeight: 500,
                }}
              >
                {curr.title}
              </Typography>
            </div>
          ) : null}
          <div>
            {renderNavItemsMobile({
              depth: 0,
              items: curr.items,
              onClose,
              pathname,
            })}
          </div>
        </Stack>
      );
      return acc;
    },
    []
  );
  return (
    <Stack component="ul" spacing={2} sx={{ listStyle: "none", m: 0, p: 0 }}>
      {children}
    </Stack>
  );
}
function getNavItemAttributesMobile({
  isBranch,
  href,
  external,
  onClose,
  handleToggle,
  handleKeyUp,
  RouterLink,
}: NavItemAttributesProps) {
  if (isBranch) {
    return {
      onClick: handleToggle,
      onKeyUp: handleKeyUp,
      role: "button",
    };
  }
  if (href) {
    return {
      component: external ? "a" : RouterLink,
      href,
      target: external ? "_blank" : undefined,
      rel: external ? "noreferrer" : undefined,
      onClick: () => onClose?.(),
    };
  }
  return { role: "button" };
}
const renderLabelChip = (label?: string) =>
  label && <Chip color="primary" label={label} size="small" />;
const renderExternalIcon = (external?: boolean) =>
  external && (
    <Box sx={{ display: "flex", flex: "0 0 auto" }}>
      <ArrowSquareOutIcon
        color="var(--NavItem-icon-color)"
        fontSize="var(--icon-fontSize-sm)"
      />
    </Box>
  );
const renderExpandIcon = ({
  isBranch,
  open,
}: {
  isBranch?: boolean;
  open: boolean;
}) => {
  const ExpandIcon = open ? CaretDownIcon : CaretRightIcon;
  return (
    isBranch && (
      <Box sx={{ display: "flex", flex: "0 0 auto" }}>
        <ExpandIcon
          color="var(--NavItem-expand-color)"
          fontSize="var(--icon-fontSize-sm)"
        />
      </Box>
    )
  );
};
export {
  renderDropdownItems,
  renderNavItems,
  renderNavGroups,
  renderNavGroupsMobile,
  getNavItemAttributesMobile,
  renderLabelChip,
  renderExternalIcon,
  renderExpandIcon,
};
