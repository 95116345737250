import {Stack, Grid, Typography, Box, Link} from '@mui/material';
import {LineItemsTableProps} from "@/features/guest/pages/confirm/components/LineItemsTable";
import * as React from "react";
import {CartItem} from '../type';
export function ItemsCartMobile(props: LineItemsTableProps) {
  const {rows} = props;
  return (
    rows.map((row: CartItem) => (
      <Stack>
        <Grid container>
          <Grid xs={4} item sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 1,
            py: 1,
            backgroundColor: "#F7F7F7",
            border: "solid 1px #EBEBEB",
            borderRight: 0,
            borderBottom: 0
          }}>
            <Typography fontSize="14px" variant="subtitle2">ファイル</Typography>
          </Grid>
          <Grid item xs={8} sx={{
            display: 'flex',
            p: 1,
            justifyContent: 'center',
            border: "solid 1px #EBEBEB",
            borderLeft: 0,
            borderBottom: 0
          }}>
            <Box
              sx={{
                backgroundImage: `url(${row.image})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                bgcolor: 'var(--mui-palette-background-level2)',
                flex: '0 0 auto',
                height: '85px',
                width: '100%',
                maxWidth: 147,
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={4} item sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 1, py: 1,
            backgroundColor: "#F7F7F7",
            border: "solid 1px #EBEBEB",
            borderRight: 0,
            borderBottom: 0
          }}>
            <Typography fontSize="14px" variant="subtitle1">ファイル名/<br/>サイズ</Typography>
          </Grid>
          <Grid xs={8} item sx={{
            border: "solid 1px #EBEBEB",
            borderLeft: 0,
            borderBottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
            textAlign: "center",
          }}>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              <Link color="text.primary" variant="h6" fontWeight={600}>
                <Box>{row.productName}</Box>
                <Box>{row.productSize}</Box>
              </Link>
            </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={4} item sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 1,
            py: 1,
            backgroundColor: "#F7F7F7",
            border: "solid 1px #EBEBEB",
            borderRight: 0,
            borderBottom: 0
          }}>
            <Typography fontSize="14px" variant="subtitle1">数量</Typography>
          </Grid>
          <Grid xs={8} item sx={{
            border: "solid 1px #EBEBEB",
            borderLeft: 0,
            borderBottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
            textAlign: "center",
          }}>
            <Typography variant="h6" fontWeight={600}>{row.quantity}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={4} item sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 1,
            py: 1,
            backgroundColor: "#F7F7F7",
            border: "solid 1px #EBEBEB",
            borderRight: 0,
            borderBottom: 0
          }}>
            <Typography fontSize="14px" variant="subtitle1">単価</Typography>
          </Grid>
          <Grid xs={8} item sx={{
            border: "solid 1px #EBEBEB",
            borderLeft: 0,
            borderBottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
            textAlign: "center",
          }}>
            <Typography variant="h6" fontWeight={600}>{row.pricePerItem}円</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={4} item sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 1,
            py: 1,
            backgroundColor: "#F7F7F7",
            border: "solid 1px #EBEBEB",
            borderRight: 0
          }}>
            <Typography fontSize="14px" variant="subtitle1">全額（税込）</Typography>
          </Grid>
          <Grid xs={8} item sx={{
            border: "solid 1px #EBEBEB",
            borderLeft: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
            textAlign: "center",
          }}>
            <Typography variant="h6" fontWeight={600}>{row.subtotalPrice}円</Typography>
          </Grid>
        </Grid>
      </Stack>
    ))
  )
}