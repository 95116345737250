import React from "react";
import {Box, FormControl, InputLabel} from "@mui/material";
import TextFieldForm from "@/common/components/TextField/TextFieldForm";
import {BoxItemFormOrder} from "@/features/guest/pages/order/components/BoxItemFormOrder.tsx";
import {styleLabelItemForm} from "@/features/guest/pages/order/helper.ts";
import {RequiredMark} from "./RequiredMark";
export function OrderFormFields() {
  return (
    <>
      <BoxItemFormOrder title="氏名">
        <Box width="100%">
          <InputLabel
            sx={styleLabelItemForm}
          >
              お名前
            <RequiredMark />
          </InputLabel>
          <FormControl fullWidth>
            <TextFieldForm
              name="name"
              placeholder="お名前"
              inputProps={{ maxLength: 50 }}
            />
          </FormControl>
        </Box>
        <Box width="100%">
          <InputLabel
            sx={styleLabelItemForm}
          >
        フリガナ
            <RequiredMark />
          </InputLabel>
          <FormControl fullWidth>
            <TextFieldForm
              name="nameFurigana"
              placeholder="フリガナ"
              inputProps={{ maxLength: 50 }}
            />
          </FormControl>
        </Box>
      </BoxItemFormOrder>
    </>
  );
}
