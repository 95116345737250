import { useState } from "react";
import HttpClientEvent from "@/common/api/HttpClientEvent";
import { GET_LIST_EVENT_PRICING } from "@/common/constant/apiConstant";
const useGetPriceList = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState<number>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const getGetListPricing = async (params = {}) => {
    setLoading(true);
    try {
      const response = await HttpClientEvent.Get({
        path: GET_LIST_EVENT_PRICING,
        params,
      });
      setTotal(response.data.total);
      setData(response.data.data);
      return response.data;
    } catch (error) {
      setError((error as Error).message);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  return {
    data,
    total,
    error,
    loading,
    getGetListPricing,
  };
};
export default useGetPriceList;
