import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import { CaretRight as CaretRightIcon } from "@phosphor-icons/react/dist/ssr";
import { NavItemConfig } from "@/common/types/nav";
import { Dropdown } from "../Dropdown/Dropdown";
import { DropdownPopover } from "../Dropdown/DropdownPopover";
import { DropdownTrigger } from "../Dropdown/DropdownTrigger";
import { RouterLink } from "../Link/link";
import { renderDropdownItems } from "./ultil";
import isNavItemActive from "./IsNavItemActive";
interface DropdownItemProps extends NavItemConfig {
  pathname: string;
}
function DropdownItem({
  disabled,
  external,
  items,
  href,
  matcher,
  pathname,
  title,
}: DropdownItemProps): React.JSX.Element {
  const active = isNavItemActive({
    disabled,
    external,
    href,
    matcher,
    pathname,
  });
  const isBranch = Boolean(items);
  const listItemStyles = {
    display: "flex",
    alignItems: "center",
    padding: "6px 16px",
    borderRadius: 1,
    cursor: disabled ? "not-allowed" : "pointer",
    color: "var(--NavItem-color)",
    backgroundColor: active
      ? "var(--mui-palette-action-selected)"
      : "transparent",
    "&:hover": {
      backgroundColor:
        !disabled && !active ? "var(--mui-palette-action-hover)" : undefined,
      color:
        !disabled && !active ? "var(--mui-palette-action-color)" : undefined,
    },
  };
  /**
   *
   */
  const getComponentProps = () => {
    if (isBranch) {
      return { role: "button" };
    }
    if (href) {
      return {
        component: external ? "a" : RouterLink,
        href,
        target: external ? "_blank" : undefined,
        rel: external ? "noreferrer" : undefined,
      };
    }
    return { role: "button" };
  };
  /**
   *
   */
  const renderElement = () => (
    <Box component="li" sx={{ userSelect: "none" }}>
      <Box {...getComponentProps() as BoxProps} sx={listItemStyles} tabIndex={0}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            component="span"
            sx={{ fontSize: "0.875rem", fontWeight: 500, lineHeight: "28px" }}
          >
            {title}
          </Typography>
        </Box>
        {isBranch && (
          <Box sx={{ flexShrink: 0 }}>
            <CaretRightIcon fontSize="var(--icon-fontSize-sm)" />
          </Box>
        )}
      </Box>
    </Box>
  );
  if (isBranch) {
    return (
      <Dropdown>
        <DropdownTrigger>{renderElement()}</DropdownTrigger>
        <DropdownPopover
          PaperProps={{ sx: { minWidth: "200px", padding: 1 } }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          {renderDropdownItems({ pathname, items })}
        </DropdownPopover>
      </Dropdown>
    );
  }
  return renderElement();
}
export default DropdownItem;
